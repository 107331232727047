import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import moment from 'moment'
import { Dispatch } from 'redux'

const initialState: { mrssJobLastRun: Record<string, any> } = {
  mrssJobLastRun: {}
}

const slice = createSlice({
  name: 'mrss',
  initialState: initialState,
  reducers: {
    triggerMrssJobSuccess(state, action) {
      const { businessId } = action.payload
      state.mrssJobLastRun[businessId] = moment().unix()
    }
  }
})
export default slice.reducer

export const { triggerMrssJobSuccess } = slice.actions

const triggerMrssJobRequest = createAction('mrss/triggerMrssJobRequest')
const triggerMrssJobFailure = createAction('mrss/triggerMrssJobFailure')

export function triggerMrssJob(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(triggerMrssJobRequest())

      const res = await api.post(`/bus/${businessId}/mrss_feed_extraction/run`)
      dispatch(triggerMrssJobSuccess({ businessId }))

      return res
    } catch (error) {
      dispatch(triggerMrssJobFailure())

      return error
    }
  }
}
