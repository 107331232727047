import React from 'react'

import { css } from '@emotion/react'
import { Flex } from '@src/components/EmotionLayout'
import { Input, Switch, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

export interface Props {
  handleSwitch: (checked: boolean) => void
  handleTextArea: (e) => void
  text: string
  collect: 'required' | 'optional'
}

const Question: React.FC<Props> = ({
  handleSwitch,
  handleTextArea,
  text,
  collect
}): JSX.Element => {
  const { TextArea } = Input
  const { t } = useTranslation()

  return (
    <Flex justifyContent="center" alignItems="center" flex={1}>
      <Flex
        flex={1}
        flexDirection="column"
        css={css`
          .ant-input-textarea-show-count::after {
            margin-right: 5px;
            margin-top: -25px;
            position: relative;
            z-index: 20;
          }
        `}
      >
        <TextArea
          showCount
          maxLength={50}
          value={text}
          autoSize={{ minRows: 3, maxRows: 3 }}
          onChange={handleTextArea}
        />
        <Flex justifyContent="space-between" mt="10px">
          {t('Collect responder’s email')}
          <Switch checked={collect ? true : false} onChange={handleSwitch} />
        </Flex>
        <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
          {t('After people answered the question, ask for their email')}
        </Typography.Paragraph>
      </Flex>
    </Flex>
  )
}

export default Question
