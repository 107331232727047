import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { ROLES } from '@src/constants'
import { Dispatch } from 'redux'

type IProps = {
  memberIdsByBusiness: Record<string, string[]>
  members: Record<string, any>
  paging: Record<string, globalLib.Paging>
  memberLiteMetricsByBusinessId: Record<string, any>
  pendingInvitations: Record<string, any>
}

const initialState: IProps = {
  memberIdsByBusiness: {},
  members: {},
  paging: {},
  memberLiteMetricsByBusinessId: {},
  pendingInvitations: {}
}

const slice = createSlice({
  name: 'membership',
  initialState: initialState,
  reducers: {
    fetchMembersSuccess(state, action) {
      const { businessId, members, paging, page } = action.payload
      members.forEach((member) => {
        state.members[member.id] = member
      })
      state.paging[businessId] = paging
      if (page) {
        members.forEach((member) => {
          state.memberIdsByBusiness[businessId].push(member.id)
        })
      } else {
        state.memberIdsByBusiness[businessId] = members.map(
          (member) => member.id
        )
      }
    },
    updateMemberSuccess(state, action) {
      const { member } = action.payload
      state.members[member.id] = member
    },
    deleteMemberSuccess(state, action) {
      const { businessId, memberId } = action.payload
      const memberIds = state.memberIdsByBusiness[businessId]
      state.memberIdsByBusiness[businessId] = memberIds.filter(
        (id) => id !== memberId
      )
    },
    fetchMemberLiteMetricsSuccess(state, action) {
      const { data, businessId } = action.payload
      state.memberLiteMetricsByBusinessId[businessId] =
        data.member_lite_insights
    },
    fetchPendingInvitationsSuccess(state, action) {
      const { data, businessId } = action.payload
      state.pendingInvitations[businessId] = data
    }
  }
})

export default slice.reducer

export const {
  fetchMembersSuccess,
  updateMemberSuccess,
  deleteMemberSuccess,
  fetchMemberLiteMetricsSuccess,
  fetchPendingInvitationsSuccess
} = slice.actions

const fetchMembersRequest = createAction('membership/fetchMembersRequest')
const fetchMembersFailure = createAction('membership/fetchMembersFailure')

export function fetchMembers(
  businessId: string,
  page?: string,
  pageSize?: number,
  q?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchMembersRequest())
      const response = await api.get(page || `/bus/${businessId}/members`, {
        params: {
          page_size: pageSize,
          q
        }
      })
      const { members, paging } = response.data
      dispatch(fetchMembersSuccess({ businessId, members, paging, page }))

      return response
    } catch (error) {
      dispatch(fetchMembersFailure())

      return error
    }
  }
}

const createMemberRequest = createAction('membership/createMemberRequest')
const createMemberSuccess = createAction('membership/createMemberSuccess')
const createMemberFailure = createAction('membership/createMemberFailure')

export function createMember(businessId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createMemberRequest())
      const request: Record<string, any> = {
        role: data.role
      }

      if (data.email) request.email = data.email
      if (data.phone) request.phone = data.phone
      if (data.newChannel && data.role === ROLES.MEMBER_LITE)
        request.channel_name = data.newChannel
      if (data.aggId && data.role === ROLES.MEMBER_LITE)
        request.aggregator_channel_id =
          data.aggId === 'default' ? undefined : data.aggId
      if (
        !data.newChannel &&
        data.channelSelection &&
        data.role === ROLES.MEMBER_LITE
      )
        request.channel_id = data.channelSelection

      const response = await api.post(`/bus/${businessId}/members`, request)
      dispatch(createMemberSuccess())

      return response
    } catch (error) {
      dispatch(createMemberFailure())

      return error
    }
  }
}

const createInvitationRequest = createAction(
  'membership/createInvitationRequest'
)
const createInvitationSuccess = createAction(
  'membership/createInvitationSuccess'
)
const createInvitationFailure = createAction(
  'membership/createInvitationFailure'
)

export function createInvitation(businessId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createInvitationRequest())
      const request: Record<string, any> = {
        role: data.role
      }

      if (data.email) request.email = data.email
      if (data.phone) request.phone = data.phone
      if (data.channelSelection && data.role === ROLES.MEMBER_LITE)
        request.allowed_channel_ids = [data.channelSelection]

      const response = await api.post(`/bus/${businessId}/invitations`, request)
      dispatch(createInvitationSuccess())

      return response
    } catch (error) {
      dispatch(createInvitationFailure())

      return error
    }
  }
}

const updateMemberRequest = createAction('membership/updateMemberRequest')
const updateMemberFailure = createAction('membership/updateMemberFailure')

export function updateMember(businessId: string, memberId: string, data?: any) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateMemberRequest())
      const response = await api.patch(
        `/bus/${businessId}/members/${memberId}`,
        {
          role: data.role,
          allowed_channel_ids: data.channelSelection
            ? [data.channelSelection]
            : undefined,
          aggregator_channel_id:
            data.aggId === 'default' ? undefined : data.aggId
        }
      )
      const member = response.data
      dispatch(updateMemberSuccess({ member }))

      return response
    } catch (error) {
      dispatch(updateMemberFailure())

      return error
    }
  }
}

const deleteMemberRequest = createAction('membership/deleteMemberRequest')
const deleteMemberFailure = createAction('membership/deleteMemberFailure')

export function deleteMember(businessId: string, memberId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteMemberRequest())
      const response = await api.delete(
        `/bus/${businessId}/members/${memberId}`
      )
      dispatch(deleteMemberSuccess({ businessId, memberId }))

      return response
    } catch (error) {
      dispatch(deleteMemberFailure())

      return error
    }
  }
}

const fetchMemberLiteMetricsRequest = createAction(
  'membership/fetchMemberLiteMetricsRequest'
)
const fetchMemberLiteMetricsFailure = createAction(
  'membership/fetchMemberLiteMetricsFailure'
)

export function fetchMemberLiteMetrics(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchMemberLiteMetricsRequest())
      const response = await api.get(`/bus/${businessId}/insights/member_lite`)
      const data = response.data
      dispatch(fetchMemberLiteMetricsSuccess({ businessId, data }))
    } catch (error) {
      dispatch(fetchMemberLiteMetricsFailure())

      return error
    }
  }
}

const fetchPendingInvitationsRequest = createAction(
  'membership/fetchPendingInvitationsRequest'
)
const fetchPendingInvitationsFailure = createAction(
  'membership/fetchPendingInvitationsFailure'
)

export function fetchPendingInvitations(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchPendingInvitationsRequest())
      const response = await api.get(`/bus/${businessId}/pending_invitations`)
      dispatch(
        fetchPendingInvitationsSuccess({
          businessId,
          data: response.data.pending_members
        })
      )
    } catch (error) {
      dispatch(fetchPendingInvitationsFailure())
    }
  }
}

const deleteInvitationRequest = createAction(
  'membership/deleteInvitationRequest'
)
const deleteInvitationFailure = createAction(
  'membership/deleteInvitationFailure'
)

export function deleteInvitation(businessId: string, invitationId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteInvitationRequest())
      const response = await api.delete(
        `/bus/${businessId}/invitations/${invitationId}`
      )

      return response
    } catch (error) {
      dispatch(deleteInvitationFailure())
    }
  }
}
