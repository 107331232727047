import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router'

const ConsentRequest = loadable(
  () => import('../pages/Library/Importer/ConsentRequest')
)

export const UnauthenticatedRoutes = (
  <>
    <Route path="/consent_request" element={<ConsentRequest />} />
  </>
)
