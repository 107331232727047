import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../../../common/utils/api'

type IProps = {
  nextPage: Record<string, number> | null
  resources: { [key: string]: AvaResource[] }
}

const initialState: IProps = {
  nextPage: null,
  resources: {}
}

const slice = createSlice({
  name: 'avaResource',
  initialState: initialState,
  reducers: {
    fetchAvaResourcesSuccess(state, action) {
      const { entries, nextPage, page, domainAssistantId } = action.payload
      state.resources[domainAssistantId] = (page
        ? state.resources[domainAssistantId]
        : []
      ).concat(entries)
      state.nextPage = nextPage
    },
    updateAvaResource(state, action) {
      const { entries, domainAssistantId } = action.payload
      const resources = state.resources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === entries.id)
        if (index !== -1) {
          const newResources = resources.slice()
          // Resolve that the event sent by the BE does not contain the complete message
          newResources[index] = { ...newResources[index], ...entries }
          state.resources[domainAssistantId] = newResources
        }
      }
    }
  }
})

export const { fetchAvaResourcesSuccess, updateAvaResource } = slice.actions

const fetchAvaResourcesRequest = createAction(
  'avaResource/fetchAvaResourcesRequest'
)
const fetchAvaResourcesFailure = createAction(
  'avaResource/fetchAvaResourcesFailure'
)

export function fetchAvaResources(
  businessId: string,
  domainAssistantId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page?: Record<string, number>,
  pageSize = 5
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaResourcesRequest())
      const response = await api.get(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/domain_assistant_resources`,
        {
          params: { ...page, page_size: pageSize }
        }
      )
      const { entries, next_page } = response.data
      dispatch(
        fetchAvaResourcesSuccess({
          entries,
          page,
          nextPage: next_page,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaResourcesFailure())

      return error
    }
  }
}

export default slice.reducer
