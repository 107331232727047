import React from 'react'

import css from '@emotion/css'
import { Typography } from 'antd'
import { EllipsisConfig } from 'antd/lib/typography/Base'

type Props = {
  level?: 1 | 2 | 3 | 4 | 5
  weight?:
    | 'normal'
    | 'bold'
    | 'light'
    | 'thin'
    | 'semiBold'
    | 'extraBold'
    | number
  type?: 'secondary' | 'danger' | 'warning' | 'success'
  fontSize?: number
  noMargin?: boolean
  ellipsis?: boolean | EllipsisConfig
  color?: string
  marginTop?: string
  marginBottom?: string
  underline?: boolean
  cursor?: string
  textAlign?: string
}

const Title: React.FC<Props> = ({
  level = 5,
  children,
  weight,
  fontSize,
  type,
  noMargin = false,
  ellipsis = false,
  color = '#404040',
  marginTop,
  marginBottom,
  underline,
  cursor,
  textAlign,
  ...props
}) => {
  return (
    <Typography.Title
      css={css`
        font-weight: ${weight || 'bold'} !important;
        font-size: ${fontSize ? `${fontSize}px` : 'inherit'} !important;
        margin-bottom: ${noMargin ? '0px' : marginBottom ?? '10px'} !important;
        margin-top: ${noMargin ? '0px' : marginTop ?? '10px'} !important;
        color: ${color} !important;
        cursor: ${cursor};
        text-align: ${textAlign};
      `}
      level={level}
      ellipsis={ellipsis}
      type={type}
      underline={underline}
      {...props}
    >
      {children}
    </Typography.Title>
  )
}

export default Title
