import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const ListWebhooks = loadable(() => import('../pages/ListWebhooks'))
const Webhook = loadable(() => import('../layouts'))
const CreateEndpoint = loadable(() => import('../pages/CreateEndpoint'))
const EndPointDetail = loadable(() => import('../pages/EndPointDetail'))

export const Routes = (
  <Route path="/business/:businessId/webhooksv2" element={<Webhook />}>
    <Route path="" element={<ListWebhooks />} />
    <Route path="create" element={<CreateEndpoint />} />
    <Route path=":webhookId" element={<EndPointDetail />} />
  </Route>
)
