import { uploadMedia2S3 } from '@src/common/utils/file'
import {
  BatchImporterMedia,
  ConsentStatus,
  ImporterSourceFrom
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import {
  ConsentRequestsData,
  convertBatchImporterMedia2ConsentRequestsData
} from '@src/modules/channel/redux/Library/Importer/consentRequests'
import { TiktokItem } from '@src/modules/channel/redux/Library/Importer/tiktokImporter'
import _ from 'lodash'
import qs from 'qs'

import api, { validateResponseSuccess } from '../../../../../common/utils/api'
import { runInstagramUrlsCrawler, runTiktokUrlsCrawler } from './apify'

export const fetchConsentRequests = async (params: {
  businessId: string
  mediaSource?: string
  mediaExternalIds?: string[]
  statuses?: string[]
}) => {
  const { businessId, mediaSource, mediaExternalIds, statuses } = params

  const config = {
    params: {
      media_source: mediaSource,
      media_external_ids: mediaExternalIds,
      statuses
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  }

  const response = await api.get(
    `/api/bus/${businessId}/consent_requests`,
    config
  )
  if (!validateResponseSuccess(response)) {
    return null
  }

  return response.data
}

export const fetchConsentRequest = async (id: string) => {
  const response = await api.get(`/api/consent_requests/${id}`)
  if (!validateResponseSuccess(response)) {
    return null
  }

  return response.data
}

export const updateConsentRequest = async (id: string, status: string) => {
  const response = await api.patch(`/api/consent_requests/${id}`, {
    status: status.toLocaleLowerCase()
  })
  if (!validateResponseSuccess(response)) {
    return null
  }

  return response.data
}

const replaceMediaUrl = async (
  inputMedia: BatchImporterMedia
): Promise<BatchImporterMedia> => {
  const media = _.cloneDeep(inputMedia)

  // replace media url
  if (media?.mediaUrl && !media?.mediaUrl?.startsWith('medias/')) {
    if (media?.sourceFrom === ImporterSourceFrom.Instagram) {
      const res = await uploadMedia2S3(media?.mediaUrl)
      if (!res?.key) {
        return null
      }
      media.mediaUrl = res.key
      media.signature = res
    } else if (media?.sourceFrom === ImporterSourceFrom.Tiktok) {
      if (!media?.sourceUrl) {
        return null
      }
      const tiktokItems = (await runTiktokUrlsCrawler([media?.sourceUrl]))
        ?.items as TiktokItem[]
      const tiktokItem = tiktokItems?.[0]
      if (!tiktokItem || tiktokItem.error) {
        return null
      }
      const res = await uploadMedia2S3(tiktokItem.mediaUrls[0])
      if (!res?.key) {
        return null
      }
      media.mediaUrl = res.key
      media.signature = res
    }
  }

  // replace thumbnail url
  if (media?.thumbnailUrl && !media?.thumbnailUrl?.startsWith('medias/')) {
    const res = await uploadMedia2S3(media?.thumbnailUrl)
    if (!res?.key) {
      return null
    }
    media.thumbnailUrl = res.key
  }

  // replace avatar url
  if (media?.avatar && !media?.avatar?.startsWith('medias/')) {
    const res = await uploadMedia2S3(media?.avatar)
    if (!res?.key) {
      return null
    }
    media.avatar = res.key
  }

  // replace child
  if (media?.children?.length) {
    const children: BatchImporterMedia[] = []
    for (let i = 0; i < media.children.length; i++) {
      const item = media.children[i]
      const newItem = await replaceMediaUrl(item)
      if (!newItem) {
        return null
      }
      children.push(newItem)
    }
    media.children = children
  }

  return media
}

export const requestConsent = async (
  businessId: string,
  media: BatchImporterMedia
) => {
  if (
    media?.sourceFrom !== ImporterSourceFrom.Tiktok &&
    media?.sourceFrom !== ImporterSourceFrom.Instagram
  ) {
    // not allowed
    return null
  }

  if (media?.sourceFrom === ImporterSourceFrom.Instagram && !media?.username) {
    if (!media?.sourceUrl) {
      return null
    }

    const res = await runInstagramUrlsCrawler([media?.sourceUrl])
    if (res?.items?.length > 0) {
      media.username = res.items[0].ownerUsername
    }
  }

  /**
   * todo://
   *  1. FE solution, download and upload to S3
   *  2. Backend solution, remove the method, the user experience is good than the FE solution. using this way, the backend need to download the media and upload to S3
   */
  const newMedia = await replaceMediaUrl(media)
  if (!newMedia) {
    return null
  }

  const consentRequestsData = convertBatchImporterMedia2ConsentRequestsData(
    newMedia
  )

  const response = await api.post(`/api/bus/${businessId}/consent_requests`, {
    media: consentRequestsData.media
  })
  if (!validateResponseSuccess(response)) {
    return null
  }
  const res: ConsentRequestsData = response.data
  media.consentStatus = res?.status as ConsentStatus
  media.consentId = res?.id
  media.signature = newMedia.signature
  media.children = media.children?.map((child) => {
    const newChild = newMedia.children?.find((newChild) => {
      return newChild.id === child.id
    })

    return {
      ...child,
      signature: newChild.signature
    }
  })

  return res
}
