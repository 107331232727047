import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Box, Flex } from '@src/components/EmotionLayout'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  BatchImporterResult,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import BatchImporterMediaItem from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterMediaItem'
import { Spin } from 'antd'

interface IProps {
  mediaList?: BatchImporterMedia[]
  selectedMediaList?: BatchImporterMedia[]
  onLoadMore?: () => Promise<BatchImporterResult>
  onSelectedMedia?: (checked: boolean, value: BatchImporterMedia) => void
  onPreviewMedia?: (value: BatchImporterMedia) => void
  hasMore?: boolean
  tracking: TrackingDetails
}

const BatchImporterMediaList: React.FC<IProps> = (props) => {
  const {
    mediaList = [],
    selectedMediaList = [],
    onSelectedMedia,
    onPreviewMedia,
    onLoadMore,
    hasMore,
    tracking
  } = props
  const paginateGridTriggerRef = useRef()
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)

  const handlePreviewMedia = (value: BatchImporterMedia) => {
    if (onPreviewMedia) {
      onPreviewMedia(value)
    }
  }

  const handleSelectedMedia = (checked: boolean, value: BatchImporterMedia) => {
    if (onSelectedMedia) {
      onSelectedMedia(checked, value)
    }
  }

  const isChecked = (value?: BatchImporterMedia): boolean => {
    if (!value) {
      return false
    }

    if (value.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM) {
      return !!value.children?.every((element) =>
        selectedMediaList.some((media) => media.id === element.id)
      )
    }

    return !!selectedMediaList.some((media) => media.id === value.id)
  }

  const isIndeterminate = (value?: BatchImporterMedia) => {
    if (
      value.mediaType !== BatchImporterMediaType.CAROUSEL_ALBUM ||
      !value.children?.length
    ) {
      return false
    }

    let result = value.children?.every((element) =>
      selectedMediaList.some((media) => media.id === element.id)
    )
    if (result) {
      return false
    }

    result = value.children?.some((element) =>
      selectedMediaList.some((media) => media.id === element.id)
    )

    return !!result
  }

  const handleReachListEnd = useCallback(async () => {
    if (!isLoadingMore && hasMore) {
      setIsLoadingMore(true)
      if (onLoadMore) {
        await onLoadMore()
      }
      setIsLoadingMore(false)
    }
  }, [onLoadMore, isLoadingMore, hasMore])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleReachListEnd()
        }
      },
      {
        threshold: [0.25]
      }
    )
    observer.observe(paginateGridTriggerRef.current)

    return () => {
      observer.disconnect()
    }
  }, [handleReachListEnd])

  return (
    <Flex flexDirection="column">
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fill, minmax(186px, 1fr))"
        gap="1rem"
        position="relative"
      >
        {!!mediaList?.length &&
          mediaList.map((item, index) => {
            return (
              <BatchImporterMediaItem
                key={index}
                mediaItem={item}
                checked={isChecked(item)}
                indeterminate={isIndeterminate(item)}
                onSelectedMedia={handleSelectedMedia}
                onPreviewMedia={handlePreviewMedia}
                tracking={tracking}
              />
            )
          })}
      </Box>
      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={hasMore ? '50px' : 0}
        ref={paginateGridTriggerRef}
      >
        {hasMore && <Spin size="small" />}
      </Flex>
    </Flex>
  )
}

export default BatchImporterMediaList
