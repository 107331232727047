import {
  createAsyncThunk,
  createSlice,
  Slice,
  SliceCaseReducers
} from '@reduxjs/toolkit'

import api from '../common/utils/api'

const SLICE_NAME = 'user'

const initialState = {
  users: {},
  searchedUserIds: {},
  searchedUserPaging: {},
  userVideos: [],
  oauthApp: ''
}

export const searchUsers = createAsyncThunk(
  `${SLICE_NAME}/searchUsers`,
  async (params: {
    businessId: string
    payload: any
    page: globalLib.Paging
  }) => {
    const { businessId, payload, page } = params
    const response = page
      ? await api.get(page.next)
      : await api.get(`/users`, { params: payload })

    const { users, paging } = response.data

    return {
      businessId,
      users,
      paging,
      page
    }
  }
)

export const fetchUserVideos = createAsyncThunk(
  `${SLICE_NAME}/fetchUserVideos`,
  async (params: { url: string }) => {
    const { url } = params
    const response = await api.get(url)

    return response.data
  }
)

const slice: Slice<
  typeof initialState,
  SliceCaseReducers<typeof initialState>,
  typeof SLICE_NAME
> = createSlice({
  name: 'user',
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(searchUsers.fulfilled, (state, action) => {
        const { businessId, users, paging, page } = action.payload

        if (page) {
          users.forEach((user) => {
            state.searchedUserIds[businessId].push(user.encoded_id)
          })
        } else {
          state.searchedUserIds[businessId] = users.map(
            (user) => user.encoded_id
          )
        }
        state.searchedUserPaging[businessId] = paging

        users.forEach((user) => {
          state.users[user.id] = user
        })
      })
      .addCase(fetchUserVideos.fulfilled, (state, action) => {
        state.userVideos = action.payload
      })
  },
  reducers: {
    setOAuthApp(state, action) {
      const { oauthApp } = action.payload
      state.oauthApp = oauthApp
    }
  }
})

export default slice.reducer

export const { setOAuthApp } = slice.actions
