import { useEffect, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'

type StoreProvider = 'local' | 'shopify' | 'woocommerce' | 'boutir'

type StoreFeatureEnablement = {
  [key in StoreProvider]?: boolean
}

export const useStoreFeatureEnablement = (
  businessId: string
): StoreFeatureEnablement => {
  const [storeFeatureEnablement, setStoreFeatureEnablement] = useState({})
  const businessData = useAppSelector(
    (state) => state.business.businesses[businessId]
  )

  useEffect(() => {
    // change feature checks to feature enablement
    const fe = getStoreFeatureEnablement(businessData)
    setStoreFeatureEnablement(fe)
  }, [businessData])

  return storeFeatureEnablement
}

export const getStoreFeatureEnablement = (
  businessData: any
): StoreFeatureEnablement => {
  const fe: StoreFeatureEnablement = {}

  businessData?.business_stores?.forEach((store) => {
    if (!fe[store.provider]) {
      fe[store.provider] = true
    }
  })

  return fe
}
