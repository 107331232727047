import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  column-gap: 32px;
  height: 100%;
  justify-content: center;
`

export const AlbumContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

export const AlbumItemContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`

export const AlbumItemContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const AlbumItemCheckBoxContainer = styled.div`
  position: absolute;
  left: 8px;
  top: 8px;
`

export const MediaGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 300px));
  position: relative;
  justify-content: center;
`

export const MediaContainer = styled.div`
  padding-bottom: 178%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
`

export const MediaContent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const Image = styled.div<{ src: string }>`
  background-color: black;
  height: 100%;
  width: 100%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
`
