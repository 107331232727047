import {
  transformRuleExpressionForAPI,
  transformRuleExpressionForUI
} from '@src/common/utils/chatChannel'
import { ChatChannelConfigRequest } from '@src/modules/one-to-one/redux/chatChannel'

/**
 * Segment Rules Helpers
 * @description: A util which provides Segment Rules object converting between API and UI
 */
const convertChatChannelSegmentCriteriaForUI = (
  criteria?: ChatChannelSegmentCriteria
): ChatChannelSegmentCriteria => {
  return {
    expression: transformRuleExpressionForUI(criteria?.expression)
  }
}

const convertChatChannelSegmentCriteriaForAPI = (
  criteria?: ChatChannelSegmentCriteria
): ChatChannelSegmentCriteria => {
  return {
    expression: transformRuleExpressionForAPI(criteria?.expression)
  }
}

const convertChatChannelSegmentToV2IfNeeded = (segment: any) => {
  if (segment) {
    const { criteria, ...reset } = segment

    // Segment in v1 has no `expression`
    if (criteria?.expression === undefined) {
      const criteriaV2: ChatChannelSegmentCriteria = {
        expression: transformRuleExpressionForAPI(criteria, true)
      }

      return { ...reset, criteria: criteriaV2 }
    }
  }

  return segment
}

/**
 * Camera Permission Helpers
 * @description: A util which provides camera permission converting between API and UI
 */
const cameraPermissionMap: Record<
  ChatChannelCameraPermission,
  ChatChannelConfigRequest['camera_permission']
> = {
  CAMERA_PERMISSION_ALWAYS_ALLOW: 'always_allow',
  CAMERA_PERMISSION_INVITE_ONLY: 'invite_only',
  CAMERA_PERMISSION_NEVER_ALLOW: 'never_allow'
}

const convertToCameraPermissionRequest = (
  permission: ChatChannelCameraPermission
): ChatChannelConfigRequest['camera_permission'] => {
  return cameraPermissionMap[permission]
}

const convertToCameraPermission = (
  permission: ChatChannelConfigRequest['camera_permission']
): ChatChannelCameraPermission => {
  return Object.keys(cameraPermissionMap).find(
    (key) => cameraPermissionMap[key] === permission
  ) as ChatChannelCameraPermission
}

export {
  convertChatChannelSegmentCriteriaForAPI,
  convertChatChannelSegmentCriteriaForUI,
  convertChatChannelSegmentToV2IfNeeded,
  convertToCameraPermission,
  convertToCameraPermissionRequest
}
