import { CDN_URL } from '@src/common/utils/api'
import { VideoVisibility } from '@src/constants'

export enum BatchImporterMediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM'
}

export const mediaTypeIconMap = {
  VIDEO: '/icon/videocam.svg',
  IMAGE: '/icon/image.svg',
  CAROUSEL_ALBUM: '/icon/album.svg'
}

export enum ConsentStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied'
}

export type BatchImporterMedia = {
  id?: string
  caption?: string
  avatar?: string
  username?: string
  displayName?: string
  userId?: string
  duration?: number // seconds
  mediaType?: BatchImporterMediaType
  thumbnailUrl?: string // maybe null
  mediaUrl?: string
  sourceUrl?: string
  likeCount?: number
  timestamp?: string
  hashtags?: string[]
  children?: BatchImporterMedia[]
  signature?: {
    key?: string
    url?: string
  }
  interactionType?: globalLib.InteractionType
  products?: {
    business_store_id?: string
    product_id: string
  }[]
  productOptions?: {
    id: string
    unitId?: string
    businessStoreId?: string
  }[]
  cta?: {
    action_url: string
    action_type: string
    custom_label?: string
  }
  poll?: {
    question: string
    options: string[]
  }
  question?: {
    prompt: string
    collect_email: 'required' | 'optional'
  }
  disabled?: boolean
  sourceFrom?: ImporterSourceFrom
  needCrawlerVideo?: boolean
  needConsent?: boolean
  consentStatus?: ConsentStatus
  consentId?: string
  error?: {
    message: string
  }
}

// Serializable
export type BatchImporterEditMedia = {
  editCaption?: string
  editHashtags?: string[]
  playlists?: string[]
  visibility?: VideoVisibility
  agreeTerms?: boolean
  publishTime?: string
} & BatchImporterMedia

export type BatchImporterResult = {
  success: boolean
  error?: string
}

export enum ImporterSource {
  BULK_UPLOAD = 'bulk_upload',
  INSTAGRAM_PERSONAL = 'instagram_personal', // login by instagram
  INSTAGRAM_BUSINESS = 'instagram_business', // login by facebook
  INSTAGRAM_LINK = 'instagram_link',
  TIKTOK_LINK = 'tiktok_link',
  TIKTOK = 'tiktok',
  YOUTUBE_LINK = 'youtube_link',
  CONSENT_REQUESTS_PAGE = 'consent_requests_page',
  GOOGLE_DRIVE = 'google_drive',
  YOUTUBE = 'youtube',
  ASYNC_MODAL = 'async_modal'
}

export type CreationDetails = {
  source: 'social_media' | 'cms' | 'cloud_storage'
  detail: 'short_video' | 'instagram' | 'tiktok' | 'youtube' | 'google_drive'
  creator_name: string
  app: 'web'
}

export type TrackingDetails = {
  source: ImporterSource
  sourceFrom?: ImporterSourceFrom
  sourceDetail?: ImporterSourceDetail
  // no value for the consent requests page
  creationDetails?: CreationDetails
}

// if ImporterSource === INSTAGRAM_BUSINESS || ImporterSource === TIKTOK
export enum ImporterSourceDetail {
  ACCOUNT = 'account',
  USERNAME = 'username',
  HASHTAG = 'hashtag',
  LINK = 'link'
}

export enum ImporterSourceFrom {
  Instagram = 'instagram',
  Tiktok = 'tiktok',
  YouTube = 'youtube',
  GoogleDrive = 'google_drive'
}

export const addCdnUrl2Key = (key?: string): string | undefined => {
  if (key?.startsWith('medias/')) {
    return `${CDN_URL}/${key}`
  }

  return key
}

export const addCdnUrl2BatchImporterMedia = (
  media: BatchImporterMedia
): BatchImporterMedia => {
  const avatar = addCdnUrl2Key(media?.avatar)
  const thumbnailUrl = addCdnUrl2Key(media?.thumbnailUrl)
  const mediaUrl = addCdnUrl2Key(media?.mediaUrl)

  return {
    ...media,
    avatar,
    thumbnailUrl,
    mediaUrl,
    children: media?.children?.map((item) => {
      return addCdnUrl2BatchImporterMedia(item)
    })
  }
}

export const flatBatchImporterMediaList = (
  mediaList: BatchImporterMedia[]
): BatchImporterMedia[] => {
  const newMediaItems = []
  mediaList?.forEach((item) => {
    const children = item.children || []
    if (!children.length) {
      newMediaItems.push(item)
    } else {
      children?.forEach((child) => {
        newMediaItems.push({
          ...child,
          caption: child.caption || item.caption,
          timestamp: child?.timestamp || item?.timestamp,
          username: item?.username
        })
      })
    }
  })

  return newMediaItems
}
