import React, { useMemo } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { DatePicker, TimePicker, Typography } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Flex } from '../EmotionLayout'

type ScheduleUploadFormProps = {
  publishTime?: string
  setPublishTime: (publishTime?: string) => void
}

export const ScheduleUploadForm: React.FC<ScheduleUploadFormProps> = ({
  publishTime: inputPublishTime,
  setPublishTime
}) => {
  const { t } = useTranslation()
  const publishTime = useMemo(() => {
    if (inputPublishTime) {
      return moment(inputPublishTime)
    }

    return null
  }, [inputPublishTime])

  const onChangeDate = (_date, dateString) => {
    if (!_date) return setPublishTime(null)

    const timeString = moment()
      .subtract(moment().minutes(), 'minutes')
      .add(1, 'hour')
      .format('hh:mm:ss a')
    let data = `${dateString} ${timeString}`
    data = data.replace(/-/g, '/') //safari doesn't support yyyy-MM-dd https://stackoverflow.com/questions/4310953/invalid-date-in-safari

    setPublishTime(moment(data).format())
  }

  const onChangeTime = (_time, timeString) => {
    let dateString
    if (publishTime) {
      dateString = publishTime.format('YYYY-MM-DD')
    } else {
      dateString = moment().format('YYYY-MM-DD')
    }
    let data = `${dateString} ${timeString}`
    data = data.replace(/-/g, '/') //safari doesn't support yyyy-MM-dd https://stackoverflow.com/questions/4310953/invalid-date-in-safari
    setPublishTime(moment(data).format())
  }

  const onDisabledDate = (current) => {
    return (
      current &&
      (current.valueOf() <= moment().subtract(1, 'day').endOf('day') ||
        current.valueOf() > moment().add(28, 'days'))
    )
  }

  const disabledMinutes = (
    selectedHour: number,
    publishTime: any
  ): number[] => {
    const minutes = []
    if (publishTime?.isSame(new Date(), 'day')) {
      if (selectedHour < moment().hour()) {
        for (let i = 0; i < 60; i++) {
          minutes.push(i)
        }
      } else if (selectedHour === moment().hour()) {
        if (moment().minute() < 30) {
          for (let i = 0; i < moment().minute(); i++) {
            minutes.push(i)
          }
        } else {
          for (let i = 0; i < 60; i++) {
            minutes.push(i)
          }
        }
      } else if (selectedHour === moment().hour() + 1) {
        for (let i = 0; i < 30 - (60 - moment().minute()); i++) {
          minutes.push(i)
        }
      }
    }

    return minutes
  }

  const onDisabledHours = () => {
    const hours = []
    if (publishTime?.isSame(new Date(), 'day')) {
      for (let i = 0; i < moment().hour(); i++) hours.push(i)
      if (moment().minute() > 30) hours.pop()
    }

    return hours
  }

  const onDisabledMinutes = (selectedHour) => {
    return disabledMinutes(selectedHour, publishTime)
  }

  const onTimeSelect = (time) => {
    onChangeTime(time, time.format('h:mm a'))
  }

  const onClearSchedule = () => {
    setPublishTime(null)
  }

  return (
    <Flex flexDirection="column" gap={8}>
      <Typography.Text>
        {t('Schedule Upload (Public visibility only)')}
      </Typography.Text>
      <Flex width="100%" gap={16} alignItems="center">
        <DatePicker
          showToday={false}
          placeholder={t('Select date')}
          allowClear={true}
          style={{ width: '120px' }}
          onChange={onChangeDate}
          disabledDate={onDisabledDate}
          value={publishTime}
        />
        <TimePicker
          allowClear={false}
          use12Hours
          format="h:mm a"
          minuteStep={15}
          value={publishTime}
          onChange={onChangeTime}
          onSelect={onTimeSelect}
          showNow={false}
          disabled={!publishTime}
          disabledHours={onDisabledHours}
          disabledMinutes={onDisabledMinutes}
        />
        {publishTime && (
          <DeleteOutlined
            onClick={onClearSchedule}
            style={{ cursor: 'pointer' }}
          />
        )}
      </Flex>
    </Flex>
  )
}
