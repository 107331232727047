import React, { useEffect, useState } from 'react'

import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { useImage2Video } from '@src/common/hooks/useImage2Video'
import { validateResponseSuccess } from '@src/common/utils/api'
import { beforeUpload } from '@src/common/utils/file'
import { Flex } from '@src/components/EmotionLayout'
import { useToast } from '@src/hooks/useToast'
import { useVideoLimit } from '@src/hooks/useVideoLimit'
import {
  BatchImporterEditMedia,
  BatchImporterMediaType,
  ImporterSourceFrom,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import {
  YouTubeCrawlerItem,
  YouTubeCrawlerMedaDataItem,
  YouTubeCrawlerUrlItem
} from '@src/modules/channel/components/Library/Importer/SourceImporter/YouTube/YouTubeImporterModel'
import { TiktokItem } from '@src/modules/channel/redux/Library/Importer/tiktokImporter'
import {
  runTiktokUrlsCrawler,
  runYouTubeUrlsCrawler
} from '@src/modules/channel/utils/Library/Importer/apify'
import { newCreationDetails } from '@src/modules/channel/utils/Library/Importer/sourceImporter'
import { createChannelVideo, createVideoInteraction } from '@src/redux/channel'
import { image2Video } from '@src/redux/image2Video'
import { getS3Signature, uploadFileToS3 } from '@src/utils/s3'
import { Progress } from 'antd'
import mimeTypes from 'mime-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

interface IProps {
  mediaList?: BatchImporterEditMedia[]
  onCreateComplete?: () => void
  tracking: TrackingDetails
}

const BatchImporterImportingBody: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { errorToast } = useToast()
  const dispatch = useDispatch()
  const { businessId, channelId } = useParams()
  const { maxVideoSize, maxVideoDuration } = useVideoLimit({
    businessId,
    channelId
  })
  const [progress, setProgress] = useState<number>(0)
  const [failureMediaList, setFailureMediaList] = useState<
    BatchImporterEditMedia[]
  >([])
  const { mediaList = [], onCreateComplete } = props

  const { setTopic } = useImage2Video()

  const user = useAppSelector((state) => state.profile.user)

  const handleCreateVideo = async (
    key: string,
    media: BatchImporterEditMedia
  ) => {
    const creationDetails = newCreationDetails(
      media?.sourceFrom,
      media?.username
    )
    const res = (await dispatch(
      createChannelVideo(businessId, channelId, {
        access: media.visibility,
        caption: media.editCaption,
        description: null,
        duration: 6.47,
        height: 1280,
        key,
        width: 720,
        repostable: true,
        badge: null,
        action_url: media.cta?.action_url
          ? media.cta.action_url.startsWith('http')
            ? media.cta.action_url
            : `http://${media.cta.action_url}`
          : null,
        action_type: media.cta?.action_type,
        action_custom_label: media.cta?.custom_label,
        playlist_ids: media.playlists,
        hashtags: media.editHashtags || [],
        creation_details: creationDetails,
        products: media.products,
        published_at: media.visibility === 'private' ? null : media.publishTime
      })
    )) as any

    if (validateResponseSuccess(res)) {
      if (media.interactionType === 'question') {
        const interactionData = {
          prompt: media.question.prompt,
          collect_email: media.question.collect_email
            ? media.question.collect_email
            : null,
          interaction_type: 'question'
        }
        await dispatch(
          createVideoInteraction(
            res.data.encoded_id,
            interactionData,
            channelId
          )
        )
      }

      if (media.interactionType === 'poll') {
        const interactionData = {
          prompt: media.poll?.question,
          interaction_type: 'poll',
          options: media.poll.options.map((option) => ({
            text: option
          }))
        }
        await dispatch(
          createVideoInteraction(
            res.data.encoded_id,
            interactionData,
            channelId
          )
        )
      }

      return res
    }
  }

  const handleCreateVideoByImage = async (
    key: string,
    media: BatchImporterEditMedia
  ) => {
    const res = (await dispatch(image2Video(key, businessId))) as any
    const topic = res?.data?.job?.topic
    const data = await setTopic(topic)
    const videoFileUrl =
      data?.payload?.batch?.video_compilations?.[0]?.video_file_url
    const matchResult = videoFileUrl?.match(/medias\/.*/)
    const videoFileKey = matchResult ? matchResult[0] : null

    return await handleCreateVideo(videoFileKey, media)
  }

  const createVideoWithKey = async (
    key: string,
    media: BatchImporterEditMedia
  ) => {
    if (!key) {
      handleUploadFailure(media)

      return
    }
    if (media.mediaType === BatchImporterMediaType.IMAGE) {
      const result = await handleCreateVideoByImage(key, media)
      if (!result?.data?.id) {
        handleUploadFailure(media)
      }
    } else if (media.mediaType === BatchImporterMediaType.VIDEO) {
      const result = await handleCreateVideo(key, media)
      if (!result?.data?.id) {
        handleUploadFailure(media)
      }
    } else {
      handleUploadFailure(media)
    }
  }

  const handleUpload = async (mediaList: BatchImporterEditMedia[]) => {
    for (let i = 0; i < mediaList.length; i++) {
      let media = mediaList[i]
      const key = media?.signature?.key

      try {
        if (!key) {
          // 0. need crawler
          if (media?.needCrawlerVideo) {
            if (media?.sourceFrom === ImporterSourceFrom.Tiktok) {
              const tiktokItems = (
                await runTiktokUrlsCrawler([
                  media?.sourceUrl || media?.mediaUrl
                ])
              )?.items as TiktokItem[]
              const item = tiktokItems?.[0]
              if (item) {
                media = {
                  ...media,
                  mediaUrl: item.mediaUrls[0]
                }
              }
            } else if (media?.sourceFrom === ImporterSourceFrom.YouTube) {
              const {
                mediaMetaData,
                mediaUrlData
              } = await runYouTubeUrlsCrawler([
                media?.sourceUrl || media?.mediaUrl
              ])
              const metaDataItems = mediaMetaData?.items as YouTubeCrawlerMedaDataItem[]
              const mediaUrlItems = mediaUrlData?.items as YouTubeCrawlerUrlItem[]
              const mediaItems: YouTubeCrawlerItem[] = metaDataItems?.map(
                (item, index) => {
                  const mediaUrlItem = mediaUrlItems[index]

                  return {
                    ...item,
                    ...mediaUrlItem
                  }
                }
              )

              const item = mediaItems?.[0]
              if (item) {
                media = {
                  ...media,
                  mediaUrl: item.downloadUrl
                }
              }
            }
          }

          // 1. download by mediaUrl
          const res = await fetch(media.mediaUrl)
          const blob = await res.blob()
          const fileType = blob.type || 'image/jpeg'
          const fileName = `${uuidv4()}.${mimeTypes.extension(fileType)}`
          const file = new File([blob], fileName, { type: fileType })

          // 2. can upload
          if (
            await beforeUpload(media, file, t, errorToast, {
              maxVideoSize,
              maxVideoDuration
            })
          ) {
            // 3. upload media
            const signature = await getS3Signature(file)
            const res = await uploadFileToS3(file, signature)
            if (!res || !res.key) {
              // upload file fail
              handleUploadFailure(media)
            } else {
              // 4. create video
              const { key } = res
              await createVideoWithKey(key, media)
            }
          } else {
            // upload file fail
            handleUploadFailure(media)
          }
        } else {
          await createVideoWithKey(key, media)
        }
      } catch (e) {
        handleUploadFailure(media)
      }

      if (i === mediaList.length - 1) {
        // If we refresh the list immediately, the list is empty.
        // The backend seems to take time to process. So do a delay here.
        setTimeout(() => {
          setProgress((preState) => preState + 1)
        }, 3000)
      } else {
        setProgress((preState) => preState + 1)
      }
    }
  }

  const handleUploadFailure = (media: BatchImporterEditMedia) => {
    setFailureMediaList((preState) => preState.concat(media))
  }

  useEffect(() => {
    if (user.encoded_id) {
      handleUpload(mediaList).then()
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (progress === mediaList.length) {
      if (onCreateComplete) {
        onCreateComplete()
      }
    }
  }, [progress, mediaList, onCreateComplete])

  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <div
        css={css`
          font-size: 24px;
        `}
      >
        {t('Uploading {{count}} videos', {
          count: mediaList.length
        })}
      </div>
      <div
        css={css`
          margin-top: 20px;
          font-size: 16px;
          text-align: center;
        `}
      >
        {t('This may take up to several minutes if you have a lot of content.')}
      </div>
      <div
        css={css`
          margin-top: 12px;
          font-size: 16px;
        `}
      >
        {`${progress}/${mediaList.length}`}
      </div>
      <div
        css={css`
          width: 186px;
          margin-top: 10px;
        `}
      >
        <Progress
          percent={(progress / mediaList.length) * 100}
          showInfo={false}
        />
      </div>
      <div
        css={css`
          width: 100%;
          margin-top: 18px;
        `}
      >
        {!!failureMediaList.length && (
          <>
            <div
              css={css`
                text-align: center;
              `}
            >
              {t('{{count}} files upload failed', {
                count: failureMediaList.length
              })}
            </div>
            {failureMediaList.map((item) => {
              return (
                <Flex key={item.id} flexDirection="row" justifyContent="center">
                  <div
                    css={css`
                      margin-top: 4px;
                      color: #e90000;
                    `}
                  >
                    {item.editCaption}
                  </div>
                </Flex>
              )
            })}
          </>
        )}
      </div>
    </Flex>
  )
}

export default BatchImporterImportingBody
