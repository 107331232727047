import { createAction, createSlice } from '@reduxjs/toolkit'
import { removeUndefined } from '@src/utils/sanitize'
import { Dispatch } from 'redux'

import api, { API_URL } from '../../../common/utils/api'

export type AccessLevel = 'private' | 'accessible_for_business_id'

type IProps = {
  assistantByBusinessId: {
    [businessId: string]: {
      nextPage: Record<string, number> | null
      asssistants: AvaAssistantProfile[]
    }
  }
}

const initialState: IProps = { assistantByBusinessId: {} }

const slice = createSlice({
  name: 'avaAssistantProfile',
  initialState: initialState,
  reducers: {
    fetchAvaAssistantProfilesSuccess(state, action) {
      const { entries, nextPage, businessId } = action.payload

      const assistant = state.assistantByBusinessId[businessId] ?? {
        nextPage: null,
        asssistants: []
      }
      assistant.nextPage = nextPage
      assistant.asssistants = assistant.asssistants.concat(entries)
      state.assistantByBusinessId[businessId] = assistant
    },
    fetchAvaAssistantProfileSuccess(state, action) {
      const { assistant, businessId } = action.payload
      const index = state.assistantByBusinessId[
        businessId
      ]?.asssistants.findIndex((item) => item.id === assistant.id)
      if (index >= 0) {
        state.assistantByBusinessId[businessId].asssistants[index] = assistant
      }
    },
    createAvaAssistantProfileSuccess(state, action) {
      const { entries, businessId } = action.payload

      const assistant = state.assistantByBusinessId[businessId] ?? {
        nextPage: null,
        asssistants: []
      }
      assistant.asssistants = [entries].concat(assistant.asssistants)
      state.assistantByBusinessId[businessId] = assistant
    },
    updateAvaAssistantProfileSuccess(state, action) {
      const { assistant, businessId } = action.payload
      const index = state.assistantByBusinessId[
        businessId
      ]?.asssistants?.findIndex((item) => item.id === assistant.id)
      if (index >= 0) {
        state.assistantByBusinessId[businessId].asssistants[index] = assistant
      }
    },
    deleteAvaAssistantProfileSuccess(state, action) {
      const { assistantId, businessId } = action.payload
      const index = state.assistantByBusinessId[
        businessId
      ]?.asssistants?.findIndex((item) => item.id === assistantId)
      if (index >= 0) {
        state.assistantByBusinessId[businessId].asssistants.splice(index, 1)
      }
    }
  }
})

export default slice.reducer

export const {
  fetchAvaAssistantProfilesSuccess,
  fetchAvaAssistantProfileSuccess,
  createAvaAssistantProfileSuccess,
  updateAvaAssistantProfileSuccess,
  deleteAvaAssistantProfileSuccess
} = slice.actions

const fetchAvaAssistantProfilesRequest = createAction(
  'avaAssistantProfile/fetchAvaAssistantProfilesRequest'
)
const fetchAvaAssistantProfilesFailure = createAction(
  'avaAssistantProfile/fetchAvaAssistantProfilesFailure'
)

export function fetchAvaAssistantProfiles(
  businessId: string,
  accessLevel?: AccessLevel,
  status?: string,
  pageSize = 10,
  nextPage?: Record<string, number>
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaAssistantProfilesRequest())
      const response = await api.get(
        `/api/bus/${businessId}/assistant_profiles`,
        {
          params: {
            status,
            access_level: accessLevel,
            page_size: pageSize,
            ...nextPage
          }
        }
      )
      const { entries, next_page } = response.data
      dispatch(
        fetchAvaAssistantProfilesSuccess({
          entries,
          nextPage: next_page,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaAssistantProfilesFailure())

      return error
    }
  }
}

const fetchAvaAssistantProfileRequest = createAction(
  'avaAssistantProfile/fetchAvaAssistantProfileRequest'
)
const fetchAvaAssistantProfileFailure = createAction(
  'avaAssistantProfile/fetchAvaAssistantProfileFailure'
)

export function fetchAvaAssistantProfile(
  businessId: string,
  assistantId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaAssistantProfileRequest())
      const response = await api.get(
        `/api/bus/${businessId}/assistant_profiles/${assistantId}`
      )
      dispatch(
        fetchAvaAssistantProfileSuccess({
          assistant: response.data,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaAssistantProfileFailure())

      return error
    }
  }
}

const createAvaAssistantProfileRequest = createAction(
  'avaAssistantProfile/createAvaAssistantProfileRequest'
)
const createAvaAssistantProfileFailure = createAction(
  'avaAssistantProfile/createAvaAssistantProfileFailure'
)

type AvaAssistantProfileRequest = {
  avatarId: string
  backgroundColor?: string
  backgroundKey?: string
  name: string
  profileInstruction?: string
  voiceoverId: string
  status?: string
  accessLevel?: string
}

export function createAvaAssistantProfile(
  businessId: string,
  data: AvaAssistantProfileRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createAvaAssistantProfileRequest())
      const response = await api.post(
        `/api/bus/${businessId}/assistant_profiles`,
        {
          ...sanitizeAssistantProfileParams(data)
        }
      )
      dispatch(
        createAvaAssistantProfileSuccess({
          entries: response.data,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(createAvaAssistantProfileFailure())

      return error
    }
  }
}

const updateAvaAssistantProfileRequest = createAction(
  'avaAssistantProfile/updateAvaAssistantProfileRequest'
)
const updateAvaAssistantProfileFailure = createAction(
  'avaAssistantProfile/updateAvaAssistantProfileFailure'
)

export function updateAvaAssistantProfile(
  businessId: string,
  assistantId: string,
  data: AvaAssistantProfileRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateAvaAssistantProfileRequest())
      const response = await api.put(
        `/api/bus/${businessId}/assistant_profiles/${assistantId}`,
        {
          ...sanitizeAssistantProfileParams(data)
        }
      )
      dispatch(
        updateAvaAssistantProfileSuccess({
          assistant: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(updateAvaAssistantProfileFailure())

      return error
    }
  }
}

const deleteAvaAssistantProfileRequest = createAction(
  'avaAssistantProfile/deleteAvaAssistantProfileRequest'
)
const deleteAvaAssistantProfileFailure = createAction(
  'avaAssistantProfile/deleteAvaAssistantProfileFailure'
)

export function deleteAvaAssistantProfile(
  businessId: string,
  assistantId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteAvaAssistantProfileRequest())
      const response = await api.delete(
        `/api/bus/${businessId}/assistant_profiles/${assistantId}`
      )
      dispatch(
        deleteAvaAssistantProfileSuccess({
          assistantId,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteAvaAssistantProfileFailure())

      return error
    }
  }
}

type CustomAssistantProfile = {
  name: string
  description?: string
  videoKey: string
  silentVideoKey?: string
  consentVideoKey?: string
  voiceoverId?: string
  status?: string
}

export async function createCustomAssistantProfile(
  businessId: string,
  {
    name,
    description = '',
    videoKey,
    silentVideoKey,
    consentVideoKey,
    voiceoverId,
    status = 'active'
  }: CustomAssistantProfile
): Promise<string | any> {
  return api.post(
    `${API_URL}/studio/avatars/create_custom_avatar`,
    removeUndefined({
      name,
      description,
      status,
      video_key: videoKey,
      silent_video_key: silentVideoKey,
      consent_video_key: consentVideoKey,
      voiceover_id: voiceoverId,
      business_id: businessId
    })
  )
}

export async function fetchVoiceOvers(
  businessId?: string,
  accessLevel?: AccessLevel,
  state?: string,
  pageSize = 100
): Promise<Voiceover[] | any> {
  return api.get(`${API_URL}/studio/voiceovers`, {
    params: {
      state,
      access_level: accessLevel,
      business_id: businessId,
      page_size: pageSize
    }
  })
}

export async function regenerateProfileVideos(
  assistantProfileId: string
): Promise<any> {
  return api.post(
    `${API_URL}/studio/video_compilation_batch/regenerate_assistant_profile_videos`,
    { assistant_profile_id: assistantProfileId }
  )
}

export async function createAssistantProfileWithVideos(
  businessId: string,
  data: AvaAssistantProfileRequest
): Promise<any> {
  return api.post(
    `${API_URL}/studio/video_compilation_batch/create_assistant_profile_with_base_video`,
    {
      business_id: businessId,
      ...sanitizeAssistantProfileParams(data)
    }
  )
}

export async function fetchDomainAssistants(
  businessId: string,
  assistantId: string
): Promise<AvaDomainAssistant[] | any> {
  return api.get(
    `/api/bus/${businessId}/assistant_profiles/${assistantId}/domain_assistances`
  )
}

const sanitizeAssistantProfileParams = (data: AvaAssistantProfileRequest) => {
  const sanitized = {
    avatar_id: data.avatarId,
    background_color: data.backgroundColor,
    background_key: data.backgroundKey,
    name: data.name,
    profile_instruction: data.profileInstruction,
    voiceover_id: data.voiceoverId,
    access_level: data.accessLevel,
    status: data.status
  }

  return removeUndefined(sanitized)
}
