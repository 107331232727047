export const isUrl = (s: string): boolean => {
  const regexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

  return regexp.test(s)
}

export const constructCurrentUrl = (path: string): string => {
  const { protocol, host } = window.location

  return `${protocol}//${host}${path}`
}

/**
 * @returns The string representation of the query param object.
 */
export const stringifyQueryParams = (obj, { addQueryPrefix = false } = {}) => {
  try {
    const qs = new URLSearchParams(obj).toString()

    return (addQueryPrefix && qs.length ? '?' : '') + qs
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
  }
}

export function hasCustomProtocol(s: string): boolean {
  const protocolRegex = /^[a-zA-Z]+:\/\//

  return protocolRegex.test(s)
}

//  * CS-674: If cta link starts with `/` then it is a relative link, else it is a
//  * absolute link and we need to prepend https://
//  * CMS-1507, CMS-1573: we need to allow the xxx://
export const updateUrlProtocol = (url?: string): string => {
  if (url) {
    const trimUrl = url.trim()
    if (
      trimUrl.startsWith('/') ||
      trimUrl.startsWith('http') ||
      hasCustomProtocol(trimUrl)
    ) {
      return trimUrl
    } else {
      return `https://${trimUrl}`
    }
  } else {
    return url
  }
}

export function extractQueryParams(url: string): Record<string, string> {
  try {
    const urlObject = new URL(url, 'http://example.com')

    const params = new URLSearchParams(urlObject.search)

    const queryParams: Record<string, string> = {}
    params.forEach((value, key) => {
      queryParams[key] = value
    })

    return queryParams
  } catch (e) {
    return {}
  }
}
