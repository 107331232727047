import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const OAuthApps = loadable(() => import('../pages/OAuthApps'))
const Showcase = loadable(() => import('../pages/Showcase'), {
  resolveComponent: (component) => component.Showcase
})

export const Routes = (
  <>
    <Route
      path="/business/:businessId/app/:oauthAppId"
      element={<OAuthApps />}
    />
    <Route
      path="/business/:businessId/app/:oauthAppId/:tab"
      element={<OAuthApps />}
    />
    <Route path="/business/:businessId/video_showcase" element={<Showcase />} />
  </>
)
