import { useNabooFeatureFlag } from '@src/common/hooks/useFeatureFlag'

const SHORT_LENGTH = 267
const LONG_LENGTH = 475

interface Return {
  width: number
  height: number
}

interface Params {
  isHorizontal: boolean
}
export const useHorizontalTrailer = ({
  isHorizontal = false
}: Params): Return => {
  const nff = useNabooFeatureFlag()
  const fusionPhase1 = nff.cms_showroom_fusion_phase_1

  return {
    width: isHorizontal
      ? LONG_LENGTH * (fusionPhase1 ? 1 : 0.75)
      : SHORT_LENGTH,
    height: isHorizontal
      ? SHORT_LENGTH * (fusionPhase1 ? 1 : 0.75)
      : LONG_LENGTH
  }
}
