import { useEffect, useRef } from 'react'

import { sessionStorageProvider } from '@src/utils/storageProvider'
import { ReadyState } from 'react-use-websocket'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'

type Props = {
  readyState: ReadyState
  sendJsonMessage: SendJsonMessage
  enabled?: boolean
}

const HEARTBEAT_INTERVAL = 30000

export const useWebsocketHeartBeat = ({
  readyState,
  sendJsonMessage,
  enabled = true
}: Props): void => {
  const heartBeatIntervalRef = useRef<number | null>(null)

  useEffect(() => {
    if (enabled) {
      switch (readyState) {
        case ReadyState.OPEN: {
          // Set up heartbeats
          if (!heartBeatIntervalRef.current) {
            const heartbeat = () => {
              sendJsonMessage({
                topic: 'phoenix',
                event: 'heartbeat',
                payload: {},
                ref: `hb${Date.now()}`
              })
            }

            heartBeatIntervalRef.current = window.setInterval(
              heartbeat,
              HEARTBEAT_INTERVAL
            )

            //remove token after websocket connection is made for oauth app purposes
            sessionStorageProvider.removeItem('fwtoken')
          }
          break
        }
        case ReadyState.CLOSED: {
          if (heartBeatIntervalRef.current) {
            clearInterval(heartBeatIntervalRef.current)
            heartBeatIntervalRef.current = null
          }
          break
        }
      }

      return () => {
        if (heartBeatIntervalRef.current) {
          clearInterval(heartBeatIntervalRef.current)
          heartBeatIntervalRef.current = null
        }
      }
    }
  }, [enabled, readyState, sendJsonMessage])
}
