import React, { useMemo, useState } from 'react'

import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { UploadLimitBanner } from '@src/components/Banners/UploadLimit'
import { Flex } from '@src/components/EmotionLayout'
import { MAX_SELECTED_VIDEOS } from '@src/constants'
import { useToast } from '@src/hooks/useToast'
import { useVideoLimit } from '@src/hooks/useVideoLimit'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  BatchImporterResult,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import BatchImporterMediaList from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterMediaList'
import BatchImporterMediaPreviewModal from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterMediaPreviewModal'
import { isPLGBusiness } from '@src/selectors/subscription'
import { useTranslation } from 'react-i18next'

interface IProps {
  businessId?: string
  channelId?: string
  selectedMediaList?: BatchImporterMedia[]
  onChangeSelectedMediaList?: (value: BatchImporterMedia[]) => void
  mediaList?: BatchImporterMedia[]
  onLoadMore?: () => Promise<BatchImporterResult>
  hasMore?: boolean
  tracking?: TrackingDetails
}

export const BatchImporterSelectMediaBody: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { warningToast } = useToast()
  const {
    businessId,
    channelId,
    selectedMediaList = [],
    mediaList = [],
    onChangeSelectedMediaList,
    onLoadMore,
    hasMore,
    tracking
  } = props

  const [previewMedia, setPreviewMedia] = useState<BatchImporterMedia>()
  const [togglePreviewModal, setTogglePreviewModal] = useState<boolean>(false)

  const plgBusiness = useAppSelector((state) =>
    isPLGBusiness(state, businessId)
  )

  const { remainingVideoCount, isVideoLimitReached } = useVideoLimit({
    channelId,
    businessId
  })
  const canSelectCount = useMemo(() => {
    let count = remainingVideoCount
    if (count >= MAX_SELECTED_VIDEOS) {
      count = MAX_SELECTED_VIDEOS
    }

    if (count < 0) {
      count = 0
    }

    return count
  }, [remainingVideoCount])

  const showLimitBanner =
    isVideoLimitReached(selectedMediaList.length) && plgBusiness

  const handleSelectedMedia = (
    checked: boolean,
    value: BatchImporterMedia,
    parent?: BatchImporterMedia
  ) => {
    if (value.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM) {
      if (!value.children?.length) {
        return
      }

      const children = value.children
      if (checked) {
        if (selectedMediaList.length < canSelectCount) {
          const moreSelectCount = canSelectCount - selectedMediaList.length
          const moreSelect: BatchImporterMedia[] = []
          for (let i = 0; i < children.length; i++) {
            if (moreSelect.length === moreSelectCount) {
              break
            }
            const child = children[i]
            if (!selectedMediaList.some((element) => element.id === child.id)) {
              moreSelect.push({
                ...child,
                caption: child?.caption || value?.caption,
                username: child?.username || value?.username,
                likeCount: child?.likeCount || value?.likeCount,
                timestamp: child?.timestamp || value?.timestamp
              })
            }
          }
          onChangeSelectedMediaList(selectedMediaList.concat(moreSelect))
        } else {
          warningToast(
            t('The maximum number of videos you can import is {{count}}', {
              count: canSelectCount
            })
          )
        }
      } else {
        onChangeSelectedMediaList(
          selectedMediaList.filter((media) => {
            return !children.some((element) => element.id === media.id)
          })
        )
      }

      return
    }

    if (checked) {
      if (selectedMediaList.length < canSelectCount) {
        onChangeSelectedMediaList(
          selectedMediaList.concat({
            ...value,
            caption: value?.caption || parent?.caption,
            username: value?.username || parent?.username,
            likeCount: value?.likeCount || parent?.likeCount,
            timestamp: value?.timestamp || parent?.timestamp
          })
        )
      } else {
        warningToast(
          t('The maximum number of videos you can import is {{count}}', {
            count: canSelectCount
          })
        )
      }
    } else {
      onChangeSelectedMediaList(
        selectedMediaList.filter((media) => {
          return media.id !== value.id
        })
      )
    }
  }

  const handlePreviewMedia = (value?: BatchImporterMedia) => {
    setPreviewMedia(value)
    setTogglePreviewModal(!!value)
  }

  return (
    <Flex flexDirection="column" flex={1} overflowY="scroll">
      {!!showLimitBanner && (
        <Flex flexDirection="column" px="50" py="16" gap="16">
          <UploadLimitBanner businessId={businessId} />
        </Flex>
      )}
      <div
        css={css`
          padding: 16px 50px;
        `}
      >
        <BatchImporterMediaList
          mediaList={mediaList}
          selectedMediaList={selectedMediaList}
          onLoadMore={onLoadMore}
          onSelectedMedia={handleSelectedMedia}
          onPreviewMedia={(value: BatchImporterMedia) => {
            handlePreviewMedia(value)
          }}
          hasMore={hasMore}
          tracking={tracking}
        />
      </div>
      {togglePreviewModal && (
        <BatchImporterMediaPreviewModal
          modalVisible={togglePreviewModal}
          onCancelClick={() => handlePreviewMedia(null)}
          media={previewMedia}
          okTitle={t('Select')}
          canSelectCount={canSelectCount}
          selectedMediaList={selectedMediaList}
          onOkClick={(currentSelectedMediaList, otherSelectedMediaList) => {
            if (
              previewMedia?.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM
            ) {
              onChangeSelectedMediaList(
                currentSelectedMediaList.concat(otherSelectedMediaList)
              )
            } else {
              handleSelectedMedia(true, previewMedia)
            }
            handlePreviewMedia(null)
          }}
          tracking={tracking}
        />
      )}
    </Flex>
  )
}
