import {
  CreationDetails,
  ImporterSource,
  ImporterSourceDetail,
  ImporterSourceFrom,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'

const newCreationDetails = (
  sourceFrom?: ImporterSourceFrom,
  username?: string
): CreationDetails => {
  switch (sourceFrom) {
    case ImporterSourceFrom.Instagram:
      return {
        source: 'social_media',
        detail: 'instagram',
        creator_name: username,
        app: 'web'
      }
    case ImporterSourceFrom.Tiktok:
      return {
        source: 'social_media',
        detail: 'tiktok',
        creator_name: username,
        app: 'web'
      }
    case ImporterSourceFrom.YouTube:
      return {
        source: 'social_media',
        detail: 'youtube',
        creator_name: username,
        app: 'web'
      }
    case ImporterSourceFrom.GoogleDrive:
      return {
        source: 'cloud_storage',
        detail: 'google_drive',
        creator_name: username,
        app: 'web'
      }
    default:
      return {
        source: 'cms',
        detail: 'short_video',
        creator_name: null,
        app: 'web'
      }
  }
}

const createTrackingDetails = (params: {
  source: ImporterSource
  sourceDetail?: ImporterSourceDetail
  username?: string
}): TrackingDetails => {
  const { source, username, sourceDetail } = params

  if (source === ImporterSource.BULK_UPLOAD) {
    return {
      source: ImporterSource.BULK_UPLOAD,
      creationDetails: newCreationDetails()
    }
  } else if (source === ImporterSource.INSTAGRAM_LINK) {
    return {
      source: ImporterSource.INSTAGRAM_LINK,
      sourceFrom: ImporterSourceFrom.Instagram,
      sourceDetail: ImporterSourceDetail.LINK,
      creationDetails: newCreationDetails(ImporterSourceFrom.Instagram)
    }
  } else if (source === ImporterSource.INSTAGRAM_PERSONAL) {
    if (!username) {
      throw new Error('Username cannot be null.')
    }

    return {
      source: ImporterSource.INSTAGRAM_PERSONAL,
      sourceFrom: ImporterSourceFrom.Instagram,
      creationDetails: newCreationDetails(
        ImporterSourceFrom.Instagram,
        username
      )
    }
  } else if (source === ImporterSource.INSTAGRAM_BUSINESS) {
    if (!sourceDetail) {
      throw new Error('Invalid instagram source detail.')
    }

    return {
      source: ImporterSource.INSTAGRAM_BUSINESS,
      sourceFrom: ImporterSourceFrom.Instagram,
      sourceDetail,
      creationDetails: newCreationDetails(
        ImporterSourceFrom.Instagram,
        username
      )
    }
  } else if (source === ImporterSource.TIKTOK_LINK) {
    return {
      source: ImporterSource.TIKTOK_LINK,
      sourceFrom: ImporterSourceFrom.Tiktok,
      sourceDetail: ImporterSourceDetail.LINK,
      creationDetails: newCreationDetails(ImporterSourceFrom.Tiktok)
    }
  } else if (source === ImporterSource.TIKTOK) {
    if (!sourceDetail) {
      throw new Error('Invalid tiktok source detail.')
    }

    return {
      source: ImporterSource.TIKTOK,
      sourceFrom: ImporterSourceFrom.Tiktok,
      sourceDetail,
      creationDetails: newCreationDetails(ImporterSourceFrom.Tiktok)
    }
  } else if (source === ImporterSource.CONSENT_REQUESTS_PAGE) {
    return {
      source: ImporterSource.CONSENT_REQUESTS_PAGE
    }
  } else if (source === ImporterSource.ASYNC_MODAL) {
    return {
      source: ImporterSource.ASYNC_MODAL
    }
  } else if (source === ImporterSource.YOUTUBE_LINK) {
    return {
      source: ImporterSource.YOUTUBE_LINK,
      sourceFrom: ImporterSourceFrom.YouTube,
      sourceDetail: ImporterSourceDetail.LINK,
      creationDetails: newCreationDetails(ImporterSourceFrom.YouTube)
    }
  } else if (source === ImporterSource.YOUTUBE) {
    return {
      source: ImporterSource.YOUTUBE,
      sourceFrom: ImporterSourceFrom.YouTube,
      creationDetails: newCreationDetails(ImporterSourceFrom.YouTube)
    }
  } else if (source === ImporterSource.GOOGLE_DRIVE) {
    return {
      source: ImporterSource.GOOGLE_DRIVE,
      sourceFrom: ImporterSourceFrom.GoogleDrive,
      creationDetails: newCreationDetails(ImporterSourceFrom.GoogleDrive)
    }
  }

  throw new Error('Invalid source.')
}

export { createTrackingDetails, newCreationDetails }
