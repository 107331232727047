import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const MyContentCentral = loadable(
  () => import('../pages/MyContent/MyContentCentral')
)

export const MyContentRoutes = (
  <Route
    path="/business/:businessId/channel/:channelId/mycontent"
    element={<MyContentCentral />}
  />
)
