import { Moment, isMoment } from 'moment'

/**
 * Converts a Moment instance to a human readable string.
 * @param momentInstance Moment instance
 * @return Formatted timestamp
 */
export function toHumanReadableTimestamp(momentInstance: Moment): string {
  if (!isMoment(momentInstance)) throw Error('Not a moment instance')

  return momentInstance.format('MMMM Do YYYY, h:mm:ss a')
}
