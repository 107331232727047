import React, { useEffect, useState } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'

export default function ImagePreview({ initialSelectedId, images, onClose }) {
  const [selectedId, setSelectedId] = useState(initialSelectedId)
  const selectedImage = images.filter((i) => i.image_id === selectedId)[0]
  const thumbnailStyle = {
    width: '80px',
    marginRight: '5px',
    marginBottom: '5px',
    cursor: 'pointer',
    height: '80px'
  }

  useEffect(() => {
    function onKeydown(event) {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keydown', onKeydown)

    return () => {
      window.removeEventListener('keydown', onKeydown)
    }
  }, [onClose])

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: 1002,
        cursor: 'default'
      }}
    >
      <CloseOutlined
        css={css`
          color: #ffffff;
          font-size: 20px;
          position: absolute;
          top: 50px;
          right: 50px;
          cursor: pointer;
        `}
        onClick={() => onClose()}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <img
            alt={selectedImage.image_src}
            src={selectedImage.image_src}
            style={{ width: '300px', height: '300px', marginBottom: '20px' }}
          />
          <div
            style={{
              display: 'flex',
              width: '60vw',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}
          >
            {images.map((i) => (
              <img
                key={i.image_id}
                alt={i.image_src}
                src={i.image_src}
                style={
                  selectedId === i.image_id
                    ? { ...thumbnailStyle, border: '3px solid white' }
                    : thumbnailStyle
                }
                onClick={() => setSelectedId(i.image_id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

ImagePreview.propTypes = {
  initialSelectedId: PropTypes.number,
  images: PropTypes.array,
  onClose: PropTypes.func
}
