import { useCallback } from 'react'

import { validateResponseSuccess } from '@src/common/utils/api'
import { getDuration } from '@src/common/utils/file'
import { timeStringToSeconds } from '@src/common/utils/time'
import { MIN_FILE_SIZE, MIN_VIDEO_DURATION } from '@src/constants'
import { BatchImporterMediaType } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import {
  YouTubeCrawlerItem,
  YouTubeCrawlerMedaDataItem,
  YouTubeCrawlerUrlItem
} from '@src/modules/channel/components/Library/Importer/SourceImporter/YouTube/YouTubeImporterModel'
import {
  AsyncSourceImporterTask,
  AsyncSourceImporterTaskDetailStatus,
  AsyncSourceImporterTaskStatus,
  updateAsyncImporterTasks
} from '@src/modules/channel/redux/Library/Importer/asyncSourceImporter'
import { runYouTubeUrlsCrawler } from '@src/modules/channel/utils/Library/Importer/apify'
import { newCreationDetails } from '@src/modules/channel/utils/Library/Importer/sourceImporter'
import { createChannelVideo, createVideoInteraction } from '@src/redux/channel'
import { getS3Signature, uploadFileToS3 } from '@src/utils/s3'
import _ from 'lodash'
import mimeTypes from 'mime-types'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

const useAsyncImporterTask = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleErrorTask = useCallback(
    async (task: AsyncSourceImporterTask, error: Error) => {
      await dispatch(
        updateAsyncImporterTasks({
          tasks: [
            {
              ...task,
              media: {
                ...task.media,
                error: {
                  message: error.message
                }
              }
            }
          ]
        })
      )
    },
    [dispatch]
  )

  const handleSuccessTask = useCallback(
    async (task: AsyncSourceImporterTask) => {
      await dispatch(
        updateAsyncImporterTasks({
          tasks: [
            {
              ...task,
              status: AsyncSourceImporterTaskStatus.Success,
              detailStatus: AsyncSourceImporterTaskDetailStatus.CreateSuccess,
              media: {
                ...task.media,
                error: undefined
              }
            }
          ]
        })
      )
    },
    [dispatch]
  )

  const handleCreateVideo = useCallback(
    async (key: string, task: AsyncSourceImporterTask) => {
      const { media, businessId, channelId } = task

      const creationDetails = newCreationDetails(
        media?.sourceFrom,
        media?.username
      )
      const res = (await dispatch(
        createChannelVideo(businessId, channelId, {
          access: media.visibility,
          caption: media.editCaption,
          description: null,
          duration: 6.47,
          height: 1280,
          key,
          width: 720,
          repostable: true,
          badge: null,
          action_url: media.cta?.action_url
            ? media.cta.action_url.startsWith('http')
              ? media.cta.action_url
              : `http://${media.cta.action_url}`
            : null,
          action_type: media.cta?.action_type,
          action_custom_label: media.cta?.custom_label,
          playlist_ids: media.playlists,
          hashtags: media.editHashtags || [],
          creation_details: creationDetails,
          products: media.products,
          published_at:
            media.visibility === 'private' ? null : media.publishTime
        })
      )) as any

      if (validateResponseSuccess(res)) {
        if (media.interactionType === 'question') {
          const interactionData = {
            prompt: media.question.prompt,
            collect_email: media.question.collect_email
              ? media.question.collect_email
              : null,
            interaction_type: 'question'
          }
          await dispatch(
            createVideoInteraction(
              res.data.encoded_id,
              interactionData,
              channelId
            )
          )
        }

        if (media.interactionType === 'poll') {
          const interactionData = {
            prompt: media.poll?.question,
            interaction_type: 'poll',
            options: media.poll.options.map((option) => ({
              text: option
            }))
          }
          await dispatch(
            createVideoInteraction(
              res.data.encoded_id,
              interactionData,
              channelId
            )
          )
        }

        await handleSuccessTask(task)

        return
      }

      await handleErrorTask(
        {
          ...task,
          status: AsyncSourceImporterTaskStatus.Fail,
          detailStatus: AsyncSourceImporterTaskDetailStatus.CreateFailed
        },
        new Error(t('Create failed'))
      )
    },
    [dispatch, handleSuccessTask, handleErrorTask, t]
  )

  const youTubeImporter = useCallback(
    async (
      inputTask: AsyncSourceImporterTask,
      limit: { maxVideoSize: number; maxVideoDuration: number }
    ) => {
      const { maxVideoSize, maxVideoDuration } = limit

      let task = inputTask
      let media = _.cloneDeep(task.media)
      let key = media?.signature?.key
      if (key) {
        // 0. create video
        await handleCreateVideo(key, task)

        return
      }

      await dispatch(
        updateAsyncImporterTasks({
          tasks: [
            {
              ...task,
              status: AsyncSourceImporterTaskStatus.InProgress
            }
          ]
        })
      )

      // 1. crawler
      try {
        if (media.needCrawlerVideo) {
          const { mediaMetaData, mediaUrlData } = await runYouTubeUrlsCrawler([
            media.sourceUrl
          ])
          const metaDataItems = mediaMetaData?.items as YouTubeCrawlerMedaDataItem[]
          const mediaUrlItems = mediaUrlData?.items as YouTubeCrawlerUrlItem[]
          const mediaItems: YouTubeCrawlerItem[] = metaDataItems?.map(
            (item, index) => {
              const mediaUrlItem = mediaUrlItems[index]

              return {
                ...item,
                ...mediaUrlItem
              }
            }
          )
          const item = mediaItems?.[0]
          if (item && item.type !== 'shorts' && item.type !== 'video') {
            await handleErrorTask(
              {
                ...task,
                status: AsyncSourceImporterTaskStatus.Fail,
                detailStatus: AsyncSourceImporterTaskDetailStatus.CheckFailed
              },
              new Error(t('Invalid media type'))
            )

            return
          }
          if (!item?.downloadUrl) {
            throw new Error()
          }

          media = {
            ...media,
            mediaUrl: item.downloadUrl,
            timestamp: item.date,
            likeCount: item.likes,
            thumbnailUrl: item.thumbnailUrl,
            needCrawlerVideo: false,
            duration: timeStringToSeconds(item?.duration || '00:00:00')
          }

          task = {
            ...task,
            media
          }
          await dispatch(
            updateAsyncImporterTasks({
              tasks: [
                {
                  ...task
                }
              ]
            })
          )
        }
      } catch {
        await handleErrorTask(
          {
            ...task,
            status: AsyncSourceImporterTaskStatus.Fail,
            detailStatus: AsyncSourceImporterTaskDetailStatus.CrawlFailed
          },
          new Error(t('Crawl failed'))
        )

        return
      }

      // 2. download
      let file = undefined
      try {
        const res = await fetch(media.mediaUrl)
        const blob = await res.blob()
        const fileType = blob.type || 'image/jpeg'
        const fileName = `${uuidv4()}.${mimeTypes.extension(fileType)}`
        file = new File([blob], fileName, { type: fileType })
      } catch {
        await handleErrorTask(
          {
            ...task,
            status: AsyncSourceImporterTaskStatus.Fail,
            detailStatus: AsyncSourceImporterTaskDetailStatus.DownloadFailed
          },
          new Error(t('Download failed'))
        )

        return
      }

      // 3. check
      // 3.a check media type
      if (media.mediaType !== BatchImporterMediaType.VIDEO) {
        await handleErrorTask(
          {
            ...task,
            status: AsyncSourceImporterTaskStatus.Fail,
            detailStatus: AsyncSourceImporterTaskDetailStatus.CheckFailed
          },
          new Error(t('Invalid media type'))
        )

        return
      }

      // 3.b check duration
      const duration = (await getDuration(file)) || 0
      if (duration < MIN_VIDEO_DURATION || duration > maxVideoDuration) {
        let errorMsg: string
        if (duration === 0) {
          errorMsg = t('The duration is null, this could be a livestream.')
        } else if (duration < MIN_VIDEO_DURATION) {
          errorMsg = t('Please upload a video longer than {{second}} seconds', {
            second: MIN_VIDEO_DURATION
          })
        } else {
          errorMsg = t(
            'Please upload a video no longer than {{maxVideoDuration}} seconds',
            { maxVideoDuration }
          )
        }

        await handleErrorTask(
          {
            ...task,
            status: AsyncSourceImporterTaskStatus.Fail,
            detailStatus: AsyncSourceImporterTaskDetailStatus.CheckFailed
          },
          new Error(errorMsg)
        )

        return
      }

      // 3.c check media size
      if (file.size < MIN_FILE_SIZE || file.size > maxVideoSize * 1_000_000) {
        const tooSmall = file.size < MIN_FILE_SIZE
        const fileSize = tooSmall
          ? `${MIN_FILE_SIZE / 1000}Kb`
          : `${maxVideoSize}MB`

        const errorMsg = tooSmall
          ? t('{{file}} file is smaller than {{fileSize}}', {
              file: file.name,
              fileSize: fileSize
            })
          : t('{{file}} file is larger than {{fileSize}}', {
              file: file.name,
              fileSize: fileSize
            })

        await handleErrorTask(
          {
            ...task,
            status: AsyncSourceImporterTaskStatus.Fail,
            detailStatus: AsyncSourceImporterTaskDetailStatus.CheckFailed
          },
          new Error(errorMsg)
        )

        return
      }

      // 4. upload media
      let s3Result = undefined
      try {
        const signature = await getS3Signature(file)
        s3Result = await uploadFileToS3(file, signature)
        if (!s3Result?.key) {
          throw new Error()
        }
      } catch {
        await handleErrorTask(
          {
            ...task,
            status: AsyncSourceImporterTaskStatus.Fail,
            detailStatus: AsyncSourceImporterTaskDetailStatus.UploadFailed
          },
          new Error(t('Upload failed'))
        )

        return
      }

      media = {
        ...media,
        signature: {
          ...s3Result
        }
      }
      task = {
        ...task,
        media
      }
      await dispatch(
        updateAsyncImporterTasks({
          tasks: [
            {
              ...task
            }
          ]
        })
      )

      // 5. create video
      key = s3Result.key
      await handleCreateVideo(key, task)
    },
    [t, dispatch, handleErrorTask, handleCreateVideo]
  )

  return { youTubeImporter }
}

export default useAsyncImporterTask
