import { createSlice } from '@reduxjs/toolkit'

export type GlobalPopupModals = 'shop_app' | 'tour'

type InitialState = {
  showPlaylistToWebsiteBanner: boolean
  showPDPEmbedBanner: boolean
  sidebarCollapsed: boolean
  popupModals: GlobalPopupModals[]
  disableSidebarAnimation: boolean
  hasNewNotifications: boolean
  isWidgetVisible: boolean
}

const initialState: InitialState = {
  showPlaylistToWebsiteBanner: false,
  showPDPEmbedBanner: false,
  sidebarCollapsed: true,
  popupModals: ['tour', 'shop_app'],
  disableSidebarAnimation: false,
  hasNewNotifications: false,
  isWidgetVisible: false
}

const slice = createSlice({
  name: 'ui',
  initialState: initialState,
  reducers: {
    setShowPlaylistToWebsiteBanner(state, action) {
      state.showPlaylistToWebsiteBanner = action.payload
    },
    setShowPDPEmbedBanner(state, action) {
      state.showPDPEmbedBanner = action.payload
    },
    setIsSidebarCollapsed(state, action) {
      state.sidebarCollapsed = action.payload
    },
    updatePopupModalsHasShown(state, action) {
      if (state.popupModals.includes(action.payload)) {
        state.popupModals = state.popupModals.filter(
          (modal) => modal !== action.payload
        )
      }
    },
    setDisableSidebarAnimation(state, action) {
      state.disableSidebarAnimation = action.payload
    },
    setHasNewNotification(state, action) {
      state.hasNewNotifications = action.payload
    },
    setIsWidgetVisible(state, action) {
      state.isWidgetVisible = action.payload
    }
  }
})

export default slice.reducer

export const {
  setShowPlaylistToWebsiteBanner,
  setShowPDPEmbedBanner,
  setIsSidebarCollapsed,
  setDisableSidebarAnimation,
  updatePopupModalsHasShown,
  setHasNewNotification,
  setIsWidgetVisible
} = slice.actions
