import { useMemo } from 'react'

import { CTA_TYPE } from '@src/constants'

import { useCTATranslation } from './useCTATranslation'

/**
 * Provide a list of CTA translation entries sorted by key.
 * @returns
 */
export const useCTATranslationEntries = (): Array<any> => {
  const { t } = useCTATranslation()

  return useMemo(
    () =>
      Object.entries(CTA_TYPE)
        .sort(([, keyA], [, keyB]) =>
          keyA.toLowerCase() > keyB.toLowerCase() ? 1 : -1
        )
        .map(([, key]) => [key, t(key)]),
    [t]
  )
}
