import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { Dispatch } from 'redux'

type IProps = {
  businessStores: Record<string, globalLib.BusinessStore[]>
  businessShopifyStores: Record<string, globalLib.BusinessStore[]>
  storeProducts: Record<string, globalLib.Product[]>
  storeProductPaging: Record<string, string>
}

const initialState: IProps = {
  businessStores: {},
  businessShopifyStores: {},
  storeProducts: {},
  storeProductPaging: {}
}

const slice = createSlice({
  name: 'storeProduct',
  initialState: initialState,
  reducers: {
    fetchBusinessStoresSuccess(state, action) {
      const { businessId, businessStores } = action.payload
      state.businessStores[businessId] = businessStores
      state.businessShopifyStores[businessId] = businessStores.filter(
        (item) => {
          return item.provider === 'shopify'
        }
      )
    },
    fetchStoreProductListSuccess(state, action) {
      const { storeId, page, products, paging } = action.payload
      if (!page) {
        state.storeProducts[storeId] = products
      } else {
        state.storeProducts[storeId] = state.storeProducts[storeId].concat(
          products
        )
      }
      state.storeProductPaging[storeId] = paging
    }
  }
})

export default slice.reducer
export const {
  fetchBusinessStoresSuccess,
  fetchStoreProductListSuccess
} = slice.actions

const fetchBusinessStoresRequest = createAction(
  'storeProduct/fetchBusinessStoresRequest'
)
const fetchBusinessStoresFailure = createAction(
  'storeProduct/fetchBusinessStoresFailure'
)

export function fetchBusinessStoreList(
  businessId: string,
  keyword?: string,
  pageSize = 100,
  page?: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchBusinessStoresRequest())
      let url = `bus/${businessId}/business_stores?page_size=${pageSize}`
      let params = {
        params: {
          q: keyword
        }
      }
      if (page) {
        url = page
        params = undefined
      }

      const response = await api.get(url, params)

      const { business_stores = [] } = response.data

      dispatch(
        fetchBusinessStoresSuccess({
          businessId,
          businessStores: business_stores
        })
      )

      return response
    } catch (error) {
      dispatch(fetchBusinessStoresFailure())

      return error
    }
  }
}

const fetchStoreProductListRequest = createAction(
  'storeProduct/fetchStoreProductListRequest'
)
const fetchStoreProductListFailure = createAction(
  'storeProduct/fetchStoreProductListFailure'
)

export function fetchStoreProductList(params: {
  businessId: string
  storeId: string
  channelId?: string
  accesses?: string
  searchTerm?: string
  priceFilter?: string
  price?: string
  sort?: string
  page?: string
  pageSize?: number
}) {
  const {
    businessId,
    storeId,
    searchTerm,
    priceFilter,
    price,
    sort,
    page,
    channelId,
    accesses = 'public,private',
    pageSize = 30
  } = params

  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchStoreProductListRequest())

      let url = `bus/${businessId}/search_local_products`
      if (page) {
        url = page
      }

      const response = await api.get(url, {
        params: {
          channel_id: channelId,
          business_store: storeId,
          page_size: pageSize,
          q: searchTerm,
          sort: sort,
          ...(priceFilter === 'lt'
            ? {
                price_max: price || 0
              }
            : {
                price_min: price || undefined
              }),
          accesses: channelId ? accesses : undefined
        }
      })

      const { products = [], paging } = response?.data || {}
      dispatch(
        fetchStoreProductListSuccess({
          storeId,
          page,
          products,
          paging
        })
      )

      return response
    } catch (error) {
      dispatch(fetchStoreProductListFailure())

      return error
    }
  }
}
