import api, { API_URL } from '@src/common/utils/api'

export function image2Video(imageKey: string, businessId: string) {
  return async (): Promise<string | any> => {
    try {
      const response = await api.post(
        `${API_URL}/studio/video_compilation_batch/create_video_from_image`,
        {
          media_key: imageKey, // medias/2024/1/17/1705457869-vifwyxbc/original/7C41D2A262F194D8299C683C773BFECA.jpg
          business_id: businessId
        }
      )

      return response
    } catch (error) {
      return error
    }
  }
}
