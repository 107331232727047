import { localStorageProvider } from '@src/utils/storageProvider'

export const businessList = 'businessList'

export const channelList = 'channelList'

export const lastChannel = 'lastChannel'

export const lastChatChannel = 'lastChatChannel'

export const lastStore = 'lastStore'

export const feedList = 'feedList'
// Set value for a node in a tree structure (store) with keyArray as the path
const recursiveSet = (keyArray, value, store) => {
  if (keyArray.length === 1) {
    if (typeof store === 'string') return { [keyArray[0]]: value }
    else return { ...store, [keyArray[0]]: value }
  } else {
    const key = keyArray.shift()
    if (typeof store === 'string')
      return { [key]: recursiveSet(keyArray, value, store) }
    else return { ...store, [key]: recursiveSet(keyArray, value, store[key]) }
  }
}
// Get value at a node in a tree structure(store) with keyArray as the path
const recursiveGet = (keyArray, store) => {
  if (keyArray.length === 1) return store[keyArray[0]] || null
  else {
    const key = keyArray.shift()
    if (key.length && store[key]) return recursiveGet(keyArray, store[key])
    else return null
  }
}

export const setStorage = (key, path = [], value) => {
  const clonePath = [...path]
  const currentValue = getStorage(key, clonePath) || {}
  const stringValue = JSON.stringify(recursiveSet(path, value, currentValue))
  localStorageProvider.setItem(key, stringValue)
}

export const getStorage = (key, path = []) => {
  const currentValue = localStorageProvider.getItem(key)
  if (currentValue) return recursiveGet(path, JSON.parse(currentValue))
  else return null
}

export const combineLocalStorageList = (storageList, dataList) => {
  const distinctList = dataList.filter((item) => item !== storageList)
  return [storageList, ...distinctList]
}
