import React, { useEffect } from 'react'

import { ExclamationCircleFilled } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Title } from '@src/components'
import FWButton from '@src/components/FWButton/FWButton'
import { PLGWrapper } from '@src/components/PLGWrapper/PLGWrapper'
import usePixelAmpTracking from '@src/hooks/usePixelAmpTracking'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  BannerContainer,
  TextContainer,
  EmojiContainer
} from '../Banners.styles'

type IUploadLimitBannerProps = {
  showNavigateAwayWarning?: boolean
  businessId: string
}

export const UploadLimitBanner: React.FC<IUploadLimitBannerProps> = ({
  showNavigateAwayWarning = false,
  businessId
}) => {
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const navigate = useNavigate()

  useEffect(() => {
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_REACHED_UPLOAD_LIMIT)
  }, [pixelAmpTracking])

  const onUpgradePlan = () => {
    if (showNavigateAwayWarning) {
      Modal.confirm({
        title: t('Warning'),
        content: t(
          'By leaving this page you will lose your uploads in progress.'
        ),
        okText: t('Confirm'),
        onOk() {
          navigate(`/business/${businessId}/pricing-plan`)
        }
      })
    } else {
      navigate(`/business/${businessId}/pricing-plan`)
    }
  }

  return (
    <PLGWrapper
      ifPLGBusiness={
        <BannerContainer>
          <EmojiContainer>
            <ExclamationCircleFilled style={{ color: '#0F6BFF' }} />
          </EmojiContainer>
          <TextContainer>
            <Title level={5} fontSize={14} weight={500} noMargin>
              {t('You’ve reached your upload limit. Upgrade to add more.')}
            </Title>
            <FWButton
              type="link"
              onClick={onUpgradePlan}
              css={css`
                padding: 0 8px;
              `}
            >
              {t('Upgrade Plan')}
            </FWButton>
          </TextContainer>
        </BannerContainer>
      }
    />
  )
}
