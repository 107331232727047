import { useCallback, useContext } from 'react'

import { useSelector } from 'react-redux'

import { BusinessContext } from '../common/contexts/BusinessContext'
import { ROLES } from '../constants'

export const PERMISSIONS = {
  MEMBERSHIP_VIEW: 'membership_view',
  MEMBERSHIP_INVITE: 'membership_invite',
  MEMBERSHIP_EDIT_ROLES: 'membership_editRoles',
  CHANNEL_CREATE: 'channel_create',
  CHANNEL_DELETE: 'channel_delete',
  CHANNEL_EDIT: 'channel_edit',
  CHANNEL_UPLOAD_VIDEOS: 'channel_upload_videos',
  PLAYLIST_CREATE: 'playlist_create',
  PLAYLIST_DELETE: 'playlist_delete',
  PLAYLIST_ADD_REMOVE_VIDEOS: 'playlist_add_remove_videos',
  PLAYLIST_EDIT: 'playlist_edit',
  PLAYLIST_COLLECTION_CREATE: 'playlis_collectiont_create',
  VIDEOS_EDIT: 'videos_edit',
  VIDEOS_CHANNEL_DELETE: 'videos_channel_delete',
  VIDEOS_EMBED: 'videos_embed',
  PAYMENTS_UPDATE: 'payments_update',
  PAYMENTS_VIEW: 'payments_view',
  BUSINESS_EDIT: 'business_edit',
  BUSINESS_CREATE: 'business_create',
  UPGRADE_SUBSCRIPTION: 'upgrade_subscription',
  BUSINESS_APPS_VIEW: 'business_apps_view',
  ACCESS_FEEDS: 'access_feeds',
  REPOST_VIDEO: 'repost_video',
  SEARCH_MY_CONTENT: 'search_my_content',
  MANAGE_AUTOMATIONS: 'manage_automations',
  SHOWROOM_ADMIN: 'showroom_admin',
  OTO_ADMIN: 'oto_admin',
  VIDEO_INSIGHTS: 'video_insights',
  SYNDICATE_VIDEO: 'syndicate_video',
  IMPORT_PROVIDER_PRODUCT: 'import_provider_product',
  VIRTUAL_ASSISTANT: 'virtual_assistant',
  STORE_ACCESS: 'store_access',
  AUDIT_TOOL: 'audit_tool',
  SEMANTIC_SEARCH: 'semantic_search'
}

/**
 * Roles and permissions hook that will control if a user, based on role,
 * will be able to see certain functionalities in the portal
 *
 * https://docs.google.com/spreadsheets/d/1DySh467e1wMuHjXIATKlp8WE0mclHPJWxWo2pOGxtCY/edit#gid=0
 */
export function useRolesAndPermissions() {
  const businessId = useContext(BusinessContext)

  const role = useSelector(
    (state) => state.business?.businesses[businessId]?.role
  )

  const userCan = useCallback(
    (permission, userRole = null) => {
      return permissions[permission].includes(userRole ?? role)
    },
    [role]
  )

  return {
    userCan
  }
}

const permissions = {
  [PERMISSIONS.MEMBERSHIP_VIEW]: [ROLES.SUPER_USER, ROLES.ADMIN, ROLES.MEMBER],
  [PERMISSIONS.MEMBERSHIP_INVITE]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.MEMBERSHIP_EDIT_ROLES]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.CHANNEL_CREATE]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.CHANNEL_DELETE]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.CHANNEL_EDIT]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.CHANNEL_UPLOAD_VIDEOS]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.PLAYLIST_CREATE]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.PLAYLIST_DELETE]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.PLAYLIST_ADD_REMOVE_VIDEOS]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.PLAYLIST_EDIT]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.VIDEOS_EDIT]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.PLAYLIST_COLLECTION_CREATE]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER
  ],
  [PERMISSIONS.VIDEOS_CHANNEL_DELETE]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER
  ],
  [PERMISSIONS.VIDEOS_EMBED]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.MEMBER_LITE,
    ROLES.CREATOR
  ],
  [PERMISSIONS.PAYMENTS_UPDATE]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.PAYMENTS_VIEW]: [ROLES.SUPER_USER, ROLES.ADMIN, ROLES.MEMBER],
  [PERMISSIONS.BUSINESS_EDIT]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.UPGRADE_SUBSCRIPTION]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.BUSINESS_CREATE]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.BUSINESS_APPS_VIEW]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER
  ],
  [PERMISSIONS.ACCESS_FEEDS]: [ROLES.SUPER_USER, ROLES.ADMIN, ROLES.MEMBER],
  [PERMISSIONS.REPOST_VIDEO]: [ROLES.ADMIN, ROLES.SUPER_USER, ROLES.MEMBER],
  [PERMISSIONS.SEARCH_MY_CONTENT]: [
    ROLES.ADMIN,
    ROLES.SUPER_USER,
    ROLES.MEMBER
  ],
  [PERMISSIONS.MANAGE_AUTOMATIONS]: [ROLES.ADMIN, ROLES.SUPER_USER],
  [PERMISSIONS.SHOWROOM_ADMIN]: [ROLES.ADMIN, ROLES.SUPER_USER],
  [PERMISSIONS.OTO_ADMIN]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.VIDEO_INSIGHTS]: [ROLES.SUPER_USER, ROLES.ADMIN, ROLES.MEMBER],
  [PERMISSIONS.SYNDICATE_VIDEO]: [ROLES.SUPER_USER, ROLES.ADMIN],
  [PERMISSIONS.IMPORT_PROVIDER_PRODUCT]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.CREATOR
  ],
  [PERMISSIONS.VIRTUAL_ASSISTANT]: [ROLES.ADMIN, ROLES.SUPER_USER],
  [PERMISSIONS.STORE_ACCESS]: [
    ROLES.SUPER_USER,
    ROLES.ADMIN,
    ROLES.MEMBER,
    ROLES.CREATOR
  ],
  [PERMISSIONS.AUDIT_TOOL]: [ROLES.SUPER_USER],
  [PERMISSIONS.SEMANTIC_SEARCH]: [ROLES.SUPER_USER]
}
