import {
  Slice,
  createSlice,
  createAsyncThunk,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import api from '@src/common/utils/api'

const SLICE_NAME = 'marketplace'

type SliceState = {
  activeLinkedChannel: {
    playlistId: string
    playlistName: string
    marketId: string
    channel: globalLib.Channel
    videoSearchObject: SearchObject
    videoPaging: globalLib.Paging
    videos: globalLib.IVideo[]
    playlistSearchObject: SearchObject
    playlistPaging: globalLib.Paging
    playlists: globalLib.Playlist[]
    channelVideoCount?: globalLib.VideoCount
  } | null
  searchedChannels: globalLib.Channel[]
  isLoading: boolean
  paging: globalLib.Paging
  error: string | null
  filterByOriginal: boolean
  filterByLinked: boolean
}

type SearchObject = {
  query?: string
  filter?: string
  sort?: string
}

const initialState: SliceState = {
  activeLinkedChannel: null,
  searchedChannels: [],
  isLoading: false,
  paging: null,
  error: null,
  filterByOriginal: true,
  filterByLinked: true
}

export const setLinkedChannel = createAsyncThunk(
  `${SLICE_NAME}/setActiveLinkedChannel`,
  async (
    params: {
      linkedChannel: globalLib.Channel
      businessId: string
      channelId: string
      page?: string
    } | null
  ) => {
    if (!params) return null
    const { encoded_id } = params.linkedChannel
    const videosResponse = await api.get(`/bus/${params.businessId}/videos`, {
      params: {
        channel_id: encoded_id,
        sort: 'started_at_or_inserted_at_desc'
      }
    })
    const playlistsUrl = `/bus/${params.businessId}/channels/${encoded_id}/playlists`
    const playlistsResponse = await api.get(playlistsUrl, {
      params: {
        page_size: 100
      }
    })

    const videoCountResponse = await api.post(
      `/bus/${params.businessId}/channels/${encoded_id}/videos/count`,
      {
        all: {},
        live_streams: {
          is_live_stream: true,
          original_content: true,
          syndicated_content: true
        },
        short_videos: { exclude_reposts: false, is_live_stream: false }
      }
    )

    const {
      live_streams_count = 0,
      short_videos_count = 0,
      all_count = 0
    } = videoCountResponse?.data

    return {
      playlistId: '',
      playlistName: '',
      marketId: params.channelId,
      channel: params.linkedChannel,
      videoSearchObject: {
        query: null,
        filter: null,
        sort: 'started_at_or_inserted_at_desc'
      },
      videoPaging: videosResponse.data.paging || null,
      videos: videosResponse.data.videos || [],
      channelVideoCount: {
        liveStreamsCount: live_streams_count,
        shortVideosCount: short_videos_count,
        allVideosCount: all_count
      },
      playlistSearchObject: {},
      playlistPaging: playlistsResponse.data.paging || null,
      playlists: playlistsResponse.data.playlists || []
    }
  }
)

export const searchLinkedChannels = createAsyncThunk(
  `${SLICE_NAME}/searchLinkedChannels`,
  async (params: { channelId: string; query?: string; page?: string }) => {
    const url =
      params.page ||
      `bus/channels/${params.channelId}/linked_channels?page_size=10`
    const apiParams = params.query ? { page_size: 5, q: params.query } : null
    const response = await api.get(url, { params: apiParams })
    const { channels, paging } = response.data

    return {
      channels,
      paging,
      page: params.page
    }
  }
)

export const setFilterBy = createAsyncThunk(
  `${SLICE_NAME}/setFilterBy`,
  async (params: { filterByOriginal?: boolean; filterByLinked?: boolean }) => {
    return {
      filterByOriginal: params.filterByOriginal,
      filterByLinked: params.filterByLinked
    }
  }
)

export const setActiveLinkedPlaylist = createAsyncThunk(
  `${SLICE_NAME}/setActiveLinkedPlaylist`,
  async (params: { id: string; name: string }, { getState }) => {
    const state = getState()['marketplace']

    return {
      ...state.activeLinkedChannel,
      playlistId: params.id,
      playlistName: params.name
    }
  }
)

export const searchLinkedVideos = createAsyncThunk(
  `${SLICE_NAME}/searchLinkedVideos`,
  async (
    params: {
      businessId: string
      paging?: globalLib.Paging
      query?: string
      filter?: string
      sort?: string
    } | null,
    { getState }
  ) => {
    const sort = params.sort || 'started_at_or_inserted_at_desc'
    const state = getState()['marketplace']
    if (!params || !state.activeLinkedChannel) return null
    const { encoded_id } = state.activeLinkedChannel.channel
    const url = params.paging
      ? params.paging.next
      : `/bus/${params.businessId}/videos`
    const response = await api.get(url, {
      params: {
        channel_id: encoded_id,
        query: params.query || '',
        filter: params.filter || '',
        sort
      }
    })
    const { paging = null, videos = [] } = response.data

    return {
      ...state.activeLinkedChannel,
      videos: params.paging
        ? [...state.activeLinkedChannel.videos, ...videos]
        : videos,
      videoPaging: paging,
      videoSearchObject: {
        query: params.query,
        filter: params.filter,
        sort
      }
    }
  }
)

export const fetchPlaylistVideos = createAsyncThunk(
  `${SLICE_NAME}/searchLinkedPlaylistVideos`,
  async (
    params: {
      businessId: string
      paging?: globalLib.Paging
    } | null,
    { getState }
  ) => {
    const state = getState()['marketplace']
    if (!params || !state.activeLinkedChannel) return null
    const { playlistId, channel } = state.activeLinkedChannel

    const url = params.paging
      ? params.paging.next
      : `/bus/${params.businessId}/channels/${channel.encoded_id}/playlists/${playlistId}/videos`

    const response = await api.get(url)
    const { paging = null, videos = [] } = response.data

    return {
      ...state.activeLinkedChannel,
      videos,
      videoPaging: paging,
      videoSearchObject: {
        query: null,
        filter: null,
        sort: 'started_at_or_inserted_at_desc'
      }
    }
  }
)

export const searchLinkedPlaylistVideos = createAsyncThunk(
  `${SLICE_NAME}/searchLinkedPlaylistVideos`,
  async (
    params: {
      businessId: string
      paging?: globalLib.Paging
      query?: string
      filter?: string
      sort?: string
    } | null,
    { getState }
  ) => {
    const state = getState()['marketplace']
    if (!params || !state.activeLinkedChannel) return null
    const { playlistId, channel } = state.activeLinkedChannel

    const url = params.paging
      ? params.paging.next
      : `/bus/${params.businessId}/videos`

    const response = await api.get(url, {
      params: {
        channel_id: channel.encoded_id,
        playlist_id: playlistId,
        query: params.query || '',
        filter: params.filter || '',
        sort: params.sort || ''
      }
    })
    const { paging = null, videos = [] } = response.data

    return {
      ...state.activeLinkedChannel,
      videos: params.paging
        ? [...state.activeLinkedChannel.videos, ...videos]
        : videos,
      videoPaging: paging,
      videoSearchObject: {
        query: params.query,
        filter: params.filter,
        sort: params.sort
      }
    }
  }
)

export const searchLinkedPlaylist = createAsyncThunk(
  `${SLICE_NAME}/searchLinkedPlaylist`,
  async (
    params: {
      businessId: string
      query?: string
      sort?: string
      paging?: globalLib.Paging
    } | null,
    { getState }
  ) => {
    const state = getState()['marketplace']
    if (!params || !state.activeLinkedChannel) return null
    const { encoded_id } = state.activeLinkedChannel.channel

    const url = params.paging
      ? params.paging.next
      : `/bus/${params.businessId}/channels/${encoded_id}/playlists`

    const response = await api.get(url, {
      params: {
        page_size: 100,
        q: params.query,
        sort: params.sort
      }
    })
    const { paging = null, playlists = [] } = response.data

    return {
      ...state.activeLinkedChannel,
      playlists: params.paging
        ? [...state.activeLinkedChannel.playlists, ...playlists]
        : playlists,
      playlistPaging: paging,
      playlistSearchObject: {
        query: params.query,
        sort: params.sort
      }
    }
  }
)

const slice: Slice<
  SliceState,
  SliceCaseReducers<SliceState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLinkedChannel.pending, onStart)
      .addCase(setLinkedChannel.rejected, onError)
      .addCase(setLinkedChannel.fulfilled, (state, action) => {
        state.activeLinkedChannel = action.payload
        state.isLoading = false
      })
      .addCase(searchLinkedChannels.pending, onStart)
      .addCase(searchLinkedChannels.rejected, onError)
      .addCase(searchLinkedChannels.fulfilled, (state, action) => {
        const { channels, paging, page } = action.payload
        if (!page) state.searchedChannels = channels
        else state.searchedChannels = state.searchedChannels.concat(channels)
        state.paging = paging || null
        state.isLoading = false
      })
      .addCase(setFilterBy.pending, onStart)
      .addCase(setFilterBy.fulfilled, (state, action) => {
        const { filterByOriginal, filterByLinked } = action.payload
        if (typeof filterByOriginal !== 'undefined')
          state.filterByOriginal = filterByOriginal
        if (typeof filterByLinked !== 'undefined')
          state.filterByLinked = filterByLinked
      })
      .addCase(setActiveLinkedPlaylist.rejected, onError)
      .addCase(setActiveLinkedPlaylist.fulfilled, (state, action) => {
        state.activeLinkedChannel = action.payload
        state.isLoading = false
      })
      .addCase(searchLinkedVideos.pending, onStart)
      .addCase(searchLinkedVideos.rejected, onError)
      .addCase(searchLinkedVideos.fulfilled, (state, action) => {
        state.activeLinkedChannel = action.payload
        state.isLoading = false
      })
      .addCase(searchLinkedPlaylist.pending, onStart)
      .addCase(searchLinkedPlaylist.rejected, onError)
      .addCase(searchLinkedPlaylist.fulfilled, (state, action) => {
        state.activeLinkedChannel = action.payload
        state.isLoading = false
      })
      .addCase(searchLinkedPlaylistVideos.pending, onStart)
      .addCase(searchLinkedPlaylistVideos.rejected, onError)
      .addCase(searchLinkedPlaylistVideos.fulfilled, (state, action) => {
        state.activeLinkedChannel = action.payload
        state.isLoading = false
      })
  }
})

// Helper actions
function onStart(state) {
  state.isLoading = true
  state.error = null
}

function onError(state, action) {
  state.error = action.type
  state.isLoading = false
}

export default slice.reducer
