import React from 'react'

import { Box, Flex } from '@src/components/EmotionLayout'
import Title from '@src/components/Title'
import {
  BatchImporterMedia,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  mediaItem?: BatchImporterMedia
  tracking: TrackingDetails
}

const { Link } = Typography

export const BatchImporterMediaLinkItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const { mediaItem } = props

  return (
    <Flex width={160} flexDirection="column" gap={8}>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={160}
        height={284}
        bg="#F5F7FA"
        borderRadius={6}
        p="62px 16px 120px"
        gap={24}
      >
        <Title fontSize={12} weight={400} textAlign="center" noMargin>
          {t('Upload video to view preview')}
        </Title>
        <Box width={42} height={42} as="img" src="/icon/play_black.svg" />
      </Flex>
      {!!mediaItem?.sourceUrl && (
        <Flex>
          <Link
            underline
            ellipsis={true}
            href={mediaItem.sourceUrl}
            target="_blank"
          >
            {mediaItem.sourceUrl}
          </Link>
        </Flex>
      )}
    </Flex>
  )
}
