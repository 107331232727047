import React from 'react'

import loadable from '@loadable/component'
import { RoutesWithoutAuth as StoreRoutes } from '@src/modules/store/routes'
import { Navigate, Route, Routes } from 'react-router-dom'

import { UnauthenticatedRoutes as ChannelUnauthenticatedRoutes } from '../../modules/channel/routes/UnauthenticatedRoutes'

const Pricing = loadable(() => import('../../pages/home/Pricing'))

const WPRedirect = loadable(() => import('../../pages/home/WPRedirect'))

const UnauthenticatedInvitation = loadable(
  () => import('../../pages/invitation/UnauthenticatedInvitation')
)

const Integrations = loadable(() => import('../../pages/home/Integrations'), {
  resolveComponent: (component) => component.Integrations
})

const Guest = loadable(() => import('../../pages/home/Guest'))

const HomePage = loadable(() => import('../../pages/home'))

export default function UnauthenticatedRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route
        path="/invitation/:token"
        element={<UnauthenticatedInvitation />}
      />
      {StoreRoutes}
      <Route path="/wp_callback" element={<WPRedirect />} />
      <Route path="/integration" element={<Integrations />} />
      <Route path="/guest" element={<Guest />} />
      {ChannelUnauthenticatedRoutes}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
