import { useEffect, useState } from 'react'

import { WEB_SOCKET_STATUS } from '@src/constants'
import { useCommonWebsocketTopics } from '@src/hooks/Websocket/useCommonWebsocketTopics'
import { useWebsocketConnection } from '@src/hooks/Websocket/useWebsocketConnection'
import { useWebsocketHeartBeat } from '@src/hooks/Websocket/useWebsocketHeartBeat'
import { setStatus } from '@src/redux/websocket'
import { useDispatch } from 'react-redux'
import { ReadyState } from 'react-use-websocket'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'

export const useImage2Video = (): {
  readyState: number
  joinTopic: (topicId: string) => void
  leaveTopic: (topicId: string) => void
  sendJsonMessage: SendJsonMessage
  setTopic: (topic: string) => Promise<any>
} => {
  const dispatch = useDispatch()
  const [topicState, setTopicState] = useState<string>()
  const [callbackState, setCallbackState] = useState<(data: any) => void>()

  const { readyState, sendJsonMessage } = useWebsocketConnection({
    path: 'studio_socket/websocket',
    options: {
      onOpen: () =>
        dispatch(setStatus({ status: WEB_SOCKET_STATUS.OPEN, error: null })),
      onClose: () => {
        dispatch(setStatus({ status: WEB_SOCKET_STATUS.CLOSED, error: null }))
      },
      onMessage: async (event) => {
        const data = JSON.parse(event.data)
        if (
          data?.topic === topicState &&
          data?.event === 'video_compilation_batch_status' &&
          data?.payload?.status === 'completed' &&
          callbackState
        ) {
          callbackState(data)
          leaveTopic(topicState)
          setCallbackState(null)
        }
      },
      onError: (event) => {
        dispatch(setStatus({ status: WEB_SOCKET_STATUS.ERROR, error: event }))
      }
    }
  })

  useWebsocketHeartBeat({ readyState, sendJsonMessage })
  const { joinTopic, leaveTopic } = useCommonWebsocketTopics({
    sendJsonMessage
  })

  useEffect(() => {
    if (readyState === ReadyState.OPEN && topicState) {
      joinTopic(topicState)
    }
  }, [readyState, joinTopic, topicState])

  const setTopic = async (topic: string) => {
    return new Promise<any>((resolve) => {
      setTopicState(topic)
      setCallbackState(() => (data) => {
        resolve(data)
      })
    })
  }

  return { readyState, joinTopic, leaveTopic, sendJsonMessage, setTopic }
}
