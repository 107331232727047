import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Applications = loadable(() => import('../pages/Applications'))

export const Routes = (
  <Route path="business/:businessId/apps" element={<Applications />} />
)
