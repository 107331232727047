import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router'

const FbAuth = loadable(() => import('../../pages/Library/Importer/FbAuth'))
const TkAuth = loadable(() => import('../../pages/Library/Importer/TkAuth'))
const GoogleAuth = loadable(
  () => import('../../pages/Library/Importer/GoogleAuth')
)
const BulkUploadContainer = loadable(
  () => import('../../pages/Library/Importer/BulkUploadContainer'),
  {
    resolveComponent: (component) => component.BulkUploadContainer
  }
)

const SourceImporterContainer = loadable(
  () => import('../../pages/Library/Importer/SourceImporterContainer'),
  {
    resolveComponent: (component) => component.SourceImporterContainer
  }
)

const ConsentRequest = loadable(
  () => import('../../pages/Library/Importer/ConsentRequest')
)

export const ImporterRoutes = (
  <>
    <Route path="/facebook/auth" element={<FbAuth />} />
    <Route path="/tiktok/oauth" element={<TkAuth />} />
    <Route path="/google/oauth" element={<GoogleAuth />} />
    <Route
      path="/business/:businessId/channel/:channelId/bulk-upload"
      element={<BulkUploadContainer />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/source-importer"
      element={<SourceImporterContainer />}
    />
    <Route path="/consent_request" element={<ConsentRequest />} />
  </>
)
