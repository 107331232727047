import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { Dispatch } from 'redux'

type IProps = {
  titles: globalLib.CustomCTATitle[]
}

const initialState: IProps = {
  titles: []
}

const slice = createSlice({
  name: 'customCTA',
  initialState: initialState,
  reducers: {
    fetchCustomCTATitlesSuccess(state, action) {
      const { titles } = action.payload
      state.titles = titles || []
    }
  }
})

export default slice.reducer
export const { fetchCustomCTATitlesSuccess } = slice.actions

const fetchCustomCTATitlesRequest = createAction(
  'customCTA/fetchCustomCTATitlesRequest'
)
const fetchCustomCTATitlesFailure = createAction(
  'customCTA/fetchCustomCTATitlesRequest'
)

export function fetchCustomCTATitles() {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchCustomCTATitlesRequest())
      const response = await api.get(`/bus/custom_cta/titles`)
      const { titles } = response.data
      dispatch(
        fetchCustomCTATitlesSuccess({
          titles
        })
      )

      return response
    } catch (error) {
      dispatch(fetchCustomCTATitlesFailure())
    }
  }
}
