import { TFunction } from 'i18next'

// `getAvaApiErrorParser` returns an error message string based on the AVA API error code.
// Parameters:
// - `error` (any): The error object from the AVA API, e.g., {code: 'xxx', details?: {maxLimit: xxx}}.
// - `t` (TFunction): The translation function.
const getAvaApiErrorParser = (error: any, t: TFunction): string => {
  const errorMessageObj = {
    exceeding_product_limit: t('Exceeding product limit: {{max}} added', {
      max: error?.details?.max
    }),
    product_not_found: t('Product not found'),
    product_not_linked: t('Product not linked'),
    product_business_mismatch: t('Product business mismatch'),
    product_used_by_video_resource: t('Product used by video resource')
  }

  return errorMessageObj[error?.code]
}

export { getAvaApiErrorParser }
