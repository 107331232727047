export const EMBED_PLACEMENT = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
  SIDE: 'side'
}

export const REQUEST_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected'
}

export const MEMBER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  PENDING: 'pending'
}

export const CTA_TYPE = {
  APPLY_NOW: 'apply',
  BOOK_A_TEST_DRIVE: 'book_a_test_drive',
  BOOK_NOW: 'book',
  CONTACT_US: 'contact',
  CTA: 'cta',
  CUSTOM: 'custom',
  DONATE: 'donate',
  DOWNLOAD: 'download',
  ENROLL_NOW: 'enroll_now',
  GET_OFFER: 'get_offer',
  GET_QUOTE: 'get_quote',
  GET_SHOWTIMES: 'get_showtimes',
  INVEST_NOW: 'invest_now',
  LEARN_MORE: 'learn_more',
  LIVE: 'live',
  ORDER_NOW: 'order',
  PLAY_NOW: 'play',
  READ_MORE: 'read_more',
  REMINDER: 'reminder',
  RSVP: 'rsvp',
  SEE_FULL_RECIPE: 'see_full_recipe',
  SHOP_NOW: 'shop_now',
  SHOP_RECIPE: 'shop_recipe',
  SIGN_UP: 'sign_up',
  VIEW: 'view',
  VIEW_MENU: 'view_menu',
  VIEW_RECIPE: 'view_recipe',
  SEE_MORE: 'see_more',
  TALK_TO_AN_EXPERT: 'talk_to_an_expert'
}

export const HASHTAGS = 'hashtags'
export const CHANNELS = 'channels'
export const RECOMMENDATION = 'recommendation'
export const ALL_YOUR_VIDEOS = 'all_your_videos'
export const FAVORITE = 'favorite'

export enum CHANNEL_TABS {
  ORIGINAL = 'original',
  ALL = 'all',
  ARCHIVE = 'archive',
  PLAYLIST_OVERVIEW = 'playlist_overview',
  PLAYLIST = 'playlist',
  LINKED_PLAYLIST = 'linked_playlist',
  PLAYLIST_COLLECTION_OVERVIEW = 'playlist_collection_overview',
  PLAYLIST_COLLECTION = 'playlist_collection',
  LIVESTREAM = 'livestream',
  FLOWS = 'Flows',
  SOURCE_IMPORTER_OVERVIEW = 'source_importer_overview',
  SOURCE_IMPORTER = 'source_importer'
}

export const PAY_PLANS = {
  FREE: 'free',
  PRO: 'pro',
  PLUS: 'plus',
  STANDARD: 'standard',
  ENTERPRISE: 'enterprise'
}

export const ONBOARDING_MODES = {
  UPDATE: 'update',
  CREATE: 'create'
}

export const BILLING_PERIOD = {
  MONTHLY: 'monthly',
  ANNUALLY: 'yearly'
}

export const ROLES = {
  SUPER_USER: 'super_user',
  ADMIN: 'admin',
  MEMBER: 'member',
  MEMBER_LITE: 'member-lite',
  CREATOR: 'creator-store-member'
}

export const ROLE_STRINGS = {
  super_user: 'Super User',
  admin: 'Admin',
  member: 'Member',
  'member-lite': 'Member Lite',
  'creator-store-member': 'Creator Store Member'
}

export const VIDEO_UPLOAD_METHOD = {
  LOCAL_UPLOAD: 'local_upload',
  EXTERNAL_IMPORT: 'external_import',
  EDIT_VIDEO: 'edit_video',
  LONG_TO_SHORT: 'long_to_short'
}

export const LIVESTREAM_CREATE_METHOD = {
  FROM_VIDEO: 'from_video'
}

export const CHANNEL = 'channel'
export const VIDEO = 'video'
export const PLAYLIST = 'playlist'
export const HTML = 'html'

export const COOKIES = {
  FW_STRIPE_COUPON_ID: 'fw_stripe_coupon_id'
}

export const LIVESTREAM_EVENT_DEFAULT_TRAILER_KEYWORD =
  'default_event_trailer.mp4'
export const LIVESTREAM_EVENT_TRAILER = 'assets/default_event_trailer.mp4'
export const LIVESTREAM_EVENT_POSTER = 'assets/default_event_trailer.jpg'
export const DEFAULT_TRAILER = `https://cdn1.fireworktv.com/assets/watermarked/540/${LIVESTREAM_EVENT_DEFAULT_TRAILER_KEYWORD}`

export const IOS_CAMERA_URL =
  'https://apps.apple.com/us/app/firework-camera/id1564522372'
export const ANDROID_CAMERA_URL =
  'https://play.google.com/store/apps/details?id=com.loopnow.camera'

export enum LIVESTREAM_STATUS {
  IDLE = 'idle',
  ACTIVE = 'active',
  PAUSED = 'paused',
  REPLAY = 'replay',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  REHEARSAL = 'rehearsal'
}

export enum LIVESTREAM_SOURCE {
  EXTERNAL = 'external',
  FIREWORK = 'fw',
  PRERECORDED = 'prerecorded'
}

export enum InteractionType {
  POLL = 'poll',
  APPOINTMENT = 'appointment',
  QUESTIONS = 'question',
  QUIZ = 'quiz',
  MULTIPLE_CHOICE = 'multiple_choice',
  PRODUCT = 'product',
  CTA = 'cta',
  REMINDER = 'reminder',
  GIVEAWAY = 'giveaway',
  LINK = 'link'
}

export const WEB_SOCKET_STATUS = {
  OPEN: 'open',
  CLOSED: 'closed',
  ERROR: 'error'
}

export const FW_FOR_BUSINESS = 'firework_for_business'

export const STORE_PROVIDERS = {
  BOUTIR: 'boutir',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  MAGENTO: 'magento',
  TEMP_SHOPIFY: 'temp_shopify',
  ECWID: 'ecwid',
  GOOGLE_MERCHANT: 'google_merchant',
  SALESFORCE: 'salesforce',
  LOCAL: 'local'
}

export const PLATFORM_PROVIDERS = {
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  TIKTOK: 'tiktok',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  LINE_LIVE: 'line'
}

export const PLATFORM_NAMES = {
  youtube: 'YouTube',
  facebook: 'Facebook',
  tiktok: 'TikTok',
  instagram: 'Instagram',
  twitter: 'Twitter',
  line: 'Line Live'
}

export const OAuthApps = {
  DUDA: 'duda',
  SHOPIFY: 'shopify'
}

export const PLAYLIST_SORT_OPTIONS = {
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  UPDATED_AT_ASC: 'updated_at_asc',
  UPDATED_AT_DESC: 'updated_at_desc'
}

export const DATETIME_FORMAT = 'MMMM Do YYYY h:mm a'
export const ONE_DAY = 86400000
export const ONE_HOUR = 3600000
export const FOREVER_STREAM_VIDEO_IDS = ['g46qzz', 'oynOpO', 'v7Jqzx']
export const TIME_TO_START = 5 * 60 * 1000
export const LIVESTREAM_EVENT_PRESTART_MS = 5 * 60 * 1000
export const LIVESTREAM_PRERECORDED_EVENT_PRESTART_MS = 5 * 60 * 1000
export const FETCH_LIVESTREAM_DETAILS_INTERVAL = 3 * 1000
export const MIN_VIDEO_DURATION = 3
export const LONG_TO_SHORT_EXTEND_TIME = 10
// This is basically 1s at a very low resolution
export const MIN_FILE_SIZE = 25000

export const LIVESTREAM_USER_STATUS = {
  ON_AIR: 'on_air',
  READY: 'ready'
}

export const LIVESTREAM_PROVIDERS = {
  IVS: 'ivs',
  AGORA: 'agora'
}

export const BULK_HASHTAG_ACTION = {
  ADD: 'add',
  DELETE: 'delete',
  TOP: 'top',
  BOTTOM: 'bottom',
  CENTER: 'center'
}

export const MAX_VIDEO_SELECTION = 50
export const MAX_BULK_HASHTAGS = 150
export const MAX_HASHTAG_VISIBLE_LENGTH = 50
export const MAX_HASHTAG_LENGTH = 100

export enum LIVESTREAM_ACTION_TYPE {
  HIGHLIGHT_ENTITY = 'highlight_entity',
  UNHIGHLIGHT_ENTITY = 'unhighlight_entity',
  ENABLE_ENTITY = 'enable_entity',
  DISABLE_ENTITY = 'disable_entity',
  GIVEAWAY_COLLECTION = 'giveaway_collection',
  END_GIVEAWAY_COLLECTION = 'end_giveaway_collection'
}

export enum LIVESTREAM_ENTITY_TYPE {
  PRODUCT = 'product',
  QUESTION = 'question',
  POLL = 'poll',
  LINK = 'link'
}

export const COLUMN_TITLES = {
  BRAND_NAME: 'brandName',
  DATE: 'date',
  PLAYLIST: 'playlist',
  USERNAME: 'username',
  INTERACTIONS: 'interactions',
  DURATION: 'duration',
  VIDEO_ID: 'videoId',
  STATUS: 'status',
  VIEWS: 'views',
  VISIBILITY: 'visibility',
  HASHTAG: 'hashtag'
}

export const TOP_PLAYLIST = {
  UNIQUE_DESCRIPTION: 'FW_BIZ_PORTAL_MOST_VIEWS_PLAYLIST',
  NUM_TRIGGER: 5,
  MAX_VIDEOS: 10
}

export const CHANNEL_URL_DEFAULT = 'fw.tv'

export const CHANNEL_URLS = {
  production: CHANNEL_URL_DEFAULT,
  staging: 'fw-staging.tv',
  development: 'fw-dev.tv',
  local: 'fw-staging.tv'
}

export const FITZ_WILLIAM_SPARK_BUSINESS_ID = '5EROOo'

export const FLOW_LINK_TARGET_TYPES = {
  VIDEO: 'video',
  URL: 'url'
}

export const DEFAULT_LS_POSTER =
  'https://cdn1-staging.fireworktv.com/assets/transcoded/540/default_event_trailer.jpg'

export const DEFAULT_AVATAR =
  'https://cdn1-staging.fireworktv.com/assets/default_avatar.png'

export const SIGMA_INSIGHTS = {
  VIDEO_CHANNEL_OVERVIEW: 'video_channel_overview',
  VIDEO_CHANNEL_SHORT_VIDEO: 'video_channel_short_video',
  VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL: 'video_channel_playlist_individual',
  VIDEO_CHANNEL_LIVESTREAM: 'video_channel_livestream',
  VIDEO_SHORT_VIDEO: 'video_short_video',
  VIDEO_LIVESTREAM: 'video_livestream',
  OTO_BUSINESS_GENERAL: 'oto_business_general',
  OTO_BUSINESS_PERFORMANCE: 'oto_business_performance',
  OTO_BUSINESS_REQUESTS: 'oto_business_requests',
  OTO_TEAM_ONLINE_HOURS: 'oto_team_online_hours',
  AVA_BUSINESS: 'ava_business',
  AVA_DOMAIN_ASSISTANT: 'ava_domain_assistant'
}

export const PLG_PLANS = {
  PILOT: 'plg_pilot',
  STARTER: 'plg_starter',
  GROWTH: 'plg_growth'
}

export enum BA_STORE_MODE {
  FW_CHECKOUT = 'FW_CHECKOUT',
  CART_PASSOVER = 'CART_PASSOVER',
  PDP_REDIRECT = 'PDP_REDIRECT'
}

export const ALL_CHANNELS_PAGE_SIZE = 200

export const KEY_ACTIVATION_STATUS = 'fw_as'
export const KEY_ONBOARDING_STATUS = 'fw_ob'
export const KEY_COPILOT_WAITLIST = 'fw_cp'

export const INSIGHTS_VERSION = 'cms_insights_version'

export const shopifyDomain =
  process.env.REACT_APP_ENV === 'production'
    ? 'firework'
    : 'firework-app-staging'

// These are the columns to be provided in the CSV.
export const csvColumns = {
  encoded_id: 'string',
  access: 'string',
  caption: 'string',
  duration: 'duration',
  download_url: 'string',
  creator: { username: 'string' },
  inserted_at: 'datetime',
  published_at: 'datetime',
  status: 'string',
  video_type: 'string',
  hashtags: 'array',
  action_type: 'string',
  action_url: 'string',
  playlist_names: 'array'
}

export type VideoVisibility = 'public' | 'private' | 'unlisted'

export const MIN_CAPTION_LENGTH = 2
export const MAX_CAPTION_LENGTH = 100
export const MAX_SELECTED_VIDEOS = 20

export const MAX_BULK_PLAYLISTS = 10
export const BULK_PLAYLIST_ACTION = {
  ADD: 'add',
  DELETE: 'delete'
}

export const SHOPIFY_TUTORIAL_VIDEOS = ['5DLDLM', 'vJRbR2', 'oWjdjy', 'gKMkM8']

//View overage modal data locale storage key
export const VIEW_OVERAGE_MODAL_DATA = 'fw_vomd'
export const SHOPIFY_PDP_TUTORIAL_VIDEOS = ['gY6408', 'g2EAyQ', 'gOj83d']

export const currentShopifyStoreKey = 'fw_current_shopify_store'
