import React from 'react'

import { useAppSelector } from '@src/app/hooks'
import { MenuItem } from '@src/components/Menu'

type IProps = {
  id: string
  selected: boolean
  onSelect: () => void
}

export default function LocalBusinessMenuItem(props: IProps): JSX.Element {
  const { id, onSelect, selected } = props

  const business = useAppSelector((state) => state.business.businesses[id])
  const favoriteBusinessList = useAppSelector(
    (state) => state.favoriteBusiness.favoriteBusinessList || []
  )

  const businessName =
    business?.name ||
    favoriteBusinessList.find((item) => {
      return item.id === id
    })?.name

  return (
    <MenuItem selected={selected} onSelect={onSelect}>
      <span>{businessName}</span>
    </MenuItem>
  )
}
