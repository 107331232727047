export const AUTO_EMBED_TABS = {
  HOME_PAGE: 'home_page',
  ALL_PAGES: 'all_pages',
  PRODUCT_PAGES: 'product_pages',
  ADVANCED: 'advanced',
  SHOP_APP: 'shop_app',
  PRODUCT_GALLERY: 'pdp_gallery'
}
export enum SHOPIFY_STORE_PAGES {
  SHOPIFY_STANDARD_HOMEPAGE = 'shopify_standard_homepage',
  SHOPIFY_STANDARD_PRODUCT_PAGE = 'shopify_standard_product_page',
  SHOPIFY_STANDARD_COLLECTION_PAGE = 'shopify_standard_collection_page'
}
