import React, { useCallback, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { FWButton } from '@src/components'
import LoadingIndicator from '@src/components/LoadingIndicator'
import { STORE_PROVIDERS } from '@src/constants'
import Products from '@src/pages/channel/Products/Products'
import { createStoreProductSuccess } from '@src/redux/businessStore'
import {
  createProduct,
  fetchProductsByProductFeed,
  removeProductFromProductFeed
} from '@src/redux/product'
import { Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export default function ProductFeed({
  onAdd
}: {
  onAdd?: (productId: string) => void
}): JSX.Element {
  const { businessId, storeId } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const businessStores = useAppSelector(
    (state) => Object.values(state.businessStore.businessStores) || []
  )
  const productFeedResults = useAppSelector(
    (state) => state.product.productFeedResults
  )

  const [businessStoreId, setBusinessStoreId] = useState(storeId || '')
  const [provider, setProvider] = useState(null)
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)

  const next = useAppSelector(
    (state) => state.product.productFeedPaging?.next || null
  )
  let nextPage = null
  let nextCursor = null
  if (next) {
    const params = new URLSearchParams(next)
    if (
      provider === STORE_PROVIDERS.SHOPIFY ||
      provider === STORE_PROVIDERS.TEMP_SHOPIFY
    ) {
      nextCursor = params.get('next_cursor')
    } else {
      nextPage = params.get('page')
    }
  }
  const hasMore = next ? true : false

  const [form] = Form.useForm()

  const layout = {
    labelCol: { span: 30 },
    wrapperCol: { span: 30 }
  }
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 }
  }

  // Just for initialization
  useEffect(() => {
    if (storeId) {
      setTimeout(() => {
        onSearchProduct({})
      }, 1000)
    }
    // TODO: CMS-300
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (storeId) {
      setBusinessStoreId(storeId)
      const businessStore = businessStores.find((b) => b.id === storeId)
      setProvider(businessStore.provider)
    }
  }, [businessStores, storeId])

  const onSearchProduct = useCallback(
    (values) => {
      setLoading(true)
      let businessStore = businessStores.find((b) => b.id === storeId)
      if (values.businessStoreId && values.businessStoreId !== storeId) {
        setBusinessStoreId(values.businessStoreId)
        businessStore = businessStores.find(
          (b) => b.id === values.businessStoreId
        )
      }
      const query = values.query ? values.query : ''
      setQuery(query)

      dispatch(
        fetchProductsByProductFeed(
          businessId,
          values.businessStoreId || storeId,
          businessStore?.provider,
          query,
          [STORE_PROVIDERS.SHOPIFY, STORE_PROVIDERS.TEMP_SHOPIFY].indexOf(
            businessStore?.provider
          ) !== -1
            ? null
            : 1
        )
      ).then(() => setLoading(false))
    },
    [businessId, businessStores, dispatch, storeId]
  )

  const onCreateProduct = (productExtId) => {
    const newProduct = productFeedResults.find(
      (product) => product.product_ext_id === productExtId
    )
    dispatch(createProduct(businessId, newProduct)).then((res) => {
      if (onAdd) {
        onAdd(res.data.id)
      }
      if (storeId) {
        dispatch(createStoreProductSuccess({ storeId, product: res.data }))
      }
    })
    dispatch(removeProductFromProductFeed(productExtId))
  }

  const nextAction = useCallback(() => {
    dispatch(
      fetchProductsByProductFeed(
        businessId,
        businessStoreId,
        provider,
        query,
        nextPage,
        nextCursor
      )
    )
  }, [
    dispatch,
    businessId,
    businessStoreId,
    provider,
    query,
    nextPage,
    nextCursor
  ])

  return (
    <>
      <Form {...layout} form={form} layout="inline" onFinish={onSearchProduct}>
        <Form.Item
          name="businessStoreId"
          label={t('Store')}
          rules={[{ required: true }]}
          initialValue={storeId ? storeId : ''}
          hidden={storeId ? true : false}
        >
          <Select style={{ width: '200px' }} placeholder={t('Select a store')}>
            {businessStores
              .filter((b) => b.provider === STORE_PROVIDERS.BOUTIR)
              .map((businessStore) => (
                <Select.Option key={businessStore.id}>
                  {businessStore.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name="query" label={t('Keyword')}>
          <Input
            placeholder={t('Pajamas')}
            style={{ width: '200px', marginBottom: '20px' }}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <FWButton
            type="primary"
            htmlType="submit"
            style={{ position: 'absolute', top: 0 }}
          >
            {t('Search')}
          </FWButton>
        </Form.Item>
      </Form>
      {loading ? (
        <LoadingIndicator margin="100px 0" />
      ) : (
        <Products
          products={productFeedResults}
          nextAction={nextAction}
          hasMore={hasMore}
          identifier="product_ext_id"
          onCreate={onCreateProduct}
          heightOffset={300}
        />
      )}
    </>
  )
}
