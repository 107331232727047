import React from 'react'

import { css, SerializedStyles } from '@emotion/react'
import { animated, useSpring } from 'react-spring'

export const SideBarItemText: React.FC<{
  text: string
  show: boolean
  customStyles?: SerializedStyles // New property for adding custom CSS styles
}> = ({ text, show, customStyles }) => {
  const animatedLinkStyle = useSpring({
    to: { opacity: show ? 1 : 0 },
    delay: 150
  })

  if (!show) {
    return null
  }

  return (
    <animated.span
      style={animatedLinkStyle}
      css={[
        css`
          color: white;
          white-space: nowrap;
          margin-left: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 120px;
        `,
        customStyles
      ]}
    >
      {text}
    </animated.span>
  )
}
