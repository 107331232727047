import { combineReducers } from '@reduxjs/toolkit'
import * as oneToOneReducers from '@src/modules/one-to-one/redux'
import activationStatus from '@src/redux/activationStatus'
import websocket from '@src/redux/websocket'

import * as common from '../../common/redux'
import * as automationReducers from '../../modules/automation/redux'
import * as avaReducers from '../../modules/ava/redux'
import * as channelReducers from '../../modules/channel/redux'
import * as liveChatReducers from '../../modules/live-chat/redux'
import * as oauthAppReducers from '../../modules/oauth-app/redux'
import * as profileReducers from '../../modules/profile/redux'
import * as searchReducers from '../../modules/search/redux'
import * as settingsReducers from '../../modules/settings/redux'
import * as showroomReducers from '../../modules/showroom/redux'
import * as storeReducers from '../../modules/store/redux'
import * as webhookReducers from '../../modules/webhook/redux'
import bulkVideoSelection from '../../redux/bulkVideoSelection'
import business from '../../redux/business'
import businessStore from '../../redux/businessStore'
import channel from '../../redux/channel'
import channelStore from '../../redux/channelStore'
import content from '../../redux/content'
import display from '../../redux/display'
import favoriteBusiness from '../../redux/favoriteBusiness'
import favoriteChannel from '../../redux/favoriteChannel'
import geoLocation from '../../redux/geoLocation'
import insight from '../../redux/insight'
import insightAnnotations from '../../redux/insightAnnotations'
import livestream from '../../redux/livestream'
import playlists from '../../redux/playlist'
import product from '../../redux/product'
import request from '../../redux/request'
import session from '../../redux/session'
import shopMinis from '../../redux/shopMinis'
import sigmaEmbed from '../../redux/sigmaEmbed'
import storeProduct from '../../redux/storeProduct'
import subscriptions from '../../redux/subscriptions'
import ui from '../../redux/ui'
import user from '../../redux/user'
import video from '../../redux/video'
import videoUpload from '../../redux/videoUpload'

export default combineReducers({
  activationStatus,
  ...channelReducers,
  bulkVideoSelection,
  business,
  businessStore,
  channel,
  channelStore,
  content,
  ...storeReducers,
  display,
  ...common,
  favoriteBusiness,
  favoriteChannel,
  geoLocation,
  insight,
  insightAnnotations,
  livestream,
  ...settingsReducers,
  ...oauthAppReducers,
  ...oneToOneReducers,
  ...avaReducers,
  ...automationReducers,
  playlists,
  product,
  ...profileReducers,
  request,
  ui,
  ...searchReducers,
  ...liveChatReducers,
  session,
  shopMinis,
  ...showroomReducers,
  sigmaEmbed,
  storeProduct,
  subscriptions,
  user,
  video,
  videoUpload,
  ...webhookReducers,
  websocket
})
