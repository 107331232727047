import React, { useCallback, useEffect } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { useAppDispatch } from '@src/app/hooks'
import { Box, Grid } from '@src/components/EmotionLayout'
import { fetchProductsByProductIds } from '@src/redux/product'

type SelectedProductsLayout = {
  productIds: string[]
}

export const SelectedProductsLayout: React.FC<SelectedProductsLayout> = ({
  productIds
}) => {
  const dispatch = useAppDispatch()

  const [fetchingProductData, setFetchingProductData] = React.useState<boolean>(
    false
  )
  const [products, setProducts] = React.useState<globalLib.Product[]>([])

  const fetchProductDetails = useCallback(
    async (ids: string[]) => {
      setFetchingProductData(true)
      const response = await dispatch(fetchProductsByProductIds(ids))
      const products = response.data
      setProducts(products)
      setFetchingProductData(false)
    },
    [dispatch]
  )

  useEffect(() => {
    if (productIds.length) {
      fetchProductDetails(productIds)
    }
  }, [productIds, fetchProductDetails])

  if (fetchingProductData) return <LoadingOutlined />

  return (
    <Grid gridTemplateColumns="repeat(auto-fill, 48px)" gap="8" width="100%">
      {products?.map((product) => (
        <Box
          key={product.id}
          as="img"
          alt="product"
          width="48px"
          height="48px"
          objectFit="contain"
          borderRadius="2px"
          border="1px solid #E1E4E8"
          data-test-product-name={product.product_name}
          src={product?.product_images?.[0]?.image_src || ''}
        />
      ))}
    </Grid>
  )
}
