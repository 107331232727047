import { Dispatch, createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { selfErrorHandlerRequest } from '@src/common/utils/api'

type IProps = {
  [domainAssistantId: string]: {
    canonicalMessages: CanonicalMessage[]
    paging: Record<string, string> | null
  }
}

const initialState: IProps = {}

const slice = createSlice({
  name: 'avaCanonicalMessage',
  initialState: initialState,
  reducers: {
    fetchCanonicalMessagesSuccess(state, action) {
      const {
        domainAssistantId,
        canonical_messages,
        paging,
        refresh
      } = action.payload
      const domainAssistant = refresh
        ? { paging: null, canonicalMessages: [] }
        : state[domainAssistantId] ?? { paging: null, canonicalMessages: [] }

      domainAssistant.paging = paging
      domainAssistant.canonicalMessages = domainAssistant.canonicalMessages.concat(
        canonical_messages
      )
      state[domainAssistantId] = domainAssistant
    },
    updateCanonicalMessageSuccess(state, action) {
      const { domainAssistantId, canonical_message } = action.payload
      const index = state[domainAssistantId].canonicalMessages.findIndex(
        (item) => item.id === canonical_message.id
      )
      if (index >= 0) {
        state[domainAssistantId].canonicalMessages[index] = canonical_message
      }
    }
  }
})

export default slice.reducer

export const {
  fetchCanonicalMessagesSuccess,
  updateCanonicalMessageSuccess
} = slice.actions

const fetchCanonicalMessagesRequest = createAction(
  'avaCanonicalMessage/fetchCanonicalMessagesRequest'
)
const fetchCanonicalMessagesFailure = createAction(
  'avaCanonicalMessage/fetchCanonicalMessagesFailure'
)
export function fetchCanonicalMessages(
  businessId: string,
  domainAssistantId: string,
  reviewStatus?: ReviewStatus,
  resolutionStatus?: ResolutionStatus,
  nextPage?: Record<string, string>,
  refresh = false,
  pageSize = 20,
  category?: string
) {
  return async (dispatch: Dispatch): Promise<CanonicalMessages | any> => {
    try {
      dispatch(fetchCanonicalMessagesRequest())

      const url = `bus/${businessId}/domain_assistants/${domainAssistantId}/canonical_messages`
      const response = await api.get(url, {
        params: {
          review_status: reviewStatus,
          resolution_status: resolutionStatus,
          category,
          page_size: pageSize,
          ...nextPage
        }
      })

      const { canonical_messages, paging } = response.data
      dispatch(
        fetchCanonicalMessagesSuccess({
          domainAssistantId,
          canonical_messages,
          paging,
          refresh
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchCanonicalMessagesFailure())

      return error
    }
  }
}

const updateCanonicalMessageRequest = createAction(
  'avaCanonicalMessage/updateCanonicalMessageRequest'
)
const updateCanonicalMessageFailure = createAction(
  'avaCanonicalMessage/updateCanonicalMessageFailure'
)
export function updateCanonicalMessage(
  businessId: string,
  domainAssistantId: string,
  canonicalMessageId: string,
  note: string
) {
  return async (dispatch: Dispatch): Promise<CanonicalMessage | any> => {
    try {
      dispatch(updateCanonicalMessageRequest())

      const url = `bus/${businessId}/domain_assistants/${domainAssistantId}/canonical_messages/${canonicalMessageId}`
      const response = await api.patch(url, {
        internal_note: note
      })

      dispatch(
        updateCanonicalMessageSuccess({
          domainAssistantId,
          canonical_message: response.data
        })
      )

      return response.data
    } catch (error) {
      dispatch(updateCanonicalMessageFailure())

      return error
    }
  }
}

export function updateCanonicalMessageReviewStatus(
  businessId: string,
  domainAssistantId: string,
  canonicalMessageId: string,
  reviewStatus: ReviewStatus
) {
  return async (dispatch: Dispatch): Promise<CanonicalMessage | any> => {
    try {
      dispatch(updateCanonicalMessageRequest())

      const url = `bus/${businessId}/domain_assistants/${domainAssistantId}/canonical_messages/${canonicalMessageId}/reviews`
      const response = await api.post(url, {
        review_status: reviewStatus
      })

      dispatch(
        updateCanonicalMessageSuccess({
          domainAssistantId,
          canonical_message: response.data
        })
      )

      return response.data
    } catch (error) {
      dispatch(updateCanonicalMessageFailure())

      return error
    }
  }
}

export function updateCanonicalMessageResolutionStatus(
  businessId: string,
  domainAssistantId: string,
  canonicalMessageId: string,
  resolutionStatus: ResolutionStatus
) {
  return async (dispatch: Dispatch): Promise<CanonicalMessage | any> => {
    try {
      dispatch(updateCanonicalMessageRequest())
      const url = `bus/${businessId}/domain_assistants/${domainAssistantId}/canonical_messages/${canonicalMessageId}/resolutions`

      const response = await api.post(url, {
        resolution_status: resolutionStatus
      })

      dispatch(
        updateCanonicalMessageSuccess({
          domainAssistantId,
          canonical_message: response.data
        })
      )

      return response.data
    } catch (error) {
      dispatch(updateCanonicalMessageFailure())

      return error
    }
  }
}

export async function fetchCanonicalMessage(
  businessId: string,
  domainAssistantId: string,
  canonicalMessageId: string
): Promise<CanonicalMessage | any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${domainAssistantId}/canonical_messages/${canonicalMessageId}`
    const response = await api.get(url, selfErrorHandlerRequest())

    return response
  } catch (error) {
    return error
  }
}

export async function fetchOriginalMessages(
  businessId: string,
  domainAssistantId: string,
  canonicalMessageId: string,
  nextPage?: Record<string, string>,
  pageSize = 20
): Promise<OriginalMessages | any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${domainAssistantId}/canonical_messages/${canonicalMessageId}/messages`

    const response = await api.get(url, {
      params: {
        page_size: pageSize,
        ...nextPage
      }
    })

    return response
  } catch (error) {
    return error
  }
}

export async function fetchAssistance(
  businessId: string,
  messageId: string
): Promise<Assistance | any> {
  try {
    const url = `bus/${businessId}/assistances/by_message/${messageId}`

    const response = await api.get(url)

    return response
  } catch (error) {
    return error
  }
}

export async function fetchAssistanceByAssistanceId(
  businessId: string,
  avaId: string,
  assistanceId: string
): Promise<Assistance | any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${avaId}/assistances/${assistanceId}`

    const response = await api.get(url, selfErrorHandlerRequest())

    return response
  } catch (error) {
    return error
  }
}

export async function fetchAssistances(
  businessId: string,
  domainAssistantId: string,
  review_status?: 'reviewed' | 'unreviewed',
  nextPage?: Record<string, string>,
  pageSize = 20
): Promise<Assistances | any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${domainAssistantId}/assistances`

    const response = nextPage?.next
      ? await api.get(nextPage.next)
      : await api.get(url, {
          params: {
            review_status,
            page_size: pageSize,
            ...nextPage
          }
        })

    return response
  } catch (error) {
    return error
  }
}

export async function fetchOriginalMessagesByAssistanceId(
  businessId: string,
  assistanceId: string,
  nextPage?: Record<string, string>,
  pageSize = 20
): Promise<OriginalMessages | any> {
  try {
    const url = `bus/${businessId}/assistances/${assistanceId}/messages`

    const response = await api.get(url, {
      params: {
        page_size: pageSize,
        ...nextPage
      }
    })

    return response
  } catch (error) {
    return error
  }
}

export async function fetchOriginalMessagesByAssistanceIds(
  businessId: string,
  assistanceIds: string,
  nextPage?: Record<string, string>,
  pageSize = 100
): Promise<OriginalMessages | any> {
  try {
    const url = `bus/${businessId}/assistances/batch_messages`

    const response = nextPage?.next
      ? await api.get(nextPage.next)
      : await api.get(url, {
          params: {
            assistance_ids: assistanceIds,
            page_size: pageSize
          }
        })

    return response
  } catch (error) {
    return error
  }
}

export async function createFeedback(
  businessId: string,
  assistantId: string,
  message_id: string,
  created_by: 'visitor' | 'user' | 'system',
  feedback: ReviewStatus,
  comment: string
): Promise<any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${assistantId}/message_feedbacks`

    const response = await api.post(url, {
      message_id,
      created_by,
      feedback,
      comment
    })

    return response
  } catch (error) {
    return error
  }
}

export async function updateFeedback(
  businessId: string,
  assistantId: string,
  feedback_id: string,
  feedback: ReviewStatus,
  comment: string
): Promise<any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${assistantId}/message_feedbacks/${feedback_id}`

    const response = await api.patch(url, {
      feedback,
      comment
    })

    return response
  } catch (error) {
    return error
  }
}

export async function setFeedbackResolution(
  businessId: string,
  assistantId: string,
  feedback_id: string,
  resolution: ResolutionStatus,
  comment: string
): Promise<any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${assistantId}/message_feedbacks/${feedback_id}/resolutions`

    const response = await api.post(url, {
      resolution,
      comment
    })

    return response
  } catch (error) {
    return error
  }
}

export async function deleteFeedback(
  businessId: string,
  assistantId: string,
  feedback_id: string
): Promise<any> {
  try {
    const url = `bus/${businessId}/domain_assistants/${assistantId}/message_feedbacks/${feedback_id}`

    const response = await api.delete(url)

    return response
  } catch (error) {
    return error
  }
}

export async function completeReview(
  businessId: string,
  assistanceId: string
): Promise<any> {
  const url = `bus/${businessId}/assistances/${assistanceId}/reviews`
  const response = await api.post(url, {
    assistance_id: assistanceId,
    comments: ''
  })

  return response
}

export async function revokeReview(
  businessId: string,
  assistanceId: string,
  assistanceReviewId: string
): Promise<any> {
  const url = `bus/${businessId}/assistances/${assistanceId}/reviews/${assistanceReviewId}`
  const response = await api.delete(url)

  return response
}

export async function listReviews(
  businessId: string,
  assistanceId: string
): Promise<any> {
  try {
    const url = `/bus/${businessId}/assistances/${assistanceId}/reviews`

    const response = await api.get(url)

    return response
  } catch (error) {
    return error
  }
}

export async function listFeedback(
  businessId: string,
  domainAssistantId: string,
  resolutionStatus?: ResolutionStatus,
  feedbFrom?: FeedbackFrom,
  nextPage?: Record<string, string>,
  pageSize = 20
): Promise<any> {
  try {
    const url = `/bus/${businessId}/domain_assistants/${domainAssistantId}/message_feedbacks`

    const response = nextPage?.next
      ? await api.get(nextPage.next)
      : await api.get(url, {
          params: {
            resolution: resolutionStatus,
            created_by: feedbFrom,
            page_size: pageSize
          }
        })

    return response
  } catch (error) {
    return error
  }
}

export async function getFeedback(
  businessId: string,
  domainAssistantId: string,
  feedbackId: string
): Promise<any> {
  try {
    const url = `/bus/${businessId}/domain_assistants/${domainAssistantId}/message_feedbacks/${feedbackId}`

    const response = await api.get(url, selfErrorHandlerRequest())

    return response
  } catch (error) {
    return error
  }
}
