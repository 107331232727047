import React, { useEffect } from 'react'

import LinkRow from '@src/common/components/Sidebar/LinkRow'
import { SidebarIcon } from '@src/common/components/Sidebar/SidebarIcon'
import { Box } from '@src/components/EmotionLayout'
import { usePixelAmpTracking } from '@src/hooks'
import { showLiveChatButton } from '@src/modules/live-chat/redux/liveChat'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export const LiveChatButton: React.FC<{
  expand?: boolean
  onClick?: () => void
}> = ({ expand, onClick }) => {
  const { t } = useTranslation()
  const { pixelAmpTracking, pixelGuestTracking } = usePixelAmpTracking()
  const dispatch = useDispatch()

  const showWidget = () => {
    dispatch(showLiveChatButton(false))
    pixelAmpTracking(
      TRACKING_EVENTS.HELP_CENTER_EVENTS.BUSINESS_CLICK_LIVE_CHAT
    )
    if (window._fwn && window._fwn.liveHelper) {
      // _fwn is available, use the API immediately
      window._fwn.liveHelper.actions.showWidget()
    } else {
      // Wait for fw:ready event
      document.addEventListener('fw:ready', () => {
        window._fwn.liveHelper.actions.showWidget()
      })
    }
  }

  const openOfflineSupportModal = () => {
    pixelAmpTracking(
      TRACKING_EVENTS.HELP_CENTER_EVENTS.BUSINESS_CLICK_LIVE_CHAT
    )
    onClick?.()
  }

  // send business:track_guest event automatically when users access business page
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_VERCEL_ENV === 'production'
    ) {
      script.src = 'https://asset.fwbiz1.com/js/analytics.js'
    } else {
      script.src = 'https://asset-staging.fireworktv.com/js/analytics.js'
    }
    script.onload = () => {
      const guestId = window._fwn?.analytics?.guest_id
      pixelGuestTracking(TRACKING_EVENTS.BUSINESS_TRACK_GUEST, {
        guest_id: guestId,
        visitor_id: guestId
      })
    }
    document.head.appendChild(script)
  }, [pixelGuestTracking])

  return (
    <>
      <fw-agent-status action="showWidget">
        <div slot="available">
          <Box>
            <LinkRow
              mt={0}
              showTooltip={expand}
              icon={
                <SidebarIcon
                  width="18px"
                  height="18px"
                  icon="/ic_sidebar_chat.svg"
                />
              }
              text={t('Live Chat')}
              onClick={showWidget}
            />
          </Box>
        </div>
        <div slot="not-available">
          <Box>
            <LinkRow
              mt={0}
              showTooltip={expand}
              icon={
                <SidebarIcon
                  width="18px"
                  height="18px"
                  icon="/ic_sidebar_chat.svg"
                />
              }
              text={t('Live Chat')}
              onClick={openOfflineSupportModal}
            />
          </Box>
        </div>
        <div slot="loading">&nbsp;</div>
      </fw-agent-status>
    </>
  )
}
