import React, { useEffect, useMemo, useState } from 'react'

import { CopyOutlined, LoadingOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { Box } from '@src/components/EmotionLayout'
import FWButton from '@src/components/FWButton/FWButton'
import Title from '@src/components/Title'
import { useNabooFeatureFlag } from '@src/hooks'
import { useToast } from '@src/hooks/useToast'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ConsentStatus,
  ImporterSourceFrom,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import {
  BodyContainer,
  BodyLeftContainer,
  BodyRightContainer,
  ContentContainer,
  FooterContainer,
  FooterLeftContainer,
  FooterRightContainer,
  FWModal,
  MediaInfoContainer,
  MediaInfoItemContainer,
  MediaInfoLeftContainer,
  MediaInfoRightContainer,
  MediaLikeContainer,
  RequestConsentContainer,
  RequestConsentCopyContainer,
  RequestConsentDetailContainer,
  RequestConsentHowContainer,
  RequestConsentHowDetailContainer,
  TitleContainer,
  UserContainer
} from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterMediaPreviewModal.styles'
import BatchImporterPreviewMedia from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterPreviewMedia'
import { ConsentRequestsData } from '@src/modules/channel/redux/Library/Importer/consentRequests'
import { requestConsent } from '@src/modules/channel/utils/Library/Importer/consentRequestUtils'
import { Avatar, Spin } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

interface IProps {
  canSelectCount?: number
  selectedMediaList?: BatchImporterMedia[]
  media?: BatchImporterMedia
  modalVisible?: boolean
  onCancelClick?: () => void
  okTitle?: string
  onOkClick?: (
    currentSelectedMediaList?: BatchImporterMedia[],
    otherSelectedMediaList?: BatchImporterMedia[]
  ) => void
  tracking: TrackingDetails
}

const BatchImporterMediaPreviewModal: React.FC<IProps> = (props) => {
  const nff = useNabooFeatureFlag()
  const { t } = useTranslation()
  const { warningToast } = useToast()
  const {
    canSelectCount,
    selectedMediaList,
    media,
    modalVisible,
    onCancelClick,
    okTitle,
    onOkClick
  } = props
  const [duration, setDuration] = useState<number>()
  // selectedMediaList = currentSelectedMediaList + selectedOtherMediaList
  const [currentSelectedMediaList, setCurrentSelectedMediaList] = useState<
    BatchImporterMedia[]
  >([])
  const [otherSelectedMediaList, setOtherSelectedMediaList] = useState<
    BatchImporterMedia[]
  >([])
  const [showConsentDetail, setShowConsentDetail] = useState<boolean>(false)
  const { businessId } = useParams()
  const business = useAppSelector(
    (state) => state.business.businesses[businessId]
  )
  const { errorToast } = useToast()

  const [consentRequestLoading, setConsentRequestLoading] = useState<boolean>(
    false
  )
  const [
    consentRequestResult,
    setConsentRequestResult
  ] = useState<ConsentRequestsData>()

  const findCommonElementsById = (array1, array2) => {
    if (!array1 || !array2) {
      return []
    }

    return array1.filter((item1) =>
      array2.some((item2) => item1.id === item2.id)
    )
  }

  const findUniqueElementsById = (array1, array2) => {
    if (!array1 || !array2) {
      return []
    }

    return array1.filter(
      (item1) => !array2.some((item2) => item1.id === item2.id)
    )
  }

  useEffect(() => {
    if (media && media?.consentId) {
      setConsentRequestResult({
        id: media?.consentId,
        status: media?.consentStatus
      })
    }
  }, [media])

  useEffect(() => {
    let curMediaList
    if (media?.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM) {
      curMediaList = media?.children
    } else {
      curMediaList = [media]
    }
    const currentCheckedMediaList =
      findCommonElementsById(selectedMediaList, curMediaList) || []
    const otherCheckedMediaList =
      findUniqueElementsById(selectedMediaList, curMediaList) || []
    setCurrentSelectedMediaList(currentCheckedMediaList)
    setOtherSelectedMediaList(otherCheckedMediaList)
  }, [selectedMediaList, media])

  const handleRequestConsent = async () => {
    if (consentRequestLoading) {
      return
    }
    if (consentRequestResult?.id) {
      setShowConsentDetail(true)

      return
    }
    setConsentRequestLoading(true)
    try {
      const res = await requestConsent(businessId, media)
      if (!res) {
        errorToast(t('Failed to request consent'))
        setConsentRequestLoading(false)

        return
      }
      setConsentRequestResult(res)
      setShowConsentDetail(true)
    } catch (error) {
      errorToast(t('Failed to request consent'))
    } finally {
      setConsentRequestLoading(false)
    }
  }

  const handleCopyAndOpenProfile = () => {
    // open profile
    // copy message
    navigator.clipboard.writeText(
      `${t(
        `${business?.name} would like to use your video on our website. Click the link to approve or deny the request.`
      )}\n\n${window.location.origin}/consent_request?id=${
        consentRequestResult?.id
      }`
    )
    if (media?.sourceFrom === ImporterSourceFrom.Tiktok) {
      window.open(`https://www.tiktok.com/messages?u=${media.userId}`, '_blank')
    } else {
      window.open(`https://www.instagram.com/m/${media.username}`, '_blank')
    }
  }

  const handleSelectedAlbumMedia = (
    checked: boolean,
    value: BatchImporterMedia
  ) => {
    if (checked) {
      if (
        currentSelectedMediaList.length + otherSelectedMediaList.length >=
        canSelectCount
      ) {
        warningToast(
          t('The maximum number of videos you can import is {{count}}', {
            count: canSelectCount
          })
        )

        return
      }
    }
    const exist =
      currentSelectedMediaList?.findIndex((item) => {
        return item.id === value.id
      }) > -1
    if (checked && !exist) {
      setCurrentSelectedMediaList((preState) =>
        (preState || []).concat({
          ...value,
          caption: value?.caption || media?.caption,
          timestamp: value?.timestamp || media?.timestamp,
          likeCount: value?.likeCount || media?.likeCount,
          username: value?.username || media?.username
        })
      )
    } else if (!checked && exist) {
      setCurrentSelectedMediaList((preState) =>
        (preState || []).filter((item) => {
          return item.id !== value.id
        })
      )
    }
  }

  const handleOkClick = () => {
    if (!onOkClick) {
      return
    }
    if (media?.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM) {
      onOkClick(currentSelectedMediaList, otherSelectedMediaList)
    } else {
      onOkClick([media], otherSelectedMediaList)
    }
  }

  const sourceFromName = useMemo(() => {
    const source = media?.sourceFrom
    if (source === ImporterSourceFrom.Tiktok) {
      return 'TikTok'
    } else if (source === ImporterSourceFrom.Instagram) {
      return 'Instagram'
    } else if (source === ImporterSourceFrom.YouTube) {
      return 'YouTube'
    }

    return undefined
  }, [media])

  return (
    <FWModal
      width="70vw"
      centered
      title={
        <TitleContainer>
          <Title fontSize={18} weight={600} noMargin>
            {t('View Post')}
          </Title>
        </TitleContainer>
      }
      onCancel={onCancelClick}
      footer={
        <FooterContainer>
          {media?.needCrawlerVideo && !!media?.sourceUrl?.length ? (
            <FooterLeftContainer
              onClick={() => {
                window.open(media?.sourceUrl, '_blank')
              }}
            >
              <Title fontSize={16} weight={500} color="#0F6BFF" noMargin>
                {`${t('Open in')} ${sourceFromName}`}
              </Title>
              <Box
                as="img"
                src="/icon/link_out.svg"
                height="16px"
                width="16px"
              />
            </FooterLeftContainer>
          ) : (
            <FooterLeftContainer />
          )}
          <FooterRightContainer>
            <FWButton type="default" onClick={onCancelClick}>
              {t('Cancel')}
            </FWButton>
            {okTitle && (
              <FWButton
                style={{ marginLeft: '12px' }}
                type="primary"
                onClick={handleOkClick}
              >
                {okTitle}
              </FWButton>
            )}
          </FooterRightContainer>
        </FooterContainer>
      }
      open={modalVisible}
      mask
      closable={false}
      destroyOnClose={true}
    >
      <BodyContainer>
        <BodyLeftContainer
          flex={
            media?.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM ? 1 : 2
          }
        >
          <BatchImporterPreviewMedia
            media={media}
            selectedMediaList={currentSelectedMediaList}
            onSelectedAlbumMedia={handleSelectedAlbumMedia}
            onChangeDuration={setDuration}
            canSelect={true}
            allowDownload={true}
            autoPlay={false}
            showSourceUrl={false}
          />
        </BodyLeftContainer>
        <BodyRightContainer
          flex={
            media?.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM ? 1 : 3
          }
        >
          {media.username && (
            <UserContainer>
              {media?.avatar && (
                <Avatar
                  src={media?.avatar}
                  css={css`
                    flex-shrink: 0;
                  `}
                  size={28}
                />
              )}
              <Title fontSize={17} weight={600} noMargin>
                {`@${media.username}`}
              </Title>
            </UserContainer>
          )}
          {(duration || media?.timestamp) && (
            <MediaInfoContainer>
              <MediaInfoLeftContainer>
                {media?.mediaType === BatchImporterMediaType.VIDEO &&
                  !!duration && (
                    <MediaInfoItemContainer>
                      <Title fontSize={12} weight={500} noMargin>
                        {`${t('Duration')}:`}
                      </Title>
                      <Title fontSize={12} weight={400} noMargin>
                        {duration}
                      </Title>
                    </MediaInfoItemContainer>
                  )}
                {media?.timestamp && (
                  <MediaInfoItemContainer>
                    <Title fontSize={12} weight={500} noMargin>
                      {`${t('Published')}:`}
                    </Title>
                    <Title fontSize={12} weight={400} noMargin>
                      {media?.timestamp?.length
                        ? moment(media.timestamp).format('MMM D, YYYY h:mm A')
                        : '-'}
                    </Title>
                  </MediaInfoItemContainer>
                )}
              </MediaInfoLeftContainer>
              <MediaInfoRightContainer>
                {!!media?.likeCount && (
                  <MediaLikeContainer>
                    <img alt="like-count" src="/icon/heart_black.svg" />
                    <Title fontSize={16} weight={500} noMargin>
                      {media.likeCount}
                    </Title>
                  </MediaLikeContainer>
                )}
              </MediaInfoRightContainer>
            </MediaInfoContainer>
          )}
          {!showConsentDetail &&
            media?.needConsent &&
            media?.consentStatus !== ConsentStatus.Approved &&
            !!nff.cms_consent_management && (
              <RequestConsentContainer onClick={handleRequestConsent}>
                <Title fontSize={16} weight={500} color="#003580" noMargin>
                  {t('Request Consent')}
                </Title>
                <img alt="send" src="/icon/send.svg" />
                {consentRequestLoading && (
                  <Spin indicator={<LoadingOutlined spin />} size="small" />
                )}
              </RequestConsentContainer>
            )}
          {showConsentDetail && (
            <RequestConsentDetailContainer>
              <RequestConsentHowContainer>
                <Title fontSize={16} weight={500} noMargin>
                  {t('How to request consent')}
                </Title>
                <RequestConsentHowDetailContainer>
                  <Title fontSize={16} weight={500} color="#3f4145" noMargin>
                    {`1. ${t('Copy the message and link below.')}`}
                  </Title>
                  <Title fontSize={16} weight={500} color="#3f4145" noMargin>
                    {`2. ${t(
                      'Visit the creator’s profile and send them a direct message including the link.'
                    )}`}
                  </Title>
                  <Title fontSize={16} weight={500} color="#3f4145" noMargin>
                    {`3. ${t(
                      'The creator can open the link and approve your request.'
                    )}`}
                  </Title>
                </RequestConsentHowDetailContainer>
              </RequestConsentHowContainer>
              <RequestConsentCopyContainer>
                <Title fontSize={16} weight={500} noMargin>
                  {t(
                    `${business?.name} would like to use your video on our website. Click the link to approve or deny the request.`
                  )}
                </Title>
                <Title fontSize={16} weight={500} noMargin>
                  {`${window.location.origin}/consent_request?id=${consentRequestResult?.id}`}
                </Title>
              </RequestConsentCopyContainer>
              <FWButton
                css={css`
                  align-self: flex-start;
                `}
                type="primary"
                icon={<CopyOutlined />}
                onClick={handleCopyAndOpenProfile}
              >
                {t('Copy & open profile')}
              </FWButton>
            </RequestConsentDetailContainer>
          )}
          {!showConsentDetail && (
            <ContentContainer>
              <Title fontSize={16} weight={500} noMargin>
                {media.caption || '--'}
              </Title>
            </ContentContainer>
          )}
        </BodyRightContainer>
      </BodyContainer>
    </FWModal>
  )
}

export default BatchImporterMediaPreviewModal
