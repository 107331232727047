import { createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

type IProps = {
  offlineEmbedCode: any
  embedType: string
}

const initialState: IProps = {
  offlineEmbedCode: {},
  embedType: ''
}

const slice = createSlice({
  name: 'embed',
  initialState: initialState,
  reducers: {
    getOfflineEmbedCodeSuccess(state, action) {
      const { tab, code } = action.payload
      state.offlineEmbedCode[tab] = code
    },
    setEmbedTypeSuccess(state, action) {
      const { type } = action.payload
      state.embedType = type
    }
  }
})
export default slice.reducer

export const { getOfflineEmbedCodeSuccess, setEmbedTypeSuccess } = slice.actions

export function getOfflineEmbedCode(tab: string, code: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(getOfflineEmbedCodeSuccess({ tab, code }))
  }
}

export function setEmbedType(type: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    dispatch(setEmbedTypeSuccess({ type }))
  }
}
