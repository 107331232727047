import React from 'react'

import { Flex } from '@src/components/EmotionLayout'
import FWButton from '@src/components/FWButton/FWButton'
import { FWModal } from '@src/components/FWModal/FWModal'
import { ModalCloseIcon } from '@src/components/ModalCloseIcon'
import OverlayProduct from '@src/components/Overlays/OverlayProduct'
import usePixelAmpTracking from '@src/hooks/usePixelAmpTracking'
import { ImporterSource } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import { ProductOptions } from '@src/pages/channel/ChannelVideoUpload/components/VU.types'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { useTranslation } from 'react-i18next'

import { SelectedProductsLayout } from './SelectedProductsLayout/SelectedProductsLayout'

type ProductProps = {
  context?: ImporterSource
  selectedProductIds: string[]
  selectedProductOptions: ProductOptions[]
  setSelectedProductIds: (value) => void
  setSelectedProductOptions: (value) => void
  convertAndAddProducts?: (
    products: {
      business_store_id?: string
      product_id: string
    }[],
    productOptions: ProductOptions[]
  ) => void
}

export const Products: React.FC<ProductProps> = ({
  context = ImporterSource.BULK_UPLOAD,
  selectedProductIds,
  selectedProductOptions,
  setSelectedProductIds,
  setSelectedProductOptions,
  convertAndAddProducts
}) => {
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  const handleModalOpen = () => {
    let trackingEvent: string
    switch (context) {
      case ImporterSource.INSTAGRAM_LINK:
      case ImporterSource.INSTAGRAM_BUSINESS:
      case ImporterSource.INSTAGRAM_PERSONAL:
        trackingEvent = TRACKING_EVENTS.BUSINESS_IG_CLICK_PRODUCT_OVERLAY
        break
      case ImporterSource.TIKTOK_LINK:
        trackingEvent = TRACKING_EVENTS.BUSINESS_TIKTOK_CLICK_PRODUCT_OVERLAY
        break
      default:
        trackingEvent = TRACKING_EVENTS.BUSINESS_BULK_CLICK_PRODUCT_OVERLAY
    }

    pixelAmpTracking(trackingEvent)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  if (isModalOpen) {
    return (
      <FWModal
        open
        onCancel={handleModalClose}
        title={t('Products')}
        footer={null}
        closable
        closeIcon={<ModalCloseIcon onClick={handleModalClose} />}
        width={886}
        centered
      >
        <OverlayProduct
          selectedProductIds={selectedProductIds}
          setSelectedProductIds={setSelectedProductIds}
          selectedProductOptions={selectedProductOptions}
          setSelectProductOptions={setSelectedProductOptions}
          onClose={handleModalClose}
          convertAndAddProducts={convertAndAddProducts}
          trackingProps={{
            context
          }}
        />
      </FWModal>
    )
  }

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        gap="10"
        cursor="pointer"
      >
        <Flex gap="8px">
          <FWButton type="primary" ghost onClick={handleModalOpen}>
            {t('Tag Products')}
          </FWButton>
          {selectedProductOptions?.length > 0 && (
            <Flex
              bg="#F0F0F0"
              color="#424242"
              onClick={() => {
                setSelectedProductIds([])
                setSelectedProductOptions([])
              }}
              cursor="pointer"
              borderRadius="4px"
              gap="8px"
              padding="1px 8px"
              alignItems="center"
            >
              {t('X')}
              <span>{t('Clear All')}</span>
            </Flex>
          )}
        </Flex>
        {selectedProductOptions?.length > 0 && (
          <SelectedProductsLayout productIds={selectedProductIds} />
        )}
      </Flex>
    </>
  )
}
