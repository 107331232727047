import React from 'react'

import { AutomationRoutes } from '@src/modules/channel/routes/Automation'
import { DynamicContentRoutes } from '@src/modules/channel/routes/DynamicContent'
import { ImporterRoutes } from '@src/modules/channel/routes/Library/Importer'
import { JourneyRoutes } from '@src/modules/channel/routes/Library/Journey'
import { ManagementRoutes } from '@src/modules/channel/routes/Management'
import { MyContentRoutes } from '@src/modules/channel/routes/MyContent'
import { SearchRoutes } from '@src/modules/channel/routes/Search'

export const Routes = (
  <>
    {DynamicContentRoutes}
    {AutomationRoutes}
    {JourneyRoutes}
    {ImporterRoutes}
    {ManagementRoutes}
    {MyContentRoutes}
    {SearchRoutes}
  </>
)
