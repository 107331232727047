import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const PricingPlan = loadable(() => import('../pages/PricingPlan'))

export const Routes = (
  <Route path="/business/:businessId/pricing-plan" element={<PricingPlan />} />
)
