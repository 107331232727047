const transformRuleExpressionForUI = (
  expression?: ChatChannelRuleExpression
): ChatChannelRuleExpression | undefined => {
  if (!expression) {
    return undefined
  }

  const transformedRuleGroups: ChatChannelRuleGroup[] = expression.rule_groups.map(
    (ruleGroup) => {
      const transformedRules: ChatChannelRule[] = ruleGroup.rules.map(
        (rule) => {
          if (rule.field === undefined) {
            // If the rule is ChatChannelCountryStateRule, add field = 'country'.
            // Different rule components will be switched based on this field.
            return {
              ...rule,
              field: 'country'
            }
          }

          return rule
        }
      )

      return {
        logical_connective: ruleGroup.logical_connective,
        rules: transformedRules,
        result: ruleGroup.result
      }
    }
  )

  return {
    logical_connective: expression.logical_connective,
    rule_groups: transformedRuleGroups
  }
}

const transformRuleExpressionForAPI = (
  expression?: ChatChannelRuleExpression,
  fromV1?: boolean
): ChatChannelRuleExpression | undefined => {
  if (!expression) {
    return undefined
  }

  const transformedRuleGroups: ChatChannelRuleGroup[] = expression.rule_groups.map(
    (ruleGroup) => {
      const transformedRules: ChatChannelRule[] = ruleGroup.rules.map(
        (rule) => {
          if (rule.field === 'country') {
            // If the rule is country rule in v1 schema
            if (fromV1) {
              const ruleV1: ChatChannelGeneralRule = rule as ChatChannelGeneralRule
              const ruleV2: ChatChannelCountryStateRule = {
                country: ruleV1.value
              }

              return ruleV2
            }

            // If the rule is ChatChannelCountryStateRule, remove field
            rule.field = undefined

            return rule
          }

          return rule
        }
      )

      return {
        logical_connective: ruleGroup.logical_connective,
        rules: transformedRules,
        result: ruleGroup.result
      }
    }
  )

  return {
    logical_connective: expression.logical_connective,
    rule_groups: transformedRuleGroups
  }
}

/**
 * Visibility Rules Helpers
 * @description: A util which provides Visibility Rules object converting between API and UI
 */
const convertChatChannelVisibilityForUI = (
  rules?: ChatChannelWidgetVisibilityRules
): ChatChannelWidgetVisibilityRules => {
  return {
    show: transformRuleExpressionForUI(rules?.show),
    hide: transformRuleExpressionForUI(rules?.hide)
  }
}

const convertChatChannelVisibilityForAPI = (
  rules?: ChatChannelWidgetVisibilityRules
): ChatChannelWidgetVisibilityRules => {
  return {
    show: transformRuleExpressionForAPI(rules?.show),
    hide: transformRuleExpressionForAPI(rules?.hide)
  }
}

export {
  convertChatChannelVisibilityForAPI,
  convertChatChannelVisibilityForUI,
  transformRuleExpressionForAPI,
  transformRuleExpressionForUI
}
