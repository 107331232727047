import { createAction, createSlice } from '@reduxjs/toolkit'
import { BatchImporterEditMedia } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import {
  filterExistingMediaList,
  filterNewMediaList,
  getAsyncImporterTasks,
  saveAsyncImporterTasks
} from '@src/modules/channel/utils/Library/Importer/asyncSourceImporter'
import { Dispatch } from 'redux'

const SLICE_NAME = 'asyncSourceImporter'

export enum AsyncSourceImporterTaskStatus {
  Pending = 'Pending',
  InProgress = 'InProgress',
  Fail = 'Fail',
  Success = 'Success'
}

export enum AsyncSourceImporterTaskDetailStatus {
  // Pending
  Pending = 'Pending',
  // InProgress
  Crawling = 'Crawling',
  Downloading = 'Downloading',
  Checking = 'Checking', // check size, duration
  Uploading = 'Uploading', // to Firework CDN
  Creating = 'Creating',
  // Fail
  CrawlFailed = 'CrawlFailed',
  DownloadFailed = 'DownloadFailed',
  CheckFailed = 'CheckFailed',
  UploadFailed = 'UploadFailed',
  CreateFailed = 'CreateFailed',
  // Success
  CreateSuccess = 'CreateSuccess'
}

export type AsyncSourceImporterTask = {
  businessId: string
  channelId: string
  userId: string
  id: string
  status: AsyncSourceImporterTaskStatus
  detailStatus: AsyncSourceImporterTaskDetailStatus
  media: BatchImporterEditMedia
  addTime: number // timestamp, millisecond
}

type IProps = {
  tasks: AsyncSourceImporterTask[]
}

const initialState: IProps = {
  tasks: []
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    fetchAsyncImporterTasksSuccess(state) {
      const task = getAsyncImporterTasks()
      state.tasks = task.map((task) => {
        if (task.status === AsyncSourceImporterTaskStatus.InProgress) {
          return {
            ...task,
            status: AsyncSourceImporterTaskStatus.Pending,
            detailStatus: AsyncSourceImporterTaskDetailStatus.Pending
          }
        }

        return task
      })
    },
    addAsyncImporterTasksSuccess(state, action) {
      const { businessId, channelId, userId, mediaList } = action.payload
      if (!businessId || !channelId || !userId) {
        return
      }

      const newMediaList: BatchImporterEditMedia[] =
        filterNewMediaList(mediaList, state.tasks) || []

      const date = new Date()
      const newTasks = newMediaList.map((newMedia) => {
        return {
          businessId,
          channelId,
          userId,
          id: newMedia.id,
          status: AsyncSourceImporterTaskStatus.Pending,
          detailStatus: AsyncSourceImporterTaskDetailStatus.Pending,
          media: newMedia,
          addTime: date.getTime()
        }
      })
      state.tasks = state.tasks.concat(newTasks)
      saveAsyncImporterTasks(state.tasks)
    },
    updateAsyncImporterTasksSuccess(state, action) {
      const { tasks } = action.payload
      if (!tasks?.length) {
        return
      }
      state.tasks = state.tasks.map((task) => {
        const newTask = tasks.find((t) => t.id === task.id)
        if (newTask) {
          return {
            ...newTask
          }
        }

        return task
      })
      saveAsyncImporterTasks(state.tasks)
    },
    resetAsyncImporterTasksSuccess(state, action) {
      const { businessId, channelId, userId, mediaList } = action.payload
      if (!businessId || !channelId || !userId) {
        return
      }

      const newMediaList: BatchImporterEditMedia[] =
        filterExistingMediaList(mediaList, state.tasks) || []

      const date = new Date()
      const newTasks = newMediaList.map((newMedia) => {
        return {
          businessId,
          channelId,
          userId,
          id: newMedia.id,
          status: AsyncSourceImporterTaskStatus.Pending,
          detailStatus: AsyncSourceImporterTaskDetailStatus.Pending,
          media: newMedia,
          addTime: date.getTime()
        }
      })
      state.tasks = state.tasks
        .filter((task) => {
          return newTasks.find((newTask) => {
            return newTask.id !== task.id
          })
        })
        .concat(newTasks)
      saveAsyncImporterTasks(state.tasks)
    },
    clearAsyncImporterTasksSuccess(state, action) {
      const { businessId, channelId, userId } = action.payload
      if (!businessId || !channelId || !userId) {
        return
      }
      state.tasks = state.tasks.filter((task) => {
        return !(
          task.businessId === businessId &&
          task.channelId === channelId &&
          task.userId === userId
        )
      })
      saveAsyncImporterTasks(state.tasks)
    }
  }
})

export default slice.reducer
export const {
  fetchAsyncImporterTasksSuccess,
  addAsyncImporterTasksSuccess,
  updateAsyncImporterTasksSuccess,
  resetAsyncImporterTasksSuccess,
  clearAsyncImporterTasksSuccess
} = slice.actions

const fetchAsyncImporterTasksRequest = createAction(
  `${SLICE_NAME}/fetchAsyncImporterTasksRequest`
)
const fetchAsyncImporterTasksFailure = createAction(
  `${SLICE_NAME}/fetchAsyncImporterTasksFailure`
)

export function fetchAsyncImporterTasks() {
  return async (dispatch: Dispatch): Promise<void | Error> => {
    try {
      dispatch(fetchAsyncImporterTasksRequest())
      dispatch(fetchAsyncImporterTasksSuccess())
    } catch (error) {
      dispatch(fetchAsyncImporterTasksFailure())

      return error
    }
  }
}

const addAsyncImporterTasksRequest = createAction(
  `${SLICE_NAME}/addAsyncImporterTasksRequest`
)
const addAsyncImporterTasksFailure = createAction(
  `${SLICE_NAME}/addAsyncImporterTasksFailure`
)

export function addAsyncImporterTasks(params: {
  businessId: string
  channelId: string
  userId: string
  mediaList: BatchImporterEditMedia[]
}) {
  return async (dispatch: Dispatch): Promise<void | Error> => {
    const { businessId, channelId, userId, mediaList } = params

    try {
      dispatch(addAsyncImporterTasksRequest())
      dispatch(
        addAsyncImporterTasksSuccess({
          businessId,
          channelId,
          userId,
          mediaList
        })
      )
    } catch (error) {
      dispatch(addAsyncImporterTasksFailure())

      return error
    }
  }
}

const updateAsyncImporterTasksRequest = createAction(
  `${SLICE_NAME}/updateAsyncImporterTasksRequest`
)
const updateAsyncImporterTasksFailure = createAction(
  `${SLICE_NAME}/updateAsyncImporterTasksFailure`
)

export function updateAsyncImporterTasks(params: {
  tasks: AsyncSourceImporterTask[]
}) {
  return async (dispatch: Dispatch): Promise<void | Error> => {
    const { tasks } = params

    try {
      dispatch(updateAsyncImporterTasksRequest())
      dispatch(
        updateAsyncImporterTasksSuccess({
          tasks
        })
      )
    } catch (error) {
      dispatch(updateAsyncImporterTasksFailure())

      return error
    }
  }
}

const resetAsyncImporterTasksRequest = createAction(
  `${SLICE_NAME}/resetAsyncImporterTasksRequest`
)
const resetAsyncImporterTasksFailure = createAction(
  `${SLICE_NAME}/resetAsyncImporterTasksFailure`
)

export function resetAsyncImporterTasks(params: {
  businessId: string
  channelId: string
  userId: string
  mediaList: BatchImporterEditMedia[]
}) {
  return async (dispatch: Dispatch): Promise<void | Error> => {
    const { businessId, channelId, userId, mediaList } = params

    try {
      dispatch(resetAsyncImporterTasksRequest())
      dispatch(
        resetAsyncImporterTasksSuccess({
          businessId,
          channelId,
          userId,
          mediaList
        })
      )
    } catch (error) {
      dispatch(resetAsyncImporterTasksFailure())

      return error
    }
  }
}

const clearAsyncImporterTasksRequest = createAction(
  `${SLICE_NAME}/clearAsyncImporterTasksRequest`
)
const clearAsyncImporterTasksFailure = createAction(
  `${SLICE_NAME}/clearAsyncImporterTasksFailure`
)

export function clearAsyncImporterTasks(params: {
  businessId: string
  channelId: string
  userId: string
}) {
  return async (dispatch: Dispatch): Promise<void | Error> => {
    const { businessId, channelId, userId } = params

    try {
      dispatch(clearAsyncImporterTasksRequest())
      dispatch(
        clearAsyncImporterTasksSuccess({
          businessId,
          channelId,
          userId
        })
      )
    } catch (error) {
      dispatch(clearAsyncImporterTasksFailure())

      return error
    }
  }
}
