import { TFunction } from 'i18next'

const getShopMinisErrorMessage = (errorCode: string, t: TFunction): string => {
  const errorMessageObj = {
    ShopIneligible: t(
      'Shop is not eligible; Shop App not installed via Shop Minis Dashboard.'
    ),
    EligibilityCheckFailed: t(
      'Store eligibility check failed due to a Shopify error.'
    ),
    ShopMinisNoValidVideoFound: t(
      'No valid video found; ensure videos exist and include store products.'
    ),
    CreateImageResourceFailed: t(
      'Failed to create Shopify image resources due to a Shopify error.'
    ),
    CheckImageResourceReachMaxAttempts: t(
      'Max attempts reached; required image resources not ready.'
    ),
    StagedUploadCreateFailed: t(
      'Failed to create Shopify staged video upload due to a Shopify error.'
    ),
    CheckVideoResourceReachMaxAttempts: t(
      'Max attempts reached; required video resources not ready.'
    ),
    PrepareResourcesCompletelyFailed: t(
      'All items failed to prepare for syncing.'
    ),
    PrepareResourcesPartiallyFailed: t(
      'Some items failed to prepare for syncing.'
    ),
    SetBrandPageFailed: t(
      'Failed to set brand page entry point due to a Shopify error.'
    ),
    SetHomeFeedCompletelyFailed: t(
      'Failed to set home feed entry point; all items failed.'
    ),
    SetHomeFeedPartiallyFailed: t(
      'Partially failed to set home feed entry point; some items failed.'
    ),
    SetProductPageCompletelyFailed: t(
      'Failed to set product page entry point; all items failed.'
    ),
    SetProductPagePartiallyFailed: t(
      'Partially failed to set product page entry point; some items failed.'
    )
  }

  return errorMessageObj[errorCode] || errorCode
}

export { getShopMinisErrorMessage }
