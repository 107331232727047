import styled from 'styled-components'

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 9px 16px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid #8fbaff;
  background: #e5efff;
  height: 42px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const EmojiContainer = styled.div`
  justify-content: center;
  align-items: center;
`
