import React, { useContext, useEffect, useMemo, useState } from 'react'

import loadable from '@loadable/component'
import { useAppSelector } from '@src/app/hooks'
import Sidebar from '@src/common/components/Sidebar/Sidebar'
import { DevTools } from '@src/components/DevTools'
import { Flex } from '@src/components/EmotionLayout'
import { ReviewModalContainer } from '@src/components/ReviewModal/ReviewModalContainer'
import { TourComponent } from '@src/components/TourComponent'
import useOTOWidget from '@src/hooks/useOTOWidget'
import { useShopifyFullPage } from '@src/hooks/useShopifyFullPage'
import { useToast } from '@src/hooks/useToast'
import { FloatFlowComponent } from '@src/modules/channel/components/Library/Importer/SourceImporter/AsyncFlow/FloatFlowComponent'
import NewShopModal from '@src/modules/channel/components/YourSite/NewShopModal'
import { setIsSidebarCollapsed, setIsWidgetVisible } from '@src/redux/ui'
import { makeGetRequest } from '@src/selectors/request'
import { Result } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import { TopNavigationContext } from '../../common/components/TopNavigation'
import { BusinessContext } from '../../common/contexts/BusinessContext'
import LoadingIndicator from '../../components/LoadingIndicator'
import { useWindowSize } from '../../hooks'

const SidebarMask = loadable(() => import('./SidebarMask'))
const PageContainer = loadable(() => import('./PageContainer'))

const ChangelogModal = loadable(
  () => import('@src/components/ChangelogModal'),
  {
    resolveComponent: (components) => components.ChangelogModal
  }
)

const sidebarWidth = 180
const collapsedSidebarWidth = 52
// const collapseBreakpoint = 1200
const mobileSidebarBreakpoint = 768
const sidebarAnimationConfig = { tension: 150, friction: 20, clamp: true }

export default function AppLayout({ children }) {
  const { t } = useTranslation()
  const { errorToast } = useToast()

  const dispatch = useDispatch()
  const [mobileSidebarEnabled, setMobileSidebarEnabled] = useState(
    window.innerWidth < mobileSidebarBreakpoint
  )
  const { sidebarCollapsed, disableSidebarAnimation } = useAppSelector(
    (state) => state.ui
  )
  const [businessesDidLoad, setBusinessesDidLoad] = useState(false)

  const [showReviewModal, setShowReviewModal] = useState(false)

  const isFullPage = useShopifyFullPage()

  const businessId = useContext(BusinessContext)
  const errors = useSelector((state) => state.errors)

  const isWidgetReady = useOTOWidget()
  const isWidgetVisible = useAppSelector((state) => state.ui.isWidgetVisible)

  if (process.env.REACT_APP_ENV === 'production') {
    dispatch(setIsWidgetVisible(true))
  }

  useEffect(() => {
    const handleReady = () => {
      if (
        window._fwn &&
        window._fwn.liveHelper &&
        window._fwn.liveHelper.actions
      ) {
        window._fwn.liveHelper.actions.showWidget()
      }
    }
    if (isWidgetReady && isWidgetVisible) {
      if (window._fwn && window._fwn.liveHelper) {
        // _fwn is available, use the API immediately
        handleReady()
      } else {
        // Wait for fw:ready event
        document.addEventListener('fw:ready', () => {
          handleReady()
        })
      }
    }
  }, [isWidgetReady, isWidgetVisible])

  useEffect(() => {
    if (!isWidgetVisible) {
      const widget = document.getElementById('fw-lazy-load-live-helper')
      if (widget) {
        widget.remove() // Completely removes the widget from the DOM
      }
    }
  })

  // Display error notification with a centralized component
  // Specifically used to display errors from axios interceptors in api.js
  useEffect(() => {
    if (errors.message) {
      errorToast(errors.message)
    }
  }, [dispatch, errors, errorToast])

  useWindowSize((windowSize) => {
    if (
      windowSize.innerWidth < mobileSidebarBreakpoint &&
      !mobileSidebarEnabled
    ) {
      setMobileSidebarEnabled(true)
    } else if (
      windowSize.innerWidth >= mobileSidebarBreakpoint &&
      mobileSidebarEnabled
    ) {
      setMobileSidebarEnabled(false)
    }
  })

  const [animatedSidebarWidth, setAnimatedSidebarWidth] = useState(
    collapsedSidebarWidth
  )

  useEffect(() => {
    if (mobileSidebarEnabled) {
      setAnimatedSidebarWidth(collapsedSidebarWidth)
    } else {
      setAnimatedSidebarWidth(
        sidebarCollapsed ? collapsedSidebarWidth : sidebarWidth
      )
    }
  }, [mobileSidebarEnabled, sidebarCollapsed])

  let sidebarTransform
  if (mobileSidebarEnabled) {
    sidebarTransform = sidebarCollapsed
      ? `translateX(${-sidebarWidth}px)`
      : `translateX(0px)`
  } else {
    sidebarTransform = `translateX(0px)`
  }

  const animatedSidebarStyle = useSpring({
    to: {
      width: animatedSidebarWidth,
      transform: sidebarTransform
    },
    config: sidebarAnimationConfig
  })

  const animatedContentMargin =
    mobileSidebarEnabled || !businessId ? 0 : collapsedSidebarWidth

  const contentWrapperStyle = useSpring({
    to: { marginLeft: animatedContentMargin },
    config: sidebarAnimationConfig
  })

  const getRequest = useMemo(makeGetRequest, [])
  const { state: fetchBusinessesRequest } = useSelector((state) =>
    getRequest(state, 'business/fetchBusinesses')
  )

  useEffect(() => {
    if (!businessesDidLoad && fetchBusinessesRequest === 'resolved') {
      setBusinessesDidLoad(true)
    }
  }, [businessesDidLoad, fetchBusinessesRequest])

  useEffect(() => {
    if (businessesDidLoad) {
      setTimeout(() => {
        setShowReviewModal(true)
      }, 1000)
    }
  }, [businessesDidLoad])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (mobileSidebarEnabled && !sidebarCollapsed) {
      // Prevent body from scrolling while the mobile sidebar is open
      body.setAttribute('style', 'overflow: hidden')
    }
    if (sidebarCollapsed || !mobileSidebarEnabled) {
      body.style.removeProperty('overflow')
    }
  }, [mobileSidebarEnabled, sidebarCollapsed])

  function renderPageContent() {
    if (!businessesDidLoad) {
      return <LoadingIndicator />
    }

    return children
  }

  if (fetchBusinessesRequest === 'rejected') {
    return (
      <Flex
        width="100%"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Result
          status="500"
          title={t('Hmmmm, something went wrong')}
          subTitle={t('Please contact support for more help')}
        />
      </Flex>
    )
  }

  return (
    <>
      <TourComponent />
      {businessId && !isFullPage && (
        <Sidebar
          style={
            disableSidebarAnimation
              ? { width: animatedSidebarWidth, transform: sidebarTransform }
              : animatedSidebarStyle
          }
          businessId={businessId}
          mobileSidebarEnabled={mobileSidebarEnabled}
          onToggleSidebar={() => {
            // setSidebarCollapsed(!sidebarCollapsed)
          }}
          onClickLink={() => {
            if (mobileSidebarEnabled && !sidebarCollapsed) {
              dispatch(setIsSidebarCollapsed(true))
            }
          }}
        />
      )}
      {!isFullPage ? (
        <animated.div
          style={{
            ...contentWrapperStyle,
            ...{ height: '100vh', display: 'flex', flexDirection: 'column' }
          }}
        >
          {/* <Navbar
            toggleHidden={!businessId}
            onToggleSidebar={() => {
              setSidebarCollapsed(!sidebarCollapsed)
            }}
          /> */}
          <TopNavigationContext.Provider
            value={{
              toggleShow: mobileSidebarEnabled,
              onToggleSidebar: () => {
                dispatch(setIsSidebarCollapsed(!sidebarCollapsed))
              }
            }}
          >
            <PageContainer businessId={businessId}>
              {renderPageContent()}
            </PageContainer>
          </TopNavigationContext.Provider>
        </animated.div>
      ) : (
        <PageContainer businessId={businessId}>
          {renderPageContent()}
        </PageContainer>
      )}
      {mobileSidebarEnabled && !isFullPage && (
        <SidebarMask
          opacity={sidebarCollapsed ? 0 : 1}
          height={sidebarCollapsed ? 0 : 100}
          onClick={() => {
            dispatch(setIsSidebarCollapsed(true))
          }}
        />
      )}

      {showReviewModal && <ReviewModalContainer businessId={businessId} />}
      <ChangelogModal />
      <DevTools />
      <NewShopModal />
      <FloatFlowComponent />
    </>
  )
}

AppLayout.propTypes = {
  children: PropTypes.node
}
