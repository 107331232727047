import moment from 'moment'
export { toHumanReadableTimestamp } from '../../utils/toHumanReadableTimestamp'

/**
 * Format moment countdown to be more humane.
 * @param {target} moment future Moment object
 * @return {string} moment format
 */
export function countDownFormat(target: moment.Moment): string {
  if (target) {
    const diff = moment(target).diff(Date.now())

    if (diff >= 2628000000) {
      return 'MM [m]'
    } else if (diff >= 86400000) {
      return 'D[d]'
    } else if (diff >= 3600000) {
      return 'H[h] m [min] s[s]'
    } else if (diff >= 60000) {
      return 'm[min] s[s]'
    } else if (diff >= 1000) {
      return 's[s]'
    }
  }

  return 'HH:mm:ss'
}

/**
 * Looking at the difference in time between now and future to see if it should be disabled
 * @param {target} moment future Moment object
 * @param {disabledWhen} integer milliseconds
 * @return {boolean} true/false
 */
export function isDisabled(
  target: moment.Moment,
  disabledWhen: number
): boolean {
  if (target) {
    const diff = moment(target).diff(Date.now())
    if (diff <= disabledWhen) {
      return true
    }
  }

  return false
}

export function formatSecondsToMMSS(seconds: number): string {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}

export const timeStringToSeconds = (timeString) => {
  if (!timeString || typeof timeString !== 'string') {
    throw new Error('Invalid time string')
  }
  const parts = timeString.split(':').map(Number)
  if (parts.length !== 3 || parts.some(isNaN)) {
    throw new Error('Invalid time format. Expected format: HH:mm:ss')
  }
  const [hours, minutes, seconds] = parts

  return hours * 3600 + minutes * 60 + seconds
}
