import React, { useCallback, useEffect, useState } from 'react'

import {
  CloseOutlined,
  DownOutlined,
  LoadingOutlined,
  UpOutlined
} from '@ant-design/icons'
import { useAppSelector } from '@src/app/hooks'
import { Flex } from '@src/components/EmotionLayout'
import FWButton from '@src/components/FWButton/FWButton'
import Title from '@src/components/Title'
import { useCustomNavigate } from '@src/hooks/useCustomNavigate'
import {
  BatchImporterContainer,
  BatchImporterSteps
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterContainer'
import {
  BatchImporterEditMedia,
  ImporterSource
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import useAsyncImporterTaskManager from '@src/modules/channel/hooks/Library/Importer/useAsyncImporterTaskManager'
import {
  AsyncSourceImporterTask,
  AsyncSourceImporterTaskStatus,
  clearAsyncImporterTasks,
  fetchAsyncImporterTasks
} from '@src/modules/channel/redux/Library/Importer/asyncSourceImporter'
import { createTrackingDetails } from '@src/modules/channel/utils/Library/Importer/sourceImporter'
import {
  fetchChannelAllVideos,
  fetchChannelCreatedVideos,
  fetchChannelPendingVideos,
  fetchChannelPlaylists,
  fetchChannelVideoCount
} from '@src/redux/channel'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { AiFillCheckCircle, AiFillExclamationCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

enum LayoutMode {
  Maximize = 'Maximize',
  Minimize = 'Minimize'
}

enum FinalFlowStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Fail = 'Fail'
}

export const FloatFlowComponent: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { navigateToHome } = useCustomNavigate()
  const { businessId, channelId } = useParams()
  const user = useAppSelector((state) => state.profile.user)
  useAsyncImporterTaskManager({
    businessId,
    channelId,
    userId: user?.encoded_id
  })

  const location = window.location
  const inVideoPage = location.href.match(
    /^https?:\/\/[^/]+\/business\/[^/]+\/channel\/[^/]+\/(videos|source-importer)(\?(?!activeTab=livestream).*)?$/
  )
  const [canShowComponent, setCanShowComponent] = useState<boolean>(false)

  const [currentLayoutMode, setCurrentLayoutMode] = useState<LayoutMode>(
    LayoutMode.Maximize
  )
  const [
    currentFinalFlowStatus,
    setCurrentFinalFlowStatus
  ] = useState<FinalFlowStatus>(FinalFlowStatus.Success)

  const [currentTasks, setCurrentTasks] = useState<AsyncSourceImporterTask[]>(
    []
  )
  const [, setLoadingTasks] = useState<AsyncSourceImporterTask[]>([])
  const [successTasks, setSuccessTasks] = useState<AsyncSourceImporterTask[]>(
    []
  )
  const [failTasks, setFailTasks] = useState<AsyncSourceImporterTask[]>([])
  const [failedMediaList, setFailedMediaList] = useState<
    BatchImporterEditMedia[]
  >([])
  const [showFailedDetail, setShowFailedDetail] = useState<boolean>(false)

  const tasks = useAppSelector((state) => state.asyncSourceImporter.tasks)

  const handleClickClose = useCallback(() => {
    if (businessId && channelId && user?.encoded_id) {
      dispatch(
        clearAsyncImporterTasks({
          businessId,
          channelId,
          userId: user?.encoded_id
        })
      )
    }
  }, [dispatch, businessId, channelId, user])

  useEffect(() => {
    dispatch(fetchAsyncImporterTasks())
  }, [dispatch])

  useEffect(() => {
    setCanShowComponent(!!inVideoPage)
  }, [inVideoPage])

  useEffect(() => {
    if (businessId && channelId && user?.encoded_id) {
      const cTasks = tasks.filter((task) => {
        return (
          task.businessId === businessId &&
          task.channelId === channelId &&
          task.userId === user?.encoded_id
        )
      })
      setCurrentTasks(cTasks)

      const lTasks = cTasks.filter((item) => {
        return (
          item.status === AsyncSourceImporterTaskStatus.Pending ||
          item.status === AsyncSourceImporterTaskStatus.InProgress
        )
      })
      setLoadingTasks(lTasks)

      const sTasks = cTasks.filter((item) => {
        return item.status === AsyncSourceImporterTaskStatus.Success
      })
      setSuccessTasks(sTasks)

      const fTasks = cTasks.filter((item) => {
        return item.status === AsyncSourceImporterTaskStatus.Fail
      })
      setFailTasks(fTasks)
      setFailedMediaList(
        fTasks.map((item) => {
          const media: BatchImporterEditMedia = item.media

          return {
            ...media,
            caption: media.editCaption,
            hashtags: media.editHashtags
          }
        })
      )

      if (lTasks.length > 0) {
        setCurrentFinalFlowStatus(FinalFlowStatus.InProgress)
      } else if (fTasks.length > 0) {
        setCurrentFinalFlowStatus(FinalFlowStatus.Fail)
      } else if (sTasks.length > 0) {
        setCurrentFinalFlowStatus(FinalFlowStatus.Success)
      }
    }
  }, [businessId, channelId, user, tasks])

  const handleShowFailedDetail = () => {
    setShowFailedDetail(true)
  }

  useEffect(() => {
    if (!currentTasks.length) {
      setShowFailedDetail(false)
    }
  }, [currentTasks])

  const handleViewResults = () => {
    dispatch(fetchChannelPendingVideos(businessId, channelId))
    dispatch(fetchChannelAllVideos(businessId, channelId))
    dispatch(fetchChannelCreatedVideos(businessId, channelId))
    dispatch(fetchChannelPlaylists(businessId, channelId))
    dispatch(fetchChannelVideoCount(businessId, channelId))
    navigateToHome({ businessId, channelId })
  }

  if (!canShowComponent || !currentTasks.length) {
    return <></>
  }

  return (
    <>
      {currentLayoutMode === LayoutMode.Maximize && (
        <Flex
          position="fixed"
          right={24}
          bottom={24}
          zIndex={99}
          width={276}
          borderRadius={12}
          p={12}
          background="#FFF"
          boxShadow="2px 2px 8px 0px rgba(0, 0, 0, 0.15)"
        >
          <Flex flexDirection="column" gap={12}>
            <Flex
              px={4}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Title ellipsis={{ rows: 1 }} fontSize={14} weight={600} noMargin>
                {t('{{num}}/{{total}} videos uploaded', {
                  num: successTasks.length + failTasks.length,
                  total: currentTasks.length
                })}
              </Title>
              <Flex
                width={16}
                height={16}
                justifyContent="center"
                alignItems="center"
              >
                {currentFinalFlowStatus === FinalFlowStatus.InProgress && (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 16 }} spin />
                    }
                  />
                )}
                {currentFinalFlowStatus === FinalFlowStatus.Fail && (
                  <AiFillExclamationCircle fontSize={16} color="#FF4040" />
                )}
                {currentFinalFlowStatus === FinalFlowStatus.Success && (
                  <AiFillCheckCircle fontSize={16} color="#006AFF" />
                )}
              </Flex>
            </Flex>
            <Flex px={4} flexDirection="column" gap={2}>
              <Flex minHeight={50}>
                {currentFinalFlowStatus === FinalFlowStatus.InProgress && (
                  <Title fontSize={12} weight={400} noMargin>
                    {t(
                      'Your video upload is in progress. You can navigate the business portal while you wait.'
                    )}
                  </Title>
                )}
                {currentFinalFlowStatus === FinalFlowStatus.Success && (
                  <Title fontSize={12} weight={400} noMargin>
                    {t(
                      'Your video upload is complete. Your videos are in your video library.'
                    )}
                  </Title>
                )}
                {currentFinalFlowStatus === FinalFlowStatus.Fail && (
                  <Title fontSize={12} weight={400} noMargin>
                    {t(
                      'Some of your videos failed to upload. Please check if your videos meet the requirements.'
                    )}
                  </Title>
                )}
              </Flex>
              {successTasks.length > 0 && (
                <Flex alignItems="center" justifyContent="flex-end">
                  <FWButton
                    onClick={handleViewResults}
                    size="small"
                    type="link"
                  >
                    {t('View Results')}
                  </FWButton>
                </Flex>
              )}
            </Flex>
            <Flex
              height={24}
              mt={8}
              pl={4}
              alignItems="center"
              justifyContent="space-between"
              gap={8}
            >
              <Flex onClick={handleShowFailedDetail}>
                {!!failTasks.length && (
                  <Title
                    underline
                    cursor="pointer"
                    color="#FF4040"
                    fontSize={12}
                    weight={500}
                  >
                    {t('Failed to upload {{num}} videos', {
                      num: failTasks.length
                    })}
                  </Title>
                )}
              </Flex>
              {currentFinalFlowStatus === FinalFlowStatus.Success ||
              currentFinalFlowStatus === FinalFlowStatus.Fail ? (
                <Flex
                  width={24}
                  height={24}
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={handleClickClose}
                >
                  <CloseOutlined />
                </Flex>
              ) : (
                <Flex
                  width={24}
                  height={24}
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => setCurrentLayoutMode(LayoutMode.Minimize)}
                >
                  <DownOutlined />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
      {currentLayoutMode === LayoutMode.Minimize && (
        <Flex
          position="fixed"
          right={24}
          bottom={0}
          zIndex={99}
          width={276}
          borderTopLeftRadius={12}
          borderTopRightRadius={12}
          p="12px 16px 16px"
          background="#FFF"
          boxShadow="2px 2px 8px 0px rgba(0, 0, 0, 0.15)"
        >
          <Flex
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Title ellipsis={{ rows: 1 }} fontSize={14} weight={600} noMargin>
              {t('{{num}}/{{total}} videos uploaded', {
                num: successTasks.length + failTasks.length,
                total: currentTasks.length
              })}
            </Title>
            <Flex alignItems="center" gap={4}>
              <Flex
                width={24}
                height={24}
                justifyContent="center"
                alignItems="center"
              >
                {currentFinalFlowStatus === FinalFlowStatus.InProgress && (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 16 }} spin />
                    }
                  />
                )}
                {currentFinalFlowStatus === FinalFlowStatus.Fail && (
                  <AiFillExclamationCircle fontSize={16} color="#FF4040" />
                )}
                {currentFinalFlowStatus === FinalFlowStatus.Success && (
                  <AiFillCheckCircle fontSize={16} color="#006AFF" />
                )}
              </Flex>
              <Flex
                width={24}
                height={24}
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={() => setCurrentLayoutMode(LayoutMode.Maximize)}
              >
                <UpOutlined />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
      {showFailedDetail && (
        <BatchImporterContainer
          businessId={businessId}
          channelId={channelId}
          steps={[BatchImporterSteps.FillCaption]}
          mediaList={failedMediaList}
          onBack={() => setShowFailedDetail(false)}
          onClose={() => setShowFailedDetail(false)}
          tracking={createTrackingDetails({
            source: ImporterSource.ASYNC_MODAL
          })}
        />
      )}
    </>
  )
}
