import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const GeneralSearch = loadable(() => import('../pages/Search/GeneralSearch'), {
  resolveComponent: (component) => component.GeneralSearch
})

export const SearchRoutes = (
  <Route path="/business/:businessId/search" element={<GeneralSearch />} />
)
