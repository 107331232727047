export const TRACKING_EVENTS = {
  BUSINESS_VIEW_PLANS: 'business:view_plans',
  BUSINESS_CLICK_PLANS: 'business:click_plans',
  BUSINESS_CLICK_CHANNEL_UPLOAD_CONTENT:
    'business:click_channel_upload_content',
  BUSINESS_CLICK_CHANNEL_NAV: 'business:click_channel_nav',
  BUSINESS_VIEW_CREATE_BUSINESS: 'business:view_create_business',
  BUSINESS_VIEW_BUSINESS_NAME_ENTRY: 'business:view_business_name_entry',
  BUSINESS_ENTERED_BUSINESS_NAME: 'business:entered_business_name',
  BUSINESS_ENTERED_BUSINESS_WEBSITE: 'business:entered_business_website',
  BUSINESS_ENTERED_BUSINESS_EMAIL: 'business:entered_business_email',
  BUSINESS_ENTERED_BUSINESS_COUNTRY: 'business:entered_business_country',
  BUSINESS_EMAIL_EMBED: 'business:email_embed',
  BUSINESS_COMPLETE_ACCOUNT_SETUP: 'business:complete_account_setup',
  BUSINESS_COMPLETE_CHANNEL_SETUP: 'business:complete_channel_setup',
  BUSINESS_DISMISS_CHECKLIST: 'business:dismiss_checklist',
  BUSINESS_CLICK_GENERATED_VIDEO: 'business:click_generated_video',
  BUSINESS_FINISHED_VIDEO_PROCESSING: 'business:finished_video_processing',
  BUSINESS_SELECT_GENERATED_VIDEO: 'business:select_generated_video',
  BUSINESS_UPLOAD_VIDEO: 'business:upload_video',
  BUSINESS_CLICK_UPLOAD_FROM_COMPUTER: 'business:click_upload_from_computer',
  BUSINESS_CLICK_COPY_CODE: 'business:click_copy_code',
  BUSINESS_CLOSE_CONGRATULATIONS_MODAL: 'business:close_congratulations_modal',
  BUSINESS_SEARCH_ALL: 'business:search_all',
  BUSINESS_CREATE_POST: 'business:create_post',
  BUSINESS_CLICK_CREATE_CONTENT: 'business:click_create_content',
  BUSINESS_UPDATE_POST: 'business:update_post',
  BUSINESS_NO_NAME: 'business:click_no_name',
  BUSINESS_ENTERED_WEBSITE: 'business:entered_website',
  BUSINESS_NO_WEBSITE: 'business:no_website',
  BUSINESS_CLICK_NO_WEBSITE: 'business:click_no_website',
  BUSINESS_VIEW_CHANNEL_VIDEOS: 'business:view_channel_videos',
  BUSINESS_IMPORT_INTO_FW_STUDIO: 'business:menu_context_import_into_fw_studio',
  BUSINESS_EDIT_IN_FW_STUDIO: 'business:menu_context_edit_in_fw_studio',
  BUSINESS_OPEN_FW_STUDIO: 'business:open_fw_studio',
  BUSINESS_CLICKED_ADD_CONTENT: 'business:clicked_add_content',
  BUSINESS_EXIT_ADD_CONTENT: 'business:exit_add_content',
  BUSINESS_CLICK_ADD_CONTENT_OPTION: 'business:clicked_add_content_option',
  BUSINESS_CLICKED_UPGRADE_TO_PRO: 'business:clicked_upgrade_to_pro',
  BUSINESS_AUTOGENERATED_BUSINESS: 'business:autogenerated_business',
  BUSINESS_CLICK_SHOWCASE: 'business:click_showcase',
  BUSINESS_CLICKED_EXPLORE_FIREWORK: 'business:clicked_explore_firework',
  BUSINESS_CLICKED_ONBOARDING_NEXT: 'business:clicked_onboarding_next',
  BUSINESS_CLICKED_CLOSE_MODAL: 'business:clicked_close_modal',
  BUSINESS_CLICKED_QUIT_TUTORIAL: 'business:clicked_quit_tutorial',
  BUSINESS_CLICKED_CONTINUE_TUTORIAL: 'business:clicked_continue_tutorial',
  BUSINESS_CLICK_YT_PROCEED: 'business:clicked_yt_proceed',
  BUSINESS_CREATE_STREAM: 'business:create_stream',
  BUSINESS_CLICK_VIDEO_OVERLAYS: 'business:click_video_overlays',
  BUSINESS_CLICK_PRODUCT_OVERLAY: 'business:click_product_overlay',
  BUSINESS_BULK_CLICK_PRODUCT_OVERLAY: 'business:bulk_click_product_overlay',
  BUSINESS_IG_CLICK_PRODUCT_OVERLAY: 'business:ig_click_product_overlay',
  BUSINESS_TIKTOK_CLICK_PRODUCT_OVERLAY:
    'business:tiktok_click_product_overlay',
  BUSINESS_CLICK_CTA_OVERLAY: 'business:click_cta_overlay',
  BUSINESS_CLICK_QUESTION_OVERLAY: 'business:click_question_overlay',
  BUSINESS_CLICK_POLL_OVERLAY: 'business:click_poll_overlay',
  BUSINESS_CLICK_MULTIPLE_CHOICE_OVERLAY:
    'business:click_multiple_choice_overlay',
  BUSINESS_CLICK_GIVEAWAY_OVERLAY: 'business:click_giveaway_overlay',
  BUSINESS_CLICK_LINK_OVERLAY: 'business:click_link_overlay',
  BUSINESS_ADD_PRODUCT_OVERLAY: 'business:add_product_overlay',
  BUSINESS_BULK_ADD_PRODUCT_OVERLAY: 'business:bulk_add_product_overlay',
  BUSINESS_IG_ADD_PRODUCT_OVERLAY: 'business:ig_add_product_overlay',
  BUSINESS_TIKTOK_ADD_PRODUCT_OVERLAY: 'business:tiktok_add_product_overlay',
  BUSINESS_ADD_CTA_OVERLAY: 'business:add_cta_overlay',
  BUSINESS_ADD_QUESTION_OVERLAY: 'business:add_question_overlay',
  BUSINESS_ADD_POLL_OVERLAY: 'business:add_poll_overlay',
  BUSINESS_CREATE_STREAM_FINISH: 'business:create_stream_finish',
  BUSINESS_CLICK_IOS_APPSTORE: 'business:click_ios_appstore',
  BUSINESS_CLICK_ANDROID_APPSTORE: 'business:click_android_appstore',
  BUSINESS_CLICK_GOLIVE: 'business:click_golive',
  BUSINESS_SHARE_LIVESTREAM_CODE: 'business:share_livestream_code',
  BUSINESS_CLICK_ADD_TO_PLAYLIST: 'business:click_add_to_playlist',
  BUSINESS_UPDATE_STREAM: 'business:update_stream',
  BUSINESS_CLICK_EDIT_VIDEO: 'business:click_edit_video',
  BUSINESS_CLICK_WEB_EMBED: 'business:click_web_embed',
  BUSINESS_CLICK_EMAIL_EMBED: 'business:click_email_embed',
  BUSINESS_CLICK_DELETE_VIDEO: 'business:click_delete_video',
  BUSINESS_ADD_LIVESTREAM_PRODUCT: 'business:add_livestream_product',
  BUSINESS_REMOVE_LIVESTREAM_PRODUCT: 'business:remove_livestream_product',
  BUSINESS_CREATE_SIMULCAST: 'business:create_simulcast',
  BUSINESS_CLICK_SIMULCAST_REMOVE: 'business:click_simulcast_remove',
  BUSINESS_PLAY_SIMULCAST_TUTORIAL: 'business:play_simulcast_tutorial',
  BUSINESS_CLICK_SCHEDULE_UPLOAD: 'business:click_schedule_upload',
  BUSINESS_CREATE_PRODUCT_AUTOFILL: 'business:create_product_autofill',
  BUSINESS_CLICK_CREATE_NEW_CHANNEL: 'business:click_create_new_channel',
  BUSINESS_DUPLICATE_PLAYLIST: 'business:duplicate_playlist',
  BUSINESS_DATA_HIGHLIGHTS: 'business:data_highlights',
  BUSINESS_DATA_PERFORMANCE: 'business:data_performance',
  BUSINESS_DATA_CONTENT: 'business:data_content',
  BUSINESS_DATA_PERFORMANCE_DTPICKER: 'business:data_performance_dtpicker',
  BUSINESS_CLICK_DUPLICATE_CREATE_STREAM_FINISH:
    'business:click_duplicate_createstream_finish',
  BUSINESS_PORTAL_LOGIN_EXTERNAL: 'business:portal_login_external',
  BUSINESS_SHOW_REVIEW_POPUP: 'business:show_review_popup',
  BUSINESS_DIMISS_REVIEW_POPUP: 'business:dismiss_review_popup',
  BUSINESS_DONT_ASK_AGAIN_REVIEW_POPUP: 'business:dont_ask_again_review_popup',
  BUSINESS_CLICK_RATING_REVIEW_POPUP: 'business:click_rating_review_popup',
  BUSINESS_INPUT_COMMENT_REVIEW_POPUP: 'business:input_comment_review_popup',
  BUSINESS_SUBMIT_REVIEW_POPUP: 'business:input_comment_review_popup',
  BUSINESS_BLOCK_USER: 'business:block_user',
  BUSINESS_UNBLOCK_USER: 'business:unblock_user',
  BUSINESS_CLICK_SOLDOUT: 'business:click_soldout',
  BUSINESS_CLICK_INSTOCK: 'business:click_instock',
  BUSINESS_END_POLL: 'business:end_poll',
  BUSINESS_END_STREAM: 'business:end_stream',
  BUSINESS_PAUSE_STREAM: 'business:pause_stream',
  BUSINESS_RESUME_STREAM: 'business:resume_stream',
  BUSINESS_SEND_CHAT: 'business:send_chat',
  BUSINESS_SHARE_STREAM: 'business:share_stream',
  BUSINESS_START_POLL: 'business:start_poll',
  BUSINESS_TOGGLE_METRICS_SELECTION: 'business:toggle_metrics_selection',
  BUSINESS_UNHIGHLIGHT_PRODUCT: 'business:unhighlight_product',
  BUSINESS_HIGHLIGHT_PRODUCT: 'business:highlight_product',
  BUSINESS_HIGHLIGHT_QUESTION: 'business:highlight_question',
  BUSINESS_UNHIGHLIGHT_QUESTION: 'business:unhighlight_question',
  BUSINESS_UNHIGHLIGHT_GIVEAWAY: 'business:unhighlight_giveaway',
  BUSINESS_HIGHLIGHT_GIVEAWAY: 'business:highlight_giveaway',
  BUSINESS_UNHIGHLIGHT_LINK: 'business:unhighlight_link',
  BUSINESS_HIGHLIGHT_LINK: 'business:highlight_link',
  BUSINESS_PIN_MESSAGE: 'business:pin_message',
  BUSINESS_UNPIN_MESSAGE: 'business:unpin_message',
  BUSINESS_REMOVE_MESSAGE: 'business:remove_message',
  BUSINESS_UNDO_REMOVE_MESSAGE: 'business:undo_remove_message',
  BUSINESS_REMOVE_CTA_OVERLAY: 'business:remove_cta_overlay',
  BUSINESS_REMOVE_PRODUCT_OVERLAY: 'business:remove_product_overlay',
  BUSINESS_DELETE_STREAM: 'business:delete_stream',
  BUSINESS_CLICK_SIMULCAST_OVERLAY: 'business:click_simulcast_overlay',
  BUSINESS_DOWNLOAD_LIVESTREAM_CODE: 'business:download_livestream_code',
  BUSINESS_FORCE_START_LIVESTREAM: 'business:force_start_livestream',
  BUSINESS_RESET_STREAM: 'business:reset_stream',
  BUSINESS_START_ASSISTANT_MODE: 'business:start_assistant_mode',
  BUSINESS_UPLOAD_VIDEO_FINISH: 'business:upload_video_finish',
  BUSINESS_HOVER_METRICS_DESCRIPTION: 'business:hover_metrics_description',
  BUSINESS_SELECT_METRICS_OPTION: 'business:select_metrics_option',
  BUSINESS_SLIDE_METRICS_TIMELINE: 'business:slide_metrics_timeline',
  BUSINESS_CLICK_DCS_COHORT_CREATE: 'business:click_dcs_cohort_create',
  BUSINESS_CLICK_DCS_COHORT_DELETE: 'business:click_dcs_cohort_delete',
  BUSINESS_CLICK_DCS_COHORT_EDIT: 'business:click_dcs_cohort_edit',
  BUSINESS_CLICK_DCS_TAG_GENERATE: 'business:click_dcs_tag_generate',
  BUSINESS_CONFIRM_DCS_COHORT_CREATE: 'business:confirm_dcs_cohort_create',
  BUSINESS_CONFIRM_DCS_COHORT_DELETE: 'business:confirm_dcs_cohort_delete',
  BUSINESS_CONFIRM_DCS_COHORT_EDIT: 'business:confirm_dcs_cohort_edit',
  BUSINESS_NAVIGATE_CHANNEL_DCS_PAGE: 'business:navigate_channel_dcs_page',
  BUSINESS_CONFIRM_LOGIN: 'business:confirm_login',
  BUSINESS_CLICK_NEW_AUTOMATION_CREATE: 'business:click_new_automation_create',
  BUSINESS_CLICK_NEW_AUTOMATION_FINISH: 'business:click_new_automation_finish',
  BUSINESS_CLICK_MOST_POPULAR_PLAYLIST: 'business:click_most_popular_playlist',
  BUSINESS_CLICK_MOST_POPULAR_PLAYLIST_EMBED:
    'business:click_most_popular_playlist_embed',
  BUSINESS_FEEDBACK: 'business:feedback',
  BUSINESS_HELP_CENTER: 'business:help_center',
  STUDIO_CREATE_SHORT_CLIP: 'fwstudio:create_short_clip',
  STUDIO_PUBLISH_SHORT_CLIP: 'fwstudio:publish_short_clip',
  STUDIO_CREATE_LIVESTREAM_FROM_VIDEO: 'fwstudio:create_livestream_from_video',
  BUSINESS_INSIGHT_V3_IMPRESSION: 'business:insight_v3_impression',
  BUSINESS_CLICK_SWITCH_TO_INSIGHT_V2: 'business:click_switch_to_insight_v2',
  BUSINESS_CLICK_SWITCH_TO_INSIGHT_V3: 'business:click_switch_to_insight_v3',
  BUSINESS_CLICK_EMBED_BUTTON: 'business:click_embed_button',
  BUSINESS_CLICK_EMBED_DONE_BUTTON: 'business:click_embed_done_button',
  BUSINESS_PDP_CLICK_EMBED_DONE_BUTTON: 'business:pdp:click_embed_done_button',
  BUSINESS_CLICK_LEARN_MORE: 'business:click_learn_more',
  BUSINESS_LIVESTREAM_LIST_VIEW_IMPRESSION:
    'business:livestream_list_view_impression',
  BUSINESS_LIVESTREAM_CALENDAR_VIEW_IMPRESSION:
    'business:livestream_calendar_view_impression',

  INTERACTIVE_VIDEO_EVENTS: {
    CLICK_JOURNEYS_BUTTON: 'interactive_video:click_journeys_button',
    CLICK_JOURNEY: 'interactive_video:click_journey',
    CLICK_RENAME_JOURNEY: 'interactive_video:click_rename_journey',
    CLICK_DELETE_JOURNEY: 'interactive_video:click_delete_journey',
    CLICK_CANCEL_NEW_JOURNEY: 'interactive_video:click_cancel_new_journey',
    CLICK_CANCEL_DELETE_JOURNEY:
      'interactive_video:click_cancel_delete_journey',
    DELETE_JOURNEY: 'interactive_video:delete_journey',
    CLICK_NEW_JOURNEY: 'interactive_video:click_new_journey',
    CREATE_JOURNEY: 'interactive_video:create_journey',
    CLICK_CANCEL_CREATE_JOURNEY:
      'interactive_video:click_cancel_create_journey',
    SELECT_FIRST_VIDEO: 'interactive_video:select_first_video',
    CLICK_ADD_INTERACTION: 'interactive_video:click_add_interaction',
    ADD_PRODUCT_INTERACTION: 'interactive_video:add_product_interaction',
    ADD_QUESTION_POLL_INTERACTION: 'interactive_video:add_question_interaction',
    ADD_CTA_INTERACTION: 'interactive_video:add_cta_interaction',
    ADD_MULTIPLE_CHOICE_INTERACTION:
      'interactive_video:add_multiple_choice_interaction',
    CLICK_CONNECT_VIDEO: 'interactive_video:click_connect_video',
    SELECT_VIDEO: 'interactive_video:select_video',
    CLICK_FLOW_VIDEO: 'interactive_video:click_flow_video',
    CLICK_DELETE_INTERACTION: 'interactive_video:click_delete_interaction',
    CLICK_CHANGE_INTERACTION: 'interactive_video:click_change_interaction',
    CLICK_BACK_TO_JOURNEYS: 'interactive_video:click_back_to_journeys',
    CLICK_DELETE_STEP: 'interactive_video:click_delete_step',
    CONFIRM_DELETE_STEP: 'interactive_video:confirm_delete_step'
  },
  // TODO: Add/Update schema for these events
  EMBED_EVENTS: {
    BUSINESS_EMBED_CLICK_WIDGET_TURNED_ON:
      'business:click_embed_widget_turned_on',
    PDP: {
      BUSINESS_EMBED_CLICK_WIDGET_TURNED_ON:
        'business:pdp:click_widget_turned_on',
      BUSINESS_EMBED_COPY_CHANNEL: 'business:pdp:copy_embed_channel'
    },
    BUSINESS_EMBED_CLICK_SHOPIFY_EDITOR: 'business:click_embed_shopify_editor',
    BUSINESS_EMBED_CLICK_STEP_2: 'business:click_embed_step_2',
    BUSINESS_EMBED_COPY_CHANNEL: 'business:copy_embed_channel',
    BUSINESS_EMBED_COPY_PLAYLIST: 'business:copy_embed_playlist',
    BUSINESS_EMBED_CLICK_STEP_3: 'business:click_embed_step_3',
    BUSINESS_EMBED_CLICK_GET_HELP: 'business:click_embed_get_help'
  },
  // TODO: Add/Update schema for these events
  PLAYLIST_EVENTS: {
    CREATE_PLAYLIST: 'business:create_playlist',
    CREATE_PLAYLIST_BUTTON_PLUS: 'business:create_playlist_button_plus',
    REMOVE_PLAYLIST: 'business:remove_playlist',
    ARCHIVE_PLAYLIST: 'business:archive_playlist',
    ADD_PLAYLIST_VIDEO: 'business:add_playlist_video',
    REMOVE_PLAYLIST_VIDEO: 'business:remove_playlist_video',
    EDIT_PLAYLIST_VIDEO: 'business:edit_playlist_video',
    OPEN_CHANNEL_SEARCH_MODAL: 'business:open_channel_search_modal',
    ADD_PLAYLIST_VIDEO_DONE: 'business:add_playlist_video_done',
    CREATE_PLAYLIST_TITLE: 'business:create_playlist_title',
    EDIT_PLAYLIST_OPTION_GRID_TABLE: {
      ADD_TO_PLAYLIST:
        'business:edit_playlist_option_grid_table:add_to_playlist'
    },
    EDIT_PLAYLIST_BULK_OPTION: {
      ADD_TO_PLAYLIST: 'business:edit_playlist_bulk_option:add_to_playlist'
    }
  },
  BUSINESS_URL_IMPORTER_EVENT: {
    CLICK_START_IMPORTING_BUTTON:
      'business:url_importer:click:start_importing_button'
  },
  BUSINESS_IG_HASHTAG_IMPORTER_EVENT: {
    CLICK_ADD_HASHTAG: 'business:ig_hashtag_importer:click:add_hashtag',
    CLICK_REMOVE_HASHTAG: 'business:ig_hashtag_importer:click:remove_hashtag',
    CLICK_DISCOVER_NAME: 'business:ig_hashtag_importer:click:discover_name'
  },
  BUSINESS_SOURCE_IMPORTER_EVENT: {
    CLICK_CHOOSE_PAGE_BACK_BUTTON: 'business:choose_videos_page:click_back',
    CLICK_BULK_UPLOAD_BUTTON: 'business:choose_videos_page:click_bulk_upload',
    CLICK_IG_IMPORTER_BUTTON: 'business:choose_videos_page:click_ig_importer',
    CLICK_TIKTOK_IMPORTER_BUTTON:
      'business:choose_videos_page:click_tiktok_importer',
    CLICK_YOUTUBE_IMPORTER_BUTTON:
      'business:choose_videos_page:click_youtube_importer',
    CLICK_GOOGLE_DRIVE_IMPORTER_BUTTON:
      'business:choose_videos_page:click_google_drive_importer',
    VIEW_IMPORTER_VIDEO_LIST_PAGE: 'business:importer_video_list_impression',
    VIEW_IG_IMPORTER_VIDEO_LIST_PAGE:
      'business:ig_importer_video_list_impression',
    CLICK_IG_IMPORTER_VIDEO_LIST_LOGOUT:
      'business:click_ig_importer_video_list_logout_button',
    CLICK_IG_IMPORTER_VIDEO_LIST_REMOVE:
      'business:click_ig_importer_video_list_remove_button',
    CLICK_TIKTOK_IMPORTER_VIDEO_LIST_LOGOUT:
      'business:click_tiktok_importer_video_list_logout_button',
    CLICK_TIKTOK_IMPORTER_VIDEO_LIST_REMOVE:
      'business:click_tiktok_importer_video_list_remove_button',
    CLICK_YOUTUBE_IMPORTER_VIDEO_LIST_LOGOUT:
      'business:click_youtube_importer_video_list_logout_button',
    CLICK_GOOGLE_DRIVE_IMPORTER_VIDEO_LIST_LOGOUT:
      'business:click_google_drive_importer_video_list_logout_button',
    VIEW_GOOGLE_DRIVE_IMPORTER_VIDEO_LIST_PAGE:
      'business:google_drive_importer_video_list_impression',
    VIEW_IMPORTER_MODAL: 'business:importer_modal_impression',
    CLICK_IMPORTER_MODAL_LOGOUT: 'business:click_importer_modal_logout_button',
    CLICK_IMPORTER_MODAL_MANAGE_ACCOUNT:
      'business:click_importer_modal_manage_account_button',
    CLICK_IMPORTER_MODAL_CANCEL: 'business:click_importer_modal_cancel_button',
    CLICK_IMPORTER_MODAL_NEXT: 'business:click_importer_modal_next_button',
    CLICK_IMPORTER_MODAL_CONNECT_ACCOUNT:
      'business:click_importer_modal_connect_account_button'
  },
  BUSINESS_BATCH_IMPORTER_EVENT: {
    CLICK_IMPORTER_BUTTON: 'business:click_importer_button',
    CLICK_LOGOUT_BUTTON: 'business:click_logout_button',
    CLICK_CAPTION_EDITING_NEXT_BUTTON:
      'business:click_caption_editing_next_button',
    CLICK_PLAYLIST_EDITING_NEXT_BUTTON:
      'business:click_playlist_editing_next_button',
    VIEW_CHOOSE_VIDEOS_PAGE: 'business:choose_videos_page_impression',
    VIEW_CAPTION_EDITING_PAGE: 'business:caption_editing_page_impression',
    VIEW_PLAYLIST_EDITING_PAGE: 'business:playlist_editing_page_impression',
    VIEW_IMPORTING_PAGE: 'business:importing_page_impression',
    DO_IMAGE_TO_VIDEO: 'business:image_to_video_action'
  },
  BUSINESS_BULK_ACTION_EVENT: {
    CLICK_BULK_ACTION_BUTTON: 'business:bulk_action:click:bulk_action_button',
    CLICK_REMOVE_FROM_PLAYLIST_ENTRY:
      'business:bulk_action:click:remove_from_playlist_entry',
    CLICK_COPY_TO_PLAYLIST_ENTRY:
      'business:bulk_action:click:copy_to_playlist_entry',
    CLICK_MOVE_TO_PLAYLIST_ENTRY:
      'business:bulk_action:click:move_to_playlist_entry',
    VIEW_REMOVE_FROM_PLAYLIST_MODAL:
      'business:bulk_action:view:remove_from_playlist_modal',
    VIEW_COPY_TO_PLAYLIST_MODAL:
      'business:bulk_action:view:copy_to_playlist_modal',
    VIEW_MOVE_TO_PLAYLIST_MODAL:
      'business:bulk_action:view:move_to_playlist_modal',
    CLICK_REMOVE_FROM_PLAYLIST_PRIMARY:
      'business:bulk_action:click:remove_from_playlist_primary',
    CLICK_COPY_TO_PLAYLIST_PRIMARY:
      'business:bulk_action:click:copy_to_playlist_primary',
    CLICK_MOVE_TO_PLAYLIST_PRIMARY:
      'business:bulk_action:click:move_to_playlist_primary'
  },
  BUSINESS_LANDING_PAGE_EVENT: {
    ALL_VIDEOS: 'business:landing_page:view:all_videos.json',
    SHORT_VIDEO: 'business:landing_page:view:short_video.json',
    LIVE_STREAM: 'business:landing_page:view:live_stream.json',
    SHOWROOM: 'business:landing_page:view:digital_showroom.json',
    PLAYLIST: 'business:landing_page:view:playlist.json',
    PLAYLIST_COLLECTION: 'business:landing_page:view:playlist_collection.json',
    AVA_DASHBOARD: 'business:landing_page:view:ava:dashboard.json',
    STORE: 'business:landing_page:view:store.json',
    ONE_TO_ONE: 'business:landing_page:view:one_to_one.json'
  },
  BUSINESS_EXPAND_PLAYLIST_COLLECTIONS: 'business:expand_playlist_collections',
  BUSINESS_COLLAPSE_PLAYLIST_COLLECTIONS:
    'business:collapse_playlist_collections',
  BUSINESS_CLICK_CREATE_PLAYLIST_COLLECTION:
    'business:click_create_playlist_collection',
  BUSINESS_SUBMIT_CREATE_PLAYLIST_COLLECTION:
    'business:submit_create_playlist_collection',
  BUSINESS_CANCEL_CREATE_PLAYLIST_COLLECTION:
    'business:cancel_create_playlist_collection',
  BUSINESS_CLICK_RENAME_PLAYLIST_COLLECTION:
    'business:click_rename_playlist_collection',
  BUSINESS_SUBMIT_RENAME_PLAYLIST_COLLECTION:
    'business:submit_rename_playlist_collection',
  BUSINESS_CANCEL_RENAME_PLAYLIST_COLLECTION:
    'business:cancel_rename_playlist_collection',
  BUSINESS_CLICK_DELETE_PLAYLIST_COLLECTION:
    'business:click_delete_playlist_collection',
  BUSINESS_SUBMIT_DELETE_PLAYLIST_COLLECTION:
    'business:submit_delete_playlist_collection',
  BUSINESS_CANCEL_DELETE_PLAYLIST_COLLECTION:
    'business:cancel_delete_playlist_collection',

  BUSINESS_EXPAND_PLAYLISTS: 'business:expand_playlists',
  BUSINESS_COLLAPSE_PLAYLISTS: 'business:collapse_playlists',
  BUSINESS_CLICK_CREATE_PLAYLIST: 'business:click_create_playlist',
  BUSINESS_SUBMIT_CREATE_PLAYLIST: 'business:submit_create_playlist',
  BUSINESS_CANCEL_CREATE_PLAYLIST: 'business:cancel_create_playlist',
  BUSINESS_CLICK_RENAME_PLAYLIST: 'business:click_rename_playlist',
  BUSINESS_SUBMIT_RENAME_PLAYLIST: 'business:submit_rename_playlist',
  BUSINESS_CANCEL_RENAME_PLAYLIST: 'business:cancel_rename_playlist',
  BUSINESS_CLICK_DELETE_PLAYLIST: 'business:click_delete_playlist',
  BUSINESS_SUBMIT_DELETE_PLAYLIST: 'business:submit_delete_playlist',
  BUSINESS_CANCEL_DELETE_PLAYLIST: 'business:cancel_delete_playlist',

  BUSINESS_ADD_CONTENT_OPTION: {
    CLICK_SEARCH_YOUR_CONTENT: 'business:click_search_your_content',
    CLICK_EDIT_PLAYLIST_OPTION: 'business:click_edit_playlist_option'
  },

  BUSINESS_PROFILE_EVENT: {
    CLICK_OPEN_VIDEO_IN_A_NEW_WINDOW:
      'business:profile:click:open_video_in_a_new_window'
  },
  BUSINESS_SHOP_MINIS_EVENT: {
    SHOP_APP_ELIGIBLE: 'business:shop_app_eligible',
    SHOP_APP_NOT_ELIGIBLE: 'business:shop_app_not_eligible',
    SHOP_APP_IMPRESSION: 'business:shop_app_impression',
    CLICK_ADD_TO_SHOP_APP: 'business:click_add_to_shop_app_button',
    CLICK_PUBLISH_TO_SHOP_APP: 'business:click_publish_to_shop_app_button',
    CLICK_PUBLISH_TO_BRAND_PAGE: 'business:click_publish_to_brand_page_button',
    CLICK_PUBLISH_TO_HOME_PAGE: 'business:click_publish_to_home_page_button',
    CLICK_PUBLISH_TO_PRODUCT_PAGE:
      'business:click_publish_to_product_page_button',
    CLICK_REMOVE_FROM_BRAND_PAGE:
      'business:click_remove_from_brand_page_button',
    CLICK_REMOVE_FROM_HOME_PAGE: 'business:click_remove_from_home_page_button',
    CLICK_REMOVE_FROM_PRODUCT_PAGE:
      'business:click_remove_from_product_page_button'
  },
  BUSINESS_VIDEOS_EVENT: {
    CLICK_TABLE_COLUMN_SELECTED_CHECKBOX:
      'business:videos:click:column_selection_checkbox',
    CLICK_GRID_TABLE_SWITCH: 'business:videos:click:grid_table_switch',
    VIDEO_LAYOUT_IMPRESSION: 'business:video_layout_impression',
    CLICK_QUICK_EDIT_BUTTON: 'business:click_quick_edit_button',
    CLICK_QUICK_BATCH_EDIT_BUTTON: 'business:click_quick_batch_edit_button',
    QUICK_UPDATE_VIDEO: 'business:quick_update_video',
    QUICK_BATCH_UPDATE_VIDEO: 'business:quick_batch_update_video'
  },
  BUSINESS_SUBSCRIPTION_CHECKOUT: 'business:subscription_checkout',
  // TODO: Add schema for these events
  HELP_CENTER_EVENTS: {
    BUSINESS_CLICK_HELP_CENTER: 'business:click_help_center',
    BUSINESS_CLICK_DEVELOPER_DOCS: 'business:click_developer_docs',
    BUSINESS_CLICK_LIVE_CHAT: 'business:click_live_chat'
  },
  BUSINESS_ACCESS_SEARCH_NAVIGATION: 'business:access_search_navigation',
  BUSINESS_DOWNLOAD_REPLAY_EVENT: {
    BUSINESS_CLICK_DOWNLOAD_REPLAY_MENU_ITEM:
      'business:click_download_replay_menu_item',
    BUSINESS_CLICK_DOWNLOAD_REPLAY_FILE_BUTTON:
      'business:click_download_replay_file_button',
    BUSINESS_CLICK_COPY_REPLAY_LINK_BUTTON:
      'business:click_copy_replay_link_button'
  },
  BUSINESS_DOWNLOAD_VIDEO_EVENT: 'business:download_video',
  // TODO: Add schema for these events
  BUSINESS_CLICK_USER_AVATAR: 'business:click_user_avatar',
  BUSINESS_CLICK_USER_PROFILE: 'business:click_user_profile',
  BUSINESS_CLICK_USER_LOGOUT: 'business:click_user_logout',

  //TODO: Add schema for these events
  BUSINESS_ACTIVATION_STATUS_EVENTS: {
    CLICK_UPLOAD_VIDEO: 'business:activation_status:click_upload_video',
    PDP: {
      CLICK_UPLOAD_VIDEO: 'business:activation_status:pdp:click_upload_video',
      CLICK_ADD_VIDEO_TO_PRODUCT:
        'business:activation_status:pdp:click_add_video_to_product'
    },
    CLICK_CREATE_PLAYLIST_AND_ADD_VIDEO:
      'business:activation_status:click_create_playlist_and_add_video',
    CLICK_ADD_PLAYLIST_TO_SITE:
      'business:activation_status:click_add_playlist_to_site',
    CLICK_TURN_ON_FIREWORK_EMBED:
      'business:activation_status:click_turn_on_firework_embed',
    CLICK_ADD_EMBED_TO_SHOPIFY:
      'business:activation_status:click_add_embed_to_shopify'
  },

  // TODO: Add schema for these events
  BUSINESS_AVA_BUSINESS_DASHBOARD_IMPRESSION:
    'business:ava_business_dashboard_impression',
  BUSINESS_AVA_INDIVIDUAL_DASHBOARD_IMPRESSION:
    'business:ava_individual_dashboard_impression',
  //TODO: Add schema for these events
  BUSINESS_SUBMIT_ONBOARDING_QUESTIONNAIRE:
    'business:submit_onboarding_questionnaire',

  //TODO: Add schema for these events
  BUSINESS_VIEW_ONBOARDING_QUESTIONNAIRE:
    'business:view_onboarding_questionnaire',
  BUSINESS_CLICK_ONBOARDING_QUESTIONNAIRE_DONE:
    'business:click_onboarding_questionnaire_done',
  BUSINESS_CLICK_ONBOARDING_QUESTIONNAIRE_SKIP:
    'business:click_onboarding_questionnaire_skip',

  //TODO: Add schema for these events
  BUSINESS_VU_FORM_EVENTS: {
    ADD_VIDEO_TITLE: 'business:vu_form:add_video_title'
  },
  //TODO: Add schema for this event
  BUSINESS_TRACK_GUEST: 'business:track_guest',

  //TODO: Add schema for these events
  BUSINESS_CLICK_UPGRADE_BUTTON: 'business:click_upgrade_button',

  //TODO: Add schema for these event
  BUSINESS_JOIN_COPILOT_WAITLIST: 'business:join_copilot_waitlist',

  //TODO: Add schema for these events CS-6356
  BUSINESS_BULK_UPLOAD_ADD_TO_PLAYLIST: 'business:bulk_upload:add_to_playlist',
  BUSINESS_IG_IMPORTER_ADD_TO_PLAYLIST: 'business:ig_importer:add_to_playlist',
  BUSINESS_TIKTOK_ADD_TO_PLAYLIST: 'business:tiktok:add_to_playlist',
  BUSINESS_ONBOARDING_MODAL_EVENTS: {
    CLICK_GET_STARTED: 'business:onboarding_modal:click_get_started',
    CLICK_SKIP: 'business:onboarding_modal:click_skip'
  },
  BUSINESS_REACHED_UPLOAD_LIMIT: 'business:reached_upload_limit',
  BUSINESS_OPEN_OVERVIEW_MODAL: 'business:open_overview_modal',
  VIDEO_UPLOAD_BANNER: {
    BUSINESS_VIEW_ADD_VIDEOS_BANNER: 'business:view_add_videos_banner',
    BUSINESS_BANNER_CLICK_UPLOAD_VIDEO: 'business:banner_click_upload_video',
    PDP: {
      VIDE_ADD_VIDEOS_BANNER: 'business:pdp:view_add_videos_banner',
      CLICK_UPLOAD_VIDEO: 'business:banner:pdp:click_upload_video'
    }
  },
  PLAYLIST_BANNER: {
    BUSINESS_VIEW_ADD_VIDEOS_TO_PLAYLIST_BANNER:
      'business:view_add_videos_to_playlist_banner',
    BUSINESS_BANNER_CLICK_CREATE_PLAYLIST_AND_ADD_VIDEO:
      'business:banner:click_create_playlist_and_add_video'
  },
  PLAYLIST_TO_SITE_BANNER: {
    BUSINESS_VIEW_ADD_PLAYLIST_TO_WEBSITE_BANNER:
      'business:view_add_playlist_to_website_banner',
    BUSINESS_BANNER_CLICK_ADD_PLAYLIST_TO_SITE:
      'business:banner:click_add_playlist_to_site'
  },
  PRODUCTS_BANNER: {
    BUSINESS_VIEW_ADD_PRODUCT_BANNER: 'business:view_add_product_banner',
    BUSINESS_CLICK_ADD_PRODUCT_BANNER: 'business:click_add_product_banner'
  },
  PDP_BANNER: {
    BUSINESS_VIEW_ADD_VIDEOS_TO_PDP_PAGES_BANNER:
      'business:view_add_videos_to_pdp_pages_banner',
    BUSINESS_CLICK_ADD_VIDEOS_TO_PDP_PAGES_BANNER:
      'business:click_add_videos_to_pdp_pages_banner'
  },
  BUSINESS_VIEW_SHOW_MATCHING_PRODUCTS_TOOLTIP:
    'business:view_show_matching_products_tooltip',
  BUSINESS_CLICK_ADD_VIDEOS_TO_PLAYLIST:
    'business:click_add_videos_to_playlist',
  BUSINESS_CLICK_PLAYLIST_ADD_VIDEOS: 'business:click_playlist_add_videos',
  BUSINESS_ADD_VIDEOS_TO_PLAYLIST: 'business:add_videos_to_playlist',

  //TODO: Add schema for these 4 events
  BUSINESS_SHORT_VIDEO_INSIGHT_V3_IMPRESSION:
    'business:short_video_insight_v3_impression',
  BUSINESS_SHORT_VIDEO_INSIGHT_V2_IMPRESSION:
    'business:short_video_insight_v2_impression',
  BUSINESS_SELECT_SHORT_VIDEO_INSIGHT_V3:
    'business:select_short_video_insight_v3',
  BUSINESS_SELECT_SHORT_VIDEO_INSIGHT_V2:
    'business:select_short_video_insight_v2',

  BUSINESS_LIVE_STREAM_INSIGHT_V3_IMPRESSION:
    'business:live_stream_insight_v3_impression',
  BUSINESS_LIVE_STREAM_INSIGHT_V2_IMPRESSION:
    'business:live_stream_insight_v2_impression',
  BUSINESS_SELECT_LIVE_STREAM_INSIGHT_V3:
    'business:select_live_stream_insight_v3',
  BUSINESS_SELECT_LIVE_STREAM_INSIGHT_V2:
    'business:select_live_stream_insight_v2',

  //TODO: Add schema for these events
  BUSINESS_EDIT_PLAYLIST_OPTION_ADD_TO_PLAYLIST:
    'business:edit_playlist_option:add_to_playlist',
  BUSINESS_CLICK_EMBED_WEB_BUTTON: 'business:click_embed_web_button',

  //TODO: Add schema for these events
  HOME_TAB_EVENTS: {
    BUSINESS_VIEW_HOME_PAGE: 'business:view_home_page',
    BUSINESS_CLICK_ADD_TO_HOME_PAGE: 'business:click_add_to_home_page',
    BUSINESS_CLICK_ADD_TO_PDP_PAGE: 'business:click_add_to_pdp_page',
    BUSINESS_VIEW_IG_IMPORTER_MODAL: 'business:view_ig_importer_modal'
  },
  SHOPIFY_EMBED_PAGES_EVENTS: {
    BUSINESS_VIEW_SHOPIFY_HOME_PAGE_TAB_FIRST_PAGE:
      'business:view_shopify_home_page_tab_first_page',
    BUSINESS_VIEW_SHOPIFY_HOME_PAGE_TAB_SECOND_PAGE:
      'business:view_shopify_home_page_tab_second_page',
    BUSINESS_VIEW_SHOPIFY_PDP_PAGES_TAB_FIRST_PAGE:
      'business:view_shopify_pdp_pages_tab_first_page',
    BUSINESS_VIEW_SHOPIFY_PDP_PAGES_TAB_SECOND_PAGE:
      'business:view_shopify_pdp_pages_tab_second_page',
    BUSINESS_VIEW_STEP_0_TOOLTIP: 'business:view_step_0_tooltip',
    BUSINESS_CLICK_STEP_0_DONE: 'business:click_step_0_done'
  },

  //TODO: Add schema for these events
  BUSINESS_CLICK_ADD_INTERACTION_ICON: 'business:click_add_interaction_icon',
  UPGRADE_MODAL_EVENTS: {
    BUSINESS_VIEW_UPGRADE_MODAL_MAYBE_LATER:
      'business:view_upgrade_modal_maybe_later',
    BUSINESS_VIEW_UPGRADE_MODAL_UPGRADE: 'business:view_upgrade_modal_upgrade',
    BUSINESS_VIEW_UPGRADE_MODAL_VIEW: 'business:view_upgrade_modal_view'
  },

  BUSINESS_NEW_VIDEO_DETAIL_TAB: 'business:new_video_detail_tab',
  BUSINESS_EDIT_VIDEO_PAGE_ADD_VIDEOS_TO_PLAYLIST:
    'business:edit_video_page_add_videos_to_playlist',

  //TODO: Add schema for these events
  REVIEW_MODAL_EVENTS: {
    BUSINESS_VIEW_REVIEW_MODAL: 'business:view_review_modal',
    BUSINESS_SUBMIT_FEEDBACK: 'business:submit_feedback',
    BUSINESS_REDIRECT_TO_SHOPIFY_FEEDBACK:
      'business:redirect_to_shopify_feedback',
    BUSINESS_CLOSE_REVIEW_MODAL: 'business:close_review_modal'
  },
  PRODUCTS_TAB: {
    BUSINESS_VIEW_PRODUCTS_PAGE: 'business:view_products_page',
    BUSINESS_CLICK_ADD_VIDEOS_TO_PRODUCTS:
      'business:click_add_videos_to_products',
    BUSINESS_CLICK_ADD_X_VIDEOS_TO_PRODUCTS:
      'business:click_add_x_videos_to_products',
    BUSINESS_CLICK_REMOVE_VIDEOS_FROM_PRODUCTS:
      'business:click_remove_videos_from_products',
    BUSINESS_CLICK_SORT_PRODUCTS_BY_NAME:
      'business:click_sort_products_by_name',
    BUSINESS_CLICK_SORT_PRODUCTS_BY_NUMBER_OF_VIDEOS:
      'business:click_sort_products_by_number_of_videos'
  },
  BUSINESS_TOUR_REMIND_ME_LATER: 'business:tour_remind_me_later',
  BUSINESS_TOUR_NEVER_SHOW: 'business:tour_never_show',
  //TODO: Add schema for these events
  AUTO_EMBED_EVENTS: {
    BUSINESS_AUTO_EMBED_YOUR_SITE_TAB: 'business:auto_embed_your_site_tab',
    BUSINESS_AUTO_EMBED_HOME_PAGE: 'business:auto_embed_home_page',
    BUSINESS_AUTO_EMBED_PDP_PAGE: 'business:auto_embed_pdp_page',
    BUSINESS_AUTO_EMBED_PRODUCT_GALLERY: 'business:auto_embed_product_gallery',
    BUSINESS_AUTO_EMBED_ALL_PAGES: 'business:auto_embed_all_pages',
    BUSINESS_AUTO_EMBED_ADVANCED_PAGE: 'business:auto_embed_advanced_page',
    BUSINESS_AUTO_EMBED_SHOW_PLAYLIST_ON_HOME_PAGE:
      'business:auto_embed_show_playlist_on_home_page',
    BUSINESS_AUTO_EMBED_SAVE_AND_PREVIEW:
      'business:auto_embed_save_and_preview',
    BUSINESS_AUTO_EMBED_SAVE_TO_SITE: 'business:auto_embed_save_to_site',
    BUSINESS_AUTO_EMBED_PAGE_POSITION: 'business:auto_embed_page_position',
    BUSINESS_AUTO_EMBED_TITLE: 'business:auto_embed_title',
    BUSINESS_AUTO_EMBED_PLAYLIST: 'business:auto_embed_playlist',
    BUSINESS_AUTO_EMBED_YES_DISPLAY_SAVE_TO_SITE:
      'business:auto_embed_yes_display_save_to_site',
    BUSINESS_AUTO_EMBED_CANCEL_SAVE_TO_SITE:
      'business:auto_embed_cancel_save_to_site',
    BUSINESS_AUTO_EMBED_DONE_SAVE_TO_SITE:
      'business:auto_embed_done_save_to_site',
    BUSINESS_AUTO_EMBED_PLAYLIST_ALL_PAGES:
      'business:auto_embed_playlist_all_pages',
    BUSINESS_AUTO_EMBED_ADD_TO_HOMEPAGE: 'business:auto_embed_add_to_homepage',
    BUSINESS_AUTO_EMBED_ADD_TO_OTHER_PAGES:
      'business:auto_embed_add_to_other_pages',
    BUSINESS_AUTO_EMBED_PREVIEW: 'business:auto_embed_preview'
  }
}

export const getOS = (): string | null => {
  const userAgent = typeof window !== 'undefined' && window.navigator.userAgent
  const platform = typeof window !== 'undefined' && window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darwin']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

export const getBrowser = (): string | null => {
  if (typeof window === 'undefined') {
    return null
  }
  if (navigator.userAgent.search('MSIE') >= 0) {
    return 'IE'
  } else if (navigator.userAgent.search('Edge') >= 0) {
    return 'Edge'
  } else if (
    navigator.userAgent.search('Chrome') >= 0 ||
    navigator.userAgent.search('CriOS') >= 0
  ) {
    return 'Chrome'
  } else if (navigator.userAgent.search('Firefox') >= 0) {
    return 'Firefox'
  } else if (
    navigator.userAgent.search('Safari') >= 0 &&
    navigator.userAgent.search('Chrome') < 0
  ) {
    return 'Safari'
  } else if (navigator.userAgent.search('Opera') >= 0) {
    return 'Opera'
  } else {
    return null
  }
}

export const getLocale = (): string | null =>
  (typeof window !== 'undefined' && navigator.language) || null
