import { createAction, createSlice } from '@reduxjs/toolkit'
import api, { IMPORTER_PROXY_URL } from '@src/common/utils/api'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ImporterSourceFrom
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import {
  getTKRedirectUri,
  getTKToken,
  saveTKToken,
  TK_CLIENT_KEY,
  TK_URL,
  TKToken
} from '@src/utils/tkAuth'
import moment from 'moment'
import { Dispatch } from 'redux'

const SLICE_NAME = 'tkAuth'

export type TKVideo = {
  cover_image_url: string
  share_url: string
  id: string
  title: string
  like_count: number
  comment_count: number
  share_count: number
  video_description: string
  create_time: number
}

const initialState = {
  videos: [] as TKVideo[],
  cursor: '',
  hasMore: false
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    fetchTkTokenSuccess(state, action) {
      const { userId, tkToken } = action.payload
      saveTKToken(userId, tkToken)
    },
    fetchTkVideoListSuccess(state, action) {
      const { videos, reqCursor, cursor, hasMore } = action.payload
      if (reqCursor?.length) {
        state.videos = (state.videos || []).concat(videos || [])
      } else {
        state.videos = videos
      }
      state.cursor = cursor
      state.hasMore = hasMore
    }
  }
})

export default slice.reducer
export const { fetchTkTokenSuccess, fetchTkVideoListSuccess } = slice.actions

const fetchTkTokenRequest = createAction('tkAuth/fetchTkTokenRequest')
const fetchTkTokenFailure = createAction('tkAuth/fetchTkTokenFailure')

export function code2Token(userId: string, code: string, clientKey?: string) {
  return async (dispatch: Dispatch): Promise<TKToken> => {
    try {
      dispatch(fetchTkTokenRequest())

      const clientId = clientKey || TK_CLIENT_KEY
      const response = await fetch(
        `${IMPORTER_PROXY_URL}/tiktok/exchange?client_id=${clientId}&redirect_uri=${getTKRedirectUri()}&code=${code}`,
        {
          method: 'GET',
          headers: {
            Authorization: `bearer ${api.getToken()}`,
            'Content-Type': 'application/json'
          }
        }
      )
      const data = (await response.json()) as any

      const { access_token, expires_in } = data

      const meRes = await fetch(`${IMPORTER_PROXY_URL}/tiktok/forward`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: `${TK_URL}/user/info/?fields=open_id,union_id,avatar_url,display_name,username`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${access_token}`,
            'Content-Type': 'application/json'
          }
        })
      })
      const meJson = (await meRes.json()) as any
      const {
        open_id,
        union_id,
        avatar_url,
        display_name,
        username,
        likes_count,
        video_count
      } = meJson.data.user

      const date = new Date()
      const tkToken: TKToken = {
        accessToken: access_token,
        expiresIn: date.getTime() + expires_in * 1000,
        userInfo: {
          openId: open_id,
          unionId: union_id,
          avatarUrl: avatar_url,
          displayName: display_name,
          username: username || display_name,
          likesCount: likes_count,
          videoCount: video_count
        }
      }

      dispatch(
        fetchTkTokenSuccess({
          userId,
          tkToken
        })
      )

      return tkToken
    } catch (error) {
      dispatch(fetchTkTokenFailure())

      return error
    }
  }
}

const fetchTkVideoListRequest = createAction('tkAuth/fetchTkVideoListRequest')
const fetchTkVideoListFailure = createAction('tkAuth/fetchTkVideoListFailure')

export function fetchTkVideoList(
  userId: string,
  cursor?: string,
  maxCount = 20
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(fetchTkVideoListRequest())
      const tkToken = getTKToken(userId)

      const response = await fetch(`${IMPORTER_PROXY_URL}/tiktok/forward`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: `${TK_URL}/video/list/?fields=duration,cover_image_url,share_url,id,title,like_count,comment_count,share_count,video_description,create_time`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${tkToken.accessToken}`,
            'Content-Type': 'application/json'
          },
          body: {
            max_count: maxCount,
            cursor: cursor || undefined
          }
        })
      })
      const data = await response.json()

      const { videos, cursor: newCursor, has_more: hasMore } = data.data

      dispatch(
        fetchTkVideoListSuccess({
          videos,
          reqCursor: cursor,
          cursor: newCursor,
          hasMore
        })
      )
    } catch (error) {
      dispatch(fetchTkVideoListFailure())
    }
  }
}

export const convertTKVideoList2BatchImporterMediaList = (params: {
  list?: TKVideo[]
  tkToken?: TKToken
}): BatchImporterMedia[] => {
  const { list, tkToken } = params

  return (
    list?.map((item) => {
      return {
        id: item.id,
        caption: item.title,
        mediaType: 'VIDEO' as BatchImporterMediaType,
        thumbnailUrl: item.cover_image_url,
        username: tkToken?.userInfo?.username,
        displayName: tkToken?.userInfo?.displayName,
        avatar: tkToken?.userInfo?.avatarUrl,
        mediaUrl: item?.share_url,
        sourceUrl: item?.share_url,
        likeCount: item.like_count,
        timestamp: moment(item.create_time * 1000).toISOString(),
        sourceFrom: ImporterSourceFrom.Tiktok,
        needCrawlerVideo: true
      }
    }) || []
  )
}
