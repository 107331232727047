import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/components/EmotionLayout'
import theme from '@src/styles/theme'
import { Typography } from 'antd'

interface Props {
  title: string
  subTitle?: string | React.ReactNode
  onCloseButtonClick?: () => void
  hideCloseButton?: boolean
  rightControls?: React.ReactNode
  leftControls?: React.ReactNode
}

const BatchImporterHeader: React.FC<Props> = ({
  title,
  subTitle,
  onCloseButtonClick,
  hideCloseButton = false,
  rightControls,
  leftControls
}): JSX.Element => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      bg={theme.white}
      zIndex="102"
      py="medium"
      padding="24px"
      borderBottom={`1px solid ${theme.gray20}`}
      gap="10"
    >
      <Flex flex="1 10%" justifyContent="flex-start" alignItems="center">
        {leftControls}
      </Flex>
      <Flex
        flex="1 80%"
        width="100%"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography.Title
          level={4}
          css={css`
            margin-bottom: 0 !important;
            display: inline-block;
          `}
        >
          {title}
        </Typography.Title>
        <Flex justifyContent="center" width="100%">
          {subTitle}
        </Flex>
      </Flex>
      <Flex flex="1 10%" justifyContent="flex-end" alignItems="center">
        {rightControls ? (
          <>{rightControls}</>
        ) : (
          <>
            {!hideCloseButton && (
              <Flex
                cursor="pointer"
                alignItems="center"
                justifyContent="center"
                width="24px"
                height="24px"
                onClick={onCloseButtonClick}
              >
                <Box
                  as="img"
                  alt="close"
                  src="/close_black.svg"
                  width="16"
                  height="16"
                />
              </Flex>
            )}
          </>
        )}
      </Flex>
    </Box>
  )
}

export default BatchImporterHeader
