const getCaptionHashtags = (caption: string): string[] => {
  const hashtagRegex = /#(\w+)/g
  const hashtags = []

  let match
  while ((match = hashtagRegex.exec(caption)) !== null) {
    hashtags.push(match[1])
  }

  return hashtags
}

// We need to calculate the length of emoji as 1
const captionLength = (caption?: string): number => {
  return Array.from(caption || '').length
}

export { getCaptionHashtags, captionLength }
