import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const HelpCenter = loadable(() => import('../pages/HelpCenter'))

export const Routes = (
  <Route path="/business/:businessId/helpcenter" element={<HelpCenter />} />
)
