import { Dispatch, createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'

type IProps = {
  chatChannelId: string | null
  visitor_feeds: ChatChannelFeed[]
  paging: Record<string, any>
}

const initialState: IProps = {
  chatChannelId: null,
  visitor_feeds: [],
  paging: {}
}

const slice = createSlice({
  name: 'chatChannelFeeds',
  initialState,
  reducers: {
    fetchChatChannelFeedsSuccess(state, action) {
      const { chatChannelId, visitor_feeds, paging, page } = action.payload

      // Clear feeds if the segments do not belong to the chat channel
      if (chatChannelId !== state.chatChannelId) {
        state.visitor_feeds = []
      }

      state.chatChannelId = chatChannelId
      state.paging = paging

      if (page) {
        visitor_feeds.forEach((feed) => {
          const hasFeed = state.visitor_feeds.some((item) => {
            return item.id === feed.id
          })

          if (!hasFeed) {
            state.visitor_feeds.push(feed)
          }
        })
      } else {
        state.visitor_feeds = visitor_feeds
      }
    },
    updateChatChannelFeedSuccess(state, action) {
      const { visitor_feed } = action.payload
      const feed = state.visitor_feeds.find(
        (feed) => feed.id === visitor_feed.id
      )

      if (feed) {
        feed.name = visitor_feed.name
        feed.default = visitor_feed.default
      }
    },
    deleteChatChannelFeedSuccess(state, action) {
      const { feedId } = action.payload
      const feedIndex = state.visitor_feeds.findIndex((f) => f.id === feedId)

      if (feedIndex > -1) {
        state.visitor_feeds.splice(feedIndex, 1)
      }
    },
    createChatChannelFeedSuccess(state, action) {
      const { chatChannelId, visitor_feed } = action.payload

      if (chatChannelId !== state.chatChannelId) {
        return
      }

      state.visitor_feeds.unshift({
        ...visitor_feed,
        subscribed: !!visitor_feed.subscribed,
        subscription_count: visitor_feed.subscription_count
          ? visitor_feed.subscription_count
          : 0
      })
    }
  }
})

export default slice.reducer
export const {
  fetchChatChannelFeedsSuccess,
  updateChatChannelFeedSuccess,
  deleteChatChannelFeedSuccess,
  createChatChannelFeedSuccess
} = slice.actions

/**
 * Fetch Chat Channel Feeds
 */
const fetchChatChannelFeedsRequest = createAction(
  'chatChannelFeeds/fetchChatChannelFeedsRequest'
)
const fetchChatChannelFeedsFailure = createAction(
  'chatChannelFeeds/fetchChatChannelFeedsFailure'
)

export function fetchChatChannelFeeds(
  businessId: string,
  chatChannelId: string,
  query?: string,
  page?: string,
  pageSize = 10
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChatChannelFeedsRequest())
      let url = `bus/${businessId}/chat_channels/${chatChannelId}/visitor_feeds/search?query=${
        query ?? ''
      }&page_size=${pageSize}`

      if (page) {
        url = page
      }

      const response = await api.get(url)
      const { visitor_feeds, paging } = response.data
      dispatch(
        fetchChatChannelFeedsSuccess({
          businessId,
          chatChannelId,
          visitor_feeds,
          paging,
          page
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChatChannelFeedsFailure())

      return error
    }
  }
}

/**
 * Update Chat Channel Feed
 */
const updateChatChannelFeedRequest = createAction(
  'chatChannelFeeds/updateChatChannelFeedRequest'
)
const updateChatChannelFeedFailure = createAction(
  'chatChannelFeeds/updateChatChannelFeedFailure'
)

interface ChatChannelFeedUpdateRequest {
  id?: string
  name?: string
  is_default?: boolean
  segment_ids?: string[]
}

export function updateChatChannelFeed(
  businessId: string,
  chatChannelId: string,
  feedId: string,
  data: ChatChannelFeedUpdateRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateChatChannelFeedRequest())

      const response = await api.patch(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}/visitor_feeds/${feedId}`,
        { ...data }
      )
      const { visitor_feed } = response.data
      dispatch(
        updateChatChannelFeedSuccess({
          businessId,
          chatChannelId,
          visitor_feed
        })
      )

      return response
    } catch (error) {
      dispatch(updateChatChannelFeedFailure())

      return error
    }
  }
}

/**
 * Delete Chat Channel Feed
 */
const deleteChatChannelFeedRequest = createAction(
  'chatChannelFeeds/deleteChatChannelFeedRequest'
)
const deleteChatChannelFeedFailure = createAction(
  'chatChannelFeeds/deleteChatChannelFeedFailure'
)

export function deleteChatChannelFeed(
  businessId: string,
  chatChannelId: string,
  feedId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteChatChannelFeedRequest())

      const response = await api.delete(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}/visitor_feeds/${feedId}`
      )
      dispatch(
        deleteChatChannelFeedSuccess({
          businessId,
          chatChannelId,
          feedId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteChatChannelFeedFailure())

      return error
    }
  }
}

/**
 * Creat Chat Channel Feed
 */
const createChatChannelFeedRequest = createAction(
  'chatChannelFeeds/creatChatChannelFeedRequest'
)
const createChatChannelFeedFailure = createAction(
  'chatChannelFeeds/createChatChannelFeedFailure'
)

interface ChatChannelFeedCreateRequest {
  name?: string
  segment_ids?: string[]
}

export function createChatChannelFeed(
  businessId: string,
  chatChannelId: string,
  data: ChatChannelFeedCreateRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createChatChannelFeedRequest())

      const response = await api.post(
        `/api/bus/${businessId}/chat_channels/${chatChannelId}/visitor_feeds`,
        { ...data }
      )
      const { visitor_feed } = response.data
      dispatch(
        createChatChannelFeedSuccess({
          chatChannelId,
          visitor_feed
        })
      )

      return response
    } catch (error) {
      dispatch(createChatChannelFeedFailure())

      return error
    }
  }
}
