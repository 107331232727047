import {
  Slice,
  createSlice,
  SliceCaseReducers,
  createAsyncThunk
} from '@reduxjs/toolkit'
import api from '@src/common/utils/api'

const SLICE_NAME = 'cohorts'

type SliceState = {
  cohorts: globalLib.Cohort[]
  cohort: globalLib.Cohort | null
  cohortPlaylistVideos: globalLib.IVideo[]
  loading: boolean
  error: string
}

type CohortPayload = {
  channel_id: string
  segment: string
  name: string
  category: string
  country_code: string
  playlist_id: string
}

const initialState: SliceState = {
  cohorts: [],
  cohort: null,
  cohortPlaylistVideos: [],
  loading: false,
  error: null
}

export const createCohort = createAsyncThunk(
  `${SLICE_NAME}/createCohort`,
  async (params: {
    businessId: string
    channelId: string
    data: CohortPayload
  }) => {
    const response = await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/cohorts`,
      params.data
    )

    return response
  }
)

export const updateCohort = createAsyncThunk(
  `${SLICE_NAME}/updateCohort`,
  async (params: {
    businessId: string
    channelId: string
    cohortId: string
    data: CohortPayload
  }) => {
    const response = await api.put(
      `/bus/${params.businessId}/channels/${params.channelId}/cohorts/${params.cohortId}`,
      params.data
    )

    return response
  }
)

export const deleteCohort = createAsyncThunk(
  `${SLICE_NAME}/deleteCohort`,
  async (params: {
    businessId: string
    channelId: string
    cohortId: string
  }) => {
    const response = await api.delete(
      `/bus/${params.businessId}/channels/${params.channelId}/cohorts/${params.cohortId}`
    )

    return response
  }
)

export const fetchCohortById = createAsyncThunk(
  `${SLICE_NAME}/fetchCohortById`,
  async (params: {
    businessId: string
    channelId: string
    cohortId: string
  }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/cohorts/${params.cohortId}`
    )

    return data.data
  }
)

export const fetchCohorts = createAsyncThunk(
  `${SLICE_NAME}/fetchCohorts`,
  async (params: { businessId: string; channelId: string }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/cohorts`
    )

    return data.data
  }
)

export const fetchCohortPlaylistVideos = createAsyncThunk(
  `${SLICE_NAME}/fetchCohortPlaylistVideos`,
  async (params: {
    businessId: string
    channelId: string
    playlistId: string
  }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/playlists/${params.playlistId}/videos`,
      {
        params: {
          is_archived: false
        }
      }
    )

    return data.videos
  }
)

type ISlice = {
  resetCohortPlaylist: (state) => void
}

const slice: Slice<
  SliceState,
  SliceCaseReducers<SliceState> | ISlice,
  typeof SLICE_NAME
> = createSlice({
  name: 'cohorts',
  initialState: initialState,
  reducers: {
    resetCohortPlaylist: (state) => {
      state.cohortPlaylistVideos = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCohort.rejected, (state, action) => {
        state.error = action.error?.message
      })
      .addCase(updateCohort.rejected, (state, action) => {
        state.error = action.error?.message
      })
      .addCase(deleteCohort.rejected, (state, action) => {
        state.error = action.error?.message
      })
      .addCase(fetchCohortById.rejected, (state, action) => {
        state.loading = false
        state.cohort = null
        state.error = action.error?.message
      })
      .addCase(fetchCohortById.pending, (state) => {
        state.loading = true
        state.cohort = null
        state.error = null
      })
      .addCase(fetchCohortById.fulfilled, (state, action) => {
        state.loading = false
        state.cohort = action.payload
        state.error = null
      })
      .addCase(fetchCohorts.rejected, (state, action) => {
        state.loading = false
        state.cohorts = []
        state.error = action.error?.message
      })
      .addCase(fetchCohorts.pending, (state) => {
        state.loading = true
        state.cohorts = []
        state.error = null
      })
      .addCase(fetchCohorts.fulfilled, (state, action) => {
        state.loading = false
        state.cohorts = action.payload
        state.error = null
      })
      .addCase(fetchCohortPlaylistVideos.rejected, (state, action) => {
        state.loading = false
        state.cohortPlaylistVideos = []
        state.error = action.error?.message
      })
      .addCase(fetchCohortPlaylistVideos.fulfilled, (state, action) => {
        state.loading = false
        state.cohortPlaylistVideos = action.payload
        state.error = null
      })
  }
})

export default slice.reducer

export const { resetCohortPlaylist } = slice.actions
