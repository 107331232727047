import React, { useCallback, useMemo, useState } from 'react'

import { CreditCardOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import loadable from '@loadable/component'
import { useAppSelector } from '@src/app/hooks'
import LinkRow from '@src/common/components/Sidebar/LinkRow'
import { SidebarIcon } from '@src/common/components/Sidebar/SidebarIcon'
import { SideBarItemText } from '@src/common/components/Sidebar/SideBarItemText'
import { Box, Flex } from '@src/components/EmotionLayout'
import FeatureEnablementGate from '@src/components/FeatureEnablementGate'
import { useNabooFeatureFlag, usePixelAmpTracking } from '@src/hooks'
import useOAuthApp from '@src/hooks/useOAuthApp'
import {
  PERMISSIONS,
  useRolesAndPermissions
} from '@src/hooks/useRolesAndPermissions'
import { LiveChatButton } from '@src/modules/live-chat/components/LiveChatButton'
import SupportAvaModal from '@src/modules/live-chat/components/SupportAvaModal'
import { ProfileButton } from '@src/modules/profile/components/ProfileButton'
import CommandBar from '@src/modules/search/components/CommandBar'
import { setCmdBarIsOpen } from '@src/modules/search/redux/search'
import { setIsSidebarCollapsed } from '@src/redux/ui'
import { isPLGBusiness } from '@src/selectors/subscription'
import theme from '@src/styles/theme'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { animated } from 'react-spring'

import { resetVideoSearchParams } from '../../../redux/channel'
import {
  getStorage,
  lastChannel,
  lastChatChannel,
  lastStore
} from '../../../utils/localStorage'

const AccountUsage = loadable(
  () => import('@src/common/components/AccountUsage'),
  {
    resolveComponent: (components) => components.AccountUsage
  }
)

type SidebarProps = {
  style: any
  businessId: string
  onClickLink: () => void
  onToggleSidebar: () => void
  sidebarCollapsed: boolean
  mobileSidebarEnabled: boolean
  showUsage: (width: number) => void
}

const Sidebar: React.FC<SidebarProps> = ({
  style,
  businessId,
  onClickLink,
  mobileSidebarEnabled
}) => {
  const [showOfflineSupportModal, setShowOfflineSupportModal] = useState(false)
  const [showUsage, setShowUsage] = useState(-1)

  const dispatch = useDispatch()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const username = useAppSelector(
    (state) => state.profile?.user?.username || ''
  )

  const lastSelectChannel = getStorage(lastChannel, [username, businessId])

  const channelIds = useAppSelector(
    (state) => state.channel.idsByBusiness[businessId] || []
  )

  const { sidebarCollapsed } = useAppSelector((state) => state.ui)

  const oauthAppIds = useAppSelector(
    (state) => state.oauthApp.idsByBusiness[businessId] || []
  )

  const businessData = useAppSelector(
    (state) => state.business.businesses[businessId]
  )

  let businessStoreIds = useAppSelector(
    (state) => state.businessStore.ids || []
  )

  const isPLGCustomer = useAppSelector((state) =>
    isPLGBusiness(state, businessId)
  )

  const chatChannelIds = useAppSelector((state) => state.chatChannel.ids || [])

  const { t } = useTranslation()

  const nabooFF = useNabooFeatureFlag()

  const { isOAuthAppEmbed, oauthDefaultBusinessStoreId } = useOAuthApp()

  if (isOAuthAppEmbed && oauthDefaultBusinessStoreId) {
    businessStoreIds = businessStoreIds.filter(
      (storeId) => oauthDefaultBusinessStoreId === storeId
    )
  }

  // Get user permissions
  const { userCan } = useRolesAndPermissions()

  const storeAccess = useMemo(() => {
    return (
      ((nabooFF['cms_hide_store_for_member_lite'] &&
        userCan(PERMISSIONS.STORE_ACCESS)) ||
        !nabooFF['cms_hide_store_for_member_lite']) &&
      nabooFF.cms_oauth_app_sidebar_nav
    )
  }, [userCan, nabooFF])

  const onClickLinkWithTracking = (url, data = {}) => {
    pixelAmpTracking(url, data)
    onClickLink()
  }

  const handleCommandBarClick = useCallback(() => {
    dispatch(setCmdBarIsOpen())
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_ACCESS_SEARCH_NAVIGATION, {
      action: 'click'
    })
  }, [dispatch, pixelAmpTracking])

  return (
    <>
      <animated.div
        className="cy-side-bar"
        style={style}
        onMouseEnter={() => {
          dispatch(setIsSidebarCollapsed(false))
        }}
        onMouseLeave={() => {
          dispatch(setIsSidebarCollapsed(true))
        }}
        css={css`
          z-index: 13;
          padding: 8px 6px 16px 6px;
          position: fixed;
          width: 100%;
          max-width: 180px;
          height: 100vh;
          overflow: hidden;
          background-color: ${theme.sidebarBg};
          box-shadow: ${mobileSidebarEnabled && sidebarCollapsed
            ? 'none'
            : '2px 0 6px rgba(0, 21, 41, 0.35)'};
        `}
      >
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          position="relative"
          height="100%"
        >
          <Flex
            flex={1}
            overflowY="auto"
            overflowX="hidden"
            css={css`
              &::-webkit-scrollbar {
                display: none;
              }
            `}
            flexDirection="column"
          >
            <Flex
              style={{
                height: 40,
                width: '100%',
                flexShrink: 0,
                paddingLeft: 6,
                alignItems: 'center'
              }}
            >
              <Box as="img" src="/ic_sidebar_logo_v2.svg" />
              <SideBarItemText
                text={t('Firework')}
                show={!sidebarCollapsed}
                customStyles={css`
                  color: white;
                  margin-left: 6px;
                  width: 120px;
                  font-size: 20px;
                  -webkit-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                `}
              />
            </Flex>
            <Flex
              flexDirection="column"
              className="tour-app-container-step-1"
              mt={16}
            >
              <FeatureEnablementGate
                featureName={['short_videos', 'live_events']}
                businessId={businessId}
              >
                <LinkRow
                  to={
                    channelIds.length
                      ? `/business/${businessId}/channel/${
                          lastSelectChannel?.encoded_id ?? channelIds[0]
                        }`
                      : `/business/${businessId}/channel/create`
                  }
                  icon={
                    <SidebarIcon
                      width="18px"
                      height="14px"
                      icon="/channels.svg"
                    />
                  }
                  css={css`
                    margin-top: 0;
                  `}
                  iconWidth={18}
                  data-testid="video-link"
                  text={t('Video')}
                  onClick={() => {
                    dispatch(resetVideoSearchParams())
                    if (channelIds.length) {
                      onClickLinkWithTracking(
                        TRACKING_EVENTS.BUSINESS_CLICK_CHANNEL_NAV,
                        {
                          channel: channelIds[0]
                        }
                      )
                    }
                  }}
                  showTooltip={!sidebarCollapsed}
                />
              </FeatureEnablementGate>

              <FeatureEnablementGate
                featureName="digital_showrooms"
                businessId={businessId}
              >
                {nabooFF.cms_showroom_entrance &&
                nabooFF.cms_oauth_app_sidebar_nav ? (
                  <>
                    <LinkRow
                      to={`/business/${businessId}/showrooms`}
                      icon={
                        <VideoCameraOutlined
                          style={{
                            fontSize: '16px',
                            color: '#B5D1FF',
                            marginRight: 0
                          }}
                        />
                      }
                      data-testid="showrooms-link"
                      text={t('Showrooms')}
                      onClick={onClickLink}
                      showTooltip={!sidebarCollapsed}
                    />
                  </>
                ) : null}
              </FeatureEnablementGate>

              <FeatureEnablementGate
                featureName="one_to_one"
                businessId={businessId}
              >
                {nabooFF.cms_oauth_app_sidebar_nav && chatChannelIds.length ? (
                  <LinkRow
                    to={`/business/${businessId}/chat_channel/${
                      getStorage(lastChatChannel, [username, businessId]) ??
                      chatChannelIds[0]
                    }`}
                    icon={
                      <SidebarIcon
                        width="18px"
                        height="14px"
                        icon="/one_to_one.svg"
                      />
                    }
                    text={t('1:1 Video Chat')}
                    onClick={onClickLink}
                    showTooltip={!sidebarCollapsed}
                  />
                ) : null}
              </FeatureEnablementGate>

              {nabooFF.cms_smart_button && (
                <>
                  <LinkRow
                    to={`/business/${businessId}/automations`}
                    icon={
                      <SidebarIcon
                        width="16px"
                        height="16px"
                        icon="/automation.svg"
                      />
                    }
                    data-testid="automations-link"
                    onClick={onClickLink}
                    text={t('Automation')}
                    showTooltip={!sidebarCollapsed}
                  />
                </>
              )}
              <FeatureEnablementGate featureName="ava" businessId={businessId}>
                <LinkRow
                  to={`/business/${businessId}/ava`}
                  icon={
                    <SidebarIcon
                      width="16px"
                      height="21px"
                      icon="/virtual_assistant.svg"
                    />
                  }
                  data-testid="ava-link"
                  text={t('AI Video Assistant')}
                  onClick={onClickLink}
                  showTooltip={!sidebarCollapsed}
                />
              </FeatureEnablementGate>
            </Flex>

            {storeAccess ? (
              <>
                <LinkRow
                  to={
                    businessStoreIds.length
                      ? `/business/${businessId}/store/${
                          getStorage(lastStore, [username, businessId]) ??
                          businessStoreIds[0]
                        }`
                      : `/business/${businessId}/connect_store`
                  }
                  icon={
                    <SidebarIcon width="18px" height="15px" icon="/store.svg" />
                  }
                  data-testid="store-link"
                  text={t('Stores')}
                  showTooltip={!sidebarCollapsed}
                />
              </>
            ) : null}

            {/* OAuth Apps section */}
            {!!oauthAppIds.length && nabooFF.cms_oauth_app_sidebar_nav && (
              <>
                <LinkRow
                  to={`/business/${businessId}/app/${oauthAppIds[0]}`}
                  icon={
                    <SidebarIcon
                      width="18px"
                      height="15px"
                      icon="/apps_inactive.svg"
                    />
                  }
                  data-testid="apps-link"
                  text={t('Apps')}
                  showTooltip={!sidebarCollapsed}
                />
              </>
            )}

            {nabooFF.cms_oauth_app_sidebar_nav && (
              <>
                {/* <LinkRow
                to={`/business/${businessId}/video_showcase`}
                icon={
                  <Box
                    as="img"
                    pr={sidebarCollapsed ? '0' : '15'}
                    mb={'xxsmall'}
                    src={
                      showcaseActive
                        ? '/subscriptions_outlined.svg'
                        : '/subscriptions.svg'
                    }
                  />
                }
                text={t('Showcase')}
                onClick={onClickLink}
                animatedLinkStyle={animatedLinkStyle}
              /> */}
                {!isOAuthAppEmbed && (
                  <>
                    {userCan(PERMISSIONS.BUSINESS_APPS_VIEW) &&
                      !isPLGCustomer && (
                        <LinkRow
                          to={`/business/${businessId}/apps`}
                          icon={
                            <SidebarIcon
                              width="16px"
                              height="16px"
                              icon="/business_apps.svg"
                            />
                          }
                          text={t('Business Apps')}
                          onClick={onClickLink}
                          showTooltip={!sidebarCollapsed}
                        />
                      )}
                    {nabooFF.cms_business_webhooks && (
                      <LinkRow
                        to={`/business/${businessId}/webhooksv2`}
                        icon={
                          <SidebarIcon
                            width="18px"
                            height="18px"
                            icon="/webhook.svg"
                          />
                        }
                        text={t('Webhooks')}
                        onClick={onClickLink}
                        showTooltip={!sidebarCollapsed}
                      />
                    )}
                  </>
                )}

                {businessData?.metadata?.show_meter_usage && (
                  <LinkRow
                    icon={
                      <SidebarIcon
                        width="16px"
                        height="16px"
                        icon="/analytics.svg"
                      />
                    }
                    text={t('Account Usage')}
                    showTooltip={!sidebarCollapsed}
                    onClick={(e) => {
                      const element = document.elementFromPoint(
                        e.clientX,
                        e.clientY
                      )
                      const top = element.getBoundingClientRect().top
                      setShowUsage(showUsage > 0 ? -1 : top)
                    }}
                  />
                )}
                {!isOAuthAppEmbed && (
                  <LinkRow
                    className="tour-setting-step-3"
                    to={`/business/${businessId}/settings`}
                    icon={
                      <SidebarIcon
                        width="18px"
                        height="18px"
                        icon="/settings.svg"
                      />
                    }
                    data-testid="setting-link"
                    onClick={onClickLink}
                    text={t('Settings')}
                    showTooltip={!sidebarCollapsed}
                  />
                )}
              </>
            )}
          </Flex>

          <Flex
            flexDirection="column"
            className="tour-profile-step-4"
            gap={8}
            style={{ marginTop: 8 }}
          >
            {isPLGCustomer && (
              <LinkRow
                mt={0}
                icon={
                  <CreditCardOutlined style={{ marginRight: 0, padding: 1 }} />
                }
                to={`/business/${businessId}/pricing-plan`}
                text={t('Pricing Plan')}
                onClick={onClickLink}
                showTooltip={!sidebarCollapsed}
                backgroundColor="#003580"
              />
            )}
            <LinkRow
              icon={
                <SidebarIcon
                  width="18px"
                  height="18px"
                  icon="/ic_sidebar_search.svg"
                />
              }
              mt={0}
              text={t('Search')}
              onClick={handleCommandBarClick}
              showTooltip={!sidebarCollapsed}
            />
            <LiveChatButton
              expand={!sidebarCollapsed}
              onClick={() => setShowOfflineSupportModal(true)}
            />

            <LinkRow
              mt={0}
              to={`/business/${businessId}/helpcenter`}
              icon={
                <SidebarIcon
                  width="18px"
                  height="18px"
                  icon="/help_center.svg"
                />
              }
              text={t('Support')}
              onClick={onClickLink}
              showTooltip={!sidebarCollapsed}
            />
            <ProfileButton
              expand={!sidebarCollapsed}
              businessId={businessId}
              onClickLink={onClickLink}
            />
          </Flex>
        </Flex>
      </animated.div>
      {showOfflineSupportModal && (
        <SupportAvaModal onCancel={() => setShowOfflineSupportModal(false)} />
      )}
      {showUsage > 0 && (
        <AccountUsage
          businessId={businessId}
          setShowUsage={setShowUsage}
          top={showUsage}
        />
      )}
      <CommandBar />
    </>
  )
}

export default Sidebar
