import { Dispatch, createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'

type IProps = {
  chatChannelId: string | null
  snippets: ChatChannelSnippet[]
}

const initialState: IProps = {
  chatChannelId: null,
  snippets: []
}

const slice = createSlice({
  name: 'chatChannelSnippet',
  initialState: initialState,
  reducers: {
    fetchChatChannelSnippetsSuccess(state, action) {
      const { chatChannelId, snippets } = action.payload

      state.chatChannelId = chatChannelId
      state.snippets = snippets
    }
  }
})

export default slice.reducer
export const { fetchChatChannelSnippetsSuccess } = slice.actions

/**
 * Fetch Chat Channel Snippets
 */
const fetchChatChannelSnippetsRequest = createAction(
  'chatChannelSnippet/fetchChatChannelSnippetsRequest'
)
const fetchChatChannelSnippetsFailure = createAction(
  'chatChannelSnippet/fetchChatChannelSnippetsFailure'
)

export function fetchChatChannelSnippets(
  businessId: string,
  chatChannelId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchChatChannelSnippetsRequest())
      const url = `bus/${businessId}/chat_channels/${chatChannelId}/user_snippets`
      const response = await api.get(url)
      dispatch(
        fetchChatChannelSnippetsSuccess({
          chatChannelId,
          snippets: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(fetchChatChannelSnippetsFailure())

      return error
    }
  }
}

/**
 * Update Chat Channel Snippets
 */
const updateChatChannelSnippetsRequest = createAction(
  'chatChannelSnippet/updateChatChannelSnippetsRequest'
)
const updateChatChannelSnippetsSuccess = createAction(
  'chatChannelSnippet/updateChatChannelSnippetsSuccess'
)
const updateChatChannelSnippetsFailure = createAction(
  'chatChannelSnippet/updateChatChannelSnippetsFailure'
)

export interface ChatChannelSnippetRequest {
  text_snippets: { shortcut: string; phrase: string }[]
}

export function updateChatChannelSnippets(
  businessId: string,
  chatChannelId: string,
  data: ChatChannelSnippetRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateChatChannelSnippetsRequest())
      const url = `bus/${businessId}/chat_channels/${chatChannelId}/user_snippets`
      const response = await api.patch(url, data)

      // Refetch snippets as no response returned from PATCH api call (204)
      dispatch(updateChatChannelSnippetsSuccess())
      dispatch(fetchChatChannelSnippets(businessId, chatChannelId) as any)

      return response
    } catch (error) {
      dispatch(updateChatChannelSnippetsFailure())

      return error
    }
  }
}
