import { localStorageProvider } from '@src/utils/storageProvider'

const getFavoriteChannels = (
  businessId: string,
  userId: string
): {
  encoded_id: string
  name: string
}[] => {
  return JSON.parse(
    localStorageProvider.getItem(`favorite_channel_${businessId}_${userId}`) ||
      '[]'
  )
}

const addBusinessFavoriteChannel = (
  businessId: string,
  userId: string,
  channel: {
    encoded_id: string
    name: string
  }
): void => {
  const favoriteChannel = getFavoriteChannels(businessId, userId)
  const exist = favoriteChannel.find((item) => {
    return item.encoded_id === channel.encoded_id
  })
  if (!exist) {
    favoriteChannel.push(channel)
  }
  localStorageProvider.setItem(
    `favorite_channel_${businessId}_${userId}`,
    JSON.stringify(favoriteChannel)
  )
}

const removeBusinessFavoriteChannel = (
  businessId: string,
  userId: string,
  encodeId: string
): void => {
  let favoriteChannel = getFavoriteChannels(businessId, userId)
  favoriteChannel = favoriteChannel.filter((item) => {
    return item.encoded_id !== encodeId
  })
  localStorageProvider.setItem(
    `favorite_channel_${businessId}_${userId}`,
    JSON.stringify(favoriteChannel)
  )
}

const renameBusinessFavoriteChannel = (
  businessId: string,
  userId: string,
  channel: {
    encoded_id: string
    name: string
  }
): void => {
  let favoriteChannel = getFavoriteChannels(businessId, userId)
  favoriteChannel = favoriteChannel.map((item) => {
    if (item.encoded_id === channel.encoded_id) {
      return {
        encoded_id: channel.encoded_id,
        name: channel.name
      }
    } else {
      return item
    }
  })

  localStorageProvider.setItem(
    `favorite_channel_${businessId}_${userId}`,
    JSON.stringify(favoriteChannel)
  )
}

export {
  addBusinessFavoriteChannel,
  getFavoriteChannels,
  removeBusinessFavoriteChannel,
  renameBusinessFavoriteChannel
}
