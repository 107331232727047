import { MIN_FILE_SIZE, MIN_VIDEO_DURATION } from '@src/constants'
import {
  BatchImporterEditMedia,
  BatchImporterMediaType
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import { getS3Signature, S3Result, uploadFileToS3 } from '@src/utils/s3'
import mimeTypes from 'mime-types'
import { TFunction } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

const getDuration = (file: File): Promise<number> => {
  return new Promise((resolve) => {
    const videoElement = document.createElement('video')
    videoElement.preload = 'metadata'

    videoElement.onloadedmetadata = function () {
      window.URL.revokeObjectURL(videoElement.src)
      resolve(videoElement.duration)
    }

    videoElement.src = window.URL.createObjectURL(file)
  })
}

const beforeUpload = async (
  mediaExtension: BatchImporterEditMedia,
  file: File,
  t: TFunction,
  toast: (text: string) => void,
  options: { maxVideoSize: number | null; maxVideoDuration: number | null }
): Promise<boolean> => {
  const { maxVideoSize, maxVideoDuration } = options
  if (mediaExtension.mediaType === BatchImporterMediaType.IMAGE) {
    return true
  } else if (mediaExtension.mediaType === BatchImporterMediaType.VIDEO) {
    // check video duration
    const duration = (await getDuration(file)) || 0
    if (duration < MIN_VIDEO_DURATION) {
      toast(
        t('Please upload a video longer than {{second}} seconds', {
          second: MIN_VIDEO_DURATION
        })
      )

      return false
    } else if (duration > maxVideoDuration) {
      toast(
        t(
          'Please upload a video no longer than' +
            ' {{maxVideoDuration}} seconds',
          { maxVideoDuration }
        )
      )

      return false
    }

    // check video size
    if (file.size < MIN_FILE_SIZE || file.size > maxVideoSize * 1_000_000) {
      const tooSmall = file.size < MIN_FILE_SIZE
      const fileSize = tooSmall
        ? `${MIN_FILE_SIZE / 1000}Kb`
        : `${maxVideoSize}MB`

      const errorMsg = tooSmall
        ? t('{{file}} file is smaller than {{fileSize}}', {
            file: file.name,
            fileSize: fileSize
          })
        : t('{{file}} file is larger than {{fileSize}}', {
            file: file.name,
            fileSize: fileSize
          })

      toast(errorMsg)

      return false
    }

    return true
  }

  return false
}

const uploadMedia2S3 = async (mediaUrl: string): Promise<S3Result> => {
  // 1. download by mediaUrl
  const res = await fetch(mediaUrl)
  const blob = await res.blob()
  const fileType = blob.type || 'image/jpeg'
  const fileName = `${uuidv4()}.${mimeTypes.extension(fileType)}`
  const file = new File([blob], fileName, { type: fileType })
  // 2. beforeUpload, need t(''). ignore first
  // 3. upload media
  const signature = await getS3Signature(file)
  const s3Res = await uploadFileToS3(file, signature)

  return new Promise((resolve, reject) => {
    if (!s3Res || !s3Res.key) {
      reject()
    } else {
      resolve(s3Res)
    }
  })
}

export { getDuration, beforeUpload, uploadMedia2S3 }
