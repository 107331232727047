import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Ava = loadable(() => import('../pages/Ava'))
const AvaDetail = loadable(() => import('../pages/AvaDetail'))
const AvaMessageAudit = loadable(() => import('../pages/AvaMessageAudit'))
const AvaMessageFeedback = loadable(() => import('../pages/AvaMessageFeedback'))
const AssistantProfileCreation = loadable(
  () => import('../pages/AssistantProfileCreation')
)
const AvaCreationV2 = loadable(() => import('../pages/AvaCreationV2'))
const AssistantProfileEdit = loadable(
  () => import('../pages/AssistantProfileEdit')
)
const ConversationHistory = loadable(
  () => import('../pages/ConversationHistory')
)

export const AvaRoutes = (
  <>
    <Route path="/business/:businessId/ava" element={<Ava />} />
    <Route
      path="/business/:businessId/ava/:domainAssistantId"
      element={<AvaDetail />}
    />
    <Route
      path="/business/:businessId/ava/assistant_profile/create"
      element={<AssistantProfileCreation />}
    />
    <Route
      path="/business/:businessId/ava/assistant/create"
      element={<AvaCreationV2 />}
    />
    <Route
      path="/business/:businessId/ava/:domainAssistantId/conversations"
      element={<ConversationHistory />}
    />
    <Route
      path="/business/:businessId/ava/:domainAssistantId/audit"
      element={<AvaMessageAudit />}
    />
    <Route
      path="/business/:businessId/ava/:domainAssistantId/message_feedback"
      element={<AvaMessageFeedback />}
    />
    <Route
      path="/business/:businessId/ava/assistant_profile/:assistantId/edit"
      element={<AssistantProfileEdit />}
    />
  </>
)
