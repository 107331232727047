import React, { useLayoutEffect } from 'react'

import { useTheme } from '@src/common/contexts/ThemeContext'

import { Flex, Styles } from './EmotionLayout'

export const FullScreenLayout: React.FC<Styles> = ({ children, ...rest }) => {
  useLayoutEffect(() => {
    document.body.style.overflowY = 'hidden'

    return () => {
      document.body.style.overflowY = 'auto'
    }
  }, [])

  const { theme } = useTheme()

  return (
    <Flex
      width="100%"
      minHeight="100vh"
      bg={theme['background-color']}
      zIndex="20"
      position="absolute"
      top="0"
      left="0"
      flexDirection="column"
      {...rest}
    >
      {children}
    </Flex>
  )
}
