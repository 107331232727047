import React from 'react'

import { useAppSelector } from '@src/app/hooks'
import { MenuItem } from '@src/components/Menu'

function BusinessMenuItem({
  id,
  onSelect,
  selected
}: {
  id: string
  onSelect: () => void
  selected: boolean
}): JSX.Element {
  const business = useAppSelector((state) => state.business.businesses[id])

  return (
    <MenuItem selected={selected} onSelect={onSelect}>
      <span>{business?.name}</span>
    </MenuItem>
  )
}

export default React.memo(BusinessMenuItem)
