import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { SIGMA_INSIGHTS } from '@src/constants'
import { localStorageProvider } from '@src/utils/storageProvider'

type InitialState = {
  sigmaUrlByLevel: Record<string, Record<string, string>>
  loading: boolean
}

type argsType = {
  businessId: string
  channelId: string
  dashboardId: string
  level: string
  params?:
    | {
        start_date: string
        end_date: string
      }
    | Record<string, unknown>
  id: string
}

const initialState: InitialState = {
  sigmaUrlByLevel: {
    [SIGMA_INSIGHTS.VIDEO_CHANNEL_OVERVIEW]: {},
    [SIGMA_INSIGHTS.VIDEO_CHANNEL_SHORT_VIDEO]: {},
    [SIGMA_INSIGHTS.VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL]: {},
    [SIGMA_INSIGHTS.VIDEO_CHANNEL_LIVESTREAM]: {},
    [SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO]: {},
    [SIGMA_INSIGHTS.VIDEO_LIVESTREAM]: {},
    [SIGMA_INSIGHTS.OTO_BUSINESS_GENERAL]: {},
    [SIGMA_INSIGHTS.OTO_BUSINESS_PERFORMANCE]: {},
    [SIGMA_INSIGHTS.OTO_BUSINESS_REQUESTS]: {},
    [SIGMA_INSIGHTS.OTO_TEAM_ONLINE_HOURS]: {},
    [SIGMA_INSIGHTS.AVA_BUSINESS]: {},
    [SIGMA_INSIGHTS.AVA_DOMAIN_ASSISTANT]: {}
  },
  loading: true
}

const sigmaLocales = {
  zh: 'zh-cn',
  en: 'en',
  fr: 'fr',
  de: 'de',
  it: 'it',
  ja: 'ja',
  ko: 'ko-kr',
  pl: 'pl',
  pt: 'pt',
  ru: 'ru',
  es: 'es',
  sv: 'sv-se',
  th: 'th'
}

const sigmaEnvironment = {
  staging: 'staging',
  production: 'prod'
}

const mapFilterId = {
  [SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO]: 'video_id',
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL]: 'playlist_id',
  [SIGMA_INSIGHTS.VIDEO_LIVESTREAM]: 'live_stream_id',
  [SIGMA_INSIGHTS.AVA_DOMAIN_ASSISTANT]: 'domain_assistant_id'
}

const mapUserAttributeId = {
  [SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO]: ':ua_video_id',
  [SIGMA_INSIGHTS.VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL]: ':ua_playlist_id',
  [SIGMA_INSIGHTS.VIDEO_LIVESTREAM]: ':ua_live_stream_id',
  [SIGMA_INSIGHTS.AVA_DOMAIN_ASSISTANT]: ':ua_domain_assistant_id'
}

export const getLocale = (): string => {
  const browserLocale = localStorageProvider.getItem('i18nextLng') || 'en'

  return sigmaLocales[browserLocale.slice(0, 2)] || 'en'
}

export const fetchSigmaUrl = createAsyncThunk(
  'sigmaEmbed/fetchSigmaUrl',
  async (args: argsType) => {
    const { businessId, channelId, dashboardId, level, params, id } = args
    if (
      [
        SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO,
        SIGMA_INSIGHTS.VIDEO_CHANNEL_PLAYLIST_INDIVIDUAL,
        SIGMA_INSIGHTS.VIDEO_LIVESTREAM,
        SIGMA_INSIGHTS.AVA_DOMAIN_ASSISTANT
      ].includes(level)
    ) {
      params[mapFilterId[level]] = id
      params[mapUserAttributeId[level]] = id
    }
    params[':lng'] = getLocale()
    params[':ua_business_id'] = businessId
    params[':ua_channel_id'] = channelId
    const environment =
      sigmaEnvironment[process.env.REACT_APP_ENV || 'production']
    params['environment'] = environment

    const res = await api.get(
      `bus/${businessId}/channels/${channelId}/sigma/${dashboardId}`,
      { params }
    )

    return { ...res.data, level, id }
  }
)

export const clearSigmaUrl = createAsyncThunk(
  'sigmaEmbed/clearSigmaUrl',
  async (args: { level: string; id: string }) => {
    return args
  }
)

const slice = createSlice({
  name: 'sigmaEmbed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSigmaUrl.fulfilled, (state, action) => {
        state.loading = false
        const { level, id, url } = action.payload
        state.sigmaUrlByLevel[level][id] = url
      })
      .addCase(clearSigmaUrl.fulfilled, (state, action) => {
        const { level, id } = action.payload
        delete state.sigmaUrlByLevel[level][id]
      })
  }
})

export default slice.reducer
