import React, { useContext, useEffect, useState } from 'react'

import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { useAppSelector } from '@src/app/hooks'
import { BusinessContext } from '@src/common/contexts/BusinessContext'
import { useNabooFeatureFlag, usePixelAmpTracking } from '@src/hooks'
import {
  setModalShownInStorage,
  useGlobalPopupModals
} from '@src/hooks/useGlobalPopupModals'
import {
  setDisableSidebarAnimation,
  setIsSidebarCollapsed
} from '@src/redux/ui'
import { isPLGBusiness } from '@src/selectors/subscription'
import { TRACKING_EVENTS } from '@src/utils/tracking'
import { Button, Modal, Typography } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Tour from 'reactour'

import { Box, Flex } from './EmotionLayout'

const NavigationLayer: React.FC<{
  stepCount: number
  currentStep: number
  padding?: string
  setCurrentStep: (step: number) => void
  onDone: () => void
}> = ({ stepCount, currentStep, setCurrentStep, padding, onDone }) => {
  const { t } = useTranslation()

  return (
    <Flex justifyContent="space-between" alignItems="center" padding={padding}>
      <Flex flexDirection="row" gap={5}>
        {Array.from({ length: stepCount }).map((_, index) => (
          <Box
            key={index}
            as="span"
            borderRadius={5}
            style={{
              backgroundColor: index === currentStep ? '#006AFF' : '#BFDAFF'
            }}
            width={12}
            height={4}
            flexShrink={0}
          />
        ))}
      </Flex>
      <Flex gap={12}>
        {currentStep > 0 && (
          <Button
            onClick={() => {
              setCurrentStep(currentStep - 1)
            }}
          >
            {t('Back')}
          </Button>
        )}
        <Button
          type="primary"
          onClick={async () => {
            if (currentStep === stepCount - 1) {
              onDone()
            } else {
              setCurrentStep(currentStep + 1)
            }
          }}
        >
          {currentStep === stepCount - 1 ? t('Done') : t('Next')}
        </Button>
      </Flex>
    </Flex>
  )
}

const withNavigationLayer = (
  Component: React.ReactNode,
  stepCount: number,
  currentStep: number,
  setCurrentStep: (step: number) => void,
  padding?: string,
  onDone?: () => void
) => {
  return (
    <Flex flexDirection="column" gap={16}>
      {Component}
      <NavigationLayer
        padding={padding}
        stepCount={stepCount}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onDone={onDone}
      />
    </Flex>
  )
}

export const TourComponent = (): JSX.Element => {
  const location = window.location // Get the current location from window
  const { cms_hide_all_videos_menu } = useNabooFeatureFlag()
  const matchVideoHome = cms_hide_all_videos_menu
    ? location.href.match(
        /^https?:\/\/[^/]+\/business\/[^/]+\/channel\/[^/]+\/videos(\?activeTab=original|\?activeTab=all|$)/
      )
    : location.href.match(
        /^https?:\/\/[^/]+\/business\/[^/]+\/channel\/[^/]+\/videos\?activeTab=all/
      )
  const urlMatch =
    matchVideoHome ||
    location.href.match(
      /^https?:\/\/[^/]+\/business\/[^/]+\/channel\/[^/]+\/home/
    )
  const businessId = useContext(BusinessContext)
  const plgBusiness = useAppSelector((state) =>
    isPLGBusiness(state, businessId)
  )
  const [showModal, setShowModal] = useState(true)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showHighlight, setShowHighlight] = useState(false)
  const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false)
  const profile = useAppSelector((state) => state.profile)
  const [currentStep, setCurrentStep] = useState(-1)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const { shouldShowModalGlobal, hasShowModal } = useGlobalPopupModals('tour')
  const stepCount = plgBusiness ? 4 : 5
  const steps = [
    {
      selector: '.tour-app-container-step-1',
      resizeObservables: ['.cy-side-bar'],
      style: {
        maxWidth: 400
      },
      stepInteraction: false,
      content: withNavigationLayer(
        <Flex flexDirection="column" gap={16}>
          <Typography.Title level={4}>
            {t('All your products in arms reach! ')}
          </Typography.Title>
          <Typography.Text>
            {t(
              "We've simplified access to all your subscribed products. Just go to the left-hand navigation to find them."
            )}
          </Typography.Text>
        </Flex>,
        stepCount,
        currentStep,
        setCurrentStep
      ),
      action: () => {
        dispatch(setIsSidebarCollapsed(false))
      }
    },
    {
      selector: '.tour-h1-navigation-step-2',
      style: {
        maxWidth: 400
      },
      resizeObservables: ['.cy-side-bar'],
      stepInteraction: false,
      content: withNavigationLayer(
        <Flex flexDirection="column" gap={16}>
          <Typography.Title level={4}>
            {t('The things that matter, brought to the surface')}
          </Typography.Title>
          <Typography.Text>
            {t(
              'At the top level, the navigation provides quick and easy access to vital parts of your business portal, allowing you to operate efficiently.'
            )}
          </Typography.Text>
        </Flex>,
        stepCount,
        currentStep,
        setCurrentStep
      ),
      position: [200, 84],
      action: () => {
        dispatch(setIsSidebarCollapsed(true))
      }
    },
    plgBusiness
      ? null
      : {
          resizeObservables: ['.cy-side-bar'],
          selector: '.tour-setting-step-3',
          style: {
            maxWidth: 400,
            padding: '0 0 24px 0'
          },
          stepInteraction: false,
          content: withNavigationLayer(
            <Flex flexDirection="column" borderRadius={16}>
              <Flex
                alignItems="center"
                padding="32px 48px 24px 48px"
                gap={32}
                justifyContent="center"
                style={{
                  backgroundColor: '#E5F0FF',
                  fontSize: 16,
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16
                }}
              >
                <Flex flexDirection="column" alignItems="center" gap={10}>
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    width={80}
                    height={80}
                    justifyContent="center"
                    borderRadius={16}
                    style={{
                      backgroundColor: '#fff'
                    }}
                  >
                    <Box
                      as="img"
                      src="/tour/sdk.svg"
                      width="48px"
                      height="48px"
                    />
                  </Flex>
                  <Typography.Title level={4} style={{ fontSize: 14 }}>
                    {t('SDK')}
                  </Typography.Title>
                </Flex>
                <Flex flexDirection="column" alignItems="center" gap={10}>
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    width={80}
                    height={80}
                    justifyContent="center"
                    borderRadius={16}
                    style={{
                      backgroundColor: '#fff'
                    }}
                  >
                    <Box
                      as="img"
                      src="/tour/automation.svg"
                      width="48px"
                      height="48px"
                    />
                  </Flex>
                  <Typography.Title level={4} style={{ fontSize: 14 }}>
                    {t('Automation')}
                  </Typography.Title>
                </Flex>
                <Flex flexDirection="column" alignItems="center" gap={10}>
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    width={80}
                    height={80}
                    justifyContent="center"
                    borderRadius={16}
                    style={{
                      backgroundColor: '#fff'
                    }}
                  >
                    <Box
                      as="img"
                      src="/tour/webhook.svg"
                      width="48px"
                      height="48px"
                    />
                  </Flex>
                  <Typography.Title level={4} style={{ fontSize: 14 }}>
                    {t('Webhook')}
                  </Typography.Title>
                </Flex>
              </Flex>
              <Flex flexDirection="column" gap={16} padding="24px 30px 0 30px">
                <Typography.Title level={4}>
                  {t('Settings now contains all advance features')}
                </Typography.Title>
                <Typography.Text>
                  {t(
                    'To access features such as Webhooks, Automations, or to fine-tune your SDK.'
                  )}
                </Typography.Text>
              </Flex>
            </Flex>,
            stepCount,
            currentStep,
            setCurrentStep,
            '0 30px'
          ),
          action: () => {
            dispatch(setIsSidebarCollapsed(false))
          }
        },
    {
      selector: '.tour-profile-step-4',
      style: {
        maxWidth: 400
      },
      resizeObservables: ['.cy-side-bar'],
      stepInteraction: false,
      content: withNavigationLayer(
        <Flex flexDirection="column" gap={16}>
          <Typography.Title level={4}>
            {t('Your profile and support, now more accessible')}
          </Typography.Title>
          <Typography.Text>
            {t(
              'Find your profile and support easily at the bottom left of your screen. Everything you need, within reach.'
            )}
          </Typography.Text>
        </Flex>,
        stepCount,
        currentStep,
        setCurrentStep
      ),
      action: () => {
        dispatch(setIsSidebarCollapsed(false))
      }
    },
    {
      selector: '[data-tour="tour-video-container-step-5"]',
      resizeObservables: ['.cy-side-bar'],
      stepInteraction: false,
      content: withNavigationLayer(
        <Flex flexDirection="column" gap={16}>
          <Typography.Title level={4}>
            {t('More room for your content')}
          </Typography.Title>
          <Typography.Text>
            {t(
              'We now have a larger canvas for your content, allowing you to showcase more content in one place.'
            )}
          </Typography.Text>
        </Flex>,
        stepCount,
        currentStep,
        setCurrentStep,
        undefined,
        () => {
          setShowHighlight(false)
          setShowConfirmModal(true)
          setShowModal(false)
        }
      ),
      style: {
        top: 'auto',
        left: 'auto',
        right: 40,
        bottom: 40,
        transform: 'none',
        maxWidth: 400
      },
      action: () => {
        dispatch(setIsSidebarCollapsed(true))
      }
    }
  ].filter(Boolean)

  const isVerifiedAfterDate = (dateString) => {
    const dateToCompare = moment('2024-09-20')
    const verifiedDate = moment(dateString)

    return verifiedDate.isAfter(dateToCompare)
  }

  useEffect(() => {
    dispatch(setDisableSidebarAnimation(showHighlight))
  }, [showHighlight, dispatch])

  const isPrimaryEmailVerifiedAfterDate = profile.primaryEmailGroup?.verified_at
    ? isVerifiedAfterDate(profile.primaryEmailGroup.verified_at)
    : false

  if (!shouldShowModalGlobal) {
    return null
  }

  if (!urlMatch || isPrimaryEmailVerifiedAfterDate) {
    hasShowModal()

    return null
  }

  return (
    <>
      <Tour
        steps={steps}
        rounded={8}
        disableDotsNavigation
        showNavigation={false}
        startAt={currentStep >= 0 ? currentStep : 0}
        showNumber={false}
        closeWithMask={false}
        showButtons={false}
        goToStep={currentStep}
        getCurrentStep={(step) => {
          setCurrentStep(step)
        }}
        maskSpace={5}
        isOpen={showHighlight}
        onRequestClose={() => {
          if (currentStep === 4) {
            setShowHighlight(false)
            setShowConfirmModal(true)
            setShowModal(false)
          } else {
            setShowHighlight(false)
            setShowCloseConfirmModal(true)
            setShowModal(false)
          }
        }}
      />
      <Modal
        open={showModal}
        footer={null}
        centered
        width={600}
        css={css`
          .ant-modal-content {
            border-radius: 10px;
          }
        `}
        bodyStyle={{ padding: 0, borderRadius: 10 }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        onCancel={() => {
          setShowModal(false)
          setShowCloseConfirmModal(true)
        }}
      >
        <video
          src="/tour_animation.webm"
          width="100%"
          autoPlay
          muted
          loop
          style={{
            borderRadius: '10px 10px 0 0',
            objectFit: 'cover',
            aspectRatio: '1280 / 776'
          }}
        />
        <Flex
          alignItems="center"
          width="100%"
          flexDirection="column"
          padding="48px 80px"
        >
          <Typography.Title style={{ fontSize: 24 }}>
            {t('Check out our brand new look!')}
          </Typography.Title>
          <Typography.Text style={{ textAlign: 'center' }}>
            {t(
              'You asked, and we listened! We’re excited to share that we have overhauled our business portal! '
            )}
          </Typography.Text>
          <Flex width="100%" justifyContent="center" gap="12" mt={16}>
            <Button
              onClick={() => {
                setShowModal(false)
                setShowCloseConfirmModal(false)
                dispatch(setIsSidebarCollapsed(true))
                pixelAmpTracking(TRACKING_EVENTS.BUSINESS_TOUR_REMIND_ME_LATER)
              }}
            >
              {t('Remind me later')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowModal(false)
                setShowHighlight(true)
              }}
            >
              {t('Take the tour')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <Modal
        open={showConfirmModal}
        footer={null}
        centered
        width={600}
        closable={false}
      >
        <Flex
          alignItems="center"
          width="100%"
          flexDirection="column"
          padding="24px 56px"
        >
          <Typography.Title style={{ fontSize: 24 }}>
            {t('Try it for yourself')}
          </Typography.Title>
          <Typography.Text style={{ textAlign: 'center' }}>
            {t(
              'You’ve completed the tour! Now, explore and experience the features firsthand. Let’s see what you can achieve.'
            )}
          </Typography.Text>
          <Flex width="100%" justifyContent="center" gap="12" mt={32}>
            <Button
              onClick={() => {
                setShowConfirmModal(false)
                setShowHighlight(true)
                setCurrentStep(0)
              }}
            >
              {t('Start again')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowConfirmModal(false)
                dispatch(setIsSidebarCollapsed(true))
                setModalShownInStorage('tour')
              }}
            >
              {t('Try it out')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <Modal
        open={showCloseConfirmModal}
        footer={null}
        css={css`
          .ant-modal-body {
            border-radius: 12px !important;
          }
          .ant-modal-content {
            border-radius: 12px !important;
          }
        `}
        centered
        width={600}
        closable={false}
      >
        <Box
          display="flex"
          mb={12}
          alignSelf="start"
          alignItems="center"
          position="absolute"
          left={20}
          zIndex={1000}
          gap={4}
          fontWeight={500}
          color="#000"
          fontSize={14}
          top={20}
          cursor="pointer"
          onClick={() => {
            setShowCloseConfirmModal(false)
            setShowModal(false)
            if (currentStep >= 0) {
              setShowHighlight(true)
            } else {
              setShowModal(true)
            }
          }}
        >
          <LeftOutlined />
          {t('Back')}
        </Box>
        <Flex
          alignItems="center"
          width="100%"
          flexDirection="column"
          textAlign="center"
          position="relative"
          padding="24px 56px"
        >
          <Typography.Title style={{ fontSize: 24 }}>
            {t('Closing the Tutorial?')}
          </Typography.Title>
          <Typography.Text>
            {t(
              'You can close the tutorial now and come back later, it will show up again next time you come back to business portal.'
            )}
          </Typography.Text>
          <Flex width="100%" justifyContent="center" gap="12" mt={32}>
            <Button
              onClick={() => {
                setShowCloseConfirmModal(false)
                setShowModal(false)
                dispatch(setIsSidebarCollapsed(true))
                setModalShownInStorage('tour')
                pixelAmpTracking(TRACKING_EVENTS.BUSINESS_TOUR_NEVER_SHOW)
              }}
            >
              {t('Don’t show again')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShowCloseConfirmModal(false)
                setShowModal(false)
                dispatch(setIsSidebarCollapsed(true))
                pixelAmpTracking(TRACKING_EVENTS.BUSINESS_TOUR_REMIND_ME_LATER)
              }}
            >
              {t('Remind me later')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
