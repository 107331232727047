import store from '@src/app/redux/store'
import { AppDispatch } from '@src/app/redux/store'
import { setErrorMessage } from '@src/common/redux/errors'
import { getShopMinisErrorMessage } from '@src/common/utils/error-handler/parser/shopMinisErrorParser'
import { getAvaApiErrorParser } from '@src/modules/ava/utils/avaApiErrorParser'
import { AxiosResponse } from 'axios'
import i18n, { TFunction } from 'i18next'

const dispatch = store.dispatch as AppDispatch
const tFunc = i18n.getFixedT(i18n.language)

/**
 * The object `statusErrorMessage` contains predefined error messages based on
 * the HTTP status code of an API response.
 */

/**
 * The object `endPoints` contains regular expressions that match the URLs of
 * API endpoints.
 */
const endPoints = {
  videos: /\/bus\/[a-zA-Z0-9]+\/channels\/[a-zA-Z0-9]+\/videos/,
  shopify_embed_status: /shopify\/embed_status/,
  shop_minis: /\/shop_mini\//,
  playlist_videos: /\/bus\/[a-zA-Z0-9]+\/channels\/[a-zA-Z0-9]+\/playlists\/[a-zA-Z0-9]+\/videos/,
  pdp_embed: /\/bus\/[a-zA-Z0-9]+\/pdp_embed/,
  ava: /\/domain_assistants|\/assistances|\/assistant_profiles/
}

/**
 * The function sets a custom error message by dispatching an action with the
 * message.
 * @param {string} message - The `message` parameter is a string that represents
 * the custom error message that you want to set.
 */
const setCustomErrorMessage = (message: string): void => {
  dispatch(setErrorMessage(message))
}

/**
 * The function sets an API error message based on the status code and URL.
 * @param {number} status - The `status` parameter is a number that represents the
 * HTTP status code of the API response.
 * @param {string} url - The `url` parameter is a string that represents the URL of
 * the API endpoint that returned an error.
 */
const setAPIErrorMessage = (status: number, t: TFunction): void => {
  const statusErrorMessage = {
    400: t('Oops something went wrong, Please let us know.'),
    401: t('Unauthorized access.'),
    403: t('Unauthorized access.'),
    404: t('Oops something went wrong, Please let us know.'),
    422: t('Missing or invalid data, Please try again.'),
    500: t('Experiencing intermittent issue, please check back later')
  }

  /**
   * The `defaultErrorMessage` variable contains the default error message.
   */
  const defaultErrorMessage = t(
    'Oops something went wrong, Please let us know.'
  )

  dispatch(setErrorMessage(statusErrorMessage[status] || defaultErrorMessage))
}

interface ErrorMessages {
  [key: string]: string
}

/**
 * Parses and handles errors received from the video endpoint.
 * It checks the error key and sets a custom error message based on the error type.
 *
 * @param {ErrorMessages} errors - An object containing error messages as key-value pairs.
 * @returns {void} - The function does not return any value.
 */
const videoEndPointParser = (errors: ErrorMessages, t: TFunction) => {
  const LIVE_STREAM_ERRORS = {
    stream_source: t(
      'Cannot assign or change stream source less than 5 minutes before the event starts'
    )
  }
  const CREATE_VIDEO_ERRORS = {
    published_at: t('Scheduled upload cannot be in the past')
  }

  const errorKey = Object.keys(errors).shift()

  if (LIVE_STREAM_ERRORS[errorKey]) {
    setCustomErrorMessage(LIVE_STREAM_ERRORS[errorKey])

    return
  }

  if (CREATE_VIDEO_ERRORS[errorKey]) {
    setCustomErrorMessage(CREATE_VIDEO_ERRORS[errorKey])

    return
  }
}

const shopifyEmbedStatusEndPointParser = (status: number) => {
  if (status === 404) {
    return
  }
}

const playlistErrorParser = ({
  status,
  t
}: {
  status?: number
  t: TFunction
}) => {
  if (status === 404) {
    setCustomErrorMessage(t('This playlist has been deleted.'))

    return
  }
}

const pdpEmbedErrorParser = (status: number) => {
  if (status === 404) {
    return
  }
}

/**
 * The function `customErrorHandler` handles errors from an Axios response and
 * performs specific actions based on the URL and error data.
 * @param {AxiosResponse} response - The `response` parameter is an object that
 * contains information about the HTTP response received from the server. It
 * typically includes properties such as `status` (the HTTP status code), `config`
 * (the Axios request configuration), and `data` (the response data).
 * @returns In this code snippet, nothing is being returned. The function
 * `customErrorHandler` has a return type of `void`, which means it does not return
 * any value.
 */
export const customErrorHandler = (response: AxiosResponse): void => {
  const {
    status,
    config: { url },
    data: { errors, error }
  } = response

  if (url.match(endPoints.videos) && errors) {
    return videoEndPointParser(errors, tFunc)
  } else if (url.match(endPoints.shopify_embed_status)) {
    return shopifyEmbedStatusEndPointParser(status)
  } else if (url.match(endPoints.playlist_videos)) {
    return playlistErrorParser({ status, t: tFunc })
  } else if (url.match(endPoints.shop_minis) && error) {
    const errorMessage = getShopMinisErrorMessage(error, tFunc)
    setCustomErrorMessage(errorMessage)
  } else if (url.match(endPoints.pdp_embed)) {
    return pdpEmbedErrorParser(status)
  } else if (url.match(endPoints.ava)) {
    const errorMessage = getAvaApiErrorParser(error, tFunc)
    if (errorMessage) {
      return setCustomErrorMessage(errorMessage)
    }
    setAPIErrorMessage(status, tFunc)
  } else {
    setAPIErrorMessage(status, tFunc)
  }
}
