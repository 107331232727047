import React from 'react'

import { css } from '@emotion/core'
import { FWButton } from '@src/components'
import { Box, Flex } from '@src/components/EmotionLayout'
import { Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
  modalVisible?: boolean
  onCancelClick?: () => void
  onOkClick?: () => void
  username?: string
}

const BatchImporterLogoutModal: React.FC<Props> = ({
  modalVisible,
  onCancelClick,
  onOkClick,
  username
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      maskClosable={true}
      open={modalVisible}
      onCancel={onCancelClick}
      closable={true}
      title={null}
      footer={null}
    >
      <Flex flexDirection="row">
        <Box as="img" src="/icon/logout.svg" width={24} height={24} />
        <div
          css={css`
            width: 100%;
            padding-left: 16px;
          `}
        >
          <Flex flexDirection="column" width="100%">
            <div
              css={css`
                font-size: 16px;
                font-weight: 600;
              `}
            >
              {t('Logout')}
            </div>
            <div
              css={css`
                font-size: 14px;
                margin-top: 8px;
              `}
            >
              <Trans t={t}>
                Are you sure you want to log out of{' '}
                <span
                  css={css`
                    color: #0f6bff;
                    font-weight: 600;
                  `}
                >
                  {{ username: `@${username}` }}
                </span>{' '}
                account?
              </Trans>
            </div>
            <Flex
              css={css`
                justify-content: flex-end;
                width: 100%;
                margin-top: 24px;
              `}
            >
              <FWButton key="Ok" type="primary" onClick={onOkClick}>
                {t('Ok')}
              </FWButton>
            </Flex>
          </Flex>
        </div>
      </Flex>
    </Modal>
  )
}

export default BatchImporterLogoutModal
