import React from 'react'

import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons'
import { Box, Flex } from '@src/components/EmotionLayout'
import { Input, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { css } from 'styled-components'

type PollProps = {
  addOption: () => void
  removeOptionAtIndex: (index) => void
  handleTextAreaChange: (e) => void
  handleInputChange: (e) => void
  pollObj: {
    question: string
    options: string[]
  }
}

export const Poll: React.FC<PollProps> = ({
  addOption,
  removeOptionAtIndex,
  handleTextAreaChange,
  handleInputChange,
  pollObj
}) => {
  const { t } = useTranslation()

  return (
    <Flex
      width="100%"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Flex
        width="100%"
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDirection="column"
        css={css`
          .ant-input-textarea-show-count::after {
            margin-right: 5px;
            margin-top: -25px;
            position: relative;
            z-index: 20;
          }
        `}
      >
        <Input.TextArea
          css={css`
            margin-bottom: 8px;
            width: 100%;
          `}
          showCount
          placeholder={t('Your question here')}
          maxLength={50}
          autoSize={{ minRows: 3, maxRows: 3 }}
          value={pollObj?.question}
          onChange={handleTextAreaChange}
        />
        {pollObj.options.map((option, index) => (
          <Flex
            flexDirection="row"
            key={index}
            position="relative"
            width="100%"
            alignItems="center"
            mb="8px"
          >
            <Flex flexGrow="1" alignItems="center">
              <Input
                css={css`
                  width: 100%;
                `}
                suffix={
                  <>
                    {pollObj.options.length > 2 && (
                      <Box onClick={() => removeOptionAtIndex(index)}>
                        <MinusCircleFilled
                          css={css`
                            font-size: 0.9rem;
                            padding-top: 2px;
                          `}
                        />
                      </Box>
                    )}
                  </>
                }
                maxLength={25}
                value={option}
                data-index={index}
                placeholder={t(`Choice {{index}}`, { index: index + 1 })}
                onChange={handleInputChange}
              />
            </Flex>
          </Flex>
        ))}
        {pollObj.options.length < 5 && (
          <Flex justifyContent="flex-start" mt="4">
            <Box
              display="flex"
              flexDirection="row"
              maxWidth="200"
              cursor="pointer"
              onClick={addOption}
            >
              <PlusCircleFilled
                css={css`
                  font-size: 1.2rem;
                  margin-right: 10px;
                `}
              />
              <Typography.Text strong>{t('Add choice')}</Typography.Text>
            </Box>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
