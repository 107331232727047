import React, { useMemo, useState } from 'react'

import css from '@emotion/css'
import { Box } from '@src/components/EmotionLayout'
import LoadingIndicator from '@src/components/LoadingIndicator'
import { isMdScreen } from '@src/hooks/useMediaQuery'
import useWindowSize from '@src/hooks/useWindowSize'
import {
  addRemoveProductColSize,
  checkboxColSize,
  deleteColSize,
  highlightColSize,
  importColSize,
  productAvailableColSize,
  productDropColSize,
  productNameColSize,
  productPriceColSize,
  productThumbnailColSize,
  shouldRenderAddRemoveProductCol,
  shouldRenderCheckboxCol,
  shouldRenderDeleteCol,
  shouldRenderHighlightCol,
  shouldRenderImportCol,
  shouldRenderProductAvailableCol,
  shouldRenderProductDropCol,
  shouldRenderProductThumbnailCol
} from '@src/pages/channel/Products/helpers'
import { ProductHeader } from '@src/pages/channel/Products/Product.styles'
import { Col, Row, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-sortable-hoc'

import Product from './Product'

const SortableList = SortableContainer(({ children }) => {
  return <Box width="100%">{children}</Box>
})

const DragHandle = sortableHandle(() => {
  const { t } = useTranslation()
  return (
    <div
      css={css`
        cursor: grab;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      `}
    >
      <Tooltip title={t('click and drop to reorder')}>
        <Box as="img" src="/drag_handle.svg" width="20" maxWidth="20" />
      </Tooltip>
    </div>
  )
})

const SortableItem = SortableElement(({ value, index, children, sortable }) => (
  <Box key={value} index={index} display="flex" width="100%" overflowX="hidden">
    {sortable && <DragHandle />}
    <Box width="100%">{children}</Box>
  </Box>
))

export default function Products({
  products,
  nextAction,
  hasMore,
  onAdd,
  onRemove,
  setAvailability,
  selected = [],
  highlightedProductIds = [],
  onHighlight,
  selectType = 'button',
  selectLimit = 2,
  onDelete,
  identifier = 'id',
  onCreate,
  onSortEnd,
  infinityScrollOff = false,
  needVariant = false,
  onToggleProductDrop,
  maxHeight = '100%',
  heightOffset = 480,
  sortable
}) {
  const { t } = useTranslation()
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  useWindowSize((windowSize) => setWindowHeight(windowSize.innerHeight))
  const [expandProducts, setExpandProducts] = useState([])

  const sortedProductImages = useMemo(() => {
    return products.reduce(
      (acc, product) => ({
        ...acc,
        [product.product_ext_id]: (product.product_images || [])
          .slice()
          .sort((a, b) => a.image_position - b.image_position)
      }),
      {}
    )
  }, [products])

  const productsToImages = useMemo(() => {
    return products.reduce(
      (acc, product) => ({
        ...acc,
        [product.product_ext_id]: sortedProductImages[
          product.product_ext_id
        ].reduce(
          (acc, img) => ({
            ...acc,
            [img.image_id]: img
          }),
          {}
        )
      }),
      {}
    )
  }, [products, sortedProductImages])

  const tableHeaders = (
    <Row
      wrap={false}
      css={css`
        width: 100%;
      `}
    >
      {shouldRenderCheckboxCol(onAdd, onRemove, selectType) ? (
        <Col
          {...checkboxColSize}
          style={{
            padding: '0 2px'
          }}
        />
      ) : null}
      {shouldRenderHighlightCol(onHighlight) ? (
        <Col
          {...highlightColSize}
          style={{
            padding: '0 2px'
          }}
        >
          <ProductHeader>{t('Highlight')}</ProductHeader>
        </Col>
      ) : null}
      {shouldRenderProductThumbnailCol(false) ? (
        <Col
          md={productThumbnailColSize.md}
          xs={productThumbnailColSize.xs}
          style={{
            padding: '0 2px',
            textAlign: 'center'
          }}
        >
          <ProductHeader>{t('Thumbnail')}</ProductHeader>
        </Col>
      ) : null}
      {shouldRenderProductThumbnailCol(false) ? (
        <Col
          md={productThumbnailColSize.md}
          xs={productThumbnailColSize.xs}
          style={{
            padding: '0 2px'
          }}
        ></Col>
      ) : null}
      <Col
        {...productNameColSize(
          onAdd,
          onRemove,
          selectType,
          onCreate,
          onDelete,
          onToggleProductDrop
        )}
        style={{
          padding: '0 2px'
        }}
      >
        <ProductHeader>{t('Name')}</ProductHeader>
      </Col>
      <Col
        {...productPriceColSize}
        style={{
          padding: '0 2px'
        }}
      >
        <ProductHeader>{t('Price')}</ProductHeader>
      </Col>
      {shouldRenderProductDropCol(onToggleProductDrop) ? (
        <Col
          {...productDropColSize}
          style={{
            padding: '0 2px'
          }}
        >
          <ProductHeader>{t('Hide')}</ProductHeader>
        </Col>
      ) : null}

      {shouldRenderAddRemoveProductCol(onAdd, onRemove, selectType) ? (
        <Col
          {...addRemoveProductColSize}
          style={{
            padding: '0 2px'
          }}
        />
      ) : null}

      {shouldRenderImportCol(onCreate) ? (
        <Col
          {...importColSize}
          style={{
            padding: '0 2px'
          }}
        />
      ) : null}
      {shouldRenderDeleteCol(onDelete) ? (
        <Col
          {...deleteColSize(onToggleProductDrop)}
          style={{
            padding: '0 2px'
          }}
        >
          <div
            style={{
              visibility: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <Box
              as="img"
              src="/close_black.svg"
              width="12"
              height="12"
              alt="delete"
            />
          </div>
        </Col>
      ) : null}
      {shouldRenderProductAvailableCol(setAvailability) ? (
        <Col
          {...productAvailableColSize}
          style={{
            padding: '0 2px'
          }}
        />
      ) : null}
    </Row>
  )

  const content = (
    <SortableList
      useDragHandle
      onSortEnd={onSortEnd}
      lockAxis="y"
      helperClass="sortableHelper"
    >
      {products.map((p, index) => {
        const isSelected = selected.includes(p[identifier])
        return (
          <SortableItem
            sortable={sortable}
            key={p[identifier]}
            index={index}
            value={p}
          >
            <Product
              images={sortedProductImages[p.product_ext_id]}
              product={p}
              onAdd={onAdd}
              onRemove={onRemove}
              disabled={
                selected.length > selectLimit ||
                highlightedProductIds.length > selectLimit
              }
              highlighted={highlightedProductIds.includes(p[identifier])}
              onHighlight={onHighlight}
              selectType={selectType}
              selected={isSelected}
              indeterminate={
                selected.some((s) => s.startsWith(p[identifier])) && !isSelected
              }
              onDelete={onDelete}
              setAvailability={setAvailability}
              expandProducts={expandProducts}
              collapseUnit={
                needVariant
                  ? (product) => {
                      setExpandProducts(
                        expandProducts.includes(product.id)
                          ? expandProducts.filter((s) => s !== product.id)
                          : [...expandProducts, product.id]
                      )
                    }
                  : null
              }
              margin
              identifier={identifier}
              onCreate={onCreate}
              highlightedIndex={
                highlightedProductIds.includes(p[identifier])
                  ? highlightedProductIds.indexOf(p[identifier]) + 1
                  : null
              }
              onToggleProductDrop={onToggleProductDrop}
            />
            {!!p.product_units?.length && expandProducts.includes(p.id) && (
              <>
                <div
                  style={{
                    width: '100%',
                    height: '0.5px',
                    backgroundColor: '#f0f0f0'
                  }}
                />
                <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
                  {p.product_units.map((u) => (
                    <Product
                      images={sortedProductImages[p.product_ext_id]}
                      key={`${p[identifier]}-${u.id}`}
                      variant={u}
                      product={p}
                      margin={false}
                      onAdd={onAdd}
                      onRemove={onRemove}
                      selected={selected.includes(`${p[identifier]}-${u.id}`)}
                      disabled={
                        selected.length > selectLimit ||
                        highlightedProductIds.length > selectLimit
                      }
                      highlighted={highlightedProductIds.includes(
                        `${p[identifier]}-${u.id}`
                      )}
                      onHighlight={onHighlight}
                      selectType={selectType}
                      onDelete={onDelete}
                      setAvailability={setAvailability}
                      identifier={identifier}
                      onCreate={onCreate}
                      highlightedIndex={
                        highlightedProductIds.includes(
                          `${p[identifier]}-${u.id}`
                        )
                          ? highlightedProductIds.indexOf(
                              `${p[identifier]}-${u.id}`
                            ) + 1
                          : null
                      }
                      urlToImageMap={productsToImages[p.product_ext_id]}
                    />
                  ))}
                </div>
              </>
            )}
          </SortableItem>
        )
      })}
    </SortableList>
  )

  return (
    <>
      <Box display="flex" borderBottom="1px solid #e2e2e2">
        {tableHeaders}
      </Box>
      <div
        id="scrollableDiv"
        style={{
          height: windowHeight - heightOffset,
          maxHeight: maxHeight ? maxHeight : '100%',
          overflow: 'auto',
          minWidth: isMdScreen() ? '800px' : '350px', // Rows shrink in width when there not enough information
          marginLeft: '16px',
          paddingLeft: 0
        }}
      >
        {infinityScrollOff ? (
          content
        ) : (
          <InfiniteScroll
            dataLength={products.length}
            next={nextAction}
            hasMore={hasMore}
            loader={<LoadingIndicator margin="20px" />}
            style={{
              overflowX: 'hidden',
              paddingBottom: '30px',
              height: '100%',
              width: '100%'
            }}
            scrollableTarget="scrollableDiv"
          >
            {content}
          </InfiniteScroll>
        )}
      </div>
    </>
  )
}

Products.propTypes = {
  products: PropTypes.array,
  nextAction: PropTypes.func,
  hasMore: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  selected: PropTypes.array,
  highlightedProductIds: PropTypes.array,
  onHighlight: PropTypes.func,
  selectType: PropTypes.string,
  selectLimit: PropTypes.number,
  onDelete: PropTypes.func,
  identifier: PropTypes.string,
  onCreate: PropTypes.func,
  setAvailability: PropTypes.func,
  onSortEnd: PropTypes.func,
  sortable: PropTypes.bool,
  infinityScrollOff: PropTypes.bool,
  needVariant: PropTypes.bool,
  onToggleProductDrop: PropTypes.func,
  maxHeight: PropTypes.string,
  heightOffset: PropTypes.number
}
