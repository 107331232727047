import React from 'react'

import loadable from '@loadable/component'
import { ONBOARDING_MODES } from '@src/constants'
import { Route } from 'react-router-dom'

const Profile = loadable(() => import('../pages/Profile'))
const Notifications = loadable(() => import('../pages/Notifications'))
const NotificationDetail = loadable(() => import('../pages/NotificationDetail'))
const BusinessList = loadable(() => import('../pages/BusinessList'))
const BusinessAssociate = loadable(() => import('../pages/Associate'))
const OnboardBusiness = loadable(() => import('../pages/OnboardBusiness'))
const BusinessForm = loadable(() => import('../pages/BusinessForm'))
const SubscriptionCallbackLanding = loadable(
  () => import('../pages/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionCallbackLanding
  }
)
const SubscriptionCancelLanding = loadable(
  () => import('../pages/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionCancelLanding
  }
)
const SubscriptionSettingsLanding = loadable(
  () => import('../pages/SubscriptionLanding'),
  {
    resolveComponent: (component) => component.SubscriptionSettingsLanding
  }
)

export const Routes = (
  <>
    <Route path="/profile" element={<Profile />} />
    <Route path="/notifications" element={<Notifications />} />
    <Route path="/notifications/:id" element={<NotificationDetail />} />
    <Route path="/businesses" element={<BusinessList />} />
    <Route path="/businesses/associate" element={<BusinessAssociate />} />
    <Route path="/business/create" element={<OnboardBusiness />} />
    <Route
      path="/business/:businessId/update"
      element={<BusinessForm mode={ONBOARDING_MODES.UPDATE} />}
    />
    <Route
      path="/business/:businessId/subscription/callback"
      element={<SubscriptionCallbackLanding />}
    />
    <Route
      path="/business/:businessId/subscription/canceled"
      element={<SubscriptionCancelLanding />}
    />
    <Route
      path="/business/:businessId/subscription/settings"
      element={<SubscriptionSettingsLanding />}
    />
  </>
)
