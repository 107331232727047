import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Settings = loadable(() => import('../pages/Settings'))
const Payment = loadable(() => import('../pages/Payment'))
export const Routes = (
  <>
    <Route path="/business/:businessId/settings" element={<Settings />} />
    <Route path="/business/:businessId/settings/:tab" element={<Settings />} />
    <Route path="/business/:businessId/payment" element={<Payment />} />
  </>
)
