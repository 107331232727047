import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import { useAppSelector } from '@src/app/hooks'
import { BusinessContext } from '@src/common/contexts/BusinessContext'
import { FWButton } from '@src/components'
import { Box, Flex } from '@src/components/EmotionLayout'
import { KEY_ONBOARDING_STATUS, KEY_ACTIVATION_STATUS } from '@src/constants'
import { updateBusinessActivationStatus } from '@src/redux/business'
import { setIsWidgetVisible } from '@src/redux/ui'
import {
  localStorageProvider,
  sessionStorageProvider
} from '@src/utils/storageProvider'
import { Card, Space, Input, Switch, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export const DevTools: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const businessId = useContext(BusinessContext)
  const [showTools, setShowTools] = React.useState(false)
  const [apiURL, setApiURL] = React.useState('')
  const isWidgetVisible = useAppSelector((state) => state.ui.isWidgetVisible)

  const [isDragging, setIsDragging] = useState(false)

  const buttonRef = useRef<HTMLDivElement | null>(null)
  const position = useRef({ x: 0, y: 0 })

  const dragState = useRef({ isDragging: false })

  const [buttonPosition, setButtonPosition] = useState({
    bottom: 42,
    right: 100
  })

  const handleMouseDown = (e) => {
    setIsDragging(true)
    position.current = {
      x: e.clientX - buttonRef.current.getBoundingClientRect().left,
      y: e.clientY - buttonRef.current.getBoundingClientRect().top
    }
    dragState.current.isDragging = false
  }

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging) return
      const dx = e.clientX - position.current.x
      const dy = e.clientY - position.current.y

      if (Math.abs(dx) > 3 || Math.abs(dy) > 3) {
        dragState.current.isDragging = true

        const newBottom =
          window.innerHeight -
          e.clientY -
          (buttonRef.current.offsetHeight - position.current.y)
        const newRight =
          window.innerWidth -
          e.clientX -
          (buttonRef.current.offsetWidth - position.current.x)

        setButtonPosition({ bottom: newBottom, right: newRight })
      }
    },
    [isDragging]
  )

  const handleMouseUp = useCallback(() => {
    if (!dragState.current.isDragging) {
      setShowTools(!showTools)
    }

    setIsDragging(false)
  }, [showTools])

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove)
      window.addEventListener('mouseup', handleMouseUp)
    } else {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  const handleResetOnboarding = async () => {
    await updateBusinessActivationStatus(businessId, true)
    localStorageProvider.setItem(
      KEY_ONBOARDING_STATUS,
      JSON.stringify({ oq: false, wm: false, pb: false, pdp: false })
    )
    localStorageProvider.setItem(
      KEY_ACTIVATION_STATUS,
      JSON.stringify({
        uploadVideo: 'active',
        createPlaylist: 'inactive',
        embedPlaylist: 'inactive'
      })
    )
    window.location.reload()
  }

  if (process.env.REACT_APP_ENV === 'production') return null

  const handleToggleWidget = () => {
    dispatch(setIsWidgetVisible(!isWidgetVisible))
  }

  return (
    <>
      {showTools && (
        <Flex
          position="fixed"
          bottom={`${buttonPosition.bottom + 50}px`}
          right={`${buttonPosition.right}px`}
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          background={showTools ? 'white' : 'transparent'}
          zIndex={100}
        >
          <Card bordered>
            <Flex flexDirection="column" gap="10">
              <Space>
                <Input
                  value={apiURL}
                  onChange={(e) => setApiURL(e.target.value)}
                  placeholder={t('API URL')}
                />
                <FWButton
                  onClick={() => {
                    sessionStorageProvider.setItem('apiURL', apiURL)
                    window.location.reload()
                  }}
                >
                  {t('Set API URL')}
                </FWButton>
              </Space>
              <Flex width="100%" gap="16px">
                <Switch
                  checked={isWidgetVisible}
                  onChange={handleToggleWidget}
                />
                <Box>
                  <Typography.Text>{t('Show OTO Widget')}</Typography.Text>
                </Box>
              </Flex>
              <FWButton onClick={handleResetOnboarding}>
                {t('Reset Onboarding')}
              </FWButton>
            </Flex>
          </Card>
        </Flex>
      )}
      <Box
        ref={buttonRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        position="fixed"
        bottom={buttonPosition.bottom}
        right={buttonPosition.right}
        width="50px"
        height="50px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor={isDragging ? 'grabbing' : 'grab'}
        zIndex={101}
        color={showTools ? 'white' : 'black'}
        fontSize="12px"
        textAlign="center"
        style={{
          borderRadius: '50%',
          backgroundColor: showTools ? '#007bff' : '#d3d3d3',
          userSelect: 'none'
        }}
      >
        {t('Dev Tools')}
      </Box>
    </>
  )
}
