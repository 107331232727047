import styled from '@emotion/styled'

export const VideoCardIconContainer = styled.div<{ padding?: string }>`
  height: 28px;
  min-width: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
  padding: ${(props) => props.padding || '4px'};
  gap: 5px;
`
