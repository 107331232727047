import React, { ReactElement, useCallback } from 'react'

import {
  FeatureNames,
  useFeatureEnablement
} from '@src/common/hooks/useFeatureEnablement'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import UpgradeModal from './UpgradeModal'

export type IProps = {
  businessId: string
  featureName: FeatureNames | FeatureNames[]
  children?: React.ReactNode
  disableType?: 'hide' | 'disable'
  filterAction?: 'some' | 'every'
}

export const FeatureEnablementGate: React.FC<IProps> = ({
  businessId,
  featureName,
  disableType = 'hide',
  filterAction = 'some',
  children
}): JSX.Element => {
  const featureEnablement = useFeatureEnablement(businessId)
  const { t } = useTranslation()
  const [isUpgradeModalVisible, setIsUpgradeModalVisible] = React.useState(
    false
  )

  const showModal = useCallback(() => {
    setIsUpgradeModalVisible(true)
  }, [])

  const hideModal = useCallback(() => {
    setIsUpgradeModalVisible(false)
  }, [])

  const featureNames = Array.isArray(featureName) ? featureName : [featureName]

  if (isUpgradeModalVisible) {
    return (
      <UpgradeModal
        title={t('Upgrade to unlock feature')}
        description={t(
          'Your current plan does not include this feature. Upgrade to unlock this feature among many others!'
        )}
        onClose={hideModal}
      />
    )
  }

  if (disableType === 'disable') {
    return (
      <>
        {featureEnablement &&
        featureNames[filterAction]((name) => featureEnablement[name]) ? (
          <>{children}</>
        ) : (
          <Tooltip title={t('Upgrade to unlock')} trigger={['hover']}>
            {React.Children.map(children, (child) =>
              React.cloneElement(child as ReactElement, {
                onSelect: showModal,
                onClick: showModal,
                style: {
                  ...(child as ReactElement).props.style,
                  opacity: 0.5
                }
              })
            )}
          </Tooltip>
        )}
      </>
    )
  }

  return (
    <>
      {featureEnablement &&
        featureNames[filterAction]((name) => featureEnablement[name]) &&
        children}
    </>
  )
}

export default FeatureEnablementGate
