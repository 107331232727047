import { createAction, createSlice } from '@reduxjs/toolkit'
import { sumBy } from 'lodash'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { Dispatch } from 'redux'

import api from '../common/utils/api'
import { toHumanReadableDuration } from '../utils/sanitize'
import {
  convertTimeMinute,
  convertTimeUnit,
  fillMissingDates
} from '../utils/stats'

type IProps = {
  videoInsightsAggregated: {
    businessOoInsights: globalLib.VideoAggregateInsight[] | null
    ooInsights: globalLib.VideoAggregateInsight[] | null
    repostInsights: globalLib.VideoAggregateInsight[] | null
  }
  thumbnailInsightsByCountry: globalLib.ThumbnailByCountry[]
  thumbnailInsightsByDevice: globalLib.ThumbnailByDevice[]
  videoInsights: Record<string, globalLib.IVideo[]>
  videoInsightsByVideo: Record<string, globalLib.IVideo>
  playlistInsights: globalLib.PlaylistInsight[]
  hashtagInsights: globalLib.HashtagInsight[]
  categoryViewInsights: globalLib.CategoryViewInsight[]
  videoUrlInsightsByVideo: globalLib.VideoUrlInsight[]
  internalRefererInsights: globalLib.VideoUrlInsight[]
  presentationInsights: globalLib.VideoInsight[]
  externalRefererInsights: globalLib.VideoUrlInsight[]
  pageViewInsights: globalLib.PageViewInsight[]
  visorMetrics: {
    last_week: globalLib.VisorMetricInsight
    this_week: globalLib.VisorMetricInsight
  }
  embedFunnelInsights: Partial<globalLib.VideoInsight>[]
  thumbnailDailyInsights: globalLib.ThumbnailDailyInsight[]
  insightsV3RangeSelection: Record<string, any>
  playerVisibility: Record<string, any> // InsightsV3: PlayerVisibilityChart Custom
  playFunnel: Record<string, any> // InsightsV3: PlayFunnelChart Custom
  interactionRate: Record<string, any> // InsightsV3: InteractionRateV3 Custom
  playlistPerformanceV3: Record<string, any>
  pageViewInsightsV3: Record<string, any>
  videoPerformanceV3: Record<string, any>
  showVideoDetailsV3: Record<string, any>
  shortVideoInsightV3: boolean
}

const initialState: IProps = {
  videoInsightsAggregated: {
    businessOoInsights: null,
    ooInsights: null,
    repostInsights: null
  },
  thumbnailInsightsByCountry: [],
  thumbnailInsightsByDevice: [],
  videoInsights: {},
  videoInsightsByVideo: {},
  playlistInsights: [],
  hashtagInsights: [],
  categoryViewInsights: [],
  videoUrlInsightsByVideo: [],
  internalRefererInsights: [],
  presentationInsights: [],
  externalRefererInsights: [],
  pageViewInsights: [],
  visorMetrics: {
    last_week: {
      average_completion_rate: 0,
      average_watch_time_minutes: 0,
      cta_click_rate: 0,
      cta_clicks: 0,
      engagement_rate: 0,
      total_minutes_watched: 0,
      total_num_of_viewers: 0,
      total_views: 0
    },
    this_week: {
      average_completion_rate: 0,
      average_watch_time_minutes: 0,
      cta_click_rate: 0,
      cta_clicks: 0,
      engagement_rate: 0,
      total_minutes_watched: 0,
      total_num_of_viewers: 0,
      total_views: 0
    }
  },
  embedFunnelInsights: [],
  thumbnailDailyInsights: [],
  insightsV3RangeSelection: {},
  playerVisibility: {},
  playFunnel: {},
  interactionRate: {},
  playlistPerformanceV3: {},
  pageViewInsightsV3: {},
  videoPerformanceV3: {},
  showVideoDetailsV3: {},
  shortVideoInsightV3: true
}

const slice = createSlice({
  name: 'insight',
  initialState: initialState,
  reducers: {
    fetchVisorMetricsSuccess(state, action) {
      state.visorMetrics = action.payload
    },

    fetchAggregateVideoInsightsDetailsSuccess(state, action) {
      const {
        endDate,
        startDate,
        video_insights: videoInsights
      } = action.payload
      let ooInsights = videoInsights.oo_insights.map((i) => ({
        ...i,
        total_minutes_watched: Math.round(i.total_minutes_watched),
        total_seconds_watched: Math.round(i.total_seconds_watched),
        source: 'Channel O&O'
      }))
      ooInsights = fillMissingDates({
        data: ooInsights,
        dataInitFields: {
          source: 'Channel O&O',
          engaged_views: 0,
          total_minutes_watched: 0,
          total_seconds_watched: 0,
          total_views: 0,
          cta_clicks: 0,
          cta_click_rate: 0
        },
        endDate: moment(endDate).add(1, 'days'),
        startDate
      })

      let businessOoInsights
      if ('business_oo_insights' in videoInsights) {
        businessOoInsights = videoInsights.business_oo_insights.map((i) => ({
          ...i,
          total_minutes_watched: Math.round(i.total_minutes_watched),
          total_seconds_watched: Math.round(i.total_seconds_watched),
          source: 'Business O&O'
        }))
        businessOoInsights = fillMissingDates({
          data: businessOoInsights,
          dataInitFields: {
            source: 'Business O&O',
            engaged_views: 0,
            total_minutes_watched: 0,
            total_seconds_watched: 0,
            total_views: 0,
            cta_clicks: 0,
            cta_click_rate: 0
          },
          endDate: moment(endDate).add(1, 'days'),
          startDate
        })
      }
      let repostInsights
      if ('repost' in videoInsights) {
        repostInsights = videoInsights.repost.map((i) => ({
          ...i,
          total_minutes_watched: Math.round(i.total_minutes_watched),
          total_seconds_watched: Math.round(i.total_seconds_watched),
          source: 'Repost'
        }))
        repostInsights = fillMissingDates({
          data: repostInsights,
          dataInitFields: {
            source: 'Repost',
            engaged_views: 0,
            total_minutes_watched: 0,
            total_seconds_watched: 0,
            total_views: 0,
            cta_clicks: 0,
            cta_click_rate: 0
          },
          endDate: moment(endDate).add(1, 'days'),
          startDate
        })
      }
      // ** @TODO remove this mock data when backend is available
      const max = 100
      const min = 50
      ooInsights = ooInsights.map((i) => ({
        ...i,
        impressions: Math.floor(Math.random() * (max - min) + min),
        engaged_views: Math.floor(Math.random() * (max - min) + min)
      }))
      businessOoInsights = businessOoInsights.map((i) => ({
        ...i,
        impressions: Math.floor(Math.random() * (max - min) + min),
        engaged_views: Math.floor(Math.random() * (max - min) + min)
      }))
      repostInsights = repostInsights.map((i) => ({
        ...i,
        impressions: Math.floor(Math.random() * (max - min) + min),
        engaged_views: Math.floor(Math.random() * (max - min) + min)
      }))
      // ** end mock data

      state.videoInsightsAggregated = {
        ooInsights,
        businessOoInsights,
        repostInsights
      }
    },

    fetchThumbnailVideoInsightsDetailsSuccess(state, action) {
      const {
        country_thumbnail_insights: countryThumbnailInsights,
        device_thumbnail_insights: deviceThumbnailInsights,
        daily_thumbnail_insights: dailyThumbnailInsights
      } = action.payload

      state.thumbnailInsightsByCountry = countryThumbnailInsights
        .map((i) => ({
          ...i,
          thumbnail_click_rate: i.thumbnail_click_rate * 100
        }))
        .sort((a, b) => b.thumbnail_click_rate - a.thumbnail_click_rate)

      state.thumbnailInsightsByDevice = deviceThumbnailInsights
        .map((i) => ({
          ...i,
          thumbnail_click_rate: i.thumbnail_click_rate * 100
        }))
        .sort((a, b) => b.thumbnail_click_rate - a.thumbnail_click_rate)
      state.thumbnailDailyInsights = dailyThumbnailInsights
    },

    fetchVideoInsightsDetailsSuccess(state, action) {
      const { video_insights: videoInsights, channelId } = action.payload
      // Process by video stats
      state.videoInsights[channelId] = videoInsights
        .map((i) => ({
          ...i,
          avg_watch_time: convertTimeUnit(
            i.total_seconds_watched / i.total_views
          ),
          watch_time: toHumanReadableDuration(
            i.total_minutes_watched,
            'minutes'
          )
        }))
        .sort((a, b) => b.total_views - a.total_views)
    },

    fetchVideoInsightsDetailForVideoSuccess(state, action) {
      momentDurationFormatSetup(moment)
      const { video_insight: videoInsight, endDate, startDate } = action.payload
      // Compute averages for all stats
      videoInsight.watch_time = convertTimeMinute(
        videoInsight.total_minutes_watched,
        'h[h]'
      )
      videoInsight.average_watch_time = convertTimeMinute(
        videoInsight.total_minutes_watched / videoInsight.total_views,
        's[s]'
      )
      // Convert average completion rates to integer percentages
      videoInsight.source_insights = videoInsight.source_insights.map((i) => ({
        ...i,
        average_comp_100p: Math.round(i.average_comp_100p * 100),
        average_comp_25p: Math.round(i.average_comp_25p * 100),
        average_comp_50p: Math.round(i.average_comp_50p * 100),
        average_comp_75p: Math.round(i.average_comp_75p * 100),
        average_watch_time_seconds: Math.round(i.average_watch_time_seconds),
        average_watch_time_minutes: Math.round(i.average_watch_time_minutes),
        total_minutes_watched: Math.round(i.total_minutes_watched),
        total_seconds_watched: Math.round(i.total_seconds_watched)
      }))

      if ('insights' in videoInsight) {
        videoInsight.insights = fillMissingDates({
          data: videoInsight.insights,
          dataInitFields: {
            engaged_views: 0,
            total_minutes_watched: 0,
            total_seconds_watched: 0,
            total_views: 0,
            cta_click_rate: 0
          },
          endDate,
          startDate
        })
      }

      state.videoInsightsByVideo[videoInsight.video_id] = videoInsight
    },

    fetchPlaylistInsightsSuccess(state, action) {
      const { playlist_insights: playlistInsights } = action.payload
      state.playlistInsights = playlistInsights
    },

    fetchHashtagInsightsSuccess(state, action) {
      const { hashtag_insights: hashtagInsights } = action.payload
      state.hashtagInsights = hashtagInsights
    },

    fetchExternalRefererInsightsSuccess(state, action) {
      const { referer_insights: externalRefererInsights } = action.payload
      state.externalRefererInsights = externalRefererInsights
    },

    fetchCategoryViewInsightsSuccess(state, action) {
      const { category_insights: categoryInsights } = action.payload
      // Short circuit logic here because the "empty" state depends on
      // the array being empty, but we will always push the "others" object.
      if (categoryInsights.length === 0) return
      // Separate by top categories
      const totalViews = sumBy(categoryInsights, 'total_views')
      let top5 = categoryInsights.slice(0, 5)
      const others = categoryInsights.slice(5)
      top5.push({
        tag_name: 'others',
        display_name: 'Others',
        total_views: sumBy(others, 'total_views'),
        total_minutes_watched: sumBy(others, 'total_minutes_watched')
      })
      top5 = top5.map((o) => {
        return {
          ...o,
          percentage: o.total_views / totalViews
        }
      })
      state.categoryViewInsights = top5
    },

    fetchVideoUrlInsightsForVideoSuccess(state, action) {
      const { referer_insights: videoUrlInsightsByVideo } = action.payload
      state.videoUrlInsightsByVideo = videoUrlInsightsByVideo
    },

    fetchInternalRefererInsightsSuccess(state, action) {
      const { referer_insights: internalRefererInsights } = action.payload
      state.internalRefererInsights = internalRefererInsights
    },

    fetchPresentationInsightsSuccess(state, action) {
      const { presentation_insights: presentationInsights } = action.payload
      state.presentationInsights = presentationInsights
    },

    fetchPageViewInsightsSuccess(state, action) {
      const { insights: pageViewInsights } = action.payload
      state.pageViewInsights = pageViewInsights
    },

    fetchEmbedFunnelInsightsSuccess(state, action) {
      const { embed_funnel_insights: embedFunnelInsights } = action.payload
      state.embedFunnelInsights = embedFunnelInsights
    },
    setInsightsV3RangeSelectionSuccess(state, action) {
      const { channelId, level, startDate, endDate } = action.payload
      state.insightsV3RangeSelection[channelId] = { level, startDate, endDate }
    },
    fetchPlayerVisibilityInsightsSuccess(state, action) {
      const { channelId, data } = action.payload
      state.playerVisibility[channelId] = data
    },
    fetchPlayFunnelInsightsSuccess(state, action) {
      const { channelId, data } = action.payload
      state.playFunnel[channelId] = data
    },
    fetchInteractionRateInsightsSuccess(state, action) {
      const { channelId, data } = action.payload
      state.interactionRate[channelId] = data
    },
    fetchPlaylistPerformanceV3Success(state, action) {
      const { channelId, data } = action.payload
      state.playlistPerformanceV3[channelId] = data
    },
    fetchPageViewInsightsV3Success(state, action) {
      const { insights: data, channelId } = action.payload
      state.pageViewInsightsV3[channelId] = data
    },
    fetchVideoPerformanceV3Success(state, action) {
      const { channelId, data } = action.payload
      state.videoPerformanceV3[channelId] = data
    },
    setShowVideoDetailsV3Success(state, action) {
      const { channelId, bool, video } = action.payload
      state.showVideoDetailsV3[channelId] = {
        active: bool,
        video
      }
    },
    fetchUrlPerformanceV3Success(state, action) {
      const { referer_insights: videoUrlInsightsByVideo } = action.payload
      state.videoUrlInsightsByVideo = videoUrlInsightsByVideo
    }
  }
})
export default slice.reducer
export const {
  fetchAggregateVideoInsightsDetailsSuccess,
  fetchThumbnailVideoInsightsDetailsSuccess,
  fetchVideoInsightsDetailsSuccess,
  fetchVideoInsightsDetailForVideoSuccess,
  fetchPlaylistInsightsSuccess,
  fetchHashtagInsightsSuccess,
  fetchCategoryViewInsightsSuccess,
  fetchVideoUrlInsightsForVideoSuccess,
  fetchInternalRefererInsightsSuccess,
  fetchPresentationInsightsSuccess,
  fetchExternalRefererInsightsSuccess,
  fetchPageViewInsightsSuccess,
  fetchVisorMetricsSuccess,
  fetchEmbedFunnelInsightsSuccess,
  setInsightsV3RangeSelectionSuccess,
  fetchPlayerVisibilityInsightsSuccess,
  fetchPlayFunnelInsightsSuccess,
  fetchInteractionRateInsightsSuccess,
  fetchPlaylistPerformanceV3Success,
  fetchPageViewInsightsV3Success,
  fetchVideoPerformanceV3Success,
  setShowVideoDetailsV3Success,
  fetchUrlPerformanceV3Success
} = slice.actions

const fetchVideoInsightsDetailsRequest = createAction(
  'insight/fetchVideoInsightsDetailsRequest'
)
const fetchVideoInsightsDetailsFailure = createAction(
  'insight/fetchVideoInsightsDetailsFailure'
)
export const fetchVideoInsightsDetails = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoInsightsDetailsRequest())

      const { start_date, end_date } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'videos',
            end_date,
            start_date
          }
        }
      )
      dispatch(fetchVideoInsightsDetailsSuccess({ ...res.data, channelId }))

      return res
    } catch (error) {
      dispatch(fetchVideoInsightsDetailsFailure())
    }
  }
}

const fetchVideoInsightsDetailForVideoRequest = createAction(
  'insight/fetchVideoInsightsDetailForVideoRequest'
)
const fetchVideoInsightsDetailForVideoFailure = createAction(
  'insight/fetchVideoInsightsDetailForVideoFailure'
)
export const fetchVideoInsightsDetailForVideo = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoInsightsDetailForVideoRequest())

      const { end_date, start_date, video_id } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_insights_details`,
        {
          params: {
            dimension: 'video',
            end_date,
            start_date,
            video_id
          }
        }
      )
      dispatch(
        fetchVideoInsightsDetailForVideoSuccess({
          video_insight: res.data,
          endDate: end_date,
          startDate: start_date
        })
      )

      return res
    } catch (error) {
      dispatch(fetchVideoInsightsDetailForVideoFailure())

      return error
    }
  }
}

const fetchVideoUrlInsightsForVideoRequest = createAction(
  'insight/fetchVideoUrlInsightsForVideoRequest'
)
const fetchVideoUrlInsightsForVideoFailure = createAction(
  'insight/fetchVideoUrlInsightsForVideoFailure'
)
export const fetchVideoUrlInsightsForVideo = (
  businessId: string,
  channelId: string,
  params?: Record<string, any>
) => {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchVideoUrlInsightsForVideoRequest())
      const { end_date, start_date, video_id } = params
      const res = await api.get(
        `/bus/${businessId}/channels/${channelId}/insights/video_referer_insights`,
        {
          params: {
            end_date,
            start_date,
            video_id
          }
        }
      )
      dispatch(fetchVideoUrlInsightsForVideoSuccess({ ...res.data }))
    } catch (error) {
      dispatch(fetchVideoUrlInsightsForVideoFailure())
    }
  }
}
