import React, { useCallback, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { Box, Flex } from '@src/components/EmotionLayout'
import FWButton from '@src/components/FWButton/FWButton'
import {
  PRICE_FILTER,
  SORT_OPTIONS,
  useSearchProduct
} from '@src/hooks/useSearchProduct'
import { getCurrencySymbol } from '@src/utils/currency'
import { Button, Input, Popover, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

type Props = {
  businessId: string
  storeId: string
  channelId?: string
  type?: 'linkedChannel' | 'productVideo'
  onClearSearch?: () => Promise<void>
}
const ProductSearch = ({
  businessId,
  storeId,
  channelId,
  type,
  onClearSearch
}: Props): JSX.Element => {
  const {
    handleSearchReset,
    handleSearch,
    handleEnterKey,
    priceFilter,
    setPriceFilter,
    price,
    updatePrice,
    onSearch,
    sort,
    setSort,
    searchTerm,
    updateSearchTerm,
    clearFilter,
    loading,
    isReset
  } = useSearchProduct({
    businessId,
    storeId,
    channelId: type === 'productVideo' ? channelId : undefined
  })
  const { t } = useTranslation()
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)

  const businessStore = useAppSelector((state) => {
    if (type === 'linkedChannel' && channelId) {
      const channelStores = state.channelStore.channelAllStores[channelId]

      return channelStores?.find((store) => store.id === storeId) || {}
    }

    return state.businessStore.businessStores[storeId] || {}
  })

  const handleVisibleChange = (visible) => {
    setIsFiltersVisible(visible)
  }

  const handleChange = useCallback(
    async (e) => {
      const searchValue = e.currentTarget.value
      updateSearchTerm(searchValue)
      if (!searchValue) {
        if (onClearSearch) {
          await onClearSearch()
        } else {
          await handleSearchReset()
        }
      }
    },
    [handleSearchReset, onClearSearch, updateSearchTerm]
  )

  return (
    <Flex flexDirection="row" flex={1} flexGrow="1">
      <Input.Search
        value={searchTerm}
        placeholder={t('Search product by name or description or external id.')}
        allowClear
        onChange={handleChange}
        onPressEnter={handleEnterKey}
        onSearch={onSearch}
        loading={loading}
        enterButton={
          <Button>
            <SearchOutlined />
          </Button>
        }
      />
      <Popover
        placement="bottomRight"
        open={isFiltersVisible}
        onOpenChange={handleVisibleChange}
        title={
          <Flex justifyContent="space-between" p="xxxsmall" alignItems="center">
            <Typography.Title level={4} style={{ margin: 0 }}>
              {t('Filters')}
            </Typography.Title>
            <Box onClick={() => setIsFiltersVisible(false)} cursor="pointer">
              <Box as="img" alt="close" src="/close_black.svg" />
            </Box>
          </Flex>
        }
        content={
          <Flex flexDirection="column" width={280}>
            <Select
              size="large"
              css={css`
                width: 100%;
                margin: 5px 0px;
              `}
              placeholder={t('Price filter')}
              value={priceFilter}
              onChange={(value) => setPriceFilter(value)}
            >
              <Select.Option value={PRICE_FILTER.GREATER_THAN}>
                {t('Greater than')}
              </Select.Option>
              <Select.Option value={PRICE_FILTER.LESS_THAN}>
                {t('Less than')}
              </Select.Option>
            </Select>
            {businessStore.currency && (
              <Input
                size="large"
                type="number"
                css={css`
                  width: 100%;
                  margin: 5px 0;
                `}
                placeholder={t('Price')}
                value={price}
                onChange={updatePrice}
                min="0"
                step="0.01"
                addonBefore={getCurrencySymbol(businessStore.currency)}
              />
            )}
            <Select
              size="large"
              css={css`
                width: 100%;
                margin: 5px 0px;
              `}
              value={sort}
              onChange={(value) => setSort(value)}
            >
              <Select.Option value={SORT_OPTIONS.RELEVANCE}>
                {t('Relevance')}
              </Select.Option>
              <Select.Option value={SORT_OPTIONS.NAME_ASC}>
                {t('Sort by Name Ascending')}
              </Select.Option>
              <Select.Option value={SORT_OPTIONS.NAME_DESC}>
                {t('Sort by Name Descending')}
              </Select.Option>
              <Select.Option value={SORT_OPTIONS.PRICE_ASC}>
                {t('Sort by Price Ascending')}
              </Select.Option>
              <Select.Option value={SORT_OPTIONS.PRICE_DESC}>
                {t('Sort by Price Descending')}
              </Select.Option>
            </Select>
            <Flex justifyContent="flex-end">
              <FWButton
                onClick={clearFilter}
                css={css`
                  margin: 5px 5px;
                `}
              >
                {t('Clear')}
              </FWButton>
              <FWButton
                type="primary"
                onClick={async () => {
                  if (isReset) {
                    if (onClearSearch) {
                      await onClearSearch()
                    } else {
                      await handleSearchReset()
                    }
                  } else {
                    await handleSearch()
                  }
                }}
                loading={loading}
                css={css`
                  margin: 5px 0px;
                `}
              >
                {t('Apply')}
              </FWButton>
            </Flex>
          </Flex>
        }
        trigger="click"
      >
        <FWButton
          css={css`
            margin: 0 5px;
          `}
        >
          <Box as="img" src="/filter.png" />
        </FWButton>
      </Popover>
    </Flex>
  )
}

export default ProductSearch
