import { useAppSelector } from '@src/app/hooks'
import { getFeatureEnablement } from '@src/common/hooks/useFeatureEnablement'
import useOAuthApp from '@src/hooks/useOAuthApp'
import { fetchChatChannels } from '@src/modules/one-to-one/redux/chatChannel'
import { fetchBusiness } from '@src/redux/business'
import { fetchChannels } from '@src/redux/channel'
import { getStorage, lastChannel } from '@src/utils/localStorage'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { PERMISSIONS, useRolesAndPermissions } from './useRolesAndPermissions'

/**
 * A business onboarding flow (CMS-1425):
 *
 * 1. If one of the SV / LS (AND DS) subscription flags is available,
 *    default to Video (First channel of the business)
 * 2. If not, if one-to-one is available, land on one-to-one
 * 3. if not, if ava is available, land on ava `/business/:business_id/ava`
 * 4. if not, land on payment page `/business/:business_id/payment`
 */

interface EntryPointProps {
  businessId: string
  business?: any
  channels?: { id: string }[] | string[]
  toChannelList?: boolean
  toStoreListIfNeeded?: boolean
  chatChannels?: { id: string }[] | string[]
}

export const useBusinessEntryPoint = () => {
  const businesses = useAppSelector((state) => state.business.businesses)
  const { oauthDefaultBusinessStoreId } = useOAuthApp()
  const { userCan } = useRolesAndPermissions()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const username = useAppSelector(
    (state) => state.profile?.user?.username || ''
  )
  const isVideoFeaturesEnabled = (business: any) => {
    const featureEnablement = getFeatureEnablement(business)

    return featureEnablement['short_videos'] || featureEnablement['live_events']
  }

  const switchBusiness = async (
    businessId: string,
    needFetchBusiness?: boolean
  ) => {
    const [channelList, chatChannelList, businessData] = await Promise.all([
      dispatch(fetchChannels(businessId)),
      dispatch(fetchChatChannels(businessId)),
      needFetchBusiness ? dispatch(fetchBusiness(businessId)) : null
    ])
    const channels = (channelList as unknown) as { id: string }[]
    const chatChannels = (chatChannelList as unknown) as {
      data: { chat_channels: { id: string }[] }
    }
    const business = (businessData as unknown) as { data: any }

    const routePath = getBusinessEntryPoint({
      businessId: businessId,
      business: business?.data,
      channels: channels,
      chatChannels: chatChannels?.data?.chat_channels
    })
    navigate(routePath)
  }

  const isPLGCustomer = (business: any) => {
    return business?.subscription?.payment_plan_id.startsWith('plg_')
  }

  const getBusinessEntryPoint = ({
    businessId,
    business,
    channels = [],
    toChannelList = false,
    toStoreListIfNeeded = false,
    chatChannels = []
  }: EntryPointProps): string | null => {
    const businessData = business || businesses[businessId]
    const featureEnablement = getFeatureEnablement(businessData)
    const businessIsPLG = isPLGCustomer(businessData)

    // Video (Short Videos / Livestream)
    if (isVideoFeaturesEnabled(businessData)) {
      if (toChannelList) {
        return `/business/${businessId}/channels`
      }

      if (channels.length) {
        const lastSelectChannel = getStorage(lastChannel, [
          username,
          businessId
        ])
        let id = lastSelectChannel?.encoded_id
        if (!id) {
          const channel = channels[0]
          id = typeof channel === 'string' ? channel : channel.id
        }

        if (businessIsPLG) return `/business/${businessId}/channel/${id}/home`

        return `/business/${businessId}/channel/${id}/videos`
      }

      if (toStoreListIfNeeded && oauthDefaultBusinessStoreId) {
        return `/business/${businessId}/store/${oauthDefaultBusinessStoreId}`
      } else {
        if (userCan(PERMISSIONS.CHANNEL_CREATE)) {
          return `/business/${businessId}/channel/create`
        }
      }
    }

    // One-to-One
    if (featureEnablement['one_to_one'] && chatChannels.length) {
      const chatChannel = chatChannels[0]
      const id = typeof chatChannel === 'string' ? chatChannel : chatChannel.id

      return `/business/${businessId}/chat_channel/${id}/visitors`
    }

    // @todo: Ava (Waiting for Ava moved to Subscription Plan)

    // No Subscription Plan Enabled, defaults to payment settings
    return `/business/${businessId}/payment`
  }

  return { getBusinessEntryPoint, isVideoFeaturesEnabled, switchBusiness }
}
