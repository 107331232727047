import React, { ReactNode, useCallback, useState } from 'react'

import { css } from '@emotion/core'
import { FWButton } from '@src/components'
import { Flex } from '@src/components/EmotionLayout'
import { FullScreenLayout } from '@src/components/FullScreenLayout'
import BatchImporterHeader from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterHeader'
import {
  BatchImporterMedia,
  BatchImporterResult,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import BatchImporterLogoutModal from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterSelect/BatchImporterLogoutModal'
import { BatchImporterSelectMediaBody } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterSelect/BatchImporterSelectMediaBody'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  businessId: string
  channelId: string
  title: string
  subtitle?: string | ReactNode
  onClose: () => void
  mediaList: BatchImporterMedia[]
  selectedMediaList?: BatchImporterMedia[]
  onChangeSelectedMediaList?: (value: BatchImporterMedia[]) => void
  onLoadMore?: () => Promise<BatchImporterResult>
  hasMore?: boolean
  onNextButtonClick?: () => void
  username?: string // for logout modal
  onLogout?: () => void
  tracking?: TrackingDetails
}

export const BatchImporterSelectMedia: React.FC<IProps> = (props) => {
  const {
    businessId,
    channelId,
    title,
    subtitle,
    onClose,
    mediaList = [],
    selectedMediaList = [],
    onChangeSelectedMediaList,
    onLoadMore,
    hasMore,
    onNextButtonClick,
    username,
    onLogout,
    tracking
  } = props
  const { t } = useTranslation()
  const [logoutModalVisible, setLogoutModalVisible] = useState<boolean>(false)

  const handleChangeSelectedMediaList = useCallback(
    (value?: BatchImporterMedia[]) => {
      if (onChangeSelectedMediaList) {
        onChangeSelectedMediaList(value || [])
      }
    },
    [onChangeSelectedMediaList]
  )

  return (
    <FullScreenLayout position="fixed" bottom={0} right={0}>
      <BatchImporterHeader
        title={title}
        subTitle={
          subtitle || (
            <Flex flexDirection="column" alignItems="center">
              <Typography.Text>
                {t(
                  "We've imported all of your videos. Select which ones you would like to add to your Channel."
                )}
              </Typography.Text>
              <Typography.Text>
                {t(
                  'You can select up to 20 videos at a time or the limit with your current plan.'
                )}
              </Typography.Text>
            </Flex>
          )
        }
        leftControls={
          <Flex gap="5">
            <FWButton onClick={onClose}>{t('Back')}</FWButton>
            <FWButton onClick={() => setLogoutModalVisible(true)}>
              {t('Logout')}
            </FWButton>
          </Flex>
        }
        rightControls={
          <Flex gap="5" width="100%">
            <FWButton onClick={() => handleChangeSelectedMediaList([])}>
              {t('Clear selections')}
            </FWButton>

            <FWButton
              onClick={onNextButtonClick}
              disabled={!selectedMediaList.length}
              type="primary"
              css={css`
                color: #fff !important;
                background-color: ${!selectedMediaList.length
                  ? '#b8d3ff !important'
                  : '#0F6BFF  !important'};
                border-color: ${!selectedMediaList.length
                  ? '#b8d3ff !important'
                  : '#0F6BFF  !important'};
              `}
            >
              {t('Next')}
            </FWButton>
          </Flex>
        }
      />
      <Flex flexDirection="column" flex={1} p="10px 0" overflow="hidden">
        <BatchImporterSelectMediaBody
          businessId={businessId}
          channelId={channelId}
          mediaList={mediaList}
          selectedMediaList={selectedMediaList}
          onChangeSelectedMediaList={handleChangeSelectedMediaList}
          onLoadMore={onLoadMore}
          hasMore={hasMore}
          tracking={tracking}
        />
      </Flex>
      {logoutModalVisible && (
        <BatchImporterLogoutModal
          username={username}
          modalVisible={logoutModalVisible}
          onOkClick={onLogout}
          onCancelClick={() => setLogoutModalVisible(false)}
        />
      )}
    </FullScreenLayout>
  )
}
