import React from 'react'

import loadable from '@loadable/component'
import { Navigate, Route } from 'react-router-dom'

const AutomationPage = loadable(() => import('../pages/Automation'))
const SmartButtons = loadable(() => import('../pages/SmartButtons'))
const SmartButtonsList = loadable(() => import('../pages/SmartButtonsList'))
const SmartButtonConfigure = loadable(
  () => import('../pages/SmartButtonConfigure')
)

export const Routes = (
  <Route path="/business/:businessId/automations" element={<AutomationPage />}>
    <Route path="" element={<Navigate to="./smart-buttons" />} />
    <Route path="smart-buttons" element={<SmartButtons />}>
      <Route path="" element={<SmartButtonsList />} />
      <Route path="create" element={<SmartButtonConfigure />} />
      <Route path=":buttonId" element={<SmartButtonConfigure />} />
    </Route>
  </Route>
)
