import api, { API_URL } from '@src/common/utils/api'
import useWebSocket, { Options } from 'react-use-websocket'

export const useWebsocketConnection = ({
  path = 'socket/websocket',
  options = {}
}: {
  path?: string
  options?: Options
}): {
  sendJsonMessage: (payload: any) => void
  readyState: number
} => {
  const removePrefixURL = new URL(API_URL).host
  const protocol = removePrefixURL.startsWith('localhost') ? 'ws' : 'wss'
  const SOCKET_URL = `${protocol}://${removePrefixURL}/${path}?token=${api.getToken()}`

  const { sendJsonMessage, readyState } = useWebSocket(
    SOCKET_URL,
    {
      shouldReconnect: () => {
        return true
      },
      retryOnError: true,
      share: true,
      reconnectAttempts: 10,
      reconnectInterval: 500,
      ...options
    },
    true
  )

  return {
    sendJsonMessage,
    readyState
  }
}
