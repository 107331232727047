import React, { CSSProperties, useState } from 'react'

import { Box } from '@src/components/EmotionLayout'
import { useTranslation } from 'react-i18next'

import ImagePreview from './ImagePreview'
import { ROW_HEIGHT } from './Product'

type ThumbnailProps = {
  imageId: number
  images: globalLib.Image[]
  withOverlay?: string
  style?: CSSProperties
}

export const ProductThumbnail: React.FC<ThumbnailProps> = ({
  imageId,
  images,
  withOverlay,
  style
}) => {
  const { t } = useTranslation()
  const [showImagePreview, setShowImagePreview] = useState(false)
  const source = images.length
    ? images.filter((i) => i.image_id === imageId)[0].image_src
    : ''

  return (
    <>
      {showImagePreview ? (
        <ImagePreview
          initialSelectedId={imageId}
          images={images}
          onClose={() => setShowImagePreview(false)}
        />
      ) : null}
      <div
        style={{
          height: ROW_HEIGHT,
          backgroundImage: `url("${source}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          cursor: 'pointer',
          margin: '5px',
          ...style
        }}
        onClick={() => setShowImagePreview(true)}
      >
        {withOverlay ? (
          <div
            style={{
              position: 'relative',
              height: ROW_HEIGHT,
              backgroundColor: 'rgba(0,0,0,0.7)',
              backgroundSize: 'contain',
              backgroundPosition: 'center center'
            }}
          >
            <Box
              as="img"
              src={withOverlay}
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
              zIndex="100"
              width="56%"
              height="56%"
            />
          </div>
        ) : null}
        {images.length > 1 && source === images[1].image_src ? (
          <div
            style={{
              backgroundColor: 'rgba(255,255,255,0.5)',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {images.length} {t('more')}
          </div>
        ) : null}
      </div>
    </>
  )
}
