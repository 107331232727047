import { createSlice } from '@reduxjs/toolkit'

type LiveChatState = {
  isLiveChatButtonVisible: boolean
}

const initialState: LiveChatState = {
  isLiveChatButtonVisible: false
}

const slice = createSlice({
  name: 'liveChat',
  initialState,
  reducers: {
    showLiveChatButton(state, action) {
      state.isLiveChatButtonVisible = action.payload
    }
  }
})

export default slice.reducer

export const { showLiveChatButton } = slice.actions
