import React, { Dispatch, SetStateAction } from 'react'

import { Flex } from '@src/components/EmotionLayout'
import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { FWButton } from '.'
import { UpgradeButton } from './UpgradeButton'

interface Props {
  onClose: () => Dispatch<SetStateAction<boolean>> | void
  title: string
  description: string
}

const UpgradeModal: React.FC<Props> = ({
  onClose,
  title,
  description
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Modal
      title={title}
      open={true}
      onCancel={onClose}
      footer={false}
      closable={false}
    >
      <p>{description}</p>
      <Flex justifyContent="flex-end" gap="5" width="100%">
        <FWButton onClick={onClose}>{t('Cancel')}</FWButton>
        <UpgradeButton
          isPrimary
          trackingProps={{
            context: 'upgrade_modal'
          }}
        />
      </Flex>
    </Modal>
  )
}

export default UpgradeModal
