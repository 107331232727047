import styled from '@emotion/styled'
import theme from '@src/styles/theme'
import { Typography } from 'antd'

export const Container = styled.div<{
  padding?: string
  gap?: string
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  maxWidth?: string
  disabled?: boolean
}>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  align-items: ${(props) => props.alignItems || 'flex-start'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  width: 100%;
  border-radius: 12px;
  background: #fff;
  border: solid 1px ${theme.gray14};
  padding: ${(props) => props.padding || '15px'};
  gap: ${(props) => props.gap || 'none'};
  height: 100%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 0 0;
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const BodyText = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  display: inline-block;
  text-align: center;
`

export const FooterContainer = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
