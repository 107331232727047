import { createAction, createSlice } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

import api from '../../../common/utils/api'

type IProps = {
  businessId: string | undefined
  blocklist: Record<string, string>
  canDelete: Record<string, boolean>
}

const initialState: IProps = {
  businessId: undefined,
  blocklist: {},
  canDelete: {}
}

const slice = createSlice({
  name: 'avaBlocklist ',
  initialState: initialState,
  reducers: {
    fetchAvaBlocklistSuccess(state, action) {
      const { blocklist, businessId } = action.payload
      state.canDelete[businessId] = blocklist.length > 0 ? true : false
      state.blocklist[businessId] = blocklist.length
        ? JSON.parse(blocklist)
        : ''
    },
    createAvaBlocklistSuccess(state, action) {
      const { blocklist, businessId } = action.payload
      if (blocklist.length > 0) {
        state.canDelete[businessId] = true
      }
      state.blocklist[businessId] = blocklist.length
        ? JSON.parse(blocklist)
        : ''
    },
    deleteAvaBlocklistSuccess(state, action) {
      const businessId = action.payload
      state.canDelete[businessId] = false
      state.blocklist[businessId] = ''
    }
  }
})

export default slice.reducer

export const {
  fetchAvaBlocklistSuccess,
  createAvaBlocklistSuccess,
  deleteAvaBlocklistSuccess
} = slice.actions

const fetchAvaBlocklistRequest = createAction(
  'avaBlocklist/fetchAvaBlocklistRequest'
)
const fetchAvaBlocklistFailure = createAction(
  'avaBlocklist/fetchAvaBlocklistFailure'
)

export function fetchAvaBlocklist(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaBlocklistRequest())
      const response = await api.get(
        `/api/bus/${businessId}/ava/blocklist/${businessId}`
      )
      dispatch(
        fetchAvaBlocklistSuccess({
          blocklist: response.data?.blocklist,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaBlocklistFailure())

      return error
    }
  }
}

const createAvaBlocklistRequest = createAction(
  'avaBlocklist/createAvaBlocklistRequest'
)
const createAvaBlocklistFailure = createAction(
  'avaBlocklist/createAvaBlocklistFailure'
)

export function createAvaBlocklist(businessId: string, blocklist: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createAvaBlocklistRequest())
      const response = await api.post(`/api/bus/${businessId}/ava/blocklist`, {
        blocklist
      })
      dispatch(
        createAvaBlocklistSuccess({
          blocklist: response.data?.blocklist,
          businessId
        })
      )

      return response
    } catch (error) {
      dispatch(createAvaBlocklistFailure())

      return error
    }
  }
}

const deleteAvaBlocklistRequest = createAction(
  'avaBlocklist/deleteAvaBlocklistRequest'
)
const deleteAvaBlocklistFailure = createAction(
  'avaBlocklist/deleteAvaBlocklistFailure'
)

export function deleteAvaBlocklist(businessId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteAvaBlocklistRequest())
      const response = await api.delete(
        `/api/bus/${businessId}/ava/blocklist/${businessId}`
      )
      dispatch(deleteAvaBlocklistSuccess(businessId))

      return response
    } catch (error) {
      dispatch(deleteAvaBlocklistFailure())

      return error
    }
  }
}
