import { createAction, createSlice } from '@reduxjs/toolkit'
import api, { validateResponseSuccess } from '@src/common/utils/api'
import { LIVESTREAM_STATUS, InteractionType } from '@src/constants'
import { sanitizeInteractionData } from '@src/utils/sanitize'
import { AxiosResponse } from 'axios'
import uniqBy from 'lodash/uniqBy'
import uuid from 'react-uuid'
import { Dispatch } from 'redux'

type IProps = {
  livestreams: Record<string, globalLib.LivestreamDetails>
  livestreamAnalyticData: Record<string, any>
  livestreamMinuteByMinuteData: Record<
    string,
    globalLib.LivestreamMinutelyInsights
  >
  afterEventData: globalLib.LivestreamAfterEvent[]
  lastDaysData: globalLib.LivestreamAfterEvent[]
  messagesByLivestreamId: Record<string, globalLib.LivestreamMessage[]>
  livestreamInsights: globalLib.LivestreamInsights
  deletedMessageByLivestreamId: Record<string, string[]>
  tokenDataByLivestreamId: Record<string, globalLib.LivestreamTokenData>
  topProducts: Record<string, globalLib.Product[]>
  replaysByLivestreamId: Record<string, globalLib.LivestreamReplay[]>
  metricsByLivestreamId: Record<string, globalLib.RealtimeMetrics>
  coHostInvitationDetails: Record<string, globalLib.LivestreamInvite>
  livestreamInvitationList: globalLib.LivestreamInvite[]
  activeStreamers: globalLib.LivestreamUser[]
  readyStreamers: globalLib.LivestreamUser[]
  viewerAndWatchTime: {
    live_stream_insights_summary: globalLib.LivestreamLiveSummary | null
    live_stream_replay_insights_summary: globalLib.LivestreamReplaySummary | null
  }
  broadcastQualityMetrics: globalLib.LivestreamBroadcasterQualityMetrics[]
  simulcasts: Record<string, globalLib.Livestream[]>
  requestedDownloadReplays: Record<string, boolean>
  messageSearchResults: globalLib.LivestreamMessage[]
  messageSearchResultsPaging: globalLib.Paging
  recurrences: Record<string, globalLib.LivestreamRecurrence>
  recurrenceEvents: Record<string, globalLib.LivestreamRecurrence[]>
  aiSuggestions: Record<string, globalLib.AiSuggestion[]>
  livestreamActions: Record<string, globalLib.LivestreamAction[]>
  livestreamActionsPaging: Record<string, string>
}

const initialState: IProps = {
  livestreams: {},
  livestreamAnalyticData: {},
  livestreamMinuteByMinuteData: {},
  afterEventData: [],
  lastDaysData: [],
  messagesByLivestreamId: {},
  livestreamInsights: {
    add_to_cart_count: 0,
    current_viewer_count: 0,
    duration_minutes: 0,
    duration_seconds: 0,
    hearts_count: 0,
    live_stream_id: 0,
    messages_count: 0,
    peak_concurrent_viewers_count: 0,
    shares_count: 0,
    video_id: '',
    viewers_count: 0
  },
  deletedMessageByLivestreamId: {},
  tokenDataByLivestreamId: {},
  topProducts: {},
  replaysByLivestreamId: {},
  metricsByLivestreamId: {},
  coHostInvitationDetails: {},
  livestreamInvitationList: [],
  activeStreamers: [],
  readyStreamers: [],
  viewerAndWatchTime: {
    live_stream_insights_summary: null,
    live_stream_replay_insights_summary: null
  },
  broadcastQualityMetrics: [],
  simulcasts: {},
  requestedDownloadReplays: {},
  messageSearchResults: [],
  messageSearchResultsPaging: null,
  recurrences: {},
  recurrenceEvents: {},
  aiSuggestions: {},
  livestreamActions: {},
  livestreamActionsPaging: {}
}

const slice = createSlice({
  name: 'livestream',
  initialState: initialState,
  reducers: {
    fetchLivestreamProductsSuccess(state, action) {
      const { livestreamId, status, products, deals } = action.payload
      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          status,
          products,
          deals
        }
      }
    },
    deleteLivestreamProductSuccess(state, action) {
      const { livestreamId, productId, unitId } = action.payload
      const livestream = state.livestreams[livestreamId]
      const products = livestream.products
      livestream.products = products.filter((p) => {
        if (unitId) {
          return p.selected_product_unit_id !== unitId
        }

        return p.id !== productId
      })
    },
    setLivestreamProductAvailabilitySuccess(state, action) {
      const { livestreamId, productId, disable } = action.payload
      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          products: state.livestreams[livestreamId].products.map((p) => {
            if (p.id === productId) {
              p.available_for_live_stream_replay = !disable

              return p
            }

            return p
          })
        }
      }
    },
    livestreamProductDropHideSuccess(state, action) {
      const { livestreamId, productId } = action.payload

      const updatedProducts = [...state.livestreams[livestreamId].products]
      const productIndex = updatedProducts.findIndex((p) => p.id === productId)
      if (productIndex > -1) {
        updatedProducts[productIndex].hidden = true
      }

      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          products: updatedProducts
        }
      }
    },
    livestreamProductDropUnhideSuccess(state, action) {
      const { livestreamId, productId } = action.payload

      const updatedProducts = [...state.livestreams[livestreamId].products]
      const productIndex = updatedProducts.findIndex((p) => p.id === productId)
      if (productIndex > -1) {
        updatedProducts[productIndex].hidden = false
      }

      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          products: updatedProducts
        }
      }
    },
    deleteLivestreamProductsSuccess(state, action) {
      const { livestreamId } = action.payload
      state.livestreams[livestreamId].products = []
    },
    getQRCodeSuccess(state, action) {
      const { livestreamId, data } = action.payload
      state.tokenDataByLivestreamId[livestreamId] = data
    },
    fetchSimulcastsSuccess(state, action) {
      const { livestreamId, simulcasts } = action.payload
      state.simulcasts[livestreamId] = simulcasts
    },
    deleteSimulcastSuccess(state, action) {
      const { livestreamId, simulcastId } = action.payload
      state.simulcasts[livestreamId] = state.simulcasts[livestreamId].filter(
        (s) => s.id !== simulcastId
      )
    },
    fetchLivestreamStatusSuccess(state, action) {
      const { livestreamId, details } = action.payload
      state.livestreams[livestreamId] = { ...details }
      state.livestreamInsights = details.insights
    },
    addLiveStreamMessage(state, action) {
      const { livestreamId, message } = action.payload

      if (state.messagesByLivestreamId[livestreamId]) {
        const messages = [
          ...state.messagesByLivestreamId[livestreamId],
          message
        ]
        state.messagesByLivestreamId[livestreamId] = uniqBy(
          messages,
          (m) => m.id
        )
      } else {
        state.messagesByLivestreamId[livestreamId] = []
        state.messagesByLivestreamId[livestreamId].push(message)
      }
    },
    approveLivestreamMessage(state, action) {
      const { livestreamId, messageId } = action.payload
      const index = state.messagesByLivestreamId[livestreamId].findIndex(
        (m) => m.id === messageId
      )
      state.messagesByLivestreamId[livestreamId][index] = {
        ...state.messagesByLivestreamId[livestreamId][index],
        status: 'approved',
        visibility: 'public' // set visibility to public because private are automatically approved
      }
    },
    updateInteractionSuccess(
      state,
      action: {
        payload: {
          livestreamId: string
          action_type: string
          entity_type: InteractionType
          entity_id: string
          entity_ids: string
          entities: globalLib.HighlightedEntity[]
          isModernPlayerEnabled: boolean
        }
      }
    ) {
      const {
        livestreamId,
        action_type,
        entity_type,
        entity_id,
        entity_ids,
        entities,
        isModernPlayerEnabled
      } = action.payload
      if (!state.livestreams[livestreamId].disabled_entities) {
        state.livestreams[livestreamId].disabled_entities = []
      }

      if (action_type === 'unhighlight_entity') {
        if (entity_type === 'poll') {
          // adding ended poll id to ended_polls array, so we don't have to
          // fetch stream data again
          if (state.livestreams[livestreamId].ended_polls)
            state.livestreams[livestreamId].ended_polls = [
              ...new Set([
                ...state.livestreams[livestreamId].ended_polls,
                entity_id
              ])
            ]
        } else if (entity_type === 'giveaway') {
          //Add entity_id and entity_type object to ended_one_time_entities
          // array
          state.livestreams[livestreamId].ended_one_time_entities = [
            ...state.livestreams[livestreamId].ended_one_time_entities,
            {
              entity_id: entity_id,
              entity_type: entity_type
            }
          ]
        }

        const idsToRemove = entities
          ? entities.map((e) => e.entity_id)
          : [entity_id]
        const newEntities = state.livestreams[
          livestreamId
        ].highlighted_entities.filter((e) => !idsToRemove.includes(e.entity_id))

        state.livestreams[livestreamId].highlighted_entities = newEntities
      } else if (
        action_type === 'disable_entity' &&
        entity_type === 'product'
      ) {
        state.livestreams[livestreamId].disabled_entities.push({
          entity_type: entity_type,
          entity_id: entity_id
        })
      } else if (action_type === 'enable_entity' && entity_type === 'product') {
        state.livestreams[livestreamId].disabled_entities = state.livestreams[
          livestreamId
        ].disabled_entities.filter((entity) => entity.entity_id !== entity_id)
      } else {
        // If pinning multiple entities, we need to split the entity_ids array
        if (entity_ids) {
          entity_ids.split(',').forEach((entityId) => {
            state.livestreams[livestreamId].highlighted_entities.push({
              entity_type: entity_type,
              entity_id: entityId
            })
          })
        } else if (isModernPlayerEnabled) {
          let otherHighlightedEntities = state.livestreams[
            livestreamId
          ].highlighted_entities.filter((e) => e.entity_type !== entity_type)

          // we can only have one poll or one question highlighted at a time
          // these are the only entity types that conflict
          if (['poll', 'question'].includes(entity_type)) {
            otherHighlightedEntities = otherHighlightedEntities.filter(
              (e) => !['poll', 'question'].includes(e.entity_type)
            )
          }

          state.livestreams[livestreamId].highlighted_entities = entities
            ? [...otherHighlightedEntities, ...entities]
            : [
                ...otherHighlightedEntities,
                {
                  entity_type: entity_type,
                  entity_id: entity_id
                }
              ]

          //Null out open_entry_giveaway if entity_type is giveaway
          if (entity_type === 'giveaway') {
            state.livestreams[livestreamId].open_entry_giveaway = null
          }
        } else {
          state.livestreams[livestreamId].highlighted_entities = entities
            ? [...entities]
            : [
                {
                  entity_type: entity_type,
                  entity_id: entity_id
                }
              ]

          //Null out open_entry_giveaway if entity_type is giveaway
          if (entity_type === 'giveaway') {
            state.livestreams[livestreamId].open_entry_giveaway = null
          }
        }
      }
    },
    fetchLivestreamEngagementDataSuccess(state, action) {
      const { livestreamId, data } = action.payload
      state.livestreamAnalyticData[livestreamId] = data
    },
    fetchLivestreamViewerAndWatchTimeSuccess(state, action) {
      const { livestreamId, data } = action.payload
      state.viewerAndWatchTime[livestreamId] = data
    },
    fetchLivestreamForLastXDaysSuccess(state, action) {
      const { live_stream_replay_insights } = action.payload
      state.lastDaysData = live_stream_replay_insights
    },
    fetchLivestreamForAfterXDaysSuccess(state, action) {
      const { live_stream_replay_insights } = action.payload
      state.afterEventData = live_stream_replay_insights
    },
    resetLivestreamSuccess(state, action) {
      const { livestreamId, data } = action.payload
      state.tokenDataByLivestreamId[livestreamId] = data
    },
    updateLivestreamStatus(state, action) {
      const { livestreamId, status } = action.payload
      state.livestreams[livestreamId].status = status
    },
    updateLivestreamBaseVideoReady(state, action) {
      const { livestreamId } = action.payload
      state.livestreams[livestreamId].base_video_state = 'ready'
    },
    updateLivestreamBaseVideoFailed(state, action) {
      const { livestreamId } = action.payload
      state.livestreams[livestreamId].base_video_state = 'failed'
    },
    updateLivestreamViewerCount(state, action) {
      const { livestreamId, event } = action.payload
      if (state.livestreams[livestreamId]) {
        if (event === 'add') {
          state.livestreams[livestreamId].viewers_count =
            state.livestreams[livestreamId].viewers_count + 1
        } else if (event === 'remove') {
          state.livestreams[livestreamId].viewers_count =
            state.livestreams[livestreamId].viewers_count - 1
        }
      }
    },
    shadowBlockUserSuccess(state, action) {
      const { livestreamId, username } = action.payload
      state.livestreams[livestreamId].blocked_viewers.push(username)
    },
    unblockUserSuccess(state, action) {
      const { livestreamId, username } = action.payload
      state.livestreams[livestreamId].blocked_viewers = state.livestreams[
        livestreamId
      ].blocked_viewers.filter((viewer) => viewer !== username)
    },
    sortLivestreamProductsSuccess(state, action) {
      const { livestreamId, products } = action.payload
      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          products
        }
      }
    },
    pinMessageSuccess(state, action) {
      const { livestreamId, message, username, messageId } = action.payload
      state.livestreams[livestreamId].pinned_message = {
        live_stream_id: livestreamId,
        message_id: messageId,
        text: message,
        username
      }
    },
    unpinMessageSuccess(state, action) {
      const { livestreamId } = action.payload
      state.livestreams[livestreamId].pinned_message = null
    },
    deleteMessageSuccess(state, action) {
      const { livestreamId, messageId } = action.payload
      if (!state.deletedMessageByLivestreamId[livestreamId]) {
        state.deletedMessageByLivestreamId[livestreamId] = []
      }

      state.deletedMessageByLivestreamId[livestreamId].push(messageId)
    },
    unDeleteMessageSuccess(state, action) {
      const { livestreamId, messageId } = action.payload
      state.deletedMessageByLivestreamId[
        livestreamId
      ] = state.deletedMessageByLivestreamId[livestreamId].filter(
        (id) => id !== messageId
      )
    },
    fetchMessagesSuccess(state, action) {
      const { livestreamId, entries } = action.payload
      if (!state.messagesByLivestreamId[livestreamId]) {
        state.messagesByLivestreamId[livestreamId] = []
      }

      const existingMessages = state.messagesByLivestreamId[livestreamId]

      const messages = [...entries.reverse(), ...existingMessages]

      state.messagesByLivestreamId[livestreamId] = uniqBy(messages, (m) => m.id)

      // Populate deleted messages
      if (!state.deletedMessageByLivestreamId[livestreamId]) {
        state.deletedMessageByLivestreamId[livestreamId] = []
      }

      state.deletedMessageByLivestreamId[livestreamId] = messages
        .filter((message) => message.status === 'deleted')
        .map((message) => message.id)
    },
    fetchMinuteByMinuteDataSuccess(state, action) {
      const { livestreamId } = action.payload
      state.livestreamMinuteByMinuteData[livestreamId] = action.payload
    },
    fetchTopProductsDataSuccess(state, action) {
      const { livestreamId, live_stream_top_products } = action.payload
      state.topProducts[livestreamId] = live_stream_top_products
    },
    fetchReplaysSuccess(state, action) {
      const { livestreamId, replays } = action.payload
      state.replaysByLivestreamId[livestreamId] = replays
    },
    fetchLivestreamInteractionsSuccess(state, action) {
      const { livestreamId, status, interactions } = action.payload
      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          status,
          interactions
        }
      }
    },
    createLivestreamInteractionSuccess(state, action) {
      const { livestreamId, interaction } = action.payload
      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          interactions: state.livestreams[livestreamId].interactions.concat(
            interaction
          )
        }
      }
    },
    updateLivestreamInteractionSuccess(state, action) {
      const { livestreamId, interaction } = action.payload
      const index = state.livestreams[livestreamId].interactions.findIndex(
        (i) => i.id === interaction.id
      )
      const newState = [...state.livestreams[livestreamId].interactions]
      newState[index] = interaction

      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          interactions: newState
        }
      }
    },
    deleteLivestreamInteractionSuccess(state, action) {
      const { livestreamId, interactionId } = action.payload
      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          interactions: state.livestreams[livestreamId].interactions.filter(
            (i) => i.id !== interactionId
          )
        }
      }
    },
    sortLivestreamInteractionsSuccess(state, action) {
      const { livestreamId, interactions } = action.payload
      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          interactions: interactions
        }
      }
    },
    fetchInteractionResultsSuccess(state, action) {
      const { livestreamId, interactionId, responses } = action.payload

      let results = responses
      if (
        state.livestreams[livestreamId].interaction_results &&
        state.livestreams[livestreamId].interaction_results[interactionId]
      ) {
        results = uniqBy(
          state.livestreams[livestreamId].interaction_results[
            interactionId
          ].concat(responses),
          'id'
        )
      }

      state.livestreams = {
        ...state.livestreams,
        [livestreamId]: {
          ...state.livestreams[livestreamId],
          interaction_results: {
            ...state.livestreams[livestreamId].interaction_results,
            [interactionId]: results
          }
        }
      }
    },
    updatePrimaryReplaySuccess(state, action) {
      const { livestreamId, replayId, skips_to } = action.payload
      const index = state.replaysByLivestreamId[livestreamId].findIndex(
        (r) => r.id === replayId
      )
      state.replaysByLivestreamId[livestreamId][index].skips_to = skips_to
    },
    realtimeMetrics(state, action) {
      const { metrics, livestreamId } = action.payload
      state.metricsByLivestreamId[livestreamId] = metrics
    },
    sendInvitationSuccess(state, action) {
      const { invitationDetails } = action.payload
      state.coHostInvitationDetails = invitationDetails
    },
    fetchLivestreamInvitationListSuccess(state, action) {
      const { invitations } = action.payload
      state.livestreamInvitationList = invitations
    },

    /**
     * Find user by guest id in active_streamer list or ready_streamer list
     * update username with new username from payload
     * @param {*} state
     * @param {*} action
     */
    updateGuestUsername(state, action) {
      const { uid, username } = action.payload
      const activeStreamerIndex = state.activeStreamers.findIndex(
        (s) => s.uid === uid
      )
      if (activeStreamerIndex !== -1) {
        state.activeStreamers[activeStreamerIndex].username = username
      }

      const readyStreamerIndex = state.readyStreamers.findIndex(
        (s) => s.uid === uid
      )
      if (readyStreamerIndex !== -1) {
        state.readyStreamers[readyStreamerIndex].username = username
      }
    },
    updateStreamerList(state, action) {
      const { activeStreamers, readyStreamers } = action.payload
      state.activeStreamers = activeStreamers
      state.readyStreamers = readyStreamers
    },
    addStreamerToReadyList(state, action) {
      const { streamer } = action.payload
      const index = state.readyStreamers.findIndex(
        (s) => s.uid === streamer.uid
      )
      if (index === -1) {
        state.activeStreamers = state.activeStreamers.filter(
          (s) => s.uid !== streamer.uid
        )
        state.readyStreamers.push(streamer)
      }
    },
    updateLivestreamInsights(state, action) {
      const {
        insights: {
          concurrent_viewers,
          total_click_heart,
          total_share_video,
          total_click_add_to_cart
        }
      } = action.payload

      state.livestreamInsights.current_viewer_count = concurrent_viewers.length
        ? concurrent_viewers.slice(-1)[0]
        : 0

      state.livestreamInsights.hearts_count = total_click_heart ?? 0
      state.livestreamInsights.shares_count = total_share_video ?? 0
      state.livestreamInsights.add_to_cart_count = total_click_add_to_cart ?? 0
    },
    updateProductList(state, action) {
      const { livestreamId, products } = action.payload
      state.livestreams[livestreamId] = {
        ...state.livestreams[livestreamId],
        products: products
      }
    },
    upsertProduct(state, action) {
      const { livestreamId, productId, upsertFields } = action.payload
      const idx = state.livestreams[livestreamId].products.findIndex(
        (p) => p.id === productId
      )
      if (idx !== -1) {
        state.livestreams[livestreamId].products[idx] = {
          ...state.livestreams[livestreamId].products[idx],
          ...upsertFields
        }
      }
    },
    updateInteractionResults(state, action) {
      // action payload will include livestream id, interaction id and answer value
      // need to find the interaction by interactionId - state.livestreams[livestreamId].interactions[interactionId]
      // need to increase the tally of the answer value by 1 and increase the total by 1

      const { livestreamId, interactionId, answer } = action.payload
      // find interaction by interactionId
      const interactions = [...state.livestreams[livestreamId].interactions]
      const interaction = interactions.find(
        (interaction) => interaction.id === interactionId
      )

      if (interaction?.interaction_type === InteractionType.POLL) {
        const tally = interaction.tally as globalLib.PollTally
        tally[answer] = tally[answer] + 1
        tally.total = tally.total + 1
        interaction.tally = tally
      } else if (
        [InteractionType.QUESTIONS, InteractionType.GIVEAWAY].includes(
          interaction?.interaction_type
        )
      ) {
        interaction.tally = (interaction.tally as number) + 1
      }

      state.livestreams[livestreamId].interactions = interactions
    },
    updateAnnouncement(state, action) {
      const { livestreamId, announcement } = action.payload
      state.livestreams[livestreamId].announcement = announcement
    },
    updateModeratorMessageSuccess(state, action) {
      const { livestreamId, message } = action.payload
      //find message by id in state.messagesByLivestreamId
      const messages = state?.messagesByLivestreamId?.[livestreamId]
        ? [...state.messagesByLivestreamId[livestreamId]]
        : []
      const messageIndex = messages.findIndex((m) => m.id === message.id)
      if (messageIndex !== -1) {
        messages[messageIndex] = {
          ...messages[messageIndex],
          text: message.text,
          original_text: message.text,
          inserted_at: message.inserted_at
        }
      }
      state.messagesByLivestreamId[livestreamId] = messages
    },
    updateOpenEntryCollection(state, action) {
      const { livestreamId, giveawayId } = action.payload
      state.livestreams[livestreamId].open_entry_giveaway = giveawayId
    },
    updateBroadcasterQualityMetrics(state, action) {
      const { metrics } = action.payload
      const livestreamQualityMetrics = [...state.broadcastQualityMetrics]
      const metricsIndex = livestreamQualityMetrics.findIndex(
        (m) => m.uid === metrics.uid
      )
      if (metricsIndex !== -1) {
        livestreamQualityMetrics[metricsIndex] = metrics
      } else {
        livestreamQualityMetrics.push(metrics)
      }
      state.broadcastQualityMetrics = livestreamQualityMetrics
    },
    deleteProductDealSuccess(
      state,
      action: {
        payload: { productId: string; livestreamId: string }
        type: 'livestream/deleteProductDealSuccess'
      }
    ) {
      const { livestreamId, productId } = action.payload
      const deals = state.livestreams[livestreamId].deals
      state.livestreams[livestreamId].deals = deals.filter(
        ({ product_id }) => product_id !== productId
      )
    },
    createLivestreamDealSuccess(
      state,
      action: {
        type: 'livestream/createLivestreamDealSuccess'
        payload: {
          deal: globalLib.FlashDeal
          livestreamId: string
        }
      }
    ) {
      const { deal, livestreamId } = action.payload
      const deals = state.livestreams[livestreamId].deals
      deals.push(deal)
    },
    updateLivestreamDealSuccess(
      state,
      action: {
        type: 'livestream/updateLivestreamDealSuccess'
        payload: {
          deal: globalLib.FlashDeal
          livestreamId: string
        }
      }
    ) {
      const { deal, livestreamId } = action.payload
      const deals = state.livestreams[livestreamId].deals
      const dealIndex = deals.findIndex(
        ({ product_id }) => deal.product_id === product_id
      )
      deals[dealIndex] = deal
    },
    requestDownloadReplaySuccess(
      state,
      action: {
        type: 'livestream/requestDownloadReplaySuccess'
        payload: {
          livestreamId: string
        }
      }
    ) {
      const { livestreamId } = action.payload
      state.requestedDownloadReplays[livestreamId] = true
    },
    updateLimitRateLimitSettingsSuccess(
      state,
      action: {
        payload: {
          livestreamId: string
          message_rate_limit_settings: globalLib.LivestreamMessageRateLimit
        }
      }
    ) {
      const { livestreamId, message_rate_limit_settings } = action.payload
      state.livestreams[
        livestreamId
      ].message_rate_limit = message_rate_limit_settings
    },
    updateLivestreamMessageSearchResults(state, action) {
      const { messages, paging, page } = action.payload

      if (page) {
        state.messageSearchResults = state.messageSearchResults.concat(messages)
      } else {
        state.messageSearchResults = messages
      }
      state.messageSearchResultsPaging = paging
    },
    fetchRecurrencesByIdsSuccess(state, action) {
      const { recurrences } = action.payload
      if (recurrences?.length > 0) {
        recurrences.forEach((recurrence) => {
          state.recurrences[recurrence.id] = recurrence
        })
      }
    },
    fetchRecurrencesForChannelSuccess(state, action) {
      const { recurrences, channelId } = action.payload

      state.recurrenceEvents[channelId] = recurrences
    },
    fetchRecurrenceByIdSuccess(state, action) {
      const { recurrence } = action.payload

      state.recurrences[recurrence.id] = recurrence
    },
    updateAiSuggestionsSuccess(state, action) {
      const { livestreamId, suggestion } = action.payload

      if (!state.aiSuggestions[livestreamId]) {
        state.aiSuggestions[livestreamId] = [suggestion]
      }

      if (suggestion?.message_id) {
        const index = state.aiSuggestions[livestreamId].findIndex(
          (sug) => sug.message_id === suggestion.message_id
        )
        if (index > -1) {
          state.aiSuggestions[livestreamId][index] = suggestion
        } else {
          state.aiSuggestions[livestreamId].push(suggestion)
        }
      } else if (suggestion?.product_id) {
        // we only want to store one suggestion for products
        const index = state.aiSuggestions[livestreamId].findIndex(
          (sug) => !!sug.product_id
        )
        if (index > -1) {
          state.aiSuggestions[livestreamId][index] = suggestion
        } else {
          state.aiSuggestions[livestreamId].push(suggestion)
        }
      }
    },
    removeAiSuggestionSuccess(state, action) {
      const { livestreamId, message_id, product_id } = action.payload

      if (state.aiSuggestions[livestreamId]?.length) {
        state.aiSuggestions[livestreamId] = state.aiSuggestions[
          livestreamId
        ].filter(
          (sug) =>
            sug.message_id !== message_id && sug.product_id !== product_id
        )
      }
    },
    fetchLivestreamActionsSuccess(state, action) {
      const { livestreamId, response, fresh } = action.payload

      const { next_page, entries } = response

      state.livestreamActionsPaging[livestreamId] = next_page
      if (!state.livestreamActions[livestreamId] || fresh) {
        state.livestreamActions[livestreamId] = entries
      } else {
        state.livestreamActions[livestreamId] = [
          ...state.livestreamActions[livestreamId],
          ...entries
        ]
      }
    }
  }
})

export default slice.reducer

export const {
  fetchLivestreamProductsSuccess,
  deleteLivestreamProductSuccess,
  setLivestreamProductAvailabilitySuccess,
  livestreamProductDropHideSuccess,
  livestreamProductDropUnhideSuccess,
  deleteLivestreamProductsSuccess,
  getQRCodeSuccess,
  fetchSimulcastsSuccess,
  deleteSimulcastSuccess,
  fetchLivestreamStatusSuccess,
  addLiveStreamMessage,
  updateInteractionSuccess,
  fetchLivestreamEngagementDataSuccess,
  fetchLivestreamForLastXDaysSuccess,
  fetchLivestreamForAfterXDaysSuccess,
  fetchMinuteByMinuteDataSuccess,
  resetLivestreamSuccess,
  updateLivestreamStatus,
  updateLivestreamBaseVideoReady,
  shadowBlockUserSuccess,
  unblockUserSuccess,
  sortLivestreamProductsSuccess,
  pinMessageSuccess,
  unpinMessageSuccess,
  deleteMessageSuccess,
  unDeleteMessageSuccess,
  fetchMessagesSuccess,
  fetchTopProductsDataSuccess,
  fetchReplaysSuccess,
  fetchLivestreamInteractionsSuccess,
  createLivestreamInteractionSuccess,
  updateLivestreamInteractionSuccess,
  deleteLivestreamInteractionSuccess,
  sortLivestreamInteractionsSuccess,
  fetchInteractionResultsSuccess,
  updatePrimaryReplaySuccess,
  realtimeMetrics,
  sendInvitationSuccess,
  fetchLivestreamInvitationListSuccess,
  updateGuestUsername,
  updateStreamerList,
  addStreamerToReadyList,
  approveLivestreamMessage,
  updateLivestreamInsights,
  fetchLivestreamViewerAndWatchTimeSuccess,
  updateProductList,
  upsertProduct,
  updateInteractionResults,
  updateAnnouncement,
  updateModeratorMessageSuccess,
  updateOpenEntryCollection,
  updateBroadcasterQualityMetrics,
  deleteProductDealSuccess,
  createLivestreamDealSuccess,
  updateLivestreamDealSuccess,
  requestDownloadReplaySuccess,
  updateLimitRateLimitSettingsSuccess,
  updateLivestreamMessageSearchResults,
  fetchRecurrencesByIdsSuccess,
  fetchRecurrencesForChannelSuccess,
  fetchRecurrenceByIdSuccess,
  updateAiSuggestionsSuccess,
  removeAiSuggestionSuccess,
  fetchLivestreamActionsSuccess
} = slice.actions

const createLivestreamProductsRequest = createAction(
  'livestream/createLivestreamProductsRequest'
)
const createLivestreamProductsFailure = createAction(
  'livestream/createLivestreamProductsFailure'
)

export function createLivestreamProducts(
  livestreamId: string,
  data?: {
    products: {
      product_id: string
      product_unit_id?: string
      business_store_id: string
    }[]
  }
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(createLivestreamProductsRequest())

      return await api.post(`/live_streams/${livestreamId}/products`, data)
    } catch (error) {
      dispatch(createLivestreamProductsFailure())

      return error
    }
  }
}

const fetchLivestreamProductsRequest = createAction(
  'livestream/fetchLivestreamProductsRequest'
)
const fetchLivestreamProductsFailure = createAction(
  'livestream/fetchLivestreamProductsFailure'
)

export function fetchLivestreamProducts(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamProductsRequest())
      const response = await api.get(
        `/live_streams/${livestreamId}/products?version=2`
      )
      dispatch(
        fetchLivestreamProductsSuccess({
          livestreamId,
          status: response.data.status,
          products: response.data.products,
          deals: response.data.deals
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchLivestreamProductsFailure())

      return error
    }
  }
}

const deleteLivestreamProductRequest = createAction(
  'livestream/deleteLivestreamProductRequest'
)
const deleteLivestreamProductFailure = createAction(
  'livestream/deleteLivestreamProductFailure'
)

export function deleteLivestreamProduct(
  productId: string,
  unitId: string | null,
  livestreamId: string
) {
  return async (dispatch: Dispatch): Promise<AxiosResponse> => {
    try {
      dispatch(deleteLivestreamProductRequest())
      const response = await api.delete(
        `/live_streams/${livestreamId}/products/${productId}`,
        { data: unitId ? { product_unit_id: unitId } : {} }
      )
      dispatch(
        deleteLivestreamProductSuccess({ livestreamId, productId, unitId })
      )

      return response
    } catch (error) {
      dispatch(deleteLivestreamProductFailure())

      return error
    }
  }
}

const livestreamProductDropHideRequest = createAction(
  'livestream/livestreamProductDropHideRequest'
)
const livestreamProductDropHideFailure = createAction(
  'livestream/livestreamProductDropHideFailure'
)

export function livestreamProductDropHide(
  productId: string,
  livestreamId: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(livestreamProductDropHideRequest())
      await api.post(
        `/live_streams/${livestreamId}/products/${productId}/hide_product`
      )

      dispatch(
        livestreamProductDropHideSuccess({
          livestreamId,
          productId
        })
      )
    } catch (error) {
      dispatch(livestreamProductDropHideFailure())

      return error
    }
  }
}

const livestreamProductDropUnhideRequest = createAction(
  'livestream/livestreamProductDropUnhideRequest'
)
const livestreamProductDropUnhideFailure = createAction(
  'livestream/livestreamProductDropUnhideFailure'
)

export function livestreamProductDropUnhide(
  productId: string,
  livestreamId: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(livestreamProductDropUnhideRequest())
      await api.post(
        `/live_streams/${livestreamId}/products/${productId}/unhide_product`
      )
      dispatch(
        livestreamProductDropUnhideSuccess({
          livestreamId,
          productId
        })
      )
    } catch (error) {
      dispatch(livestreamProductDropUnhideFailure())

      return error
    }
  }
}

const setLivestreamProductAvailabilityRequest = createAction(
  'livestream/setLivestreamProductAvailabilityRequest'
)
const setLivestreamProductAvailabilityFailure = createAction(
  'livestream/setLivestreamProductAvailabilityFailure'
)

export function setLivestreamProductAvailability(
  productId: string,
  livestreamId: string,
  disable?: boolean
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLivestreamProductAvailabilityRequest())
      await api.post(
        `/live_streams/${livestreamId}/products/${productId}/change_product_availability`,
        { disable }
      )
      dispatch(
        setLivestreamProductAvailabilitySuccess({
          livestreamId,
          productId,
          disable
        })
      )
    } catch (error) {
      dispatch(setLivestreamProductAvailabilityFailure())

      return error
    }
  }
}

const deleteLivestreamProductsRequest = createAction(
  'livestream/deleteLivestreamProductsRequest'
)
const deleteLivestreamProductsFailure = createAction(
  'livestream/deleteLivestreamProductsFailure'
)

export function deleteLivestreamProducts(
  productIds: string[],
  livestreamId: string
) {
  return async (dispatch: Dispatch): Promise<AxiosResponse> => {
    try {
      dispatch(deleteLivestreamProductsRequest())
      const response = await api.delete(
        `/live_streams/${livestreamId}/products?product_ids=${productIds.join(
          ','
        )}`
      )
      dispatch(deleteLivestreamProductsSuccess({ livestreamId }))

      return response
    } catch (error) {
      dispatch(deleteLivestreamProductsFailure())

      return error
    }
  }
}

const getQRCodeRequest = createAction('livestream/getQRCodeRequest')
const getQRCodeFailure = createAction('livestream/getQRCodeFailure')

export function getQRCode(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(getQRCodeRequest())
      const response = await api.get(`/live_streams/${livestreamId}/token`)
      const { data } = response
      dispatch(getQRCodeSuccess({ livestreamId, data }))
    } catch (error) {
      dispatch(getQRCodeFailure())

      return error
    }
  }
}

const createSimulcastRequest = createAction('livestream/createSimulcastRequest')
const createSimulcastFailure = createAction('livestream/createSimulcastFailure')

export function createSimulcast(
  livestreamId: string,
  data?: globalLib.Livestream
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(createSimulcastRequest())

      return await api.post(`/live_streams/${livestreamId}/simulcasts`, data)
    } catch (error) {
      dispatch(createSimulcastFailure())

      return error
    }
  }
}

const updateSimulcastRequest = createAction('livestream/updateSimulcastRequest')
const updateSimulcastFailure = createAction('livestream/updateSimulcastFailure')

export function updateSimulcast(
  livestreamId: string,
  simulcastId: string,
  data?: globalLib.Livestream
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateSimulcastRequest())

      return await api.patch(
        `/live_streams/${livestreamId}/simulcasts/${simulcastId}`,
        data
      )
    } catch (error) {
      dispatch(updateSimulcastFailure())

      return error
    }
  }
}

const fetchSimulcastsRequest = createAction('livestream/fetchSimulcastsRequest')
const fetchSimulcastsFailure = createAction('livestream/fetchSimulcastsFailure')

export function fetchSimulcasts(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchSimulcastsRequest())
      const response = await api.get(`/live_streams/${livestreamId}/simulcasts`)
      const simulcasts = response.data
      dispatch(fetchSimulcastsSuccess({ livestreamId, simulcasts }))
    } catch (error) {
      dispatch(fetchSimulcastsFailure())

      return error
    }
  }
}

const deleteSimulcastRequest = createAction(
  'livestream/deleteSimulcastRRequest'
)
const deleteSimulcastFailure = createAction('livestream/deleteSimulcastFailure')

export function deleteSimulcast(livestreamId: string, simulcastId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(deleteSimulcastRequest())
      await api.delete(
        `/live_streams/${livestreamId}/simulcasts/${simulcastId}`
      )
      dispatch(deleteSimulcastSuccess({ livestreamId, simulcastId }))
    } catch (error) {
      dispatch(deleteSimulcastFailure())

      return error
    }
  }
}

const fetchLivestreamStatusRequest = createAction(
  'livestream/fetchLivestreamStatusRequest'
)
const fetchLivestreamStatusFailure = createAction(
  'livestream/fetchLivestreamStatusFailure'
)

export function fetchLivestreamStatus(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamStatusRequest())
      const response = await api.get(`/live_streams/${livestreamId}/detail`)
      const details = response.data
      dispatch(fetchLivestreamStatusSuccess({ livestreamId, details }))
    } catch (error) {
      dispatch(fetchLivestreamStatusFailure())
    }
  }
}

const hostPostMessageRequest = createAction('livestream/hostPostMessageRequest')
const hostPostMessageFailure = createAction('livestream/hostPostMessageFailure')

export function hostPostMessage({
  livestreamId,
  message,
  username,
  replyMessage,
  messageId = uuid()
}: {
  livestreamId: string
  message?: string
  username?: string
  replyMessage?: globalLib.LivestreamMessage
  messageId?: string
}) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(hostPostMessageRequest())
      await api.post(`/live_streams/${livestreamId}/message`, {
        text: message,
        message_id: messageId,
        username,
        user_type: 'moderator',
        reply_to_message: replyMessage
          ? {
              elapsed_time: replyMessage.elapsed_time,
              message_id: replyMessage.id,
              text: replyMessage.text,
              username: replyMessage.username
            }
          : undefined
      })
    } catch (error) {
      dispatch(hostPostMessageFailure())
    }
  }
}

const replyPrivatelyToMessageRequest = createAction(
  'livestream/replyPrivatelyToMessageRequest'
)
const replyPrivatelyToMessageFailure = createAction(
  'livestream/replyPrivatelyToMessageFailure'
)

export function replyPrivatelyToMessage(
  livestreamId: string,
  moderatorMessage: {
    username: string
    text: string
  },
  replyMessage: globalLib.LivestreamMessage
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(replyPrivatelyToMessageRequest())
      await api.post(`/live_streams/${livestreamId}/private_messages`, {
        private_message_format: 'non_mobile',
        text: moderatorMessage.text,
        guest_id: replyMessage.guest_id,
        elapsed_time: replyMessage.elapsed_time + 1,
        inserted_at: replyMessage.inserted_at,
        username: moderatorMessage.username,
        reply_to_message: {
          elapsed_time: replyMessage.elapsed_time,
          message_id: replyMessage.id,
          text: replyMessage.text,
          username: replyMessage.username
        }
      })
    } catch (error) {
      dispatch(replyPrivatelyToMessageFailure())
    }
  }
}

const fetchLivestreamActionsRequest = createAction(
  'livestream/fetchLivestreamActionsRequest'
)
const fetchLivestreamActionsFailure = createAction(
  'livestream/fetchLivestreamActionsFailure'
)

export function fetchLivestreamActions(livestreamId: string, page?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamActionsRequest())
      const params = page
        ? null
        : {
            action_types: ['highlight_entity', 'unhighlight_entity'],
            entity_types: ['product']
          }

      const response = await api.get(
        `/live_streams/${livestreamId}/actions` || page,
        {
          params
        }
      )

      dispatch(
        fetchLivestreamActionsSuccess({
          livestreamId,
          response: response.data,
          fresh: !page
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchLivestreamActionsFailure())
    }
  }
}

const upsertLivestreamActionsRequest = createAction(
  'livestream/upsertLivestreamActionsRequest'
)
const upsertLivestreamActionsFailure = createAction(
  'livestream/upsertLivestreamActionsFailure'
)

export function upsertLivestreamActions(livestreamId: string, data: any) {
  return async (dispatch: Dispatch): Promise<AxiosResponse> => {
    try {
      dispatch(upsertLivestreamActionsRequest())

      const response = await api.post(
        `/live_streams/${livestreamId}/actions/upsert`,
        { actions: data }
      )

      return response
    } catch (error) {
      dispatch(upsertLivestreamActionsFailure())
    }
  }
}

interface IActionPayload {
  action_type: string
  entity_type: InteractionType
  entity_id: string
  entity_ids: string[]
  user_type: string
  username: string
}

const updateInteractionRequest = createAction(
  'livestream/updateInteractionRequest'
)
const updateInteractionFailure = createAction(
  'livestream/updateInteractionFailure'
)

export function updateInteraction(
  livestreamId: string,
  payload?: Partial<IActionPayload>,
  version?: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateInteractionRequest())
      const endpointURL = version
        ? `/live_streams/${livestreamId}/actions?version=${version}`
        : `/live_streams/${livestreamId}/actions`

      await api.post(endpointURL, payload)
    } catch (error) {
      dispatch(updateInteractionFailure())
    }
  }
}

const fetchLivestreamEngagementDataRequest = createAction(
  'livestream/fetchLivestreamEngagementDataRequest'
)
const fetchLivestreamEngagementDataFailure = createAction(
  'livestream/fetchLivestreamEngagementDataFailure'
)

export function fetchLivestreamEngagementData(
  livestreamId: string,
  businessId: string,
  channelId: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamEngagementDataRequest())
      const res = await api.get(
        `/api/bus/${businessId}/channels/${channelId}/insights/live_stream/${livestreamId}/summary`
      )
      dispatch(
        fetchLivestreamEngagementDataSuccess({
          data: res.data,
          livestreamId
        })
      )
    } catch (error) {
      dispatch(fetchLivestreamEngagementDataFailure())

      return error
    }
  }
}

const fetchLivestreamViewerAndWatchTimeRequest = createAction(
  'livestream/fetchLivestreamViewerAndWatchTimeRequest'
)
const fetchLivestreamViewerAndWatchTimeFailure = createAction(
  'livestream/fetchLivestreamViewerAndWatchTimeFailure'
)

export function fetchLivestreamViewerAndWatchTime(
  livestreamId: string,
  businessId: string,
  channelId: string,
  startDate?: string,
  endDate?: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamViewerAndWatchTimeRequest())
      const res = await api.get(
        `/api/bus/${businessId}/channels/${channelId}/insights/live_stream/${livestreamId}/summary?start_date=${startDate}&end_date=${endDate}`
      )
      dispatch(
        fetchLivestreamViewerAndWatchTimeSuccess({
          data: res.data,
          livestreamId
        })
      )
    } catch (error) {
      dispatch(fetchLivestreamViewerAndWatchTimeFailure())

      return error
    }
  }
}

const fetchMinuteByMinuteDataRequest = createAction(
  'livestream/fetchMinuteByMinuteDataRequest'
)
const fetchMinuteByMinuteDataFailure = createAction(
  'livestream/fetchMinuteByMinuteDataFailure'
)

export function fetchMinuteByMinuteData(
  livestreamId: string,
  businessId: string,
  channelId: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchMinuteByMinuteDataRequest())
      const res = await api.get(
        `/api/bus/${businessId}/channels/${channelId}/insights/live_stream/${livestreamId}/live_stream_insights_by_minute`
      )
      dispatch(
        fetchMinuteByMinuteDataSuccess({
          ...res.data,
          livestreamId
        })
      )
    } catch (error) {
      dispatch(fetchMinuteByMinuteDataFailure())

      return error
    }
  }
}

const startLivestreamRequest = createAction('livestream/startLivestreamRequest')
const startLivestreamFailure = createAction('livestream/startLivestreamFailure')

export function startLivestream(livestreamId: string, username?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(startLivestreamRequest())
      await api.patch(`/live_streams/${livestreamId}/start`, {
        user_type: 'moderator',
        username
      })
    } catch (error) {
      dispatch(startLivestreamFailure())

      return error
    }
  }
}

const endLivestreamRequest = createAction('livestream/endLivestreamRequest')
const endLivestreamFailure = createAction('livestream/endLivestreamFailure')

export function endLivestream(livestreamId: string, username?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(endLivestreamRequest())
      await api.patch(`/live_streams/${livestreamId}/end`, {
        user_type: 'moderator',
        username
      })
      dispatch(
        updateLivestreamStatus({
          livestreamId,
          status: LIVESTREAM_STATUS.REPLAY
        })
      )
    } catch (error) {
      dispatch(endLivestreamFailure())

      return error
    }
  }
}

const pauseLivestreamRequest = createAction('livestream/pauseLivestreamRequest')
const pauseLivestreamFailure = createAction('livestream/pauseLivestreamFailure')

export function pauseLivestream(livestreamId: string, username?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(pauseLivestreamRequest())
      await api.patch(`/live_streams/${livestreamId}/pause`, {
        user_type: 'moderator',
        username
      })
      dispatch(
        updateLivestreamStatus({
          livestreamId,
          status: LIVESTREAM_STATUS.PAUSED
        })
      )
    } catch (error) {
      dispatch(pauseLivestreamFailure())

      return error
    }
  }
}

const resumeLivestreamRequest = createAction(
  'livestream/resumeLivestreamRequest'
)
const resumeLivestreamFailure = createAction(
  'livestream/resumeLivestreamFailure'
)

export function resumeLivestream(livestreamId: string, username?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(resumeLivestreamRequest())
      await api.patch(`/live_streams/${livestreamId}/resume`, {
        user_type: 'moderator',
        username
      })
      dispatch(
        updateLivestreamStatus({
          livestreamId,
          status: LIVESTREAM_STATUS.ACTIVE
        })
      )
    } catch (error) {
      dispatch(resumeLivestreamFailure())

      return error
    }
  }
}

const resetLivestreamRequest = createAction('livestream/resetLivestreamRequest')
const resetLivestreamFailure = createAction('livestream/resetLivestreamFailure')

export function resetLivestream(livestreamId: string, username?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(resetLivestreamRequest())
      const response = await api.post(
        `/live_streams/${livestreamId}/reset_key`,
        {
          username
        }
      )
      const { data } = response
      dispatch(resetLivestreamSuccess({ livestreamId, data }))
    } catch (error) {
      dispatch(resetLivestreamFailure())
    }
  }
}

const shadowBlockUserRequest = createAction('livestream/shadowBlockUserRequest')
const shadowBlockUserFailure = createAction('livestream/shadowBlockUserFailure')

export function shadowBlockUser(livestreamId: string, username?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(shadowBlockUserRequest())
      const res = await api.post(
        `/live_streams/${livestreamId}/blocked_viewer/${username}`
      )
      if (validateResponseSuccess(res)) {
        dispatch(shadowBlockUserSuccess({ livestreamId, username }))
      }
    } catch (error) {
      dispatch(shadowBlockUserFailure())
    }
  }
}

const unblockUserRequest = createAction('livestream/unblockUserRequest')
const unblockUserFailure = createAction('livestream/unblockUserFailure')

export function unblockUser(livestreamId: string, username?: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(unblockUserRequest())
      const res = await api.delete(
        `/live_streams/${livestreamId}/blocked_viewer/${username}`
      )
      if (validateResponseSuccess(res)) {
        dispatch(unblockUserSuccess({ livestreamId, username }))
      }
    } catch (error) {
      dispatch(unblockUserFailure())
    }
  }
}

const fetchLivestreamForXDaysRequest = createAction(
  'livestream/fetchLivestreamEngagementDataRequest'
)
const fetchLivestreamForXDaysFailure = createAction(
  'livestream/fetchLivestreamForXDaysFailure'
)

export function fetchLivestreamForXDays(
  livestreamId: string,
  businessId: string,
  channelId: string,
  startDate?: string,
  endDate?: string,
  key?: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamForXDaysRequest())
      const res = await api.get(
        `/api/bus/${businessId}/channels/${channelId}/insights/live_stream/${livestreamId}/replay_insights?start_date=${startDate}&end_date=${endDate}`
      )
      if (key === 'last')
        dispatch(
          fetchLivestreamForLastXDaysSuccess({ ...res.data, livestreamId })
        )
      else if (key === 'after') {
        dispatch(
          fetchLivestreamForAfterXDaysSuccess({ ...res.data, livestreamId })
        )
        // use this data to balance data until 'last' data is available
        // dispatch(fetchLivestreamForLastXDaysSuccess({ ...res.data }))
      }
    } catch (error) {
      dispatch(
        fetchLivestreamForAfterXDaysSuccess({
          live_stream_replay_insights: [],
          livestreamId
        })
      )
      dispatch(fetchLivestreamForXDaysFailure())

      return error
    }
  }
}
const sortLivestreamProductsRequest = createAction(
  'livestream/sortLivestreamProductsRequest'
)
const sortLivestreamProductsFailure = createAction(
  'livestream/sortLivestreamProductsFailure'
)

export function sortLiveStreamProducts(
  livestreamId: string,
  data?: globalLib.Product[]
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(sortLivestreamProductsRequest())
      dispatch(sortLivestreamProductsSuccess({ livestreamId, products: data }))
      const productIds = data.map((product) => {
        return product.selected_product_unit_id
          ? {
              product_id: product.id,
              business_store_id: product.vendor_id,
              product_unit_id: product.selected_product_unit_id
            }
          : {
              product_id: product.id,
              business_store_id: product.vendor_id
            }
      })
      await api.post(`/live_streams/${livestreamId}/products`, {
        products: productIds
      })
    } catch (error) {
      dispatch(sortLivestreamProductsFailure())
    }
  }
}

const pinMessageRequest = createAction('livestream/pinMessageRequest')
const pinMessageFailure = createAction('livestream/pinMessageFailure')

export function pinMessage(
  livestreamId: string,
  text?: string,
  messageId?: string,
  username?: string,
  inserted_at?: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(pinMessageRequest())
      const res = await api.post(`/live_streams/${livestreamId}/pin_message`, {
        text,
        message_id: messageId,
        username,
        live_stream_id: livestreamId,
        inserted_at: inserted_at
      })
      if (validateResponseSuccess(res)) {
        dispatch(
          pinMessageSuccess({
            livestreamId,
            message: text,
            messageId,
            username
          })
        )
      }
    } catch (error) {
      dispatch(pinMessageFailure())
    }
  }
}

const unpinMessageRequest = createAction('livestream/unpinMessageRequest')
const unpinMessageFailure = createAction('livestream/unpinMessageFailure')

export function unpinMessage(
  livestreamId: string,
  text?: string,
  messageId?: string,
  username?: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(unpinMessageRequest())
      const data = {
        text,
        message_id: messageId,
        username,
        live_stream_id: livestreamId
      } as Record<string, unknown>
      const res = await api.delete(
        `/live_streams/${livestreamId}/pin_message`,
        data
      )
      if (validateResponseSuccess(res)) {
        dispatch(unpinMessageSuccess({ livestreamId }))
      }
    } catch (error) {
      dispatch(unpinMessageFailure())
    }
  }
}

const deleteMessageRequest = createAction('livestream/deleteMessageRequest')
const deleteMessageFailure = createAction('livestream/deleteMessageFailure')

export function deleteMessage(
  livestreamId: string,
  message?: globalLib.LivestreamMessage
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(deleteMessageRequest())
      const res = await api.delete(`/live_streams/${livestreamId}/message`, {
        data: {
          message_id: message.id,
          inserted_at: message.inserted_at
        }
      })
      if (validateResponseSuccess(res)) {
        dispatch(deleteMessageSuccess({ livestreamId, messageId: message.id }))
      }
    } catch (error) {
      dispatch(deleteMessageFailure())
    }
  }
}

const unDeleteMessageRequest = createAction('livestream/unDeleteMessageRequest')
const unDeleteMessageFailure = createAction('livestream/unDeleteMessageFailure')

export function unDeleteMessage(
  livestreamId: string,
  message?: globalLib.LivestreamMessage
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(unDeleteMessageRequest())
      const res = await api.put(`/live_streams/${livestreamId}/message`, {
        message_id: message.id,
        inserted_at: message.inserted_at
      })

      if (validateResponseSuccess(res)) {
        dispatch(
          unDeleteMessageSuccess({ livestreamId, messageId: message.id })
        )
      }
    } catch (e) {
      dispatch(unDeleteMessageFailure())
    }
  }
}

const fetchMessagesRequest = createAction('livestream/fetchMessagesRequest')
const fetchMessagesFailure = createAction('livestream/fetchMessagesFailure')

export function fetchMessages({
  livestreamId,
  insertedAt
}: {
  livestreamId: string
  insertedAt?: string
}) {
  return async (dispatch: Dispatch): Promise<{ hasNextPage: boolean }> => {
    try {
      dispatch(fetchMessagesRequest())
      const res = await api.get(
        `live_streams/${livestreamId}/moderated_messages`,
        { params: { inserted_at: insertedAt } }
      )
      const { entries, next_page } = res.data
      dispatch(fetchMessagesSuccess({ livestreamId, entries }))

      return { hasNextPage: Boolean(next_page) }
    } catch (e) {
      dispatch(fetchMessagesFailure())
    }
  }
}

const fetchTopProductsDataRequest = createAction(
  'livestream/fetchTopProductsDataRequest'
)
const fetchTopProductsDataFailure = createAction(
  'livestream/fetchTopProductsDataFailure'
)

export function fetchTopProductsData(
  businessId: string,
  channelId: string,
  livestreamId: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchTopProductsDataRequest())
      const res = await api.get(
        `/api/bus/${businessId}/channels/${channelId}/insights/live_stream/${livestreamId}/top_products`
      )
      dispatch(fetchTopProductsDataSuccess({ livestreamId, ...res.data }))
    } catch (error) {
      dispatch(fetchTopProductsDataFailure())
    }
  }
}

const fetchReplaysRequest = createAction('livestream/fetchReplaysRequest')
const fetchReplaysFailure = createAction('livestream/fetchReplaysFailure')

export function fetchReplays(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchReplaysRequest())
      const res = await api.get(`/live_streams/${livestreamId}/replays`)
      if (validateResponseSuccess(res)) {
        const replays = res.data
        dispatch(fetchReplaysSuccess({ livestreamId, replays }))
      }
    } catch (error) {
      dispatch(fetchReplaysFailure())
    }
  }
}

const updatePrimaryReplayRequest = createAction(
  'livestream/updatePrimaryReplayRequest'
)
const updatePrimaryReplayFailure = createAction(
  'livestream/updatePrimaryReplayFailure'
)

export function updatePrimaryReplay(
  livestreamId: string,
  replayId: string,
  startFromSeconds: number
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updatePrimaryReplayRequest())
      await api.put(`live_streams/${livestreamId}`, {
        primary_replay_id: replayId,
        skips_to: startFromSeconds
      })
      dispatch(
        updatePrimaryReplaySuccess({
          livestreamId,
          replayId,
          skips_to: startFromSeconds
        })
      )
    } catch (error) {
      dispatch(updatePrimaryReplayFailure())
    }
  }
}

const fetchLivestreamInteractionsRequest = createAction(
  'livestream/fetchLivestreamInteractionsRequest'
)
const fetchLivestreamInteractionsFailure = createAction(
  'livestream/fetchLivestreamInteractionsFailure'
)

export function fetchLivestreamInteractions(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamInteractionsRequest())
      const response = await api.get(
        `/live_streams/${livestreamId}/interactions`
      )
      dispatch(
        fetchLivestreamInteractionsSuccess({
          livestreamId,
          status: response.data.status,
          interactions: response.data.interactions
        })
      )

      return response.data
    } catch (error) {
      dispatch(fetchLivestreamInteractionsFailure())

      return error
    }
  }
}

const createLivestreamInteractionRequest = createAction(
  'channel/createLivestreamInteractionRequestRequest'
)
const createLivestreamInteractionFailure = createAction(
  'channel/createLivestreamInteractionFailure'
)

export function createLivestreamInteraction(
  livestreamId: string,
  data?: Partial<globalLib.VideoInteraction>
) {
  return async (dispatch: Dispatch): Promise<AxiosResponse> => {
    try {
      dispatch(createLivestreamInteractionRequest())
      const response = await api.post(
        `/live_streams/${livestreamId}/interactions`,
        sanitizeInteractionData(data)
      )
      dispatch(
        createLivestreamInteractionSuccess({
          livestreamId,
          interaction: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(createLivestreamInteractionFailure())

      return error
    }
  }
}

const updateLivestreamInteractionRequest = createAction(
  'channel/updateLivestreamInteractionRequestRequest'
)
const updateLivestreamInteractionFailure = createAction(
  'channel/updateLivestreamInteractionFailure'
)

export function updateLivestreamInteraction(
  livestreamId: string,
  interactionId: string,
  data?: Partial<globalLib.VideoInteraction>
) {
  return async (dispatch: Dispatch): Promise<AxiosResponse> => {
    try {
      dispatch(updateLivestreamInteractionRequest())
      const response = await api.patch(
        `/live_streams/${livestreamId}/interactions/${interactionId}`,
        sanitizeInteractionData(data)
      )
      dispatch(
        updateLivestreamInteractionSuccess({
          livestreamId,
          interaction: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(updateLivestreamInteractionFailure())

      return error
    }
  }
}

const deleteLivestreamInteractionRequest = createAction(
  'channel/deleteLivestreamInteractionRequestRequest'
)
const deleteLivestreamInteractionFailure = createAction(
  'channel/deleteLivestreamInteractionFailure'
)

export function deleteLivestreamInteraction(
  livestreamId: string,
  interactionIds?: string[],
  interactionId?: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(deleteLivestreamInteractionRequest())
      await api.post(`/live_streams/${livestreamId}/interactions/reorder`, {
        interaction_ids: interactionIds
      })
      dispatch(
        deleteLivestreamInteractionSuccess({ livestreamId, interactionId })
      )
    } catch (error) {
      dispatch(deleteLivestreamInteractionFailure())

      return error
    }
  }
}

const sortLivestreamInteractionsRequest = createAction(
  'livestream/sortLivestreamInteractionsRequest'
)
const sortLivestreamInteractionsFailure = createAction(
  'livestream/sortLivestreamInteractionsFailure'
)

export function sortLiveStreamInteractions(
  livestreamId: string,
  data?: globalLib.VideoInteraction[]
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(sortLivestreamInteractionsRequest())
      dispatch(
        sortLivestreamInteractionsSuccess({ livestreamId, interactions: data })
      )
      const interactionIds = data.map((interaction) => interaction.id)
      await api.post(`/live_streams/${livestreamId}/interactions/reorder`, {
        interaction_ids: interactionIds
      })
    } catch (error) {
      dispatch(sortLivestreamInteractionsFailure())
    }
  }
}

const fetchInteractionResultsRequest = createAction(
  'livestream/fetchInteractionResultsRequest'
)
const fetchInteractionResultsFailure = createAction(
  'livestream/fetchInteractionResultsFailure'
)

export function fetchInteractionResults(
  livestreamId: string,
  interactionId: string,
  page?: number
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchInteractionResultsRequest())

      let url = `/interactions/${interactionId}/list_responses`
      if (page) {
        url += `?page=${page}`
      }
      const response = await api.get(url)

      dispatch(
        fetchInteractionResultsSuccess({
          livestreamId,
          interactionId,
          responses: response.data.interaction_results
        })
      )
    } catch (error) {
      dispatch(fetchInteractionResultsFailure())
    }
  }
}

const fetchInteractionResultCSVRequest = createAction(
  'livestream/fetchInteractionResultCSVRequest'
)
const fetchInteractionResultCSVFailure = createAction(
  'livestream/fetchInteractionResultCSVFailure'
)
export function fetchInteractionResultCSV(interactionId: string) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(fetchInteractionResultCSVRequest())
      const url = `/interactions/${interactionId}/responses_csv`
      const response = await api.get(url)

      return response.data
    } catch (error) {
      dispatch(fetchInteractionResultCSVFailure())
    }
  }
}

const sendInvitationRequest = createAction('livestream/sendInvitationRequest')
const sendInvitationFailure = createAction('livestream/sendInvitationFailure')

export function sendInvitation(
  livestreamId: string,
  data?: Partial<globalLib.LivestreamInvite>
) {
  return async (dispatch: Dispatch): Promise<AxiosResponse> => {
    try {
      dispatch(sendInvitationRequest())
      const response = await api.post(
        `/live_streams/${livestreamId}/invitations`,
        {
          ...data
        }
      )
      dispatch(
        sendInvitationSuccess({
          invitationDetails: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(sendInvitationFailure())

      return error
    }
  }
}

const fetchLivestreamInvitationListRequest = createAction(
  'livestream/fetchLivestreamInvitationListRequest'
)
const fetchLivestreamInvitationListFailure = createAction(
  'livestream/fetchLivestreamInvitationListFailure'
)

export function fetchLiveStreamInvitations(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamInvitationListRequest())
      const response = await api.get(
        `/live_streams/${livestreamId}/invitations`
      )

      dispatch(
        fetchLivestreamInvitationListSuccess({
          invitations: response.data
        })
      )
    } catch (error) {
      dispatch(fetchLivestreamInvitationListFailure())

      return error
    }
  }
}

const revokeLivestreamInvitationRequest = createAction(
  'livestream/revokeLivestreamInvitationRequest'
)

const revokeLivestreamInvitationFailure = createAction(
  'livestream/revokeLivestreamInvitationFailure'
)

export function revokeLivestreamInvitation(
  livestreamId: string,
  invitationId: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(revokeLivestreamInvitationRequest())
      await api.get(
        `/live_streams/${livestreamId}/invitations/${invitationId}/revoke`
      )
    } catch (error) {
      dispatch(revokeLivestreamInvitationFailure())
    }
  }
}

const bringOnAirRequest = createAction('livestream/bringOnAirRequest')
const bringOnAirFailure = createAction('livestream/bringOnAirFailure')

export function bringOnAir(livestreamId: string, visitor_id: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(bringOnAirRequest())

      return await api.post(
        `/live_streams/${livestreamId}/bring_on_air/${visitor_id}`
      )
    } catch (error) {
      dispatch(bringOnAirFailure())

      return error
    }
  }
}

const removeFromStageRequest = createAction('livestream/removeFromStageRequest')
const removeFromStageFailure = createAction('livestream/removeFromStageFailure')

export function removeFromStage(livestreamId: string, visitor_id: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(removeFromStageRequest())

      return await api.post(
        `/live_streams/${livestreamId}/bring_off_air/${visitor_id}`
      )
    } catch (error) {
      dispatch(removeFromStageFailure())

      return error
    }
  }
}

const removeAndBanFromStreamRequest = createAction(
  'livestream/removeAndBanFromStreamRequest'
)
const removeAndBanFromStreamFailure = createAction(
  'livestream/removeAndBanFromStreamFailure'
)

export function removeAndBanFromStream(
  livestreamId: string,
  visitor_id: string
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(removeAndBanFromStreamRequest())

      return await api.post(
        `/live_streams/${livestreamId}/permanent_ban/${visitor_id}`
      )
    } catch (error) {
      dispatch(removeAndBanFromStreamFailure())

      return error
    }
  }
}

export const createSubtitles = async (
  livestreamId: string,
  data?:
    | Partial<globalLib.Subtitle>
    | { subtitles: Partial<globalLib.Subtitle>[] }
): Promise<void> => {
  try {
    await api.post(`/live_streams/${livestreamId}/subtitles`, data)
  } catch (error) {
    return error
  }
}

export const deleteSubtitle = async (
  livestreamId: string,
  subtitleId: string
): Promise<void> => {
  try {
    await api.delete(`/live_streams/${livestreamId}/subtitles/${subtitleId}`)
  } catch (error) {
    return error
  }
}

const updateModeratorMessageRequest = createAction(
  'livestream/updateModeratorMessageRequest'
)

const updateModeratorMessageFailure = createAction(
  'livestream/updateModeratorMessageFailure'
)

export function updateModeratorMessage(
  livestreamId: string,
  data?: {
    messageId: string
    text: string
    inserted_at: string
  }
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateModeratorMessageRequest())
      await api.patch(
        `/live_streams/${livestreamId}/message/${data.messageId}`,
        {
          text: data.text,
          inserted_at: data.inserted_at ?? undefined
        }
      )
      dispatch(
        updateModeratorMessageSuccess({
          livestreamId,
          message: {
            id: data.messageId,
            text: data.text,
            inserted_at: data.inserted_at
          }
        })
      )
    } catch (error) {
      dispatch(updateModeratorMessageFailure())

      return error
    }
  }
}

const deleteProductDealRequest = createAction(
  'livestream/deleteProductDealRequest'
)

const deleteProductDealFailure = createAction(
  'livestream/deleteProductDealFailure'
)

export function deleteProductDeal(livestreamId: string, productId: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(deleteProductDealRequest())
      await api.delete(`/live_streams/${livestreamId}/deals/${productId}`)
      dispatch(deleteProductDealSuccess({ livestreamId, productId }))
    } catch (error) {
      dispatch(deleteProductDealFailure())
    }
  }
}

const createLivestreamDealRequest = createAction(
  'livestream/createLivestreamDealRequest'
)

const createLivestreamDealFailure = createAction(
  'livestream/createLivestreamDealFailure'
)

export function createLivestreamDeal(
  livestreamId: string,
  params: globalLib.FlashDeal
) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(createLivestreamDealRequest())

      const response = await api.post(
        `/live_streams/${livestreamId}/deals`,
        params
      )
      dispatch(
        createLivestreamDealSuccess({
          deal: response.data,
          livestreamId
        })
      )

      return response
    } catch (error) {
      dispatch(createLivestreamDealFailure())

      return error
    }
  }
}

const updateLivestreamDealRequest = createAction(
  'livestream/updateLivestreamDealRequest'
)

const updateLivestreamDealFailure = createAction(
  'livestream/updateLivestreamDealFailure'
)

export function updateLivestreamDeal(
  livestreamId: string,
  dealId: string,
  params: globalLib.FlashDeal
) {
  return async (
    dispatch: Dispatch
  ): Promise<AxiosResponse<globalLib.FlashDeal>> => {
    try {
      dispatch(updateLivestreamDealRequest())
      const response = await api.patch(
        `/live_streams/${livestreamId}/deals/${dealId}`,
        params
      )
      dispatch(
        updateLivestreamDealSuccess({
          deal: response.data,
          livestreamId
        })
      )

      return response
    } catch (error) {
      dispatch(updateLivestreamDealFailure())

      return error
    }
  }
}

const requestDownloadReplayRequest = createAction(
  'livestream/requestDownloadReplayRequest'
)
const requestDownloadReplayFailure = createAction(
  'livestream/requestDownloadReplayFailure'
)

export function requestDownloadReplay(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<boolean> => {
    dispatch(requestDownloadReplayRequest())
    try {
      const res = await api.patch(
        `/live_streams/${livestreamId}/generate_replay_download`
      )
      if (validateResponseSuccess(res)) {
        dispatch(requestDownloadReplaySuccess({ livestreamId }))

        return true
      } else {
        dispatch(requestDownloadReplayFailure())

        return false
      }
    } catch (error) {
      dispatch(requestDownloadReplayFailure())

      return false
    }
  }
}

const updateLimitRateLimitSettingsRequest = createAction(
  'livestream/updateLimitRateLimitSettingsRequest'
)

const updateLimitRateLimitSettingsFailure = createAction(
  'livestream/updateLimitRateLimitSettingsFailure'
)

export function updateMessageRateLimitSettings(
  businessId: string,
  channelId: string,
  videoId: string,
  settings?: globalLib.LivestreamMessageRateLimit
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(updateLimitRateLimitSettingsRequest())
      const response = await api.patch(
        `/bus/${businessId}/channels/${channelId}/videos/${videoId}`,
        {
          message_rate_limit: settings
        }
      )
      const { data } = response
      const { live_stream_id } = data

      dispatch(
        updateLimitRateLimitSettingsSuccess({
          livestreamId: live_stream_id,
          message_rate_limit_settings: settings
        })
      )
    } catch (error) {
      dispatch(updateLimitRateLimitSettingsFailure())

      return error
    }
  }
}

const fetchLivestreamMessageSearchRequest = createAction(
  'livestream/fetchLivestreamMessageSearchRequest'
)

const fetchLivestreamMessageSearchFailure = createAction(
  'livestream/fetchLivestreamMessageSearchFailure'
)

export function fetchLivestreamMessageSearch(
  livestreamId: string,
  query: string,
  page?: globalLib.Paging
) {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(fetchLivestreamMessageSearchRequest())
      const requestUrl =
        page?.next ||
        `/live_streams/${livestreamId}/messages/search?page_size=100&text=${query}`
      const response = await api.get(requestUrl)
      const {
        data: { messages, paging }
      } = response

      dispatch(updateLivestreamMessageSearchResults({ messages, paging, page }))
    } catch (error) {
      dispatch(fetchLivestreamMessageSearchFailure())

      return error
    }
  }
}

interface DownloadChatMessagesParams {
  livestreamId: string
  translations?: boolean
}

const fetchChatMessagesCSVRequest = createAction(
  'livestream/fetchChatMessagesCSVRequest'
)
const fetchChatMessagesCSVFailure = createAction(
  'livestream/fetchChatMessagesCSVFailure'
)
export function fetchChatMessagesCSV({
  livestreamId,
  translations = false
}: DownloadChatMessagesParams) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(fetchChatMessagesCSVRequest())
      const url = `/live_streams/${livestreamId}/messages/download`
      const response = await api.get(url, { params: { translations } })

      return response.data
    } catch (error) {
      dispatch(fetchChatMessagesCSVFailure())
    }
  }
}

const uploadChatMessagesCSVRequest = createAction(
  'livestream/uploadChatMessagesCSVRequest'
)
const uploadChatMessagesCSVFailure = createAction(
  'livestream/uploadChatMessagesCSVFailure'
)
export function uploadChatMessagesCSV(livestreamId: string, key: string) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(uploadChatMessagesCSVRequest())
      const url = `/live_streams/${livestreamId}/messages/upload_translated`
      const response = await api.post(url, {
        key
      })

      return response
    } catch (error) {
      dispatch(uploadChatMessagesCSVFailure())

      return error
    }
  }
}

const deleteChatMessagesCSVRequest = createAction(
  'livestream/deleteChatMessagesCSVRequest'
)
const deleteChatMessagesCSVFailure = createAction(
  'livestream/deleteChatMessagesCSVFailure'
)
export function deleteChatMessagesCSV(livestreamId: string, key: string) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(deleteChatMessagesCSVRequest())
      const url = `/live_streams/${livestreamId}/messages/upload_translated`
      const response = await api.delete(url, {
        data: { key }
      })

      return response
    } catch (error) {
      dispatch(deleteChatMessagesCSVFailure())

      return error
    }
  }
}

const fetchProductPinsCSVRequest = createAction(
  'livestream/fetchProductPinsCSVRequest'
)
const fetchProductPinsCSVFailure = createAction(
  'livestream/fetchProductPinsCSVFailure'
)
export function fetchProductPinsCSV(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(fetchProductPinsCSVRequest())
      const url = `/live_streams/${livestreamId}/actions/download`
      const response = await api.get(url)

      return response.data
    } catch (error) {
      dispatch(fetchProductPinsCSVFailure())
    }
  }
}

const uploadProductPinsCSVRequest = createAction(
  'livestream/uploadProductPinsCSVRequest'
)
const uploadProductPinsCSVFailure = createAction(
  'livestream/uploadProductPinsCSVFailure'
)
export function uploadProductPinsCSV(livestreamId: string, key: string) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(uploadProductPinsCSVRequest())
      const url = `/live_streams/${livestreamId}/actions/upload`
      const response = await api.post(url, {
        key
      })

      return response
    } catch (error) {
      dispatch(uploadProductPinsCSVFailure())

      return error
    }
  }
}

const deleteProductPinsCSVRequest = createAction(
  'livestream/deleteProductPinsCSVRequest'
)
const deleteProductPinsCSVFailure = createAction(
  'livestream/deleteProductPinsCSVFailure'
)
export function deleteProductPinsCSV(livestreamId: string, key: string) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(deleteProductPinsCSVRequest())
      const url = `/live_streams/${livestreamId}/actions/upload`
      const response = await api.delete(url, {
        data: { key }
      })

      return response
    } catch (error) {
      dispatch(deleteProductPinsCSVFailure())

      return error
    }
  }
}

const fetchRemindMeContactsCSVRequest = createAction(
  'livestream/fetchRemindMeContactsCSVRequest'
)
const fetchRemindMeContactsCSVFailure = createAction(
  'livestream/fetchRemindMeContactsCSVFailure'
)
export function fetchRemindMeContactsCSV(livestreamId: string) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(fetchRemindMeContactsCSVRequest())
      const url = `/live_streams/${livestreamId}/signups/download`

      return await api.get(url, {
        headers: {
          Accept: 'text/html'
        }
      })
    } catch (error) {
      dispatch(fetchRemindMeContactsCSVFailure())
    }
  }
}

const fetchRecurrencesByIdsRequest = createAction(
  'livestream/fetchRecurrencesByIdsRequest'
)
const fetchRecurrencesByIdsFailure = createAction(
  'livestream/fetchRecurrencesByIdsFailure'
)

interface FetchRecurrencesByIdsParams {
  businessId: string
  channelId: string
  ids: string[]
}

export function fetchRecurrencesByIds({
  businessId,
  channelId,
  ids
}: FetchRecurrencesByIdsParams) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(fetchRecurrencesByIdsRequest())
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/recurrences`,
        {
          params: {
            ids
          }
        }
      )

      dispatch(fetchRecurrencesByIdsSuccess(response.data))
    } catch (error) {
      dispatch(fetchRecurrencesByIdsFailure())
    }
  }
}

interface CreateRecurrenceParams {
  by_day?: string[] | null
  ends_at: string
  exclusions: null
  frequency: 'DAILY' | 'WEEKLY'
  ref_video_id: string
  repost_comments: 'all_comments' | null
  starts_at: string
  playlist_ids: string[]
  time_zone: string
  title: string
  video_source: 'specific_live_stream'
}

const createRecurrenceRequest = createAction(
  'livestream/createRecurrenceRequest'
)
const createRecurrenceFailure = createAction(
  'livestream/createRecurrenceFailure'
)
export function createRecurrence(
  businessId: string,
  channelId: string,
  data?: CreateRecurrenceParams
) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(createRecurrenceRequest())

      return await api.post(
        `/bus/${businessId}/channels/${channelId}/recurrences`,
        data
      )
    } catch (error) {
      dispatch(createRecurrenceFailure())

      return error
    }
  }
}

const deleteRecurrenceRequest = createAction(
  'livestream/deleteRecurrenceRequest'
)
const deleteRecurrenceFailure = createAction(
  'livestream/deleteRecurrenceFailure'
)

export function deleteRecurrence(
  businessId: string,
  channelId: string,
  recurrenceId: string
) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(deleteRecurrenceRequest())

      return await api.delete(
        `/bus/${businessId}/channels/${channelId}/recurrences/${recurrenceId}`
      )
    } catch (error) {
      dispatch(deleteRecurrenceFailure())

      return error
    }
  }
}

const fetchRecurrencesForChannelRequest = createAction(
  'livestream/fetchRecurrencesForChannelRequest'
)
const fetchRecurrencesForChannelFailure = createAction(
  'livestream/fetchRecurrencesForChannelFailure'
)

export function fetchRecurrencesForChannel(
  businessId: string,
  channelId: string,
  params: { before: string }
) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(fetchRecurrencesForChannelRequest())
      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/recurrences`,
        {
          params
        }
      )

      dispatch(
        fetchRecurrencesForChannelSuccess({
          recurrences: response.data.events,
          channelId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchRecurrencesForChannelFailure())

      return error
    }
  }
}

const updateRecurrenceRequest = createAction(
  'livestream/updateRecurrenceRequest'
)
const updateRecurrenceFailure = createAction(
  'livestream/updateRecurrenceFailure'
)
export function updateRecurrence(
  businessId: string,
  channelId: string,
  recurrenceId: string,
  data?: any
) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(updateRecurrenceRequest())

      return await api.patch(
        `/bus/${businessId}/channels/${channelId}/recurrences/${recurrenceId}`,
        data
      )
    } catch (error) {
      dispatch(updateRecurrenceFailure())

      return error
    }
  }
}

const fetchRecurrenceByIdRequest = createAction(
  'livestream/fetchRecurrenceByIdRequest'
)
const fetchRecurrenceByIdFailure = createAction(
  'livestream/fetchRecurrenceByIdFailure'
)
export function fetchRecurrenceById(
  businessId: string,
  channelId: string,
  recurrenceId: string
) {
  return async (dispatch: Dispatch): Promise<any> => {
    try {
      dispatch(fetchRecurrenceByIdRequest())

      const response = await api.get(
        `/bus/${businessId}/channels/${channelId}/recurrences/${recurrenceId}`
      )

      dispatch(fetchRecurrenceByIdSuccess({ recurrence: response.data }))

      return response
    } catch (error) {
      dispatch(fetchRecurrenceByIdFailure())

      return error
    }
  }
}
