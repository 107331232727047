import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const InteractiveVideo = loadable(() => import('../../layout/Journey'), {
  resolveComponent: (component) => component.InteractiveVideo
})

const FlowDetails = loadable(
  () => import('../../pages/Library/Journey/FlowDetails'),
  {
    resolveComponent: (component) => component.FlowDetails
  }
)

const EmbedFlow = loadable(
  () => import('../../pages/Library/Journey/EmbedFlow'),
  {
    resolveComponent: (component) => component.EmbedFlow
  }
)

const PreviewFlow = loadable(
  () => import('../../pages/Library/Journey/PreviewFlow'),
  {
    resolveComponent: (component) => component.PreviewFlow
  }
)

const FlowAnalytics = loadable(
  () => import('../../pages/Library/Journey/FlowAnalytics'),
  {
    resolveComponent: (component) => component.FlowAnalytics
  }
)

export const JourneyRoutes = (
  <Route
    path="/business/:businessId/channel/:channelId/flows/:flowId"
    element={<InteractiveVideo />}
  >
    <Route path="" element={<FlowDetails />} />
    <Route path="embed" element={<EmbedFlow />} />
    <Route path="preview" element={<PreviewFlow />} />
    <Route path="analytics" element={<FlowAnalytics />} />
  </Route>
)
