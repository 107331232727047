import { useCallback, useContext } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { BusinessContext } from '@src/common/contexts/BusinessContext'

import { PERMISSIONS } from './useRolesAndPermissions'

export const CASE_PERMISSIONS = {
  LIVE_STREAM_GO_LIVE: 'live_stream_go_live',
  LIVE_STREAM_EDIT: 'live_stream_edit',
  CREATE_SHORT_CLIP: 'create_short_clip',
  SELECT_REPLAY: 'SELECT_REPLAY',
  CHANNEL_INSIGHTS: 'channel_insights',
  CHANNEL_MORE_TAB: 'channel_more_tab'
}

type B2BCase =
  | 'seller-in-linked-aggregator'
  | 'seller-in-linked-marketplace'
  | 'seller'
  | 'aggregator-in-same-business'
  | 'aggregator-in-cross-business'
  | 'marketplace'
  | 'aggregator-in-same-business-in-linked-channel'
  | 'aggregator-in-cross-business-in-linked-channel'
  | 'marketplace-in-linked-channel'

type B2BChannelCase =
  | 'seller-in-linked-aggregator'
  | 'seller-in-linked-marketplace'
  | 'seller'
  | 'aggregator'
  | 'marketplace'
  | 'aggregator-in-same-business-in-linked-channel'
  | 'aggregator-in-cross-business-in-linked-channel'
  | 'marketplace-in-linked-channel'

const disablePermission = {
  [PERMISSIONS.VIDEOS_EDIT]: ['aggregator-in-cross-business'],
  [PERMISSIONS.VIDEO_INSIGHTS]: ['seller-in-linked-marketplace'],
  [PERMISSIONS.VIDEOS_CHANNEL_DELETE]: [
    'marketplace',
    'aggregator-in-cross-business',
    'aggregator-in-same-business'
  ],
  [CASE_PERMISSIONS.LIVE_STREAM_GO_LIVE]: [
    'marketplace',
    'aggregator-in-cross-business'
  ],
  [CASE_PERMISSIONS.LIVE_STREAM_EDIT]: [
    'marketplace',
    'aggregator-in-cross-business'
  ],
  [PERMISSIONS.REPOST_VIDEO]: ['aggregator-in-cross-business', 'marketplace'],
  [CASE_PERMISSIONS.CREATE_SHORT_CLIP]: [
    'marketplace',
    'aggregator-in-cross-business',
    'aggregator-in-same-business'
  ],
  [CASE_PERMISSIONS.SELECT_REPLAY]: [
    'marketplace',
    'aggregator-in-cross-business',
    'aggregator-in-same-business'
  ],
  [CASE_PERMISSIONS.CHANNEL_INSIGHTS]: ['seller-in-linked-marketplace'],
  [CASE_PERMISSIONS.CHANNEL_MORE_TAB]: [
    'aggregator-in-cross-business-in-linked-channel',
    'aggregator-in-same-business-in-linked-channel',
    'seller-in-linked-marketplace',
    'marketplace-in-linked-channel'
  ]
}

export const useSyndication = (channelId?: string) => {
  const businessId = useContext(BusinessContext)

  const { username, aggregator, marketplace, linked_to } = useAppSelector(
    (state) => state.channel.channels[channelId] || {}
  )

  const linkedChannelForAggregator = useAppSelector(
    (state) => state.marketplace.activeLinkedChannel?.channel || null
  )

  const b2bCase: (video: globalLib.IVideo) => B2BCase | undefined = (
    video: globalLib.IVideo
  ) => {
    if (!video) {
      return undefined
    }
    if (video.creator.username === username) {
      if (video.creator.linked_to === 'aggregator') {
        return 'seller-in-linked-aggregator'
      } else if (video.creator.linked_to === 'marketplace') {
        return 'seller-in-linked-marketplace'
      }

      return undefined
    } else {
      if (video.creator.business_id === businessId) {
        if (video.creator.linked_to === 'aggregator' && aggregator) {
          return 'aggregator-in-same-business'
        } else {
          return undefined
        }
      } else {
        if (aggregator) {
          if (video.creator.linked_to === 'aggregator') {
            return 'aggregator-in-cross-business'
          } else if (video.creator.linked_to === 'marketplace') {
            return 'marketplace'
          }
        }

        return undefined
      }
    }
  }

  /**
   * Check if the video is available for the permission
   * @param video video to check
   * @param permission permission to check
   * @param availableCallback callback when the permission is available
   * @returns true if the video is available for the permission
   */
  const caseAvailableWithSyndicationContent = (
    video: globalLib.IVideo,
    permission,
    availableCallback?: () => void
  ) => {
    const b2bCaseValue = b2bCase(video)
    const available =
      !b2bCaseValue ||
      (!disablePermission[permission]?.includes(b2bCaseValue) ?? true)

    if (available && availableCallback) {
      availableCallback()
    }

    return available
  }

  // The channel is a linked channel if it is linked to a marketplace channel
  const isLinkedChannel = useAppSelector(
    (state) =>
      !!state.marketplace.activeLinkedChannel?.channel ||
      (channelId && state.channel.channels[channelId]?.linked)
  )

  const isLinkedToMarketplace = useAppSelector(
    (state) =>
      channelId &&
      state.channel.channels[channelId]?.linked &&
      state.channel.channels[channelId]?.linked_to === 'marketplace'
  )

  const isAggregatorInLinkedChannel = useAppSelector(
    (state) => !!state.marketplace.activeLinkedChannel?.channel
  )

  const isAggregatorChannel = useAppSelector(
    (state) =>
      channelId &&
      !state.marketplace.activeLinkedChannel?.channel &&
      state.channel.channels[channelId]?.aggregator
  )

  const isProductsFromLinkedStore = (video?: globalLib.IVideo): boolean => {
    if (!video) {
      return isLinkedChannel
    }
    const b2bCaseValue = b2bCase(video)

    return ['seller-in-linked-marketplace', 'marketplace'].includes(
      b2bCaseValue
    )
  }

  const bulkSelections = useAppSelector(
    (state) => state.bulkVideoSelection.selections
  )

  const caseAvailableForSelectionWithSynContent = (permission: string) => {
    return !bulkSelections.some(
      (selection) => !caseAvailableWithSyndicationContent(selection, permission)
    )
  }

  const b2bCaseForChannel = useCallback((): B2BChannelCase | undefined => {
    if (linkedChannelForAggregator) {
      if (linkedChannelForAggregator.linked_to === 'marketplace') {
        return 'marketplace-in-linked-channel'
      }
      if (linkedChannelForAggregator.business_id === businessId) {
        return 'aggregator-in-same-business-in-linked-channel'
      } else {
        return 'aggregator-in-cross-business-in-linked-channel'
      }
    } else {
      if (aggregator) {
        if (marketplace) {
          return 'marketplace'
        }

        return 'aggregator'
      } else {
        if (linked_to === 'marketplace') {
          return 'seller-in-linked-marketplace'
        }
        if (linked_to === 'aggregator') {
          return 'seller-in-linked-aggregator'
        }

        return undefined
      }
    }
  }, [
    aggregator,
    businessId,
    linkedChannelForAggregator,
    linked_to,
    marketplace
  ])

  const caseAvailableForChannel = useCallback(
    (permission: string) => {
      const b2bChannelCase = b2bCaseForChannel()
      const available =
        !b2bChannelCase ||
        (!disablePermission[permission]?.includes(b2bChannelCase) ?? true)

      return available
    },
    [b2bCaseForChannel]
  )

  return {
    b2bCase,
    caseAvailableWithSyndicationContent,
    isLinkedChannel,
    isLinkedToMarketplace,
    isAggregatorInLinkedChannel,
    isAggregatorChannel,
    caseAvailableForSelectionWithSynContent,
    caseAvailableForChannel,
    isProductsFromLinkedStore
  }
}
