import {
  createAsyncThunk,
  createSlice,
  Slice,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import moment from 'moment'

type AutomationState = {
  automations: globalLib.Automation[]
  automation: globalLib.Automation
  paging: globalLib.Paging
  logs: globalLib.AutomationLogs[]
  logsPaging: globalLib.Paging
  loading: boolean
}

const SLICE_NAME = 'automation'

export const fetchAutomationsByChannelId = createAsyncThunk(
  `${SLICE_NAME}/fetchAutomationsByChannelId`,
  async (params: {
    businessId: string
    channelId: string
    page?: globalLib.Paging
  }) => {
    const response = await api.get(
      params?.page?.next ||
        `/bus/${params.businessId}/channels/${params.channelId}/automations`,
      {
        params: {
          page_size: 5
        }
      }
    )
    const { data: automations, paging } = response.data

    return { automations, paging, page: params.page }
  }
)

export const createAutomationByChannelId = createAsyncThunk(
  `${SLICE_NAME}/createAutomationByChannelId`,
  async (params: {
    businessId: string
    channelId: string
    automation: globalLib.AutomationPayload
  }) => {
    const response = await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/automations`,
      params.automation
    )

    return response
  }
)

export const fetchSingleAutomationById = createAsyncThunk(
  `${SLICE_NAME}/fetchSingleAutomationById`,
  async (params: {
    businessId: string
    channelId: string
    automationId: string
  }) => {
    const response = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/automations/${params.automationId}`
    )
    const { data: automation } = response.data

    return automation
  }
)

export const deleteAutomationById = createAsyncThunk(
  `${SLICE_NAME}/deleteAutomationById`,
  async (params: {
    businessId: string
    channelId: string
    automationId: string
  }) => {
    await api.delete(
      `/bus/${params.businessId}/channels/${params.channelId}/automations/${params.automationId}`
    )
  }
)

export const fetchAutomationLogsByChannelId = createAsyncThunk(
  `${SLICE_NAME}/fetchAutomationLogsByChannelId`,
  async (params: {
    businessId: string
    channelId: string
    page?: globalLib.Paging
  }) => {
    const response = await api.get(
      params.page?.next ||
        `/bus/${params.businessId}/channels/${params.channelId}/automations/all/results`
    )
    const { data: logs, paging } = response.data

    return { logs, logsPaging: paging, page: params.page }
  }
)

export const updateAutomationStatusById = createAsyncThunk(
  `${SLICE_NAME}/updateAutomationStatusById`,
  async (params: {
    businessId: string
    channelId: string
    automationId: string
    status: boolean
  }) => {
    await api.put(
      `/bus/${params.businessId}/channels/${params.channelId}/automations/${params.automationId}`,
      {
        is_active: params.status
      }
    )
  }
)

export const runAutomationById = createAsyncThunk(
  `${SLICE_NAME}/runAutomationById`,
  async (params: {
    businessId: string
    channelId: string
    automationId: string
  }) => {
    await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/automations/${params.automationId}/run`
    )
  }
)

const initialState: AutomationState = {
  automations: [],
  automation: null,
  paging: null,
  logs: [],
  logsPaging: null,
  loading: false
}

const slice: Slice<
  AutomationState,
  SliceCaseReducers<AutomationState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAutomationsByChannelId.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAutomationsByChannelId.rejected, (state) => {
        state.loading = false
      })
      .addCase(fetchAutomationsByChannelId.fulfilled, (state, action) => {
        const { automations, paging, page } = action.payload
        if (page) {
          state.automations = state.automations.concat(automations)
        } else {
          state.automations = automations
        }
        state.paging = paging
        state.loading = false
      })
      .addCase(fetchSingleAutomationById.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchSingleAutomationById.fulfilled, (state, action) => {
        const automation = action.payload
        if (automation) state.automation = automation
        state.loading = false
      })
      .addCase(fetchSingleAutomationById.rejected, (state) => {
        state.loading = false
      })
      .addCase(deleteAutomationById.fulfilled, (state) => {
        state.automation = null
      })
      .addCase(fetchAutomationLogsByChannelId.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchAutomationLogsByChannelId.fulfilled, (state, action) => {
        const { logs, logsPaging, page } = action.payload
        const automationLogs = logs.map((log) => ({
          ...log,
          inserted_at: moment(new Date(log.inserted_at)).format(
            'MMM D, YYYY  hh:mm A'
          )
        }))
        if (page) {
          state.logs = state.logs.concat(automationLogs)
        } else {
          state.logs = automationLogs
        }
        state.logsPaging = logsPaging
        state.loading = false
      })
      .addCase(fetchAutomationLogsByChannelId.rejected, (state) => {
        state.loading = false
      })
      .addCase(updateAutomationStatusById.fulfilled, (state, action) => {
        const { automationId, status } = action.meta.arg
        const automation = state.automations.find(
          (automation) => automation.id === automationId
        )
        if (automation) automation.is_active = status
      })
      .addCase(runAutomationById.fulfilled, (state) => {
        state.loading = false
      })
  }
})

export default slice.reducer
