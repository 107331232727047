import { ProductOptions } from '@src/pages/channel/ChannelVideoUpload/components/VU.types'

export interface Option {
  product_id: string
  product_unit_id?: string
  business_store_id?: string
}
export function convertOptionArray(input: ProductOptions[]): Option[] {
  return input.map((option) => {
    return {
      product_id: option.id,
      product_unit_id: option.unitId,
      business_store_id: option.businessStoreId
    }
  })
}
