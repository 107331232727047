import React, { SetStateAction, useCallback, useMemo, useState } from 'react'

import { fetchProducts, searchProducts } from '@src/redux/businessStore'
import { useDispatch } from 'react-redux'

export const SORT_OPTIONS = {
  NAME_ASC: 'name_asc',
  NAME_DESC: 'name_desc',
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
  RELEVANCE: null
}

export const PRICE_FILTER = {
  GREATER_THAN: 'gt',
  LESS_THAN: 'lt'
}

type useSearchReturn = {
  handleSearchReset: () => Promise<void>
  handleEnterKey: (e: React.SyntheticEvent<HTMLInputElement>) => void
  updatePrice: (e: React.SyntheticEvent<HTMLInputElement>) => void
  onSearch: (value: string) => void
  priceFilter: null | string
  setPriceFilter: React.Dispatch<SetStateAction<null | string>>
  price: null | string
  sort: string
  setSort: React.Dispatch<SetStateAction<string>>
  searchTerm: null | string
  updateSearchTerm: React.Dispatch<SetStateAction<string>>
  handleSearch: () => Promise<void>
  clearFilter: () => Promise<void>
  loading: boolean
  accesses?: string
  setAccesses: React.Dispatch<SetStateAction<null | string>>
  isReset: boolean
}

type Props = {
  businessId: string
  storeId: string
  channelId?: string
}
export function useSearchProduct({
  businessId,
  storeId,
  channelId
}: Props): useSearchReturn {
  const dispatch = useDispatch()

  const [searchTerm, setSearchTerm] = useState<null | string>(null)
  const [priceFilter, setPriceFilter] = useState<null | string>(
    PRICE_FILTER.GREATER_THAN
  )
  const [price, setPrice] = useState<null | string>('0')
  const [sort, setSort] = useState<string>(SORT_OPTIONS.RELEVANCE)
  const [loading, setLoading] = useState(false)
  const [accesses, setAccesses] = useState<string>('public,private')

  const isReset = useMemo(() => {
    return (
      price === '0' &&
      !searchTerm &&
      priceFilter === PRICE_FILTER.GREATER_THAN &&
      sort === SORT_OPTIONS.RELEVANCE
    )
  }, [price, searchTerm, priceFilter, sort])

  const handleSearchReset = useCallback(async () => {
    await clearFilter()
    await dispatch(
      fetchProducts({
        businessId: businessId,
        storeId: storeId,
        channelId: channelId,
        accesses: accesses,
        page: null,
        reset: true
      })
    )
  }, [accesses, businessId, dispatch, storeId, channelId])

  const handleSearch = useCallback(async () => {
    setLoading(true)
    await dispatch(
      searchProducts({
        storeId: storeId,
        businessId: businessId,
        searchTerm: searchTerm,
        priceFilter: priceFilter,
        price: price,
        sort: sort,
        accesses,
        channelId
      })
    )
    setLoading(false)
  }, [
    businessId,
    dispatch,
    price,
    priceFilter,
    searchTerm,
    sort,
    storeId,
    accesses,
    channelId
  ])

  const handleEnterKey = async (
    searchInput: React.SyntheticEvent<HTMLInputElement>
  ) => {
    const searchValue = searchInput.currentTarget.value

    if (searchValue.length) {
      await handleSearch()
    }
  }

  const updatePrice = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setPrice(e.currentTarget.value)
  }

  const updateSearchTerm = async (value: string) => {
    setSearchTerm(value)
  }

  const clearFilter = async () => {
    setPrice('0')
    setPriceFilter(PRICE_FILTER.GREATER_THAN)
    setSort(SORT_OPTIONS.RELEVANCE)
  }

  const onSearch = useCallback(
    async (searchValue) => {
      if (searchValue?.length) {
        await handleSearch()
      }
    },
    [handleSearch]
  )

  return {
    handleSearchReset,
    handleEnterKey,
    updatePrice,
    onSearch,
    priceFilter,
    setPriceFilter,
    price,
    sort,
    setSort,
    searchTerm,
    updateSearchTerm,
    handleSearch,
    clearFilter,
    loading,
    accesses,
    setAccesses,
    isReset
  }
}
