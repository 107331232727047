import styled from '@emotion/styled'
import { FWModal as SegmentModal } from '@src/components'

export const FWModal = styled(SegmentModal)`
  .ant-modal-body {
    padding: 0;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const FooterLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`

export const FooterRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

export const BodyContainer = styled.div`
  display: flex;
  height: 70vh;
  min-height: 480px;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  gap: 24px;
  padding: 24px 24px;
`

export const BodyLeftContainer = styled.div<{ flex: number }>`
  min-width: 332px;
  flex: ${(props) => props.flex};
  display: flex;
  flex-direction: row;
  height: 100%;
`

export const BodyRightContainer = styled.div<{ flex: number }>`
  display: flex;
  min-width: 360px;
  flex: ${(props) => props.flex};
  flex-direction: column;
  padding-top: 18px;
  row-gap: 24px;
  overflow: hidden;
`

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const MediaInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
  border-bottom: 1px solid #e1e4e8;
`

export const MediaInfoLeftContainer = styled.div`
  display: flex;
  column-gap: 16px;
  flex-direction: row;
  align-items: center;
`

export const MediaInfoItemContainer = styled.div`
  display: flex;
  column-gap: 4px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`

export const MediaInfoRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const MediaLikeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
`

export const RequestConsentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
`

export const RequestConsentDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`

export const RequestConsentHowContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

export const RequestConsentHowDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export const RequestConsentCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  row-gap: 10px;
  border: 1px solid #b2b6bb;
  background-color: #f0f2f5;
  border-radius: 6px;
  overflow: hidden;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`
