import { useCallback } from 'react'

import { resetError } from '@src/common/redux/errors'
// eslint-disable-next-line no-restricted-imports
import { message } from 'antd'
import { useDispatch } from 'react-redux'

interface Return {
  successToast: (text: string, key?: string) => void
  errorToast: (text: string, key?: string) => void
  warningToast: (text: string, key?: string) => void
  infoToast: (text: string, key?: string) => void
  loadingToast: (text: string, key?: string) => void
  destroyToast: (key: string) => void
}

export const useToast = (): Return => {
  const dispatch = useDispatch()

  const createConfig = useCallback(
    (text: string, key?: string) => ({
      content: text,
      key,
      duration: 4,
      className: 'fw-notification',
      onClose: () => {
        dispatch(resetError())
      }
    }),
    [dispatch]
  )

  const successToast = useCallback(
    (text: string, key?: string) => message.success(createConfig(text, key)),
    [createConfig]
  )

  const errorToast = useCallback(
    (text: string, key?: string) => message.error(createConfig(text, key)),
    [createConfig]
  )

  const warningToast = useCallback(
    (text: string, key?: string) => message.warning(createConfig(text, key)),
    [createConfig]
  )

  const infoToast = useCallback(
    (text: string, key?: string) => message.info(createConfig(text, key)),
    [createConfig]
  )

  const loadingToast = useCallback(
    (text: string, key?: string) => message.loading(createConfig(text, key)),
    [createConfig]
  )

  const destroyToast = useCallback((key: string) => message.destroy(key), [])

  return {
    successToast,
    errorToast,
    warningToast,
    infoToast,
    loadingToast,
    destroyToast
  }
}
