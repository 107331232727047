import { Dispatch, createAction, createSlice } from '@reduxjs/toolkit'

import api from '../common/utils/api'

type IProps = {
  countryStatesMap: Record<string, string[]> // country_name: state_name[]
}

const initialState: IProps = {
  countryStatesMap: {}
}

const slice = createSlice({
  name: 'geoLocation',
  initialState,
  reducers: {
    fetchCountryStatesSuccess(state, action) {
      const { countryName, states } = action.payload

      state.countryStatesMap[countryName] = states
    }
  }
})

export default slice.reducer
export const { fetchCountryStatesSuccess } = slice.actions

/**
 * Fetch Country States
 */
const fetchCountryStatesRequest = createAction(
  'geoLocation/fetchCountryStatesRequest'
)
const fetchCountryStatesFailure = createAction(
  'geoLocation/fetchCountryStatesFailure'
)

export function fetchCountryStates(countryName: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchCountryStatesRequest())
      const response = await api.get(
        `geolocation/countries/${countryName}/states`
      )
      const { states } = response.data
      dispatch(fetchCountryStatesSuccess({ countryName, states }))

      return response
    } catch (error) {
      dispatch(fetchCountryStatesFailure())

      return error
    }
  }
}
