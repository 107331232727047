import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router'

const ChannelList = loadable(() => import('../pages/Management/ChannelList'))
const CreateChannel = loadable(
  () => import('../pages/Management/ChannelCreate')
)

export const ManagementRoutes = (
  <>
    <Route
      path="/business/:businessId/channel/create"
      element={<CreateChannel />}
    />
    <Route path="/business/:businessId/channels" element={<ChannelList />} />
  </>
)
