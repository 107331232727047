import { createSlice, Draft, Slice, SliceCaseReducers } from '@reduxjs/toolkit'
import FlowManager from '@src/modules/channel/utils/Library/flowManager'
import { isScheduledVideo } from '@src/utils/video'

import {
  createFlow,
  createVideoInteraction,
  deleteFlow,
  deleteFlowInteraction,
  deleteFlowLink,
  deleteVideoInteraction,
  fetchChannelVideos,
  fetchFlow,
  fetchFlowInteractions,
  fetchFlows,
  fetchFlowVideos,
  patchChoiceOrder,
  patchFlow,
  patchFlowInteraction,
  patchFlowLink,
  postFlowInteraction,
  postFlowLink,
  updateFlowVideo,
  updateVideoInteraction
} from './flowsApi'

type FlowState = {
  flows: Journey.Flow[]
  flowsPaging: globalLib.Paging | null
  flowInteractions: Journey.FlowInteraction[]
  flowInteractionsPaging: globalLib.Paging | null
  flowLinks: Journey.FlowLink[]
  selectedInteraction: Journey.FlowInteraction | null
  activeVideoId: string | null
  videosById: { [key: string]: globalLib.IVideo }
  sortedVideoIds: string[]
  flowVideosPaging: globalLib.Paging | null
  loading: boolean
  error: string | null
  autoSaveIndicator: boolean
  channelVideos: globalLib.IVideo[]
  channelVideosPaging: globalLib.Paging | null
  loadingInteractionDetails: boolean
}

const SLICE_NAME = 'flows'

const initialState: FlowState = {
  flows: [],
  flowsPaging: null,
  flowInteractions: [],
  flowInteractionsPaging: null,
  flowLinks: [],
  selectedInteraction: null,
  activeVideoId: null,
  videosById: {},
  sortedVideoIds: [],
  flowVideosPaging: null,
  loading: false,
  error: null,
  autoSaveIndicator: false,
  channelVideos: [],
  channelVideosPaging: null,
  loadingInteractionDetails: false
}

type ISlice = {
  addInitialVideoToFlow: (
    state: FlowState,
    action: { payload: { video: globalLib.IVideo } }
  ) => void
  addVideoToFlow: (
    state: FlowState,
    action: { payload: { video: globalLib.IVideo } }
  ) => void
  removeVideoFromFlow: (
    state: FlowState,
    action: { payload: { video_id: string } }
  ) => void
  selectVideo: (
    state: FlowState,
    action: { payload: { video_id: string | null } }
  ) => void
  resetError: (state: FlowState) => void
  updateFlowLinkTargetVideoId: (
    state: FlowState,
    action: { payload: { targetVideoId: string } }
  ) => void
  resetVideosByIdState: (state: FlowState) => void
  resetFlowState: (state: FlowState) => void
  setSortedVideoIds: (
    state: FlowState,
    action: {
      payload: { sortedVideoIds: string[] }
    }
  ) => void
  setLoadingInteractionDetails: (
    state: FlowState,
    action: {
      payload: boolean
    }
  ) => void
}

const addFlowLinksToState = (
  state: Draft<FlowState>,
  flowLinks: Journey.FlowLink[]
) => {
  flowLinks.forEach((link) => {
    state.flowLinks.push(link)
  })
}

const flowsSlice: Slice<
  FlowState,
  SliceCaseReducers<FlowState> | ISlice,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addInitialVideoToFlow: (
      state: FlowState,
      action: {
        payload: { video: globalLib.IVideo }
      }
    ) => {
      const { video } = action.payload
      state.videosById = {}
      state.videosById[video.encoded_id] = video
      state.activeVideoId = video.encoded_id
    },
    addVideoToFlow: (
      state: FlowState,
      action: {
        payload: { video: globalLib.IVideo }
      }
    ) => {
      const { video } = action.payload
      state.videosById[video.encoded_id] = video
    },
    removeVideoFromFlow: (
      state: FlowState,
      action: {
        payload: { video_id: string }
      }
    ) => {
      const { video_id } = action.payload
      const videosByIdObj = Object.assign({}, state.videosById)
      // remove video from videosById object by video_id
      delete videosByIdObj[video_id]
      state.videosById = videosByIdObj
      state.sortedVideoIds = state.sortedVideoIds.filter(
        (id) => id !== video_id
      )
    },
    selectVideo(state: FlowState, action: { payload: { video_id: string } }) {
      state.activeVideoId = action.payload.video_id
      state.selectedInteraction =
        state.flowInteractions.find(
          (interaction) => interaction.video_id === action.payload.video_id
        ) || null
    },
    resetError(state: FlowState) {
      state.error = null
    },
    updateFlowLinkTargetVideoId(
      state: FlowState,
      action: { payload: { targetVideoId: string } }
    ) {
      const { targetVideoId } = action.payload
      // loop through flowLinksByTargetVideoId and update the target_video_id to null
      state.flowLinks = state.flowLinks.map((link) => {
        if (link.target_video_id === targetVideoId) {
          return {
            ...link,
            target_video_id: null,
            target_type: null
          }
        }

        return link
      })
    },
    resetVideosByIdState(state: FlowState) {
      state.videosById = {}
      state.sortedVideoIds = []
    },
    resetFlowState(state: FlowState) {
      state.flowInteractions = []
      state.flowLinks = []
      state.selectedInteraction = null
      state.sortedVideoIds = []
      state.videosById = {}
      state.activeVideoId = null
      state.error = null
    },
    setSortedVideoIds(
      state: FlowState,
      action: {
        payload: { sortedVideoIds: string[] }
      }
    ) {
      state.sortedVideoIds = action.payload.sortedVideoIds
    },
    setLoadingInteractionDetails(
      state: FlowState,
      action: {
        payload: boolean
      }
    ) {
      state.loadingInteractionDetails = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlows.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchFlows.fulfilled, (state, action) => {
        state.loading = false
        if (action.payload.page) {
          state.flows = [...state.flows, ...action.payload.flows]
        } else {
          state.flows = []
          state.flows = action.payload.flows
        }
        state.flowsPaging = action.payload.paging
      })
      .addCase(patchFlow.pending, (state) => {
        state.loading = true
      })
      .addCase(patchFlow.fulfilled, (state, action) => {
        state.loading = false
        const { flowId, name } = action.payload
        state.flows = state.flows.map((flow) => {
          if (flow.id === flowId) {
            return {
              ...flow,
              name
            }
          }

          return flow
        })
      })
      .addCase(fetchFlow.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchFlow.fulfilled, (state, action) => {
        state.loading = false
        const flow = action.payload

        // If flow doesn't exist in flows array, add it
        if (!state.flows.find((f) => f.id === flow.id)) {
          state.flows = [...state.flows, flow]
        }
      })
      .addCase(deleteFlow.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteFlow.fulfilled, (state, action) => {
        state.loading = false
        const { flowId } = action.payload
        state.flows = state.flows.filter((flow) => flow.id !== flowId)
      })
      .addCase(fetchChannelVideos.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchChannelVideos.fulfilled, (state, action) => {
        state.loading = false
        let videosArr: Array<globalLib.IVideo> = []
        if (action.payload.page) {
          videosArr = [...state.channelVideos, ...action.payload.videos].filter(
            (video) => !isScheduledVideo(video)
          )
        } else {
          videosArr = action.payload.videos.filter(
            (video) => !isScheduledVideo(video)
          )
        }

        //Filter out private videos
        //Filter out videos that are already in the flow
        //Filter out videos that have ad badge
        videosArr = videosArr.filter(
          (video: globalLib.IVideo) =>
            video.access !== 'private' &&
            video.badge !== 'ad' &&
            !state.videosById[video.encoded_id]
        )

        state.channelVideos = videosArr

        state.channelVideosPaging = action.payload.paging
      })
      .addCase(createFlow.fulfilled, (state, action) => {
        state.flows.push({
          id: action.payload.id,
          name: action.payload.name,
          start_video_id: action.payload.start_video_id,
          channel_id: action.payload.channel_id
        })
        state.loading = false
        state.flowInteractions = []
        state.selectedInteraction = null
      })
      .addCase(createFlow.rejected, (state, action) => {
        state.error = action?.error?.message ?? null
        state.loading = false
      })
      .addCase(createFlow.pending, (state) => {
        state.loading = true
      })
      .addCase(updateFlowVideo.pending, (state) => {
        state.loading = true
      })
      .addCase(updateFlowVideo.fulfilled, (state, action) => {
        const { videoId, data } = action.payload
        state.loading = false
        state.videosById[videoId] = {
          ...state.videosById[videoId],
          ...data
        }
      })
      .addCase(createVideoInteraction.pending, (state) => {
        state.loading = true
      })
      .addCase(createVideoInteraction.fulfilled, (state, action) => {
        const { videoId, data } = action.payload
        state.loading = false
        state.videosById[videoId].interactions = []
        state.videosById[videoId].interactions.push(data)
      })
      .addCase(updateVideoInteraction.fulfilled, (state, action) => {
        const { videoId, data } = action.payload
        state.loading = false
        state.videosById[videoId].interactions = []
        state.videosById[videoId].interactions.push(data)
      })
      .addCase(deleteVideoInteraction.fulfilled, (state, action) => {
        const { videoId, interactionId } = action.payload
        state.loading = false
        state.videosById[videoId].interactions = state.videosById[
          videoId
        ].interactions.filter((i) => i.id !== interactionId)
      })
      .addCase(fetchFlowInteractions.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchFlowInteractions.fulfilled, (state, action) => {
        state.loading = false
        const activeFlowInteractions = action.payload.flowInteractions.filter(
          (interaction) => interaction.deleted_at === null
        )
        if (action.payload.page) {
          state.flowInteractions = [
            ...state.flowInteractions,
            ...activeFlowInteractions
          ]
          activeFlowInteractions.forEach((interaction) =>
            addFlowLinksToState(state, interaction.flow_links)
          )
        } else {
          state.flowInteractions = activeFlowInteractions
          activeFlowInteractions.forEach((interaction) =>
            addFlowLinksToState(state, interaction.flow_links)
          )
        }
        state.flowInteractions = FlowManager.sortFlowInteractionsByInsertedAt(
          state.flowInteractions as Journey.FlowInteraction[]
        )
        state.flowInteractionsPaging = action.payload.paging
      })
      .addCase(postFlowInteraction.pending, (state) => {
        state.loading = true
      })
      .addCase(postFlowInteraction.rejected, (state) => {
        state.loading = false
        state.error = 'Error creating interaction'
      })
      .addCase(postFlowInteraction.fulfilled, (state, action) => {
        const {
          id,
          flow_id,
          shows_in,
          prompt_text,
          type,
          video_id,
          deleted_at,
          inserted_at
        } = action.payload
        state.loading = false
        const interaction = {
          id,
          flow_id,
          video_id,
          type,
          shows_in,
          prompt_text,
          deleted_at,
          inserted_at
        }
        state.flowInteractions.push(interaction)
        if (state.activeVideoId === video_id)
          state.selectedInteraction = interaction
      })
      .addCase(patchFlowInteraction.pending, (state) => {
        state.autoSaveIndicator = true
      })
      .addCase(patchFlowInteraction.fulfilled, (state, action) => {
        const { id, shows_in, prompt_text, type } = action.payload
        state.loading = false
        state.flowInteractions = state.flowInteractions.map((interaction) => {
          if (interaction.id === id) {
            return {
              ...interaction,
              type: type,
              shows_in: shows_in ?? interaction.shows_in,
              prompt_text: prompt_text ?? null
            }
          }

          return interaction
        })
        state.selectedInteraction =
          state.flowInteractions.find((interaction) => interaction.id === id) ??
          null
        state.autoSaveIndicator = false
      })
      .addCase(deleteFlowInteraction.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteFlowInteraction.fulfilled, (state, action) => {
        const { interactionId } = action.payload
        state.loading = false
        state.flowInteractions = state.flowInteractions.filter(
          (interaction) => interaction.id !== interactionId
        )
        state.flowLinks = state.flowLinks.filter(
          (link) => link.flow_interaction_id !== interactionId
        )
        state.selectedInteraction = null
      })
      .addCase(postFlowLink.pending, (state) => {
        state.autoSaveIndicator = true
      })
      .addCase(postFlowLink.fulfilled, (state, action) => {
        const flowLink = action.payload

        state.flowLinks.push(flowLink)
        state.autoSaveIndicator = false
      })
      .addCase(patchFlowLink.pending, (state) => {
        state.autoSaveIndicator = true
      })
      .addCase(patchFlowLink.fulfilled, (state, action) => {
        const { id, target_type, target_video_id, text } = action.payload
        state.loading = false
        state.flowLinks = state.flowLinks.map((flowLink) => {
          if (flowLink.id === id) {
            return {
              ...flowLink,
              target_type: target_type ?? flowLink.target_type,
              target_video_id: target_video_id ?? null,
              text: text ?? flowLink.text
            }
          }

          return flowLink
        })
        state.autoSaveIndicator = false
      })
      .addCase(deleteFlowLink.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteFlowLink.fulfilled, (state, action) => {
        const { flowLinkId } = action.payload
        state.loading = false
        const flowLinkData = state.flowLinks.find(
          (flowLink) => flowLink.id === flowLinkId
        )

        //Check if flow link has a target video id, check if that video has any flow interactions, if not, delete the video
        if (flowLinkData?.target_video_id) {
          const videoHasFlowInteractions = state.flowInteractions.some(
            (interaction) =>
              interaction.video_id === flowLinkData.target_video_id
          )
          if (!videoHasFlowInteractions) {
            delete state.videosById[flowLinkData.target_video_id]
          }
        }

        state.flowLinks = state.flowLinks.filter(
          (flowLink) => flowLink.id !== flowLinkId
        )
      })
      .addCase(fetchFlowVideos.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchFlowVideos.fulfilled, (state, action) => {
        state.loading = false
        const { videos, paging } = action.payload

        state.videosById = videos.reduce((acc, video: globalLib.IVideo) => {
          acc[video.encoded_id] = video

          return acc
        }, state.videosById)

        state.flowVideosPaging = paging
      })
      .addCase(patchChoiceOrder.fulfilled, (state, action) => {
        const flowLinks = action.payload
        flowLinks.forEach((flowLink) => {
          const index = state.flowLinks.findIndex(
            (link) => link.id === flowLink.id
          )
          state.flowLinks[index] = flowLink
        })
      })
  }
})

export default flowsSlice.reducer

export const {
  addVideoToFlow,
  removeVideoFromFlow,
  selectVideo,
  addInitialVideoToFlow,
  updateFlowLinkTargetVideoId,
  resetVideosByIdState,
  resetFlowState,
  setSortedVideoIds,
  setLoadingInteractionDetails
} = flowsSlice.actions
