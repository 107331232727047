import React from 'react'

import loadable from '@loadable/component'
import { LIVESTREAM_CREATE_METHOD, VIDEO_UPLOAD_METHOD } from '@src/constants'
import { Routes as AutomationRoutes } from '@src/modules/automation/routes'
import { AvaRoutes } from '@src/modules/ava/routes'
import { Routes as BusinessAppRoutes } from '@src/modules/business-app/routes'
import { Routes as ChannelRoutes } from '@src/modules/channel/routes'
import { Routes as OAuthAppRoutes } from '@src/modules/oauth-app/routes'
import { OneToOneRoutes } from '@src/modules/one-to-one/routes'
import { Routes as PricingPlanRoutes } from '@src/modules/pricing-plan/routes'
import { Routes as ProfileRoutes } from '@src/modules/profile/routes'
import { Routes as SettingRoutes } from '@src/modules/settings/routes'
import { Routes as ShowroomRoutes } from '@src/modules/showroom/routes'
import { Routes as StoreRoutes } from '@src/modules/store/routes'
import { Routes as SupportRoutes } from '@src/modules/support/routes'
import { Routes as WebhookRoutes } from '@src/modules/webhook/routes'
import SigmaVideoInsights from '@src/pages/channel/ChannelVideoUpload/SigmaVideoInsights'
import SigmaLivestreamInsights from '@src/pages/livestream/Analytics/SigmaLivestreamInsights'
import { ErrorBoundary } from 'react-error-boundary'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
} from 'react-router-dom'

import Root from '../layouts/Root'
import FallbackComponent from './FallbackComponent'

// Lazy load page components using @loadable/component
const Channel = loadable(() => import('../../pages/channel'))

const Integrations = loadable(() => import('../../pages/home/Integrations'), {
  resolveComponent: (component) => component.Integrations
})
const VideoPerformance = loadable(
  () => import('../../pages/insight/VideoPerformance')
)
const Invitation = loadable(() => import('../../pages/Invitation'))
const AssistantMode = loadable(
  () => import('../../pages/livestream/AssistantMode/AssistantMode'),
  {
    resolveComponent: (component) => component.AssistantMode
  }
)
const TabletAssistantMode = loadable(
  () =>
    import('../../pages/livestream/TabletAssistantMode/TabletAssistantMode'),
  {
    resolveComponent: (component) => component.TabletAssistantMode
  }
)
const CreateLiveStream = loadable(
  () => import('../../pages/livestream/CreateLiveStream')
)
const ViewLiveStream = loadable(
  () => import('../../pages/livestream/ViewLiveStream')
)
const ViewShowroomEvent = loadable(
  () => import('../../pages/showrooms/ViewShowroomEvent')
)

const ChannelVideoUpload = loadable(
  () => import('../../pages/channel/ChannelVideoUpload'),
  {
    resolveComponent: (component) => component.ChannelVideoUpload
  }
)

const Addons = loadable(() => import('../../pages/pricing/Addons'), {
  resolveComponent: (component) => component.Addons
})

const Embed = loadable(() => import('../../pages/embed'), {
  resolveComponent: (component) => component.EmbedPage
})

const WPRedirect = loadable(() => import('../../pages/home/WPRedirect'))
const Landing = loadable(() => import('../../pages/Landing'), {
  resolveComponent: (component) => component.Landing
})
const NotFound = loadable(() => import('../../pages/NotFound'))

const Guest = loadable(() => import('../../pages/home/Guest'))

export default function createRouter() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Root />
          </ErrorBoundary>
        }
      >
        <Route path="/overview" element={<Navigate to="/" />} />
        <Route
          path="/business/:businessId/overview"
          element={<Navigate to="/" />}
        />
        <Route path="/" element={<Landing />} />
        {OneToOneRoutes}
        {AvaRoutes}
        <Route
          path="/business/:businessId/channel/:channelId/video_performance/:videoId"
          element={<VideoPerformance />}
        />
        <Route
          path="/business/:businessId/channel/:channelId"
          element={<Channel />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/insights/:subtab"
          element={<Channel />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/:tab"
          element={<Channel />}
        />
        {ChannelRoutes}
        {StoreRoutes}
        <Route
          path="/business/:businessId/channel/:channelId/livestream/create"
          element={<CreateLiveStream />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId/livestream/create"
          element={
            <CreateLiveStream method={LIVESTREAM_CREATE_METHOD.FROM_VIDEO} />
          }
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId"
          element={<ViewLiveStream />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId/showroom/:livestreamId"
          element={<ViewShowroomEvent />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/edit"
          element={<CreateLiveStream />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/assistant"
          element={<AssistantMode />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/host_dashboard"
          element={<TabletAssistantMode />}
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/create"
          element={
            <ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.LOCAL_UPLOAD} />
          }
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId"
          element={
            <ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />
          }
        />
        <Route
          path="/business/:businessId/channel/:channelId/video/:videoId/edit"
          element={
            <ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />
          }
        />
        {OAuthAppRoutes}
        <Route
          path="/business/:businessId/subscription/packages/:packageId/addons"
          element={<Addons />}
        />
        {BusinessAppRoutes}
        {PricingPlanRoutes}
        {SettingRoutes}
        {ShowroomRoutes}
        {ProfileRoutes}
        <Route path="/invitation/:token" element={<Invitation />} />
        <Route path="/wp_callback" element={<WPRedirect />} />
        <Route path="/advertiser" element={<Navigate to="/" />} />
        <Route path="/creator" element={<Navigate to="/" />} />
        <Route path="/publisher" element={<Navigate to="/" />} />
        <Route path="/pricing" element={<Navigate to="/" />} />
        <Route path="/integration" element={<Integrations />} />
        <Route path="/layout_studio" element={<Navigate to="/" />} />
        <Route
          path="/app/business/:businessId/channel/:channelId/video/:videoId/insights"
          element={<SigmaVideoInsights />}
        />
        <Route
          path="/app/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/insights"
          element={<SigmaLivestreamInsights />}
        />
        {SupportRoutes}
        {WebhookRoutes}
        {AutomationRoutes}
        <Route path="/embed" element={<Embed />} />
        <Route path="/guest" element={<Guest />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    )
  )
}
