import { Node } from 'react-flow-renderer'

type Choice = {
  text: string | null
  id: string
}

type NodeData = {
  id: string
  type: string
  position: {
    x: number
    y: number
  }
  data: {
    step: number
    thumbnailUrl: string
    interactionType: globalLib.InteractionType | null
    prompt: string | null
    video: globalLib.IVideo
    choices: Choice[]
  }
}

class FlowManager {
  /**
   * It takes in a list of nodes, a list of videos, a list of flow interactions,
   * and a list of flow links, and returns a list of node data
   * @param {Node[]} nodes - The current nodes in the graph
   * @param {globalLib.IVideo[]} videos - globalLib.IVideo[]
   * @param {Journey.FlowInteraction[]} flowInteractions - This is an array of
   * objects that contain the interaction details for each video.
   * @param {Journey.FlowLink[]} flowLinks - Journey.FlowLink[]
   * @param sortedVideoIds
   * @returns An array of objects with the following properties:
   *   id: video?.encoded_id,
   *   type: 'customNode',
   *   position,
   *   data: {
   *     step: index + 1,
   *     thumbnailUrl: video?.thumbnail_url,
   *     interactionType: interactionDetailsByVideoId?.type,
   *     prompt: interactionDetailsBy
   */
  public createNodeDataByVideoId(
    nodes: Node[],
    videos: globalLib.IVideo[],
    flowInteractions: Journey.FlowInteraction[],
    flowLinks: Journey.FlowLink[],
    sortedVideoIds: string[]
  ): NodeData[] {
    return videos.map((video) => {
      const index = sortedVideoIds.indexOf(video.encoded_id) || 1
      const interactionDetailsByVideoId = this.getInteractionDetailsByVideoId(
        flowInteractions,
        video?.encoded_id
      )
      const choices = this.getChoicesFromFlowLinksByVideoId(
        flowInteractions,
        flowLinks,
        video?.encoded_id
      )

      let position = { x: 350 * (index + 1), y: 350 }
      /* It's checking if there are any nodes in the graph. If there are, it will set the position of the new node to be 350 pixels to the right of the last node. */
      if (nodes.length) {
        position = {
          x: nodes[nodes.length - 1].position.x + 350,
          y: nodes[nodes.length - 1].position.y
        }
      }

      return {
        id: video?.encoded_id,
        type: 'customNode',
        position,
        sourcePosition: 'right',
        targetPosition: 'left',
        data: {
          step: index + 1,
          thumbnailUrl: video?.thumbnail_url,
          interactionType: interactionDetailsByVideoId?.type ?? null,
          prompt: interactionDetailsByVideoId?.prompt_text ?? null,
          video,
          choices
        }
      }
    })
  }

  /**
   * > This function takes a node, flow interactions, flow links, and videos, and
   * returns a node with updated data
   * @param {Node} node - Node - the node we're updating
   * @param {Journey.FlowInteraction[]} flowInteractions - An array of all the
   * interactions in the flow
   * @param {Journey.FlowLink[]} flowLinks - Journey.FlowLink[]
   * @param videos - Record<string, globalLib.IVideo>
   * @param sortedVideoIds
   * @returns A new node with updated data.
   */
  public updateNodeDataByVideoId(
    node: Node,
    flowInteractions: Journey.FlowInteraction[],
    flowLinks: Journey.FlowLink[],
    videos: Record<string, globalLib.IVideo>,
    sortedVideoIds: string[]
  ): Node {
    const interactionDetails = this.getInteractionDetailsByVideoId(
      flowInteractions,
      videos[node.id]?.encoded_id
    )

    /* It's getting the choices for the current video. */
    const choices = this.getChoicesFromFlowLinksByVideoId(
      flowInteractions,
      flowLinks,
      videos[node.id]?.encoded_id
    )

    return {
      ...node,
      data: {
        ...node.data,
        step: sortedVideoIds.indexOf(node.id) + 1 || 1,
        interactionType: interactionDetails?.type,
        prompt: interactionDetails?.prompt_text,
        choices,
        video: videos[node.id]
      }
    }
  }

  /**
   * It takes in an array of FlowInteraction objects and an array of FlowLink
   * objects, and returns an array of objects that have the shape { id: string;
   * source: string; target: string }
   * @param {Journey.FlowInteraction[]} flowInteractions -
   * Journey.FlowInteraction[]
   * @param {Journey.FlowLink[]} flowLinks - Journey.FlowLink[]
   * @returns An array of objects with the following properties:
   *   id: string
   *   source: string
   *   target: string
   */
  public createEdgeDataFromFlowInteractionsAndLinks(
    flowInteractions: Journey.FlowInteraction[],
    flowLinks: Journey.FlowLink[]
  ): { id: string; source: string; target: string }[] {
    return flowLinks.reduce(
      (
        acc: {
          id: string
          source: string
          target: string
        }[],
        link
      ) => {
        const interactionData = flowInteractions.find(
          (interaction) => interaction.id === link.flow_interaction_id
        )

        /* Checking if the link has a target video id. If it does, it will create an edge data object. It will then check if the edge data already exists in the array. If it doesn't, it will push the edge data into the array. */
        if (link?.target_video_id) {
          const edgeData = {
            id: link.id,
            source: interactionData?.video_id ?? '',
            target: link?.target_video_id ?? '',
            type: 'smartEdge'
          }

          //Check if edge data already exists in the array
          const edgeDataExists = acc.find(
            (edge) =>
              edge.source === edgeData.source && edge.target === edgeData.target
          )

          if (
            !edgeDataExists &&
            edgeData.source.length &&
            edgeData.target.length
          ) {
            acc.push(edgeData)
          }
        }

        return acc
      },
      []
    )
  }

  /**
   * It takes an array of FlowInteraction objects and a videoId string, and returns
   * a FlowInteraction object if it finds a match, or undefined if it doesn't
   * @param {Journey.FlowInteraction[]} flowInteractions - This is the array of
   * objects that we are searching through.
   * @param {string} videoId - The id of the video you want to get the interaction
   * details for.
   * @returns The first element in the array that matches the condition.
   */
  public getInteractionDetailsByVideoId(
    flowInteractions: Journey.FlowInteraction[],
    videoId: string
  ): Journey.FlowInteraction | undefined {
    return flowInteractions.find(
      (interaction) => interaction.video_id === videoId
    )
  }

  /**
   * > Returns an array of FlowLinks that have the same flow_interaction_id as the
   * interactionId parameter
   * @param {Journey.FlowLink[]} flowLinks - Journey.FlowLink[]
   * @param {string} interactionId - The interaction id of the interaction you want
   * to get the links for.
   * @returns An array of FlowLinks that have the same flow_interaction_id as the
   * interactionId passed in.
   */
  public getFlowLinksByInteractionId(
    flowLinks: Journey.FlowLink[],
    interactionId: string
  ): Journey.FlowLink[] {
    return flowLinks.filter(
      (link) => link.flow_interaction_id === interactionId
    )
  }

  /**
   * It takes in an array of FlowInteractions, an array of FlowLinks, and a
   * videoId, and returns an array of Choices
   * @param {Journey.FlowInteraction[]} flowInteractions - an array of all the
   * flow interactions in the flow
   * @param {Journey.FlowLink[]} flowLinks - Journey.FlowLink[]
   * @param {string} videoId - the id of the video that the user is currently
   * watching
   * @returns An array of choices
   */
  public getChoicesFromFlowLinksByVideoId(
    flowInteractions: Journey.FlowInteraction[],
    flowLinks: Journey.FlowLink[],
    videoId: string
  ): Choice[] {
    const interactionData = this.getInteractionDetailsByVideoId(
      flowInteractions,
      videoId
    )

    // guard clause if there is no interaction
    if (!interactionData) {
      return []
    }

    const linksByInteractionId = this.getFlowLinksByInteractionId(
      flowLinks,
      interactionData.id
    )

    // guard clause if there are no links
    if (!linksByInteractionId.length) {
      return []
    }

    return linksByInteractionId.map((link) => ({
      text: link.text ?? null,
      id: link.id
    }))
  }

  /**
   * Validate check if video already has an interaction, or a product,
   * action on it. If it does, it will return false. Method will accept a
   * video object and check if video.products has a length, or if
   * video.interactions has a length, or video.action_type is not null.
   */
  public canVideoBeFlowStartVideo(video: globalLib.IVideo): boolean {
    return (
      video.products.length === 0 &&
      video.interactions.length === 0 &&
      video.action_type === null
    )
  }

  /**
   * It sorts an array of FlowInteraction objects by the inserted_at property
   * @param {Journey.FlowInteraction[]} flowInteractions -
   * Journey.FlowInteraction[]
   * @returns An array of flow interactions sorted by inserted_at
   */
  public sortFlowInteractionsByInsertedAt(
    flowInteractions: Journey.FlowInteraction[]
  ): Journey.FlowInteraction[] {
    const sortedFlowInteractions = flowInteractions.slice()
    // if there are no flow interactions, return an empty array
    if (!sortedFlowInteractions.length) {
      return []
    }

    // if there is only one flow interaction, return the array
    if (sortedFlowInteractions.length === 1) {
      return sortedFlowInteractions
    }

    // if there are more than one flow interaction, sort them by inserted_at
    return sortedFlowInteractions.sort((a, b) => {
      //if flow interactions have inserted_at, sort by that
      if (a.inserted_at && b.inserted_at) {
        const aDate = new Date(a.inserted_at)
        const bDate = new Date(b.inserted_at)

        return aDate.getTime() - bDate.getTime()
      }

      // if flow interactions don't have inserted_at, sort by id
      return a.id.localeCompare(b.id)
    })
  }

  /**
   * Use FlowInteractions and FlowLinks to sort the video object
   * */

  public sortVideosByFlowInteractions(
    videos: Record<string, globalLib.IVideo>,
    flowInteractions: Journey.FlowInteraction[],
    flowLinks: Journey.FlowLink[]
  ): string[] {
    const sortedFlowInteractions = this.sortFlowInteractionsByInsertedAt(
      flowInteractions
    )

    return sortedFlowInteractions.reduce(
      (acc: string[], interaction: Journey.FlowInteraction) => {
        const video = videos[interaction.video_id]

        if (video) {
          acc.push(interaction.video_id)
        }

        /* It's getting the links for the current interaction. If there are any
        links, it will loop through each link and get the target video. If the
        target video exists, it will push the target video id into the array. */
        const links = this.getFlowLinksByInteractionId(
          flowLinks,
          interaction.id
        )
        if (links.length) {
          links.forEach((link) => {
            const targetVideo = videos[link.target_video_id]

            if (targetVideo) {
              acc.push(link.target_video_id)
            }
          })
        }

        return [...new Set(acc)]
      },
      []
    )
  }

  /**
   * It takes in an array of FlowInteractions, and a videoId, and determines if
   * the video should be deleted. It will return true if the video should be
   * deleted, and false if it should not be deleted.
   * @param flowInteractions
   * @param targetVideoId
   */
  public shouldDeleteTargetVideo(
    flowInteractions: Journey.FlowInteraction[],
    targetVideoId: string
  ) {
    const interaction = this.getInteractionDetailsByVideoId(
      flowInteractions,
      targetVideoId
    )

    return !interaction
  }
}

export default new FlowManager()
