import styled from '@emotion/styled'

export const Container = styled.div<{
  width?: number | string
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  padding: 5px 15px;
  height: 32px;
  width: ${(props) =>
    props.width
      ? typeof props.width === 'number'
        ? `${props.width}px`
        : props.width
      : '100%'};
  cursor: pointer;

  &:hover {
    background-color: #e5f0ff;
  }
`
