import { setErrorMessage } from '@src/common/redux/errors'
import { customErrorHandler } from '@src/common/utils/error-handler'
import { sessionStorageProvider } from '@src/utils/storageProvider'
import axios from 'axios'
import { isFinite } from 'lodash'
import qs from 'query-string'

import store from '../../app/redux/store'
import cookies from './cookies'

window.localStorageFallback = {}
window.sessionStorageFallback = {}

// Data team raised issue about developers send tracking events to prod pixel server when testing/developing features
// Discussion thread: https://fireworkhq.slack.com/archives/C9VAHR143/p1651778433923249
// We add another environment "prod_staging_pixel" to use prod API (https://api.firework.com in urls)
// and staging pixel server (https://p2-staging.fwpixel.com) to test/develop in biz portal when
// we run "yarn start:prod_staging_pixel" script at local
// If other developers use that script with other API groups such as businessUrls, studioUrls,...
// please add "prod_staging_pixel" env to API groups that you are using
const urls = {
  production: 'https://api.firework.com',
  preview: 'https://api.firework-staging.com',
  staging: 'https://api.firework-staging.com',
  development: 'https://api.firework-dev.com',
  prod_staging_pixel: 'https://api.firework.com',
  localhost: 'http://localhost:4000'
}

const businessUrls = {
  production: 'https://business.firework.com',
  preview: 'https://business.firework-staging.com',
  staging: 'https://business.firework-staging.com',
  development: 'https://business.firework-dev.com',
  localhost: 'http://localhost:3000'
}

const pixelUrls = {
  production: 'https://p2.fwpixel.com',
  preview: 'https://p2-staging.fwpixel.com',
  staging: 'https://p2-staging.fwpixel.com',
  development: 'https://p2-staging.fwpixel.com',
  prod_staging_pixel: 'https://p2-staging.fwpixel.com'
}

const studioUrls = {
  production: 'https://studio.fw.tv',
  preview: 'https://studio-staging.fw.tv',
  staging: 'https://studio-staging.fw.tv',
  development: 'https://geonosis-git-dev-firework.vercel.app',
  localhost: 'http://localhost:5000'
}

const cdnUrls = {
  production: 'https://cdn4.fireworktv.com',
  staging: 'https://cdn4-staging.fireworktv.com'
}

const importerProxyUrl = {
  production: 'https://ig-importer.firework-prod.com',
  staging: 'https://ig-importer.firework-staging.com'
}

const helpCenterLinks = {
  production: 'https://help.firework.com',
  staging: 'https://help.firework.com'
}

export const API_URL = getAPIURL()

export const AUTH_URL = `${API_URL}/auth/business_auth/callback?redirect_to=${window.location.href}&client=business`

export const PIXEL_URL =
  pixelUrls[process.env.REACT_APP_ENV] || pixelUrls['staging']

export const GRAPHQL_URL = API_URL + '/fireworql'

export const STUDIO_URL =
  studioUrls[process.env.REACT_APP_ENV] ||
  (process.env.NODE_ENV === 'production'
    ? studioUrls['production']
    : studioUrls['localhost'])

export const BUSINESS_URL =
  businessUrls[process.env.REACT_APP_ENV] ||
  (process.env.NODE_ENV === 'production'
    ? businessUrls['production']
    : businessUrls['localhost'])

export const HELP_CENTER_URL =
  helpCenterLinks[process.env.REACT_APP_ENV] || helpCenterLinks['staging']

const instance = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export const CDN_URL = cdnUrls[process.env.REACT_APP_ENV] || cdnUrls['staging']

export const IMPORTER_PROXY_URL =
  importerProxyUrl[process.env.REACT_APP_ENV] || importerProxyUrl['staging']

export const selfErrorHandlerRequest = (config = {}) => {
  return {
    ...config,
    headers: {
      selfErrorHandler: true
    }
  }
}

instance.interceptors.request.use(
  (config) => {
    // Pagination urls returned from naboo include '/api' in the path which
    // interferes with our default baseURL
    // - ads team uses router with /sp/ suffix
    if (
      config.url.match('/api') ||
      config.url.match('/sp/') ||
      config.url.match(/^\/microsites\//) ||
      config.url.match('/webhooks_api/') ||
      config.url.match('/shopify/business_id') ||
      config.url.match('/shopify/embed_status')
    ) {
      // since api url now is https://api.firework.com/api
      // need to slice the last instance of /api in the base url
      return { ...config, baseURL: config.baseURL.slice(0, -4) }
    }

    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const dispatch = store.dispatch
    if (error.response) {
      const { response } = error
      const {
        status,
        config: { url }
      } = response

      // Skip setApiError messages
      if (status === 404 && url.match(/^\/microsites\//)) {
        return Promise.reject(error.response)
      }

      if (response.config.headers.selfErrorHandler) {
        return Promise.reject(error.response)
      }

      customErrorHandler(response)

      return Promise.reject(error.response)
    } else {
      if (error.config) {
        dispatch(setErrorMessage(error.message) as any)
      }

      return Promise.reject(error)
    }
  }
)

const api = {
  getToken: () => {
    return cookies.get('fwtoken') || sessionStorageProvider.getItem('fwtoken')
  },
  setToken: (token) => {
    const expireDate = new Date()
    expireDate.setDate(expireDate.getDate() + 30)
    sessionStorageProvider.setItem('fwtoken', token)
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`

    return cookies.set('fwtoken', token, expireDate)
  },
  removeToken: () => {
    instance.defaults.headers.common['Authorization'] = undefined
    sessionStorageProvider.removeItem('fwtoken')

    return cookies.remove('fwtoken')
  },
  get: instance.get,
  post: instance.post,
  patch: instance.patch,
  delete: instance.delete,
  put: instance.put,
  cancelToken: axios.CancelToken,
  mock: (url): Promise<any> =>
    new Promise((resolve) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const mocks = require('../../mocks')
      const mockData = Object.values(mocks).reduce((acc: any, value: any) => {
        return { ...acc, ...value }
      }, {})
      const mockResponseData = mockData[url]
      if (!mockResponseData) {
        // eslint-disable-next-line
        console.debug(`No mock found for ${url}`)
      }
      resolve({ data: mockResponseData })
    })
}

const token = api.getToken()
// Token was saved in cookies from previous session
if (token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const hashToken = qs.parse(window.location.hash).token
// The user was redirected to mandalore from naboo and should now be logged in
// with the token in the url hash params
if (hashToken) {
  api.setToken(hashToken)
}

/**
 * Validates a successful network request response.
 * @param {object} res Network request response
 * @return {boolean} True if network request was successful
 */
export const validateResponseSuccess = (res) => {
  if (res) {
    const { status } = res
    if (!isFinite(status)) throw Error('Invalid response object')

    return [200, 201, 204].includes(status)
  }

  return false
}

export const cancelToken = axios.CancelToken

/**
 * Validate a success Promise.allSettled response.
 * @param {object} res Promise.allSettled response
 */

export const validatePromiseAllSettled = (res) => {
  return res.status === 'fulfilled'
}

export function getAPIURL() {
  const sessionStorageAPIURL = sessionStorageProvider.getItem('apiURL')
  if (
    sessionStorageAPIURL &&
    process.env.REACT_APP_ENV !== 'production' &&
    !inProdWhiteListUrls(sessionStorageAPIURL)
  ) {
    // Override API URL with session storage value
    return sessionStorageAPIURL
  } else {
    return (
      urls[process.env.REACT_APP_ENV] ||
      (process.env.NODE_ENV === 'production'
        ? urls['production']
        : urls['localhost'])
    )
  }
}

function inProdWhiteListUrls(url) {
  const list = [
    'fireworktv.com',
    'api.fireworktv.com',
    'prod.fireworktv.com',
    'api.firework.com',
    'api.firework.tv',
    'fireworkapi.com',
    'fireworkapi1.com',
    'fireworkapis.com',
    'api.fw.tv',
    'albertsons.fireworktv.com',
    'globo.fireworktv.com',
    'fireworkadservices.com',
    'fireworkadservices1.com',
    'fireworkanalytics.com'
  ]

  return list.reduce((acc, value) => {
    if (!acc) {
      return (
        url.startsWith(value) ||
        url.startsWith(`http://${value}`) ||
        url.startsWith(`http://${value}/`) ||
        url.startsWith(`https://${value}`) ||
        url.startsWith(`https://${value}/`)
      )
    }

    return acc
  }, false)
}

export function getBasePath(businessId, channelId) {
  return `/bus/${businessId}/channels/${channelId}/`
}

export default api
