import { createAction, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { Dispatch } from 'redux'

type IProps = {
  idsByBusiness: Record<string, string[]>
  lookerSsoUrls: Record<string, string>
  oauthApps: Record<string, any>
  paging: Record<string, any>
}

const initialState: IProps = {
  idsByBusiness: {},
  lookerSsoUrls: {},
  oauthApps: {},
  paging: {}
}

const slice = createSlice({
  name: 'oauthApp',
  initialState: initialState,
  reducers: {
    fetchOAuthAppsSuccess(state, action) {
      const { apps, businessId, paging, page } = action.payload
      state.paging = paging
      if (page) {
        apps.forEach((app) => {
          state.idsByBusiness[businessId].push(app.id)
        })
      } else {
        state.idsByBusiness[businessId] = apps.map((app) => app.id)
      }
      apps.forEach((app) => {
        state.oauthApps[app.id] = app
      })
    },
    fetchOAuthAppSuccess(state, action) {
      const { oauthApp } = action.payload
      state.oauthApps[oauthApp.id] = oauthApp
    },
    fetchOAuthAppLookerSsoSuccess(state, action) {
      const { oauthAppId, url } = action.payload
      state.lookerSsoUrls[oauthAppId] = url
    }
  }
})
export default slice.reducer
export const {
  fetchOAuthAppsSuccess,
  fetchOAuthAppSuccess,
  fetchOAuthAppLookerSsoSuccess
} = slice.actions

const fetchOAuthAppsRequest = createAction('oauthApp/fetchOAuthAppsRequest')
const fetchOAuthAppsFailure = createAction('oauthApp/fetchOAuthAppsFailure')

export function fetchOAuthApps(businessId: string, page?: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchOAuthAppsRequest())
      const res = await api.get(page || `/bus/${businessId}/apps`)
      dispatch(fetchOAuthAppsSuccess({ ...res.data, businessId, page }))

      return res
    } catch (error) {
      dispatch(fetchOAuthAppsFailure())

      return error
    }
  }
}

const fetchOAuthAppRequest = createAction('oauthApp/fetchOAuthAppRequest')
const fetchOAuthAppFailure = createAction('oauthApp/fetchOAuthAppFailure')
export function fetchOAuthApp(businessId: string, oauthAppId: string) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchOAuthAppRequest())
      const response = await api.get(`/bus/${businessId}/apps/${oauthAppId}`)
      const oauthApp = response.data
      dispatch(fetchOAuthAppSuccess({ oauthApp }))

      return response
    } catch (error) {
      dispatch(fetchOAuthAppFailure())

      return error
    }
  }
}

const fetchOAuthAppLookerSsoRequest = createAction(
  'oauthApp/fetchOAuthAppLookerSsoRequest'
)
const fetchOAuthAppLookerSsoFailure = createAction(
  'oauthApp/fetchOAuthAppLookerSsoFailure'
)
export function fetchOAuthAppLookerSso(
  businessId: string,
  oauthAppId: string,
  dashboardId: string,
  params?: any
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchOAuthAppLookerSsoRequest())
      const res = await api.get(
        `/bus/${businessId}/apps/${oauthAppId}/looker_sso/${dashboardId}`,
        { params }
      )
      dispatch(fetchOAuthAppLookerSsoSuccess({ ...res.data, oauthAppId }))

      return res
    } catch (error) {
      dispatch(fetchOAuthAppLookerSsoFailure())

      return error
    }
  }
}
