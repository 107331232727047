import { createSlice } from '@reduxjs/toolkit'

type SearchState = {
  showSearch: boolean
  cmdBarIsOpen: boolean
}

const initialState: SearchState = {
  showSearch: false,
  cmdBarIsOpen: false
}

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateShowSearch(state, action) {
      state.showSearch = action.payload
    },
    setCmdBarIsOpen(state) {
      state.cmdBarIsOpen = true
    },
    setCmdBarIsClosed(state) {
      state.cmdBarIsOpen = false
    }
  }
})

export default slice.reducer

export const {
  updateShowSearch,
  setCmdBarIsOpen,
  setCmdBarIsClosed
} = slice.actions
