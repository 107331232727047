import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Showrooms = loadable(() => import('../pages/Showrooms'))
const CreateShowroom = loadable(() => import('../pages/CreateShowroom'))
const ShowroomDetails = loadable(() => import('../pages/ShowroomDetails'))

export const Routes = (
  <>
    <Route path="/business/:businessId/showrooms" element={<Showrooms />} />
    <Route
      path="/business/:businessId/showrooms/create"
      element={<CreateShowroom />}
    />
    <Route
      path="/business/:businessId/showrooms/:showroomId/edit"
      element={<CreateShowroom />}
    />
    <Route
      path="/business/:businessId/showrooms/:showroomId"
      element={<ShowroomDetails />}
    />
    <Route
      path="/business/:businessId/showrooms/:showroomId/:tab"
      element={<ShowroomDetails />}
    />
  </>
)
