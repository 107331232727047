import i18n from 'i18next'

/**
 * Formats values as US Dollars.
 * @param {string} value Number to format
 * @return {string} Formatted USD value
 */
export function formatCurrency(
  value: string | number,
  currencyIso = 'USD'
): string {
  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: currencyIso
  })
}

/**
 * Get currency symbol based on locale and currency ISO.
 * @param {string} curency ISO such as 'USD'
 * @return {string} Currency symbol
 */
export const getCurrencySymbol = (currency: string): string =>
  (0)
    .toLocaleString(i18n.language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim()
